// Generated by purs bundle 0.14.1
var PS = {};
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Alt"] = $PS["Control.Alt"] || {};
  var exports = $PS["Control.Alt"];                          
  var Alt = function (Functor0, alt) {
      this.Functor0 = Functor0;
      this.alt = alt;
  };                                                       
  var alt = function (dict) {
      return dict.alt;
  };
  exports["Alt"] = Alt;
  exports["alt"] = alt;
})(PS);
(function(exports) {
  "use strict";

  exports.arrayApply = function (fs) {
    return function (xs) {
      var l = fs.length;
      var k = xs.length;
      var result = new Array(l*k);
      var n = 0;
      for (var i = 0; i < l; i++) {
        var f = fs[i];
        for (var j = 0; j < k; j++) {
          result[n++] = f(xs[j]);
        }
      }
      return result;
    };
  };
})(PS["Control.Apply"] = PS["Control.Apply"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Semigroupoid"] = $PS["Control.Semigroupoid"] || {};
  var exports = $PS["Control.Semigroupoid"];
  var Semigroupoid = function (compose) {
      this.compose = compose;
  };
  var semigroupoidFn = new Semigroupoid(function (f) {
      return function (g) {
          return function (x) {
              return f(g(x));
          };
      };
  });
  var compose = function (dict) {
      return dict.compose;
  };
  var composeFlipped = function (dictSemigroupoid) {
      return function (f) {
          return function (g) {
              return compose(dictSemigroupoid)(g)(f);
          };
      };
  };
  exports["compose"] = compose;
  exports["composeFlipped"] = composeFlipped;
  exports["semigroupoidFn"] = semigroupoidFn;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Category"] = $PS["Control.Category"] || {};
  var exports = $PS["Control.Category"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];                
  var Category = function (Semigroupoid0, identity) {
      this.Semigroupoid0 = Semigroupoid0;
      this.identity = identity;
  };
  var identity = function (dict) {
      return dict.identity;
  };
  var categoryFn = new Category(function () {
      return Control_Semigroupoid.semigroupoidFn;
  }, function (x) {
      return x;
  });
  exports["identity"] = identity;
  exports["categoryFn"] = categoryFn;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Function"] = $PS["Data.Function"] || {};
  var exports = $PS["Data.Function"];
  var flip = function (f) {
      return function (b) {
          return function (a) {
              return f(a)(b);
          };
      };
  };
  var $$const = function (a) {
      return function (v) {
          return a;
      };
  };
  var applyFlipped = function (x) {
      return function (f) {
          return f(x);
      };
  };
  exports["flip"] = flip;
  exports["const"] = $$const;
  exports["applyFlipped"] = applyFlipped;
})(PS);
(function(exports) {
  "use strict";

  exports.arrayMap = function (f) {
    return function (arr) {
      var l = arr.length;
      var result = new Array(l);
      for (var i = 0; i < l; i++) {
        result[i] = f(arr[i]);
      }
      return result;
    };
  };
})(PS["Data.Functor"] = PS["Data.Functor"] || {});
(function(exports) {
  "use strict";

  exports.unit = {};
})(PS["Data.Unit"] = PS["Data.Unit"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Unit"] = $PS["Data.Unit"] || {};
  var exports = $PS["Data.Unit"];
  var $foreign = $PS["Data.Unit"];
  exports["unit"] = $foreign.unit;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Functor"] = $PS["Data.Functor"] || {};
  var exports = $PS["Data.Functor"];
  var $foreign = $PS["Data.Functor"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Function = $PS["Data.Function"];
  var Data_Unit = $PS["Data.Unit"];                  
  var Functor = function (map) {
      this.map = map;
  };
  var map = function (dict) {
      return dict.map;
  };
  var $$void = function (dictFunctor) {
      return map(dictFunctor)(Data_Function["const"](Data_Unit.unit));
  };
  var voidLeft = function (dictFunctor) {
      return function (f) {
          return function (x) {
              return map(dictFunctor)(Data_Function["const"](x))(f);
          };
      };
  };
  var voidRight = function (dictFunctor) {
      return function (x) {
          return map(dictFunctor)(Data_Function["const"](x));
      };
  }; 
  var functorFn = new Functor(Control_Semigroupoid.compose(Control_Semigroupoid.semigroupoidFn));
  var functorArray = new Functor($foreign.arrayMap);
  var flap = function (dictFunctor) {
      return function (ff) {
          return function (x) {
              return map(dictFunctor)(function (f) {
                  return f(x);
              })(ff);
          };
      };
  };
  exports["Functor"] = Functor;
  exports["map"] = map;
  exports["void"] = $$void;
  exports["voidRight"] = voidRight;
  exports["voidLeft"] = voidLeft;
  exports["flap"] = flap;
  exports["functorFn"] = functorFn;
  exports["functorArray"] = functorArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Apply"] = $PS["Control.Apply"] || {};
  var exports = $PS["Control.Apply"];
  var $foreign = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];            
  var Apply = function (Functor0, apply) {
      this.Functor0 = Functor0;
      this.apply = apply;
  }; 
  var applyFn = new Apply(function () {
      return Data_Functor.functorFn;
  }, function (f) {
      return function (g) {
          return function (x) {
              return f(x)(g(x));
          };
      };
  });
  var applyArray = new Apply(function () {
      return Data_Functor.functorArray;
  }, $foreign.arrayApply);
  var apply = function (dict) {
      return dict.apply;
  };
  var applyFirst = function (dictApply) {
      return function (a) {
          return function (b) {
              return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(Data_Function["const"])(a))(b);
          };
      };
  };
  var applySecond = function (dictApply) {
      return function (a) {
          return function (b) {
              return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(Data_Function["const"](Control_Category.identity(Control_Category.categoryFn)))(a))(b);
          };
      };
  };
  exports["Apply"] = Apply;
  exports["apply"] = apply;
  exports["applyFirst"] = applyFirst;
  exports["applySecond"] = applySecond;
  exports["applyFn"] = applyFn;
  exports["applyArray"] = applyArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Applicative"] = $PS["Control.Applicative"] || {};
  var exports = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Unit = $PS["Data.Unit"];                  
  var Applicative = function (Apply0, pure) {
      this.Apply0 = Apply0;
      this.pure = pure;
  };
  var pure = function (dict) {
      return dict.pure;
  };
  var unless = function (dictApplicative) {
      return function (v) {
          return function (v1) {
              if (!v) {
                  return v1;
              };
              if (v) {
                  return pure(dictApplicative)(Data_Unit.unit);
              };
              throw new Error("Failed pattern match at Control.Applicative (line 66, column 1 - line 66, column 65): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  };
  var when = function (dictApplicative) {
      return function (v) {
          return function (v1) {
              if (v) {
                  return v1;
              };
              if (!v) {
                  return pure(dictApplicative)(Data_Unit.unit);
              };
              throw new Error("Failed pattern match at Control.Applicative (line 61, column 1 - line 61, column 63): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  };
  var liftA1 = function (dictApplicative) {
      return function (f) {
          return function (a) {
              return Control_Apply.apply(dictApplicative.Apply0())(pure(dictApplicative)(f))(a);
          };
      };
  };
  exports["Applicative"] = Applicative;
  exports["pure"] = pure;
  exports["liftA1"] = liftA1;
  exports["unless"] = unless;
  exports["when"] = when;
})(PS);
(function(exports) {
  "use strict";

  exports.arrayBind = function (arr) {
    return function (f) {
      var result = [];
      for (var i = 0, l = arr.length; i < l; i++) {
        Array.prototype.push.apply(result, f(arr[i]));
      }
      return result;
    };
  };
})(PS["Control.Bind"] = PS["Control.Bind"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Bind"] = $PS["Control.Bind"] || {};
  var exports = $PS["Control.Bind"];
  var $foreign = $PS["Control.Bind"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Function = $PS["Data.Function"];          
  var Bind = function (Apply0, bind) {
      this.Apply0 = Apply0;
      this.bind = bind;
  };
  var Discard = function (discard) {
      this.discard = discard;
  };
  var discard = function (dict) {
      return dict.discard;
  }; 
  var bindArray = new Bind(function () {
      return Control_Apply.applyArray;
  }, $foreign.arrayBind);
  var bind = function (dict) {
      return dict.bind;
  };
  var bindFlipped = function (dictBind) {
      return Data_Function.flip(bind(dictBind));
  };
  var composeKleisliFlipped = function (dictBind) {
      return function (f) {
          return function (g) {
              return function (a) {
                  return bindFlipped(dictBind)(f)(g(a));
              };
          };
      };
  };
  var composeKleisli = function (dictBind) {
      return function (f) {
          return function (g) {
              return function (a) {
                  return bind(dictBind)(f(a))(g);
              };
          };
      };
  }; 
  var discardUnit = new Discard(function (dictBind) {
      return bind(dictBind);
  });
  var join = function (dictBind) {
      return function (m) {
          return bind(dictBind)(m)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  exports["Bind"] = Bind;
  exports["bind"] = bind;
  exports["bindFlipped"] = bindFlipped;
  exports["discard"] = discard;
  exports["join"] = join;
  exports["composeKleisli"] = composeKleisli;
  exports["composeKleisliFlipped"] = composeKleisliFlipped;
  exports["bindArray"] = bindArray;
  exports["discardUnit"] = discardUnit;
})(PS);
(function(exports) {
  "use strict";

  var refEq = function (r1) {
    return function (r2) {
      return r1 === r2;
    };
  };

  exports.eqBooleanImpl = refEq;
  exports.eqIntImpl = refEq;   
  exports.eqCharImpl = refEq;
  exports.eqStringImpl = refEq;

  exports.eqArrayImpl = function (f) {
    return function (xs) {
      return function (ys) {
        if (xs.length !== ys.length) return false;
        for (var i = 0; i < xs.length; i++) {
          if (!f(xs[i])(ys[i])) return false;
        }
        return true;
      };
    };
  };
})(PS["Data.Eq"] = PS["Data.Eq"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Symbol"] = $PS["Data.Symbol"] || {};
  var exports = $PS["Data.Symbol"];                  
  var SProxy = (function () {
      function SProxy() {

      };
      SProxy.value = new SProxy();
      return SProxy;
  })();
  var IsSymbol = function (reflectSymbol) {
      this.reflectSymbol = reflectSymbol;
  };
  var reflectSymbol = function (dict) {
      return dict.reflectSymbol;
  };
  exports["IsSymbol"] = IsSymbol;
  exports["reflectSymbol"] = reflectSymbol;
  exports["SProxy"] = SProxy;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafeHas = function (label) {
    return function (rec) {
      return {}.hasOwnProperty.call(rec, label);
    };
  };

  exports.unsafeGet = function (label) {
    return function (rec) {
      return rec[label];
    };
  };

  exports.unsafeSet = function (label) {
    return function (value) {
      return function (rec) {
        var copy = {};
        for (var key in rec) {
          if ({}.hasOwnProperty.call(rec, key)) {
            copy[key] = rec[key];
          }
        }
        copy[label] = value;
        return copy;
      };
    };
  };
})(PS["Record.Unsafe"] = PS["Record.Unsafe"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Record.Unsafe"] = $PS["Record.Unsafe"] || {};
  var exports = $PS["Record.Unsafe"];
  var $foreign = $PS["Record.Unsafe"];
  exports["unsafeHas"] = $foreign.unsafeHas;
  exports["unsafeGet"] = $foreign.unsafeGet;
  exports["unsafeSet"] = $foreign.unsafeSet;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Type.Proxy"] = $PS["Type.Proxy"] || {};
  var exports = $PS["Type.Proxy"];
  var $$Proxy = (function () {
      function $$Proxy() {

      };
      $$Proxy.value = new $$Proxy();
      return $$Proxy;
  })();
  exports["Proxy"] = $$Proxy;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Eq"] = $PS["Data.Eq"] || {};
  var exports = $PS["Data.Eq"];
  var $foreign = $PS["Data.Eq"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Record_Unsafe = $PS["Record.Unsafe"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var EqRecord = function (eqRecord) {
      this.eqRecord = eqRecord;
  };
  var Eq = function (eq) {
      this.eq = eq;
  }; 
  var eqUnit = new Eq(function (v) {
      return function (v1) {
          return true;
      };
  });
  var eqString = new Eq($foreign.eqStringImpl);
  var eqRowNil = new EqRecord(function (v) {
      return function (v1) {
          return function (v2) {
              return true;
          };
      };
  });
  var eqRecord = function (dict) {
      return dict.eqRecord;
  };
  var eqRec = function (dictRowToList) {
      return function (dictEqRecord) {
          return new Eq(eqRecord(dictEqRecord)(Type_Proxy["Proxy"].value));
      };
  };                                           
  var eqInt = new Eq($foreign.eqIntImpl);
  var eqChar = new Eq($foreign.eqCharImpl);
  var eqBoolean = new Eq($foreign.eqBooleanImpl);
  var eq = function (dict) {
      return dict.eq;
  };
  var eqArray = function (dictEq) {
      return new Eq($foreign.eqArrayImpl(eq(dictEq)));
  }; 
  var eqRowCons = function (dictEqRecord) {
      return function (dictCons) {
          return function (dictIsSymbol) {
              return function (dictEq) {
                  return new EqRecord(function (v) {
                      return function (ra) {
                          return function (rb) {
                              var tail = eqRecord(dictEqRecord)(Type_Proxy["Proxy"].value)(ra)(rb);
                              var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                              var get = Record_Unsafe.unsafeGet(key);
                              return eq(dictEq)(get(ra))(get(rb)) && tail;
                          };
                      };
                  });
              };
          };
      };
  };
  var notEq = function (dictEq) {
      return function (x) {
          return function (y) {
              return eq(eqBoolean)(eq(dictEq)(x)(y))(false);
          };
      };
  };
  exports["Eq"] = Eq;
  exports["eq"] = eq;
  exports["notEq"] = notEq;
  exports["eqInt"] = eqInt;
  exports["eqChar"] = eqChar;
  exports["eqString"] = eqString;
  exports["eqUnit"] = eqUnit;
  exports["eqArray"] = eqArray;
  exports["eqRec"] = eqRec;
  exports["eqRowNil"] = eqRowNil;
  exports["eqRowCons"] = eqRowCons;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Maybe"] = $PS["Data.Maybe"] || {};
  var exports = $PS["Data.Maybe"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];          
  var Nothing = (function () {
      function Nothing() {

      };
      Nothing.value = new Nothing();
      return Nothing;
  })();
  var Just = (function () {
      function Just(value0) {
          this.value0 = value0;
      };
      Just.create = function (value0) {
          return new Just(value0);
      };
      return Just;
  })();
  var maybe = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Nothing) {
                  return v;
              };
              if (v2 instanceof Just) {
                  return v1(v2.value0);
              };
              throw new Error("Failed pattern match at Data.Maybe (line 230, column 1 - line 230, column 51): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  };
  var isNothing = maybe(true)(Data_Function["const"](false));
  var isJust = maybe(false)(Data_Function["const"](true));
  var functorMaybe = new Data_Functor.Functor(function (v) {
      return function (v1) {
          if (v1 instanceof Just) {
              return new Just(v(v1.value0));
          };
          return Nothing.value;
      };
  });
  var fromMaybe = function (a) {
      return maybe(a)(Control_Category.identity(Control_Category.categoryFn));
  };
  var fromJust = function (dictPartial) {
      return function (v) {
          if (v instanceof Just) {
              return v.value0;
          };
          throw new Error("Failed pattern match at Data.Maybe (line 281, column 1 - line 281, column 46): " + [ v.constructor.name ]);
      };
  }; 
  var eqMaybe = function (dictEq) {
      return new Data_Eq.Eq(function (x) {
          return function (y) {
              if (x instanceof Nothing && y instanceof Nothing) {
                  return true;
              };
              if (x instanceof Just && y instanceof Just) {
                  return Data_Eq.eq(dictEq)(x.value0)(y.value0);
              };
              return false;
          };
      });
  };
  var applyMaybe = new Control_Apply.Apply(function () {
      return functorMaybe;
  }, function (v) {
      return function (v1) {
          if (v instanceof Just) {
              return Data_Functor.map(functorMaybe)(v.value0)(v1);
          };
          if (v instanceof Nothing) {
              return Nothing.value;
          };
          throw new Error("Failed pattern match at Data.Maybe (line 68, column 1 - line 70, column 30): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var bindMaybe = new Control_Bind.Bind(function () {
      return applyMaybe;
  }, function (v) {
      return function (v1) {
          if (v instanceof Just) {
              return v1(v.value0);
          };
          if (v instanceof Nothing) {
              return Nothing.value;
          };
          throw new Error("Failed pattern match at Data.Maybe (line 126, column 1 - line 128, column 28): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  exports["Nothing"] = Nothing;
  exports["Just"] = Just;
  exports["maybe"] = maybe;
  exports["fromMaybe"] = fromMaybe;
  exports["isJust"] = isJust;
  exports["isNothing"] = isNothing;
  exports["fromJust"] = fromJust;
  exports["functorMaybe"] = functorMaybe;
  exports["applyMaybe"] = applyMaybe;
  exports["bindMaybe"] = bindMaybe;
  exports["eqMaybe"] = eqMaybe;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Either"] = $PS["Data.Either"] || {};
  var exports = $PS["Data.Either"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];              
  var Left = (function () {
      function Left(value0) {
          this.value0 = value0;
      };
      Left.create = function (value0) {
          return new Left(value0);
      };
      return Left;
  })();
  var Right = (function () {
      function Right(value0) {
          this.value0 = value0;
      };
      Right.create = function (value0) {
          return new Right(value0);
      };
      return Right;
  })();
  var note = function (a) {
      return Data_Maybe.maybe(new Left(a))(Right.create);
  }; 
  var functorEither = new Data_Functor.Functor(function (f) {
      return function (m) {
          if (m instanceof Left) {
              return new Left(m.value0);
          };
          if (m instanceof Right) {
              return new Right(f(m.value0));
          };
          throw new Error("Failed pattern match at Data.Either (line 31, column 1 - line 31, column 52): " + [ m.constructor.name ]);
      };
  });
  var either = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Left) {
                  return v(v2.value0);
              };
              if (v2 instanceof Right) {
                  return v1(v2.value0);
              };
              throw new Error("Failed pattern match at Data.Either (line 208, column 1 - line 208, column 64): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  };
  var hush = either(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);
  var applyEither = new Control_Apply.Apply(function () {
      return functorEither;
  }, function (v) {
      return function (v1) {
          if (v instanceof Left) {
              return new Left(v.value0);
          };
          if (v instanceof Right) {
              return Data_Functor.map(functorEither)(v.value0)(v1);
          };
          throw new Error("Failed pattern match at Data.Either (line 70, column 1 - line 72, column 30): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var bindEither = new Control_Bind.Bind(function () {
      return applyEither;
  }, either(function (e) {
      return function (v) {
          return new Left(e);
      };
  })(function (a) {
      return function (f) {
          return f(a);
      };
  }));
  var applicativeEither = new Control_Applicative.Applicative(function () {
      return applyEither;
  }, Right.create);
  exports["Left"] = Left;
  exports["Right"] = Right;
  exports["either"] = either;
  exports["note"] = note;
  exports["hush"] = hush;
  exports["functorEither"] = functorEither;
  exports["applicativeEither"] = applicativeEither;
  exports["bindEither"] = bindEither;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Plus"] = $PS["Control.Plus"] || {};
  var exports = $PS["Control.Plus"];                     
  var Plus = function (Alt0, empty) {
      this.Alt0 = Alt0;
      this.empty = empty;
  };       
  var empty = function (dict) {
      return dict.empty;
  };
  exports["Plus"] = Plus;
  exports["empty"] = empty;
})(PS);
(function(exports) {
  "use strict";

  exports.foldrArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;
        for (var i = len - 1; i >= 0; i--) {
          acc = f(xs[i])(acc);
        }
        return acc;
      };
    };
  };

  exports.foldlArray = function (f) {
    return function (init) {
      return function (xs) {
        var acc = init;
        var len = xs.length;
        for (var i = 0; i < len; i++) {
          acc = f(acc)(xs[i]);
        }
        return acc;
      };
    };
  };
})(PS["Data.Foldable"] = PS["Data.Foldable"] || {});
(function(exports) {
  "use strict";

  exports.boolConj = function (b1) {
    return function (b2) {
      return b1 && b2;
    };
  };

  exports.boolDisj = function (b1) {
    return function (b2) {
      return b1 || b2;
    };
  };

  exports.boolNot = function (b) {
    return !b;
  };
})(PS["Data.HeytingAlgebra"] = PS["Data.HeytingAlgebra"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.HeytingAlgebra"] = $PS["Data.HeytingAlgebra"] || {};
  var exports = $PS["Data.HeytingAlgebra"];
  var $foreign = $PS["Data.HeytingAlgebra"];
  var HeytingAlgebra = function (conj, disj, ff, implies, not, tt) {
      this.conj = conj;
      this.disj = disj;
      this.ff = ff;
      this.implies = implies;
      this.not = not;
      this.tt = tt;
  };
  var tt = function (dict) {
      return dict.tt;
  };
  var not = function (dict) {
      return dict.not;
  };
  var implies = function (dict) {
      return dict.implies;
  };
  var ff = function (dict) {
      return dict.ff;
  };
  var disj = function (dict) {
      return dict.disj;
  };
  var heytingAlgebraBoolean = new HeytingAlgebra($foreign.boolConj, $foreign.boolDisj, false, function (a) {
      return function (b) {
          return disj(heytingAlgebraBoolean)(not(heytingAlgebraBoolean)(a))(b);
      };
  }, $foreign.boolNot, true);
  var conj = function (dict) {
      return dict.conj;
  };
  var heytingAlgebraFunction = function (dictHeytingAlgebra) {
      return new HeytingAlgebra(function (f) {
          return function (g) {
              return function (a) {
                  return conj(dictHeytingAlgebra)(f(a))(g(a));
              };
          };
      }, function (f) {
          return function (g) {
              return function (a) {
                  return disj(dictHeytingAlgebra)(f(a))(g(a));
              };
          };
      }, function (v) {
          return ff(dictHeytingAlgebra);
      }, function (f) {
          return function (g) {
              return function (a) {
                  return implies(dictHeytingAlgebra)(f(a))(g(a));
              };
          };
      }, function (f) {
          return function (a) {
              return not(dictHeytingAlgebra)(f(a));
          };
      }, function (v) {
          return tt(dictHeytingAlgebra);
      });
  };
  exports["tt"] = tt;
  exports["ff"] = ff;
  exports["disj"] = disj;
  exports["not"] = not;
  exports["heytingAlgebraBoolean"] = heytingAlgebraBoolean;
  exports["heytingAlgebraFunction"] = heytingAlgebraFunction;
})(PS);
(function(exports) {
  "use strict";

  exports.concatString = function (s1) {
    return function (s2) {
      return s1 + s2;
    };
  };

  exports.concatArray = function (xs) {
    return function (ys) {
      if (xs.length === 0) return ys;
      if (ys.length === 0) return xs;
      return xs.concat(ys);
    };
  };
})(PS["Data.Semigroup"] = PS["Data.Semigroup"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Semigroup"] = $PS["Data.Semigroup"] || {};
  var exports = $PS["Data.Semigroup"];
  var $foreign = $PS["Data.Semigroup"];
  var Semigroup = function (append) {
      this.append = append;
  }; 
  var semigroupString = new Semigroup($foreign.concatString);
  var semigroupArray = new Semigroup($foreign.concatArray);
  var append = function (dict) {
      return dict.append;
  };
  var semigroupFn = function (dictSemigroup) {
      return new Semigroup(function (f) {
          return function (g) {
              return function (x) {
                  return append(dictSemigroup)(f(x))(g(x));
              };
          };
      });
  };
  exports["Semigroup"] = Semigroup;
  exports["append"] = append;
  exports["semigroupString"] = semigroupString;
  exports["semigroupFn"] = semigroupFn;
  exports["semigroupArray"] = semigroupArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Monoid"] = $PS["Data.Monoid"] || {};
  var exports = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Monoid = function (Semigroup0, mempty) {
      this.Semigroup0 = Semigroup0;
      this.mempty = mempty;
  };                 
  var monoidString = new Monoid(function () {
      return Data_Semigroup.semigroupString;
  }, "");
  var mempty = function (dict) {
      return dict.mempty;
  };
  var monoidFn = function (dictMonoid) {
      return new Monoid(function () {
          return Data_Semigroup.semigroupFn(dictMonoid.Semigroup0());
      }, function (v) {
          return mempty(dictMonoid);
      });
  };
  exports["Monoid"] = Monoid;
  exports["mempty"] = mempty;
  exports["monoidFn"] = monoidFn;
  exports["monoidString"] = monoidString;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Monoid.Disj"] = $PS["Data.Monoid.Disj"] || {};
  var exports = $PS["Data.Monoid.Disj"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];      
  var Disj = function (x) {
      return x;
  };
  var semigroupDisj = function (dictHeytingAlgebra) {
      return new Data_Semigroup.Semigroup(function (v) {
          return function (v1) {
              return Data_HeytingAlgebra.disj(dictHeytingAlgebra)(v)(v1);
          };
      });
  };
  var monoidDisj = function (dictHeytingAlgebra) {
      return new Data_Monoid.Monoid(function () {
          return semigroupDisj(dictHeytingAlgebra);
      }, Data_HeytingAlgebra.ff(dictHeytingAlgebra));
  };
  exports["Disj"] = Disj;
  exports["monoidDisj"] = monoidDisj;
})(PS);
(function(exports) {
  "use strict";

  // module Unsafe.Coerce

  exports.unsafeCoerce = function (x) {
    return x;
  };
})(PS["Unsafe.Coerce"] = PS["Unsafe.Coerce"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Unsafe.Coerce"] = $PS["Unsafe.Coerce"] || {};
  var exports = $PS["Unsafe.Coerce"];
  var $foreign = $PS["Unsafe.Coerce"];
  exports["unsafeCoerce"] = $foreign.unsafeCoerce;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Safe.Coerce"] = $PS["Safe.Coerce"] || {};
  var exports = $PS["Safe.Coerce"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var coerce = function (dictCoercible) {
      return Unsafe_Coerce.unsafeCoerce;
  };
  exports["coerce"] = coerce;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Newtype"] = $PS["Data.Newtype"] || {};
  var exports = $PS["Data.Newtype"];
  var Safe_Coerce = $PS["Safe.Coerce"];
  var wrap = function (dictNewtype) {
      return Safe_Coerce.coerce();
  };
  var unwrap = function (dictNewtype) {
      return Safe_Coerce.coerce();
  };
  var under = function (dictNewtype) {
      return function (dictNewtype1) {
          return function (v) {
              return Safe_Coerce.coerce();
          };
      };
  };
  var over = function (dictNewtype) {
      return function (dictNewtype1) {
          return function (v) {
              return Safe_Coerce.coerce();
          };
      };
  };
  var alaF = function (dictCoercible) {
      return function (dictCoercible1) {
          return function (dictNewtype) {
              return function (dictNewtype1) {
                  return function (v) {
                      return Safe_Coerce.coerce();
                  };
              };
          };
      };
  };
  exports["wrap"] = wrap;
  exports["unwrap"] = unwrap;
  exports["alaF"] = alaF;
  exports["over"] = over;
  exports["under"] = under;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Foldable"] = $PS["Data.Foldable"] || {};
  var exports = $PS["Data.Foldable"];
  var $foreign = $PS["Data.Foldable"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Monoid_Disj = $PS["Data.Monoid.Disj"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Unit = $PS["Data.Unit"];                
  var Foldable = function (foldMap, foldl, foldr) {
      this.foldMap = foldMap;
      this.foldl = foldl;
      this.foldr = foldr;
  };
  var foldr = function (dict) {
      return dict.foldr;
  };
  var traverse_ = function (dictApplicative) {
      return function (dictFoldable) {
          return function (f) {
              return foldr(dictFoldable)((function () {
                  var $311 = Control_Apply.applySecond(dictApplicative.Apply0());
                  return function ($312) {
                      return $311(f($312));
                  };
              })())(Control_Applicative.pure(dictApplicative)(Data_Unit.unit));
          };
      };
  };
  var for_ = function (dictApplicative) {
      return function (dictFoldable) {
          return Data_Function.flip(traverse_(dictApplicative)(dictFoldable));
      };
  };
  var foldl = function (dict) {
      return dict.foldl;
  };
  var indexl = function (dictFoldable) {
      return function (idx) {
          var go = function (cursor) {
              return function (a) {
                  if (cursor.elem instanceof Data_Maybe.Just) {
                      return cursor;
                  };
                  var $153 = cursor.pos === idx;
                  if ($153) {
                      return {
                          elem: new Data_Maybe.Just(a),
                          pos: cursor.pos
                      };
                  };
                  return {
                      pos: cursor.pos + 1 | 0,
                      elem: cursor.elem
                  };
              };
          };
          var $313 = foldl(dictFoldable)(go)({
              elem: Data_Maybe.Nothing.value,
              pos: 0
          });
          return function ($314) {
              return (function (v) {
                  return v.elem;
              })($313($314));
          };
      };
  };
  var intercalate = function (dictFoldable) {
      return function (dictMonoid) {
          return function (sep) {
              return function (xs) {
                  var go = function (v) {
                      return function (x) {
                          if (v.init) {
                              return {
                                  init: false,
                                  acc: x
                              };
                          };
                          return {
                              init: false,
                              acc: Data_Semigroup.append(dictMonoid.Semigroup0())(v.acc)(Data_Semigroup.append(dictMonoid.Semigroup0())(sep)(x))
                          };
                      };
                  };
                  return (foldl(dictFoldable)(go)({
                      init: true,
                      acc: Data_Monoid.mempty(dictMonoid)
                  })(xs)).acc;
              };
          };
      };
  }; 
  var foldableMaybe = new Foldable(function (dictMonoid) {
      return function (f) {
          return function (v) {
              if (v instanceof Data_Maybe.Nothing) {
                  return Data_Monoid.mempty(dictMonoid);
              };
              if (v instanceof Data_Maybe.Just) {
                  return f(v.value0);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 138, column 1 - line 144, column 27): " + [ f.constructor.name, v.constructor.name ]);
          };
      };
  }, function (v) {
      return function (z) {
          return function (v1) {
              if (v1 instanceof Data_Maybe.Nothing) {
                  return z;
              };
              if (v1 instanceof Data_Maybe.Just) {
                  return v(z)(v1.value0);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 138, column 1 - line 144, column 27): " + [ v.constructor.name, z.constructor.name, v1.constructor.name ]);
          };
      };
  }, function (v) {
      return function (z) {
          return function (v1) {
              if (v1 instanceof Data_Maybe.Nothing) {
                  return z;
              };
              if (v1 instanceof Data_Maybe.Just) {
                  return v(v1.value0)(z);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 138, column 1 - line 144, column 27): " + [ v.constructor.name, z.constructor.name, v1.constructor.name ]);
          };
      };
  });
  var foldableEither = new Foldable(function (dictMonoid) {
      return function (f) {
          return function (v) {
              if (v instanceof Data_Either.Left) {
                  return Data_Monoid.mempty(dictMonoid);
              };
              if (v instanceof Data_Either.Right) {
                  return f(v.value0);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 181, column 1 - line 187, column 28): " + [ f.constructor.name, v.constructor.name ]);
          };
      };
  }, function (v) {
      return function (z) {
          return function (v1) {
              if (v1 instanceof Data_Either.Left) {
                  return z;
              };
              if (v1 instanceof Data_Either.Right) {
                  return v(z)(v1.value0);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 181, column 1 - line 187, column 28): " + [ v.constructor.name, z.constructor.name, v1.constructor.name ]);
          };
      };
  }, function (v) {
      return function (z) {
          return function (v1) {
              if (v1 instanceof Data_Either.Left) {
                  return z;
              };
              if (v1 instanceof Data_Either.Right) {
                  return v(v1.value0)(z);
              };
              throw new Error("Failed pattern match at Data.Foldable (line 181, column 1 - line 187, column 28): " + [ v.constructor.name, z.constructor.name, v1.constructor.name ]);
          };
      };
  });
  var foldMapDefaultR = function (dictFoldable) {
      return function (dictMonoid) {
          return function (f) {
              return foldr(dictFoldable)(function (x) {
                  return function (acc) {
                      return Data_Semigroup.append(dictMonoid.Semigroup0())(f(x))(acc);
                  };
              })(Data_Monoid.mempty(dictMonoid));
          };
      };
  };
  var foldableArray = new Foldable(function (dictMonoid) {
      return foldMapDefaultR(foldableArray)(dictMonoid);
  }, $foreign.foldlArray, $foreign.foldrArray);
  var foldMap = function (dict) {
      return dict.foldMap;
  };
  var any = function (dictFoldable) {
      return function (dictHeytingAlgebra) {
          return Data_Newtype.alaF()()()()(Data_Monoid_Disj.Disj)(foldMap(dictFoldable)(Data_Monoid_Disj.monoidDisj(dictHeytingAlgebra)));
      };
  };
  var elem = function (dictFoldable) {
      return function (dictEq) {
          var $321 = any(dictFoldable)(Data_HeytingAlgebra.heytingAlgebraBoolean);
          var $322 = Data_Eq.eq(dictEq);
          return function ($323) {
              return $321($322($323));
          };
      };
  };
  exports["Foldable"] = Foldable;
  exports["foldr"] = foldr;
  exports["foldl"] = foldl;
  exports["foldMap"] = foldMap;
  exports["traverse_"] = traverse_;
  exports["for_"] = for_;
  exports["intercalate"] = intercalate;
  exports["indexl"] = indexl;
  exports["foldableArray"] = foldableArray;
  exports["foldableMaybe"] = foldableMaybe;
  exports["foldableEither"] = foldableEither;
})(PS);
(function(exports) {
  "use strict";

  exports.showIntImpl = function (n) {
    return n.toString();
  };

  exports.showStringImpl = function (s) {
    var l = s.length;
    return "\"" + s.replace(
      /[\0-\x1F\x7F"\\]/g, // eslint-disable-line no-control-regex
      function (c, i) {
        switch (c) {
          case "\"":
          case "\\":
            return "\\" + c;
          case "\x07": return "\\a";
          case "\b": return "\\b";
          case "\f": return "\\f";
          case "\n": return "\\n";
          case "\r": return "\\r";
          case "\t": return "\\t";
          case "\v": return "\\v";
        }
        var k = i + 1;
        var empty = k < l && s[k] >= "0" && s[k] <= "9" ? "\\&" : "";
        return "\\" + c.charCodeAt(0).toString(10) + empty;
      }
    ) + "\"";
  };
})(PS["Data.Show"] = PS["Data.Show"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Show"] = $PS["Data.Show"] || {};
  var exports = $PS["Data.Show"];
  var $foreign = $PS["Data.Show"];
  var Show = function (show) {
      this.show = show;
  };
  var showString = new Show($foreign.showStringImpl);
  var showInt = new Show($foreign.showIntImpl);
  var show = function (dict) {
      return dict.show;
  };
  exports["Show"] = Show;
  exports["show"] = show;
  exports["showInt"] = showInt;
  exports["showString"] = showString;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.NonEmpty"] = $PS["Data.NonEmpty"] || {};
  var exports = $PS["Data.NonEmpty"];
  var Control_Plus = $PS["Control.Plus"];
  var Data_Show = $PS["Data.Show"];                              
  var NonEmpty = (function () {
      function NonEmpty(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      NonEmpty.create = function (value0) {
          return function (value1) {
              return new NonEmpty(value0, value1);
          };
      };
      return NonEmpty;
  })();
  var singleton = function (dictPlus) {
      return function (a) {
          return new NonEmpty(a, Control_Plus.empty(dictPlus));
      };
  };
  var showNonEmpty = function (dictShow) {
      return function (dictShow1) {
          return new Data_Show.Show(function (v) {
              return "(NonEmpty " + (Data_Show.show(dictShow)(v.value0) + (" " + (Data_Show.show(dictShow1)(v.value1) + ")")));
          });
      };
  };
  exports["NonEmpty"] = NonEmpty;
  exports["singleton"] = singleton;
  exports["showNonEmpty"] = showNonEmpty;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.List.Types"] = $PS["Data.List.Types"] || {};
  var exports = $PS["Data.List.Types"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Plus = $PS["Control.Plus"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_NonEmpty = $PS["Data.NonEmpty"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Show = $PS["Data.Show"];                              
  var Nil = (function () {
      function Nil() {

      };
      Nil.value = new Nil();
      return Nil;
  })();
  var Cons = (function () {
      function Cons(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Cons.create = function (value0) {
          return function (value1) {
              return new Cons(value0, value1);
          };
      };
      return Cons;
  })();
  var NonEmptyList = function (x) {
      return x;
  };
  var toList = function (v) {
      return new Cons(v.value0, v.value1);
  };
  var listMap = function (f) {
      var chunkedRevMap = function ($copy_chunksAcc) {
          return function ($copy_v) {
              var $tco_var_chunksAcc = $copy_chunksAcc;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(chunksAcc, v) {
                  if (v instanceof Cons && (v.value1 instanceof Cons && v.value1.value1 instanceof Cons)) {
                      $tco_var_chunksAcc = new Cons(v, chunksAcc);
                      $copy_v = v.value1.value1.value1;
                      return;
                  };
                  var unrolledMap = function (v1) {
                      if (v1 instanceof Cons && (v1.value1 instanceof Cons && v1.value1.value1 instanceof Nil)) {
                          return new Cons(f(v1.value0), new Cons(f(v1.value1.value0), Nil.value));
                      };
                      if (v1 instanceof Cons && v1.value1 instanceof Nil) {
                          return new Cons(f(v1.value0), Nil.value);
                      };
                      return Nil.value;
                  };
                  var reverseUnrolledMap = function ($copy_v1) {
                      return function ($copy_acc) {
                          var $tco_var_v1 = $copy_v1;
                          var $tco_done1 = false;
                          var $tco_result;
                          function $tco_loop(v1, acc) {
                              if (v1 instanceof Cons && (v1.value0 instanceof Cons && (v1.value0.value1 instanceof Cons && v1.value0.value1.value1 instanceof Cons))) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_acc = new Cons(f(v1.value0.value0), new Cons(f(v1.value0.value1.value0), new Cons(f(v1.value0.value1.value1.value0), acc)));
                                  return;
                              };
                              $tco_done1 = true;
                              return acc;
                          };
                          while (!$tco_done1) {
                              $tco_result = $tco_loop($tco_var_v1, $copy_acc);
                          };
                          return $tco_result;
                      };
                  };
                  $tco_done = true;
                  return reverseUnrolledMap(chunksAcc)(unrolledMap(v));
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_chunksAcc, $copy_v);
              };
              return $tco_result;
          };
      };
      return chunkedRevMap(Nil.value);
  };
  var functorList = new Data_Functor.Functor(listMap);                 
  var foldableList = new Data_Foldable.Foldable(function (dictMonoid) {
      return function (f) {
          return Data_Foldable.foldl(foldableList)(function (acc) {
              var $204 = Data_Semigroup.append(dictMonoid.Semigroup0())(acc);
              return function ($205) {
                  return $204(f($205));
              };
          })(Data_Monoid.mempty(dictMonoid));
      };
  }, function (f) {
      var go = function ($copy_b) {
          return function ($copy_v) {
              var $tco_var_b = $copy_b;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(b, v) {
                  if (v instanceof Nil) {
                      $tco_done = true;
                      return b;
                  };
                  if (v instanceof Cons) {
                      $tco_var_b = f(b)(v.value0);
                      $copy_v = v.value1;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.List.Types (line 112, column 12 - line 114, column 30): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_b, $copy_v);
              };
              return $tco_result;
          };
      };
      return go;
  }, function (f) {
      return function (b) {
          var rev = (function () {
              var go = function ($copy_acc) {
                  return function ($copy_v) {
                      var $tco_var_acc = $copy_acc;
                      var $tco_done1 = false;
                      var $tco_result;
                      function $tco_loop(acc, v) {
                          if (v instanceof Nil) {
                              $tco_done1 = true;
                              return acc;
                          };
                          if (v instanceof Cons) {
                              $tco_var_acc = new Cons(v.value0, acc);
                              $copy_v = v.value1;
                              return;
                          };
                          throw new Error("Failed pattern match at Data.List.Types (line 108, column 7 - line 108, column 23): " + [ acc.constructor.name, v.constructor.name ]);
                      };
                      while (!$tco_done1) {
                          $tco_result = $tco_loop($tco_var_acc, $copy_v);
                      };
                      return $tco_result;
                  };
              };
              return go(Nil.value);
          })();
          var $206 = Data_Foldable.foldl(foldableList)(Data_Function.flip(f))(b);
          return function ($207) {
              return $206(rev($207));
          };
      };
  });
  var semigroupList = new Data_Semigroup.Semigroup(function (xs) {
      return function (ys) {
          return Data_Foldable.foldr(foldableList)(Cons.create)(ys)(xs);
      };
  });           
  var semigroupNonEmptyList = new Data_Semigroup.Semigroup(function (v) {
      return function (as$prime) {
          return new Data_NonEmpty.NonEmpty(v.value0, Data_Semigroup.append(semigroupList)(v.value1)(toList(as$prime)));
      };
  });
  var showList = function (dictShow) {
      return new Data_Show.Show(function (v) {
          if (v instanceof Nil) {
              return "Nil";
          };
          return "(" + (Data_Foldable.intercalate(foldableList)(Data_Monoid.monoidString)(" : ")(Data_Functor.map(functorList)(Data_Show.show(dictShow))(v)) + " : Nil)");
      });
  };
  var showNonEmptyList = function (dictShow) {
      return new Data_Show.Show(function (v) {
          return "(NonEmptyList " + (Data_Show.show(Data_NonEmpty.showNonEmpty(dictShow)(showList(dictShow)))(v) + ")");
      });
  }; 
  var applyList = new Control_Apply.Apply(function () {
      return functorList;
  }, function (v) {
      return function (v1) {
          if (v instanceof Nil) {
              return Nil.value;
          };
          if (v instanceof Cons) {
              return Data_Semigroup.append(semigroupList)(Data_Functor.map(functorList)(v.value0)(v1))(Control_Apply.apply(applyList)(v.value1)(v1));
          };
          throw new Error("Failed pattern match at Data.List.Types (line 158, column 1 - line 160, column 48): " + [ v.constructor.name, v1.constructor.name ]);
      };
  });
  var applicativeList = new Control_Applicative.Applicative(function () {
      return applyList;
  }, function (a) {
      return new Cons(a, Nil.value);
  });                                              
  var altList = new Control_Alt.Alt(function () {
      return functorList;
  }, Data_Semigroup.append(semigroupList));
  var plusList = new Control_Plus.Plus(function () {
      return altList;
  }, Nil.value);
  exports["Nil"] = Nil;
  exports["Cons"] = Cons;
  exports["NonEmptyList"] = NonEmptyList;
  exports["semigroupList"] = semigroupList;
  exports["foldableList"] = foldableList;
  exports["applicativeList"] = applicativeList;
  exports["plusList"] = plusList;
  exports["showNonEmptyList"] = showNonEmptyList;
  exports["semigroupNonEmptyList"] = semigroupNonEmptyList;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.List.NonEmpty"] = $PS["Data.List.NonEmpty"] || {};
  var exports = $PS["Data.List.NonEmpty"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_NonEmpty = $PS["Data.NonEmpty"];
  var singleton = (function () {
      var $172 = Data_NonEmpty.singleton(Data_List_Types.plusList);
      return function ($173) {
          return Data_List_Types.NonEmptyList($172($173));
      };
  })();
  var cons = function (y) {
      return function (v) {
          return new Data_NonEmpty.NonEmpty(y, new Data_List_Types.Cons(v.value0, v.value1));
      };
  };
  exports["singleton"] = singleton;
  exports["cons"] = cons;
})(PS);
(function(exports) {
  "use strict";

  var unsafeCompareImpl = function (lt) {
    return function (eq) {
      return function (gt) {
        return function (x) {
          return function (y) {
            return x < y ? lt : x === y ? eq : gt;
          };
        };
      };
    };
  };                                         
  exports.ordIntImpl = unsafeCompareImpl;   
  exports.ordStringImpl = unsafeCompareImpl;
  exports.ordCharImpl = unsafeCompareImpl;
})(PS["Data.Ord"] = PS["Data.Ord"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Ordering"] = $PS["Data.Ordering"] || {};
  var exports = $PS["Data.Ordering"];
  var Data_Eq = $PS["Data.Eq"];                    
  var LT = (function () {
      function LT() {

      };
      LT.value = new LT();
      return LT;
  })();
  var GT = (function () {
      function GT() {

      };
      GT.value = new GT();
      return GT;
  })();
  var EQ = (function () {
      function EQ() {

      };
      EQ.value = new EQ();
      return EQ;
  })();
  var eqOrdering = new Data_Eq.Eq(function (v) {
      return function (v1) {
          if (v instanceof LT && v1 instanceof LT) {
              return true;
          };
          if (v instanceof GT && v1 instanceof GT) {
              return true;
          };
          if (v instanceof EQ && v1 instanceof EQ) {
              return true;
          };
          return false;
      };
  });
  exports["LT"] = LT;
  exports["GT"] = GT;
  exports["EQ"] = EQ;
  exports["eqOrdering"] = eqOrdering;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Ord"] = $PS["Data.Ord"] || {};
  var exports = $PS["Data.Ord"];
  var $foreign = $PS["Data.Ord"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Ord = function (Eq0, compare) {
      this.Eq0 = Eq0;
      this.compare = compare;
  }; 
  var ordUnit = new Ord(function () {
      return Data_Eq.eqUnit;
  }, function (v) {
      return function (v1) {
          return Data_Ordering.EQ.value;
      };
  });
  var ordString = new Ord(function () {
      return Data_Eq.eqString;
  }, $foreign.ordStringImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value));
  var ordInt = new Ord(function () {
      return Data_Eq.eqInt;
  }, $foreign.ordIntImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value));
  var ordChar = new Ord(function () {
      return Data_Eq.eqChar;
  }, $foreign.ordCharImpl(Data_Ordering.LT.value)(Data_Ordering.EQ.value)(Data_Ordering.GT.value));
  var compare = function (dict) {
      return dict.compare;
  };
  exports["Ord"] = Ord;
  exports["compare"] = compare;
  exports["ordInt"] = ordInt;
  exports["ordString"] = ordString;
  exports["ordChar"] = ordChar;
  exports["ordUnit"] = ordUnit;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Tuple"] = $PS["Data.Tuple"] || {};
  var exports = $PS["Data.Tuple"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];        
  var Tuple = (function () {
      function Tuple(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Tuple.create = function (value0) {
          return function (value1) {
              return new Tuple(value0, value1);
          };
      };
      return Tuple;
  })();
  var uncurry = function (f) {
      return function (v) {
          return f(v.value0)(v.value1);
      };
  };
  var snd = function (v) {
      return v.value1;
  }; 
  var functorTuple = new Data_Functor.Functor(function (f) {
      return function (m) {
          return new Tuple(m.value0, f(m.value1));
      };
  });                                                                                                   
  var fst = function (v) {
      return v.value0;
  }; 
  var eqTuple = function (dictEq) {
      return function (dictEq1) {
          return new Data_Eq.Eq(function (x) {
              return function (y) {
                  return Data_Eq.eq(dictEq)(x.value0)(y.value0) && Data_Eq.eq(dictEq1)(x.value1)(y.value1);
              };
          });
      };
  };
  var ordTuple = function (dictOrd) {
      return function (dictOrd1) {
          return new Data_Ord.Ord(function () {
              return eqTuple(dictOrd.Eq0())(dictOrd1.Eq0());
          }, function (x) {
              return function (y) {
                  var v = Data_Ord.compare(dictOrd)(x.value0)(y.value0);
                  if (v instanceof Data_Ordering.LT) {
                      return Data_Ordering.LT.value;
                  };
                  if (v instanceof Data_Ordering.GT) {
                      return Data_Ordering.GT.value;
                  };
                  return Data_Ord.compare(dictOrd1)(x.value1)(y.value1);
              };
          });
      };
  };
  exports["Tuple"] = Tuple;
  exports["fst"] = fst;
  exports["snd"] = snd;
  exports["uncurry"] = uncurry;
  exports["ordTuple"] = ordTuple;
  exports["functorTuple"] = functorTuple;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Applicative.Free"] = $PS["Control.Applicative.Free"] || {};
  var exports = $PS["Control.Applicative.Free"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_NonEmpty = $PS["Data.List.NonEmpty"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_NonEmpty = $PS["Data.NonEmpty"];
  var Data_Tuple = $PS["Data.Tuple"];                
  var Pure = (function () {
      function Pure(value0) {
          this.value0 = value0;
      };
      Pure.create = function (value0) {
          return new Pure(value0);
      };
      return Pure;
  })();
  var Lift = (function () {
      function Lift(value0) {
          this.value0 = value0;
      };
      Lift.create = function (value0) {
          return new Lift(value0);
      };
      return Lift;
  })();
  var Ap = (function () {
      function Ap(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Ap.create = function (value0) {
          return function (value1) {
              return new Ap(value0, value1);
          };
      };
      return Ap;
  })();
  var mkAp = function (fba) {
      return function (fb) {
          return new Ap(fba, fb);
      };
  };
  var liftFreeAp = Lift.create;
  var goLeft = function ($copy_dictApplicative) {
      return function ($copy_fStack) {
          return function ($copy_valStack) {
              return function ($copy_nat) {
                  return function ($copy_func) {
                      return function ($copy_count) {
                          var $tco_var_dictApplicative = $copy_dictApplicative;
                          var $tco_var_fStack = $copy_fStack;
                          var $tco_var_valStack = $copy_valStack;
                          var $tco_var_nat = $copy_nat;
                          var $tco_var_func = $copy_func;
                          var $tco_done = false;
                          var $tco_result;
                          function $tco_loop(dictApplicative, fStack, valStack, nat, func, count) {
                              if (func instanceof Pure) {
                                  $tco_done = true;
                                  return new Data_Tuple.Tuple(new Data_List_Types.Cons({
                                      func: Control_Applicative.pure(dictApplicative)(func.value0),
                                      count: count
                                  }, fStack), valStack);
                              };
                              if (func instanceof Lift) {
                                  $tco_done = true;
                                  return new Data_Tuple.Tuple(new Data_List_Types.Cons({
                                      func: nat(func.value0),
                                      count: count
                                  }, fStack), valStack);
                              };
                              if (func instanceof Ap) {
                                  $tco_var_dictApplicative = dictApplicative;
                                  $tco_var_fStack = fStack;
                                  $tco_var_valStack = Data_List_NonEmpty.cons(func.value1)(valStack);
                                  $tco_var_nat = nat;
                                  $tco_var_func = func.value0;
                                  $copy_count = count + 1 | 0;
                                  return;
                              };
                              throw new Error("Failed pattern match at Control.Applicative.Free (line 102, column 41 - line 105, column 81): " + [ func.constructor.name ]);
                          };
                          while (!$tco_done) {
                              $tco_result = $tco_loop($tco_var_dictApplicative, $tco_var_fStack, $tco_var_valStack, $tco_var_nat, $tco_var_func, $copy_count);
                          };
                          return $tco_result;
                      };
                  };
              };
          };
      };
  };
  var goApply = function ($copy_dictApplicative) {
      return function ($copy_fStack) {
          return function ($copy_vals) {
              return function ($copy_gVal) {
                  var $tco_var_dictApplicative = $copy_dictApplicative;
                  var $tco_var_fStack = $copy_fStack;
                  var $tco_var_vals = $copy_vals;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(dictApplicative, fStack, vals, gVal) {
                      if (fStack instanceof Data_List_Types.Nil) {
                          $tco_done = true;
                          return new Data_Either.Left(gVal);
                      };
                      if (fStack instanceof Data_List_Types.Cons) {
                          var gRes = Control_Apply.apply(dictApplicative.Apply0())(fStack.value0.func)(gVal);
                          var $14 = fStack.value0.count === 1;
                          if ($14) {
                              if (fStack.value1 instanceof Data_List_Types.Nil) {
                                  $tco_done = true;
                                  return new Data_Either.Left(gRes);
                              };
                              $tco_var_dictApplicative = dictApplicative;
                              $tco_var_fStack = fStack.value1;
                              $tco_var_vals = vals;
                              $copy_gVal = gRes;
                              return;
                          };
                          if (vals instanceof Data_List_Types.Nil) {
                              $tco_done = true;
                              return new Data_Either.Left(gRes);
                          };
                          if (vals instanceof Data_List_Types.Cons) {
                              $tco_done = true;
                              return Data_Either.Right.create(new Data_Tuple.Tuple(new Data_List_Types.Cons({
                                  func: gRes,
                                  count: fStack.value0.count - 1 | 0
                              }, fStack.value1), new Data_NonEmpty.NonEmpty(vals.value0, vals.value1)));
                          };
                          throw new Error("Failed pattern match at Control.Applicative.Free (line 83, column 11 - line 88, column 50): " + [ vals.constructor.name ]);
                      };
                      throw new Error("Failed pattern match at Control.Applicative.Free (line 72, column 3 - line 88, column 50): " + [ fStack.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_dictApplicative, $tco_var_fStack, $tco_var_vals, $copy_gVal);
                  };
                  return $tco_result;
              };
          };
      };
  };
  var functorFreeAp = new Data_Functor.Functor(function (f) {
      return function (x) {
          return mkAp(new Pure(f))(x);
      };
  });
  var foldFreeAp = function (dictApplicative) {
      return function (nat) {
          return function (z) {
              var go = function ($copy_v) {
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(v) {
                      if (v.value1.value0 instanceof Pure) {
                          var v1 = goApply(dictApplicative)(v.value0)(v.value1.value1)(Control_Applicative.pure(dictApplicative)(v.value1.value0.value0));
                          if (v1 instanceof Data_Either.Left) {
                              $tco_done = true;
                              return v1.value0;
                          };
                          if (v1 instanceof Data_Either.Right) {
                              $copy_v = v1.value0;
                              return;
                          };
                          throw new Error("Failed pattern match at Control.Applicative.Free (line 54, column 17 - line 56, column 24): " + [ v1.constructor.name ]);
                      };
                      if (v.value1.value0 instanceof Lift) {
                          var v1 = goApply(dictApplicative)(v.value0)(v.value1.value1)(nat(v.value1.value0.value0));
                          if (v1 instanceof Data_Either.Left) {
                              $tco_done = true;
                              return v1.value0;
                          };
                          if (v1 instanceof Data_Either.Right) {
                              $copy_v = v1.value0;
                              return;
                          };
                          throw new Error("Failed pattern match at Control.Applicative.Free (line 57, column 17 - line 59, column 24): " + [ v1.constructor.name ]);
                      };
                      if (v.value1.value0 instanceof Ap) {
                          var nextVals = new Data_NonEmpty.NonEmpty(v.value1.value0.value1, v.value1.value1);
                          $copy_v = goLeft(dictApplicative)(v.value0)(nextVals)(nat)(v.value1.value0.value0)(1);
                          return;
                      };
                      throw new Error("Failed pattern match at Control.Applicative.Free (line 53, column 5 - line 62, column 47): " + [ v.value1.value0.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($copy_v);
                  };
                  return $tco_result;
              };
              return go(new Data_Tuple.Tuple(Data_List_Types.Nil.value, Data_List_NonEmpty.singleton(z)));
          };
      };
  };
  var retractFreeAp = function (dictApplicative) {
      return foldFreeAp(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
  };
  var applyFreeAp = new Control_Apply.Apply(function () {
      return functorFreeAp;
  }, function (fba) {
      return function (fb) {
          return mkAp(fba)(fb);
      };
  });
  var applicativeFreeAp = new Control_Applicative.Applicative(function () {
      return applyFreeAp;
  }, Pure.create);
  var hoistFreeAp = function (f) {
      return foldFreeAp(applicativeFreeAp)(function ($37) {
          return liftFreeAp(f($37));
      });
  };
  exports["retractFreeAp"] = retractFreeAp;
  exports["hoistFreeAp"] = hoistFreeAp;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Monad"] = $PS["Control.Monad"] || {};
  var exports = $PS["Control.Monad"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];                
  var Monad = function (Applicative0, Bind1) {
      this.Applicative0 = Applicative0;
      this.Bind1 = Bind1;
  };
  var unlessM = function (dictMonad) {
      return function (mb) {
          return function (m) {
              return Control_Bind.bind(dictMonad.Bind1())(mb)(function (b) {
                  return Control_Applicative.unless(dictMonad.Applicative0())(b)(m);
              });
          };
      };
  };
  var ap = function (dictMonad) {
      return function (f) {
          return function (a) {
              return Control_Bind.bind(dictMonad.Bind1())(f)(function (f$prime) {
                  return Control_Bind.bind(dictMonad.Bind1())(a)(function (a$prime) {
                      return Control_Applicative.pure(dictMonad.Applicative0())(f$prime(a$prime));
                  });
              });
          };
      };
  };
  exports["Monad"] = Monad;
  exports["unlessM"] = unlessM;
  exports["ap"] = ap;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Monad.Error.Class"] = $PS["Control.Monad.Error.Class"] || {};
  var exports = $PS["Control.Monad.Error.Class"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];                        
  var MonadThrow = function (Monad0, throwError) {
      this.Monad0 = Monad0;
      this.throwError = throwError;
  };
  var MonadError = function (MonadThrow0, catchError) {
      this.MonadThrow0 = MonadThrow0;
      this.catchError = catchError;
  };
  var throwError = function (dict) {
      return dict.throwError;
  };                                                      
  var catchError = function (dict) {
      return dict.catchError;
  };
  var $$try = function (dictMonadError) {
      return function (a) {
          return catchError(dictMonadError)(Data_Functor.map(((((dictMonadError.MonadThrow0()).Monad0()).Bind1()).Apply0()).Functor0())(Data_Either.Right.create)(a))((function () {
              var $17 = Control_Applicative.pure(((dictMonadError.MonadThrow0()).Monad0()).Applicative0());
              return function ($18) {
                  return $17(Data_Either.Left.create($18));
              };
          })());
      };
  };
  exports["throwError"] = throwError;
  exports["MonadThrow"] = MonadThrow;
  exports["MonadError"] = MonadError;
  exports["try"] = $$try;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Monad.Except.Trans"] = $PS["Control.Monad.Except.Trans"] || {};
  var exports = $PS["Control.Monad.Except.Trans"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Semigroup = $PS["Data.Semigroup"];            
  var ExceptT = function (x) {
      return x;
  };
  var runExceptT = function (v) {
      return v;
  }; 
  var mapExceptT = function (f) {
      return function (v) {
          return f(v);
      };
  };
  var functorExceptT = function (dictFunctor) {
      return new Data_Functor.Functor(function (f) {
          return mapExceptT(Data_Functor.map(dictFunctor)(Data_Functor.map(Data_Either.functorEither)(f)));
      });
  };
  var monadExceptT = function (dictMonad) {
      return new Control_Monad.Monad(function () {
          return applicativeExceptT(dictMonad);
      }, function () {
          return bindExceptT(dictMonad);
      });
  };
  var bindExceptT = function (dictMonad) {
      return new Control_Bind.Bind(function () {
          return applyExceptT(dictMonad);
      }, function (v) {
          return function (k) {
              return Control_Bind.bind(dictMonad.Bind1())(v)(Data_Either.either((function () {
                  var $91 = Control_Applicative.pure(dictMonad.Applicative0());
                  return function ($92) {
                      return $91(Data_Either.Left.create($92));
                  };
              })())(function (a) {
                  var v1 = k(a);
                  return v1;
              }));
          };
      });
  };
  var applyExceptT = function (dictMonad) {
      return new Control_Apply.Apply(function () {
          return functorExceptT(((dictMonad.Bind1()).Apply0()).Functor0());
      }, Control_Monad.ap(monadExceptT(dictMonad)));
  };
  var applicativeExceptT = function (dictMonad) {
      return new Control_Applicative.Applicative(function () {
          return applyExceptT(dictMonad);
      }, (function () {
          var $93 = Control_Applicative.pure(dictMonad.Applicative0());
          return function ($94) {
              return ExceptT($93(Data_Either.Right.create($94)));
          };
      })());
  };
  var monadThrowExceptT = function (dictMonad) {
      return new Control_Monad_Error_Class.MonadThrow(function () {
          return monadExceptT(dictMonad);
      }, (function () {
          var $103 = Control_Applicative.pure(dictMonad.Applicative0());
          return function ($104) {
              return ExceptT($103(Data_Either.Left.create($104)));
          };
      })());
  };
  var altExceptT = function (dictSemigroup) {
      return function (dictMonad) {
          return new Control_Alt.Alt(function () {
              return functorExceptT(((dictMonad.Bind1()).Apply0()).Functor0());
          }, function (v) {
              return function (v1) {
                  return Control_Bind.bind(dictMonad.Bind1())(v)(function (rm) {
                      if (rm instanceof Data_Either.Right) {
                          return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right(rm.value0));
                      };
                      if (rm instanceof Data_Either.Left) {
                          return Control_Bind.bind(dictMonad.Bind1())(v1)(function (rn) {
                              if (rn instanceof Data_Either.Right) {
                                  return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Right(rn.value0));
                              };
                              if (rn instanceof Data_Either.Left) {
                                  return Control_Applicative.pure(dictMonad.Applicative0())(new Data_Either.Left(Data_Semigroup.append(dictSemigroup)(rm.value0)(rn.value0)));
                              };
                              throw new Error("Failed pattern match at Control.Monad.Except.Trans (line 87, column 9 - line 89, column 49): " + [ rn.constructor.name ]);
                          });
                      };
                      throw new Error("Failed pattern match at Control.Monad.Except.Trans (line 83, column 5 - line 89, column 49): " + [ rm.constructor.name ]);
                  });
              };
          });
      };
  };
  exports["runExceptT"] = runExceptT;
  exports["functorExceptT"] = functorExceptT;
  exports["applicativeExceptT"] = applicativeExceptT;
  exports["bindExceptT"] = bindExceptT;
  exports["altExceptT"] = altExceptT;
  exports["monadThrowExceptT"] = monadThrowExceptT;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Monad.Except"] = $PS["Control.Monad.Except"] || {};
  var exports = $PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Newtype = $PS["Data.Newtype"];                                                
  var runExcept = (function () {
      var $0 = Data_Newtype.unwrap();
      return function ($1) {
          return $0(Control_Monad_Except_Trans.runExceptT($1));
      };
  })();
  exports["runExcept"] = runExcept;
})(PS);
(function(exports) {
  /* globals setImmediate, clearImmediate, setTimeout, clearTimeout */
  /* eslint-disable no-unused-vars, no-prototype-builtins, no-use-before-define, no-unused-labels, no-param-reassign */
  "use strict";

  var Aff = function () {
    // A unique value for empty.
    var EMPTY = {};

    /*

  An awkward approximation. We elide evidence we would otherwise need in PS for
  efficiency sake.

  data Aff eff a
    = Pure a
    | Throw Error
    | Catch (Aff eff a) (Error -> Aff eff a)
    | Sync (Eff eff a)
    | Async ((Either Error a -> Eff eff Unit) -> Eff eff (Canceler eff))
    | forall b. Bind (Aff eff b) (b -> Aff eff a)
    | forall b. Bracket (Aff eff b) (BracketConditions eff b) (b -> Aff eff a)
    | forall b. Fork Boolean (Aff eff b) ?(Fiber eff b -> a)
    | Sequential (ParAff aff a)

  */  
    var PURE    = "Pure";
    var THROW   = "Throw";
    var CATCH   = "Catch";
    var SYNC    = "Sync";
    var ASYNC   = "Async";
    var BIND    = "Bind";
    var BRACKET = "Bracket";
    var FORK    = "Fork";
    var SEQ     = "Sequential";

    /*

  data ParAff eff a
    = forall b. Map (b -> a) (ParAff eff b)
    | forall b. Apply (ParAff eff (b -> a)) (ParAff eff b)
    | Alt (ParAff eff a) (ParAff eff a)
    | ?Par (Aff eff a)

  */  
    var MAP   = "Map";
    var APPLY = "Apply";
    var ALT   = "Alt";

    // Various constructors used in interpretation
    var CONS      = "Cons";      // Cons-list, for stacks
    var RESUME    = "Resume";    // Continue indiscriminately
    var RELEASE   = "Release";   // Continue with bracket finalizers
    var FINALIZER = "Finalizer"; // A non-interruptible effect
    var FINALIZED = "Finalized"; // Marker for finalization
    var FORKED    = "Forked";    // Reference to a forked fiber, with resumption stack
    var FIBER     = "Fiber";     // Actual fiber reference
    var THUNK     = "Thunk";     // Primed effect, ready to invoke

    function Aff(tag, _1, _2, _3) {
      this.tag = tag;
      this._1  = _1;
      this._2  = _2;
      this._3  = _3;
    }

    function AffCtr(tag) {
      var fn = function (_1, _2, _3) {
        return new Aff(tag, _1, _2, _3);
      };
      fn.tag = tag;
      return fn;
    }

    function nonCanceler(error) {
      return new Aff(PURE, void 0);
    }

    function runEff(eff) {
      try {
        eff();
      } catch (error) {
        setTimeout(function () {
          throw error;
        }, 0);
      }
    }

    function runSync(left, right, eff) {
      try {
        return right(eff());
      } catch (error) {
        return left(error);
      }
    }

    function runAsync(left, eff, k) {
      try {
        return eff(k)();
      } catch (error) {
        k(left(error))();
        return nonCanceler;
      }
    }

    var Scheduler = function () {
      var limit    = 1024;
      var size     = 0;
      var ix       = 0;
      var queue    = new Array(limit);
      var draining = false;

      function drain() {
        var thunk;
        draining = true;
        while (size !== 0) {
          size--;
          thunk     = queue[ix];
          queue[ix] = void 0;
          ix        = (ix + 1) % limit;
          thunk();
        }
        draining = false;
      }

      return {
        isDraining: function () {
          return draining;
        },
        enqueue: function (cb) {
          var i, tmp;
          if (size === limit) {
            tmp = draining;
            drain();
            draining = tmp;
          }

          queue[(ix + size) % limit] = cb;
          size++;

          if (!draining) {
            drain();
          }
        }
      };
    }();

    function Supervisor(util) {
      var fibers  = {};
      var fiberId = 0;
      var count   = 0;

      return {
        register: function (fiber) {
          var fid = fiberId++;
          fiber.onComplete({
            rethrow: true,
            handler: function (result) {
              return function () {
                count--;
                delete fibers[fid];
              };
            }
          })();
          fibers[fid] = fiber;
          count++;
        },
        isEmpty: function () {
          return count === 0;
        },
        killAll: function (killError, cb) {
          return function () {
            if (count === 0) {
              return cb();
            }

            var killCount = 0;
            var kills     = {};

            function kill(fid) {
              kills[fid] = fibers[fid].kill(killError, function (result) {
                return function () {
                  delete kills[fid];
                  killCount--;
                  if (util.isLeft(result) && util.fromLeft(result)) {
                    setTimeout(function () {
                      throw util.fromLeft(result);
                    }, 0);
                  }
                  if (killCount === 0) {
                    cb();
                  }
                };
              })();
            }

            for (var k in fibers) {
              if (fibers.hasOwnProperty(k)) {
                killCount++;
                kill(k);
              }
            }

            fibers  = {};
            fiberId = 0;
            count   = 0;

            return function (error) {
              return new Aff(SYNC, function () {
                for (var k in kills) {
                  if (kills.hasOwnProperty(k)) {
                    kills[k]();
                  }
                }
              });
            };
          };
        }
      };
    }

    // Fiber state machine
    var SUSPENDED   = 0; // Suspended, pending a join.
    var CONTINUE    = 1; // Interpret the next instruction.
    var STEP_BIND   = 2; // Apply the next bind.
    var STEP_RESULT = 3; // Handle potential failure from a result.
    var PENDING     = 4; // An async effect is running.
    var RETURN      = 5; // The current stack has returned.
    var COMPLETED   = 6; // The entire fiber has completed.

    function Fiber(util, supervisor, aff) {
      // Monotonically increasing tick, increased on each asynchronous turn.
      var runTick = 0;

      // The current branch of the state machine.
      var status = SUSPENDED;

      // The current point of interest for the state machine branch.
      var step      = aff;  // Successful step
      var fail      = null; // Failure step
      var interrupt = null; // Asynchronous interrupt

      // Stack of continuations for the current fiber.
      var bhead = null;
      var btail = null;

      // Stack of attempts and finalizers for error recovery. Every `Cons` is also
      // tagged with current `interrupt` state. We use this to track which items
      // should be ignored or evaluated as a result of a kill.
      var attempts = null;

      // A special state is needed for Bracket, because it cannot be killed. When
      // we enter a bracket acquisition or finalizer, we increment the counter,
      // and then decrement once complete.
      var bracketCount = 0;

      // Each join gets a new id so they can be revoked.
      var joinId  = 0;
      var joins   = null;
      var rethrow = true;

      // Each invocation of `run` requires a tick. When an asynchronous effect is
      // resolved, we must check that the local tick coincides with the fiber
      // tick before resuming. This prevents multiple async continuations from
      // accidentally resuming the same fiber. A common example may be invoking
      // the provided callback in `makeAff` more than once, but it may also be an
      // async effect resuming after the fiber was already cancelled.
      function run(localRunTick) {
        var tmp, result, attempt;
        while (true) {
          tmp       = null;
          result    = null;
          attempt   = null;

          switch (status) {
          case STEP_BIND:
            status = CONTINUE;
            try {
              step   = bhead(step);
              if (btail === null) {
                bhead = null;
              } else {
                bhead = btail._1;
                btail = btail._2;
              }
            } catch (e) {
              status = RETURN;
              fail   = util.left(e);
              step   = null;
            }
            break;

          case STEP_RESULT:
            if (util.isLeft(step)) {
              status = RETURN;
              fail   = step;
              step   = null;
            } else if (bhead === null) {
              status = RETURN;
            } else {
              status = STEP_BIND;
              step   = util.fromRight(step);
            }
            break;

          case CONTINUE:
            switch (step.tag) {
            case BIND:
              if (bhead) {
                btail = new Aff(CONS, bhead, btail);
              }
              bhead  = step._2;
              status = CONTINUE;
              step   = step._1;
              break;

            case PURE:
              if (bhead === null) {
                status = RETURN;
                step   = util.right(step._1);
              } else {
                status = STEP_BIND;
                step   = step._1;
              }
              break;

            case SYNC:
              status = STEP_RESULT;
              step   = runSync(util.left, util.right, step._1);
              break;

            case ASYNC:
              status = PENDING;
              step   = runAsync(util.left, step._1, function (result) {
                return function () {
                  if (runTick !== localRunTick) {
                    return;
                  }
                  runTick++;
                  Scheduler.enqueue(function () {
                    // It's possible to interrupt the fiber between enqueuing and
                    // resuming, so we need to check that the runTick is still
                    // valid.
                    if (runTick !== localRunTick + 1) {
                      return;
                    }
                    status = STEP_RESULT;
                    step   = result;
                    run(runTick);
                  });
                };
              });
              return;

            case THROW:
              status = RETURN;
              fail   = util.left(step._1);
              step   = null;
              break;

            // Enqueue the Catch so that we can call the error handler later on
            // in case of an exception.
            case CATCH:
              if (bhead === null) {
                attempts = new Aff(CONS, step, attempts, interrupt);
              } else {
                attempts = new Aff(CONS, step, new Aff(CONS, new Aff(RESUME, bhead, btail), attempts, interrupt), interrupt);
              }
              bhead    = null;
              btail    = null;
              status   = CONTINUE;
              step     = step._1;
              break;

            // Enqueue the Bracket so that we can call the appropriate handlers
            // after resource acquisition.
            case BRACKET:
              bracketCount++;
              if (bhead === null) {
                attempts = new Aff(CONS, step, attempts, interrupt);
              } else {
                attempts = new Aff(CONS, step, new Aff(CONS, new Aff(RESUME, bhead, btail), attempts, interrupt), interrupt);
              }
              bhead  = null;
              btail  = null;
              status = CONTINUE;
              step   = step._1;
              break;

            case FORK:
              status = STEP_RESULT;
              tmp    = Fiber(util, supervisor, step._2);
              if (supervisor) {
                supervisor.register(tmp);
              }
              if (step._1) {
                tmp.run();
              }
              step = util.right(tmp);
              break;

            case SEQ:
              status = CONTINUE;
              step   = sequential(util, supervisor, step._1);
              break;
            }
            break;

          case RETURN:
            bhead = null;
            btail = null;
            // If the current stack has returned, and we have no other stacks to
            // resume or finalizers to run, the fiber has halted and we can
            // invoke all join callbacks. Otherwise we need to resume.
            if (attempts === null) {
              status = COMPLETED;
              step   = interrupt || fail || step;
            } else {
              // The interrupt status for the enqueued item.
              tmp      = attempts._3;
              attempt  = attempts._1;
              attempts = attempts._2;

              switch (attempt.tag) {
              // We cannot recover from an unmasked interrupt. Otherwise we should
              // continue stepping, or run the exception handler if an exception
              // was raised.
              case CATCH:
                // We should compare the interrupt status as well because we
                // only want it to apply if there has been an interrupt since
                // enqueuing the catch.
                if (interrupt && interrupt !== tmp && bracketCount === 0) {
                  status = RETURN;
                } else if (fail) {
                  status = CONTINUE;
                  step   = attempt._2(util.fromLeft(fail));
                  fail   = null;
                }
                break;

              // We cannot resume from an unmasked interrupt or exception.
              case RESUME:
                // As with Catch, we only want to ignore in the case of an
                // interrupt since enqueing the item.
                if (interrupt && interrupt !== tmp && bracketCount === 0 || fail) {
                  status = RETURN;
                } else {
                  bhead  = attempt._1;
                  btail  = attempt._2;
                  status = STEP_BIND;
                  step   = util.fromRight(step);
                }
                break;

              // If we have a bracket, we should enqueue the handlers,
              // and continue with the success branch only if the fiber has
              // not been interrupted. If the bracket acquisition failed, we
              // should not run either.
              case BRACKET:
                bracketCount--;
                if (fail === null) {
                  result   = util.fromRight(step);
                  // We need to enqueue the Release with the same interrupt
                  // status as the Bracket that is initiating it.
                  attempts = new Aff(CONS, new Aff(RELEASE, attempt._2, result), attempts, tmp);
                  // We should only coninue as long as the interrupt status has not changed or
                  // we are currently within a non-interruptable finalizer.
                  if (interrupt === tmp || bracketCount > 0) {
                    status = CONTINUE;
                    step   = attempt._3(result);
                  }
                }
                break;

              // Enqueue the appropriate handler. We increase the bracket count
              // because it should not be cancelled.
              case RELEASE:
                attempts = new Aff(CONS, new Aff(FINALIZED, step, fail), attempts, interrupt);
                status   = CONTINUE;
                // It has only been killed if the interrupt status has changed
                // since we enqueued the item, and the bracket count is 0. If the
                // bracket count is non-zero then we are in a masked state so it's
                // impossible to be killed.
                if (interrupt && interrupt !== tmp && bracketCount === 0) {
                  step = attempt._1.killed(util.fromLeft(interrupt))(attempt._2);
                } else if (fail) {
                  step = attempt._1.failed(util.fromLeft(fail))(attempt._2);
                } else {
                  step = attempt._1.completed(util.fromRight(step))(attempt._2);
                }
                fail = null;
                bracketCount++;
                break;

              case FINALIZER:
                bracketCount++;
                attempts = new Aff(CONS, new Aff(FINALIZED, step, fail), attempts, interrupt);
                status   = CONTINUE;
                step     = attempt._1;
                break;

              case FINALIZED:
                bracketCount--;
                status = RETURN;
                step   = attempt._1;
                fail   = attempt._2;
                break;
              }
            }
            break;

          case COMPLETED:
            for (var k in joins) {
              if (joins.hasOwnProperty(k)) {
                rethrow = rethrow && joins[k].rethrow;
                runEff(joins[k].handler(step));
              }
            }
            joins = null;
            // If we have an interrupt and a fail, then the thread threw while
            // running finalizers. This should always rethrow in a fresh stack.
            if (interrupt && fail) {
              setTimeout(function () {
                throw util.fromLeft(fail);
              }, 0);
            // If we have an unhandled exception, and no other fiber has joined
            // then we need to throw the exception in a fresh stack.
            } else if (util.isLeft(step) && rethrow) {
              setTimeout(function () {
                // Guard on reathrow because a completely synchronous fiber can
                // still have an observer which was added after-the-fact.
                if (rethrow) {
                  throw util.fromLeft(step);
                }
              }, 0);
            }
            return;
          case SUSPENDED:
            status = CONTINUE;
            break;
          case PENDING: return;
          }
        }
      }

      function onComplete(join) {
        return function () {
          if (status === COMPLETED) {
            rethrow = rethrow && join.rethrow;
            join.handler(step)();
            return function () {};
          }

          var jid    = joinId++;
          joins      = joins || {};
          joins[jid] = join;

          return function() {
            if (joins !== null) {
              delete joins[jid];
            }
          };
        };
      }

      function kill(error, cb) {
        return function () {
          if (status === COMPLETED) {
            cb(util.right(void 0))();
            return function () {};
          }

          var canceler = onComplete({
            rethrow: false,
            handler: function (/* unused */) {
              return cb(util.right(void 0));
            }
          })();

          switch (status) {
          case SUSPENDED:
            interrupt = util.left(error);
            status    = COMPLETED;
            step      = interrupt;
            run(runTick);
            break;
          case PENDING:
            if (interrupt === null) {
              interrupt = util.left(error);
            }
            if (bracketCount === 0) {
              if (status === PENDING) {
                attempts = new Aff(CONS, new Aff(FINALIZER, step(error)), attempts, interrupt);
              }
              status   = RETURN;
              step     = null;
              fail     = null;
              run(++runTick);
            }
            break;
          default:
            if (interrupt === null) {
              interrupt = util.left(error);
            }
            if (bracketCount === 0) {
              status = RETURN;
              step   = null;
              fail   = null;
            }
          }

          return canceler;
        };
      }

      function join(cb) {
        return function () {
          var canceler = onComplete({
            rethrow: false,
            handler: cb
          })();
          if (status === SUSPENDED) {
            run(runTick);
          }
          return canceler;
        };
      }

      return {
        kill: kill,
        join: join,
        onComplete: onComplete,
        isSuspended: function () {
          return status === SUSPENDED;
        },
        run: function () {
          if (status === SUSPENDED) {
            if (!Scheduler.isDraining()) {
              Scheduler.enqueue(function () {
                run(runTick);
              });
            } else {
              run(runTick);
            }
          }
        }
      };
    }

    function runPar(util, supervisor, par, cb) {
      // Table of all forked fibers.
      var fiberId   = 0;
      var fibers    = {};

      // Table of currently running cancelers, as a product of `Alt` behavior.
      var killId    = 0;
      var kills     = {};

      // Error used for early cancelation on Alt branches.
      var early     = new Error("[ParAff] Early exit");

      // Error used to kill the entire tree.
      var interrupt = null;

      // The root pointer of the tree.
      var root      = EMPTY;

      // Walks a tree, invoking all the cancelers. Returns the table of pending
      // cancellation fibers.
      function kill(error, par, cb) {
        var step  = par;
        var head  = null;
        var tail  = null;
        var count = 0;
        var kills = {};
        var tmp, kid;

        loop: while (true) {
          tmp = null;

          switch (step.tag) {
          case FORKED:
            if (step._3 === EMPTY) {
              tmp = fibers[step._1];
              kills[count++] = tmp.kill(error, function (result) {
                return function () {
                  count--;
                  if (count === 0) {
                    cb(result)();
                  }
                };
              });
            }
            // Terminal case.
            if (head === null) {
              break loop;
            }
            // Go down the right side of the tree.
            step = head._2;
            if (tail === null) {
              head = null;
            } else {
              head = tail._1;
              tail = tail._2;
            }
            break;
          case MAP:
            step = step._2;
            break;
          case APPLY:
          case ALT:
            if (head) {
              tail = new Aff(CONS, head, tail);
            }
            head = step;
            step = step._1;
            break;
          }
        }

        if (count === 0) {
          cb(util.right(void 0))();
        } else {
          // Run the cancelation effects. We alias `count` because it's mutable.
          kid = 0;
          tmp = count;
          for (; kid < tmp; kid++) {
            kills[kid] = kills[kid]();
          }
        }

        return kills;
      }

      // When a fiber resolves, we need to bubble back up the tree with the
      // result, computing the applicative nodes.
      function join(result, head, tail) {
        var fail, step, lhs, rhs, tmp, kid;

        if (util.isLeft(result)) {
          fail = result;
          step = null;
        } else {
          step = result;
          fail = null;
        }

        loop: while (true) {
          lhs = null;
          rhs = null;
          tmp = null;
          kid = null;

          // We should never continue if the entire tree has been interrupted.
          if (interrupt !== null) {
            return;
          }

          // We've made it all the way to the root of the tree, which means
          // the tree has fully evaluated.
          if (head === null) {
            cb(fail || step)();
            return;
          }

          // The tree has already been computed, so we shouldn't try to do it
          // again. This should never happen.
          // TODO: Remove this?
          if (head._3 !== EMPTY) {
            return;
          }

          switch (head.tag) {
          case MAP:
            if (fail === null) {
              head._3 = util.right(head._1(util.fromRight(step)));
              step    = head._3;
            } else {
              head._3 = fail;
            }
            break;
          case APPLY:
            lhs = head._1._3;
            rhs = head._2._3;
            // If we have a failure we should kill the other side because we
            // can't possible yield a result anymore.
            if (fail) {
              head._3 = fail;
              tmp     = true;
              kid     = killId++;

              kills[kid] = kill(early, fail === lhs ? head._2 : head._1, function (/* unused */) {
                return function () {
                  delete kills[kid];
                  if (tmp) {
                    tmp = false;
                  } else if (tail === null) {
                    join(fail, null, null);
                  } else {
                    join(fail, tail._1, tail._2);
                  }
                };
              });

              if (tmp) {
                tmp = false;
                return;
              }
            } else if (lhs === EMPTY || rhs === EMPTY) {
              // We can only proceed if both sides have resolved.
              return;
            } else {
              step    = util.right(util.fromRight(lhs)(util.fromRight(rhs)));
              head._3 = step;
            }
            break;
          case ALT:
            lhs = head._1._3;
            rhs = head._2._3;
            // We can only proceed if both have resolved or we have a success
            if (lhs === EMPTY && util.isLeft(rhs) || rhs === EMPTY && util.isLeft(lhs)) {
              return;
            }
            // If both sides resolve with an error, we should continue with the
            // first error
            if (lhs !== EMPTY && util.isLeft(lhs) && rhs !== EMPTY && util.isLeft(rhs)) {
              fail    = step === lhs ? rhs : lhs;
              step    = null;
              head._3 = fail;
            } else {
              head._3 = step;
              tmp     = true;
              kid     = killId++;
              // Once a side has resolved, we need to cancel the side that is still
              // pending before we can continue.
              kills[kid] = kill(early, step === lhs ? head._2 : head._1, function (/* unused */) {
                return function () {
                  delete kills[kid];
                  if (tmp) {
                    tmp = false;
                  } else if (tail === null) {
                    join(step, null, null);
                  } else {
                    join(step, tail._1, tail._2);
                  }
                };
              });

              if (tmp) {
                tmp = false;
                return;
              }
            }
            break;
          }

          if (tail === null) {
            head = null;
          } else {
            head = tail._1;
            tail = tail._2;
          }
        }
      }

      function resolve(fiber) {
        return function (result) {
          return function () {
            delete fibers[fiber._1];
            fiber._3 = result;
            join(result, fiber._2._1, fiber._2._2);
          };
        };
      }

      // Walks the applicative tree, substituting non-applicative nodes with
      // `FORKED` nodes. In this tree, all applicative nodes use the `_3` slot
      // as a mutable slot for memoization. In an unresolved state, the `_3`
      // slot is `EMPTY`. In the cases of `ALT` and `APPLY`, we always walk
      // the left side first, because both operations are left-associative. As
      // we `RETURN` from those branches, we then walk the right side.
      function run() {
        var status = CONTINUE;
        var step   = par;
        var head   = null;
        var tail   = null;
        var tmp, fid;

        loop: while (true) {
          tmp = null;
          fid = null;

          switch (status) {
          case CONTINUE:
            switch (step.tag) {
            case MAP:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }
              head = new Aff(MAP, step._1, EMPTY, EMPTY);
              step = step._2;
              break;
            case APPLY:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }
              head = new Aff(APPLY, EMPTY, step._2, EMPTY);
              step = step._1;
              break;
            case ALT:
              if (head) {
                tail = new Aff(CONS, head, tail);
              }
              head = new Aff(ALT, EMPTY, step._2, EMPTY);
              step = step._1;
              break;
            default:
              // When we hit a leaf value, we suspend the stack in the `FORKED`.
              // When the fiber resolves, it can bubble back up the tree.
              fid    = fiberId++;
              status = RETURN;
              tmp    = step;
              step   = new Aff(FORKED, fid, new Aff(CONS, head, tail), EMPTY);
              tmp    = Fiber(util, supervisor, tmp);
              tmp.onComplete({
                rethrow: false,
                handler: resolve(step)
              })();
              fibers[fid] = tmp;
              if (supervisor) {
                supervisor.register(tmp);
              }
            }
            break;
          case RETURN:
            // Terminal case, we are back at the root.
            if (head === null) {
              break loop;
            }
            // If we are done with the right side, we need to continue down the
            // left. Otherwise we should continue up the stack.
            if (head._1 === EMPTY) {
              head._1 = step;
              status  = CONTINUE;
              step    = head._2;
              head._2 = EMPTY;
            } else {
              head._2 = step;
              step    = head;
              if (tail === null) {
                head  = null;
              } else {
                head  = tail._1;
                tail  = tail._2;
              }
            }
          }
        }

        // Keep a reference to the tree root so it can be cancelled.
        root = step;

        for (fid = 0; fid < fiberId; fid++) {
          fibers[fid].run();
        }
      }

      // Cancels the entire tree. If there are already subtrees being canceled,
      // we need to first cancel those joins. We will then add fresh joins for
      // all pending branches including those that were in the process of being
      // canceled.
      function cancel(error, cb) {
        interrupt = util.left(error);
        var innerKills;
        for (var kid in kills) {
          if (kills.hasOwnProperty(kid)) {
            innerKills = kills[kid];
            for (kid in innerKills) {
              if (innerKills.hasOwnProperty(kid)) {
                innerKills[kid]();
              }
            }
          }
        }

        kills = null;
        var newKills = kill(error, root, cb);

        return function (killError) {
          return new Aff(ASYNC, function (killCb) {
            return function () {
              for (var kid in newKills) {
                if (newKills.hasOwnProperty(kid)) {
                  newKills[kid]();
                }
              }
              return nonCanceler;
            };
          });
        };
      }

      run();

      return function (killError) {
        return new Aff(ASYNC, function (killCb) {
          return function () {
            return cancel(killError, killCb);
          };
        });
      };
    }

    function sequential(util, supervisor, par) {
      return new Aff(ASYNC, function (cb) {
        return function () {
          return runPar(util, supervisor, par, cb);
        };
      });
    }

    Aff.EMPTY       = EMPTY;
    Aff.Pure        = AffCtr(PURE);
    Aff.Throw       = AffCtr(THROW);
    Aff.Catch       = AffCtr(CATCH);
    Aff.Sync        = AffCtr(SYNC);
    Aff.Async       = AffCtr(ASYNC);
    Aff.Bind        = AffCtr(BIND);
    Aff.Bracket     = AffCtr(BRACKET);
    Aff.Fork        = AffCtr(FORK);
    Aff.Seq         = AffCtr(SEQ);
    Aff.ParMap      = AffCtr(MAP);
    Aff.ParApply    = AffCtr(APPLY);
    Aff.ParAlt      = AffCtr(ALT);
    Aff.Fiber       = Fiber;
    Aff.Supervisor  = Supervisor;
    Aff.Scheduler   = Scheduler;
    Aff.nonCanceler = nonCanceler;

    return Aff;
  }();

  exports._pure = Aff.Pure;

  exports._throwError = Aff.Throw;

  exports._catchError = function (aff) {
    return function (k) {
      return Aff.Catch(aff, k);
    };
  };

  exports._map = function (f) {
    return function (aff) {
      if (aff.tag === Aff.Pure.tag) {
        return Aff.Pure(f(aff._1));
      } else {
        return Aff.Bind(aff, function (value) {
          return Aff.Pure(f(value));
        });
      }
    };
  };

  exports._bind = function (aff) {
    return function (k) {
      return Aff.Bind(aff, k);
    };
  };

  exports._fork = function (immediate) {
    return function (aff) {
      return Aff.Fork(immediate, aff);
    };
  };

  exports._liftEffect = Aff.Sync;

  exports._parAffMap = function (f) {
    return function (aff) {
      return Aff.ParMap(f, aff);
    };
  };

  exports._parAffApply = function (aff1) {
    return function (aff2) {
      return Aff.ParApply(aff1, aff2);
    };
  };

  exports.makeAff = Aff.Async;

  exports.generalBracket = function (acquire) {
    return function (options) {
      return function (k) {
        return Aff.Bracket(acquire, options, k);
      };
    };
  };

  exports._makeFiber = function (util, aff) {
    return function () {
      return Aff.Fiber(util, null, aff);
    };
  };

  exports._delay = function () {
    function setDelay(n, k) {
      if (n === 0 && typeof setImmediate !== "undefined") {
        return setImmediate(k);
      } else {
        return setTimeout(k, n);
      }
    }

    function clearDelay(n, t) {
      if (n === 0 && typeof clearImmediate !== "undefined") {
        return clearImmediate(t);
      } else {
        return clearTimeout(t);
      }
    }

    return function (right, ms) {
      return Aff.Async(function (cb) {
        return function () {
          var timer = setDelay(ms, cb(right()));
          return function () {
            return Aff.Sync(function () {
              return right(clearDelay(ms, timer));
            });
          };
        };
      });
    };
  }();

  exports._sequential = Aff.Seq;
})(PS["Effect.Aff"] = PS["Effect.Aff"] || {});
(function(exports) {
  "use strict";

  exports.pureE = function (a) {
    return function () {
      return a;
    };
  };

  exports.bindE = function (a) {
    return function (f) {
      return function () {
        return f(a())();
      };
    };
  };
})(PS["Effect"] = PS["Effect"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Effect"] = $PS["Effect"] || {};
  var exports = $PS["Effect"];
  var $foreign = $PS["Effect"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Data_Functor = $PS["Data.Functor"];                    
  var monadEffect = new Control_Monad.Monad(function () {
      return applicativeEffect;
  }, function () {
      return bindEffect;
  });
  var bindEffect = new Control_Bind.Bind(function () {
      return applyEffect;
  }, $foreign.bindE);
  var applyEffect = new Control_Apply.Apply(function () {
      return functorEffect;
  }, Control_Monad.ap(monadEffect));
  var applicativeEffect = new Control_Applicative.Applicative(function () {
      return applyEffect;
  }, $foreign.pureE);
  var functorEffect = new Data_Functor.Functor(Control_Applicative.liftA1(applicativeEffect));
  exports["functorEffect"] = functorEffect;
  exports["applyEffect"] = applyEffect;
  exports["applicativeEffect"] = applicativeEffect;
  exports["bindEffect"] = bindEffect;
  exports["monadEffect"] = monadEffect;
})(PS);
(function(exports) {
  "use strict";

  exports.new = function (val) {
    return function () {
      return { value: val };
    };
  };

  exports.read = function (ref) {
    return function () {
      return ref.value;
    };
  };

  exports.modifyImpl = function (f) {
    return function (ref) {
      return function () {
        var t = f(ref.value);
        ref.value = t.state;
        return t.value;
      };
    };
  };

  exports.write = function (val) {
    return function (ref) {
      return function () {
        ref.value = val;
      };
    };
  };
})(PS["Effect.Ref"] = PS["Effect.Ref"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Effect.Ref"] = $PS["Effect.Ref"] || {};
  var exports = $PS["Effect.Ref"];
  var $foreign = $PS["Effect.Ref"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect = $PS["Effect"];                
  var modify$prime = $foreign.modifyImpl;
  var modify = function (f) {
      return modify$prime(function (s) {
          var s$prime = f(s);
          return {
              state: s$prime,
              value: s$prime
          };
      });
  };
  var modify_ = function (f) {
      return function (s) {
          return Data_Functor["void"](Effect.functorEffect)(modify(f)(s));
      };
  };
  exports["modify'"] = modify$prime;
  exports["modify_"] = modify_;
  exports["new"] = $foreign["new"];
  exports["read"] = $foreign.read;
  exports["write"] = $foreign.write;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Monad.Rec.Class"] = $PS["Control.Monad.Rec.Class"] || {};
  var exports = $PS["Control.Monad.Rec.Class"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect = $PS["Effect"];
  var Effect_Ref = $PS["Effect.Ref"];                
  var Loop = (function () {
      function Loop(value0) {
          this.value0 = value0;
      };
      Loop.create = function (value0) {
          return new Loop(value0);
      };
      return Loop;
  })();
  var Done = (function () {
      function Done(value0) {
          this.value0 = value0;
      };
      Done.create = function (value0) {
          return new Done(value0);
      };
      return Done;
  })();
  var MonadRec = function (Monad0, tailRecM) {
      this.Monad0 = Monad0;
      this.tailRecM = tailRecM;
  };
  var tailRecM = function (dict) {
      return dict.tailRecM;
  }; 
  var monadRecEffect = new MonadRec(function () {
      return Effect.monadEffect;
  }, function (f) {
      return function (a) {
          var fromDone = function (v) {
              if (v instanceof Done) {
                  return v.value0;
              };
              throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 113, column 30 - line 113, column 44): " + [ v.constructor.name ]);
          };
          return function __do() {
              var r = Control_Bind.bindFlipped(Effect.bindEffect)(Effect_Ref["new"])(f(a))();
              (function () {
                  while (!(function __do() {
                      var v = Effect_Ref.read(r)();
                      if (v instanceof Loop) {
                          var e = f(v.value0)();
                          Effect_Ref.write(e)(r)();
                          return false;
                      };
                      if (v instanceof Done) {
                          return true;
                      };
                      throw new Error("Failed pattern match at Control.Monad.Rec.Class (line 104, column 22 - line 109, column 28): " + [ v.constructor.name ]);
                  })()) {

                  };
                  return {};
              })();
              return Data_Functor.map(Effect.functorEffect)(fromDone)(Effect_Ref.read(r))();
          };
      };
  });
  exports["Loop"] = Loop;
  exports["Done"] = Done;
  exports["MonadRec"] = MonadRec;
  exports["tailRecM"] = tailRecM;
  exports["monadRecEffect"] = monadRecEffect;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Parallel.Class"] = $PS["Control.Parallel.Class"] || {};
  var exports = $PS["Control.Parallel.Class"];       
  var Parallel = function (Applicative1, Monad0, parallel, sequential) {
      this.Applicative1 = Applicative1;
      this.Monad0 = Monad0;
      this.parallel = parallel;
      this.sequential = sequential;
  };
  var sequential = function (dict) {
      return dict.sequential;
  };
  var parallel = function (dict) {
      return dict.parallel;
  };
  exports["parallel"] = parallel;
  exports["sequential"] = sequential;
  exports["Parallel"] = Parallel;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Parallel"] = $PS["Control.Parallel"] || {};
  var exports = $PS["Control.Parallel"];
  var Control_Category = $PS["Control.Category"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Foldable = $PS["Data.Foldable"];                      
  var parTraverse_ = function (dictParallel) {
      return function (dictFoldable) {
          return function (f) {
              var $17 = Control_Parallel_Class.sequential(dictParallel);
              var $18 = Data_Foldable.traverse_(dictParallel.Applicative1())(dictFoldable)((function () {
                  var $20 = Control_Parallel_Class.parallel(dictParallel);
                  return function ($21) {
                      return $20(f($21));
                  };
              })());
              return function ($19) {
                  return $17($18($19));
              };
          };
      };
  };
  var parSequence_ = function (dictParallel) {
      return function (dictFoldable) {
          return parTraverse_(dictParallel)(dictFoldable)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  exports["parSequence_"] = parSequence_;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Effect.Class"] = $PS["Effect.Class"] || {};
  var exports = $PS["Effect.Class"];
  var Control_Category = $PS["Control.Category"];
  var Effect = $PS["Effect"];                
  var MonadEffect = function (Monad0, liftEffect) {
      this.Monad0 = Monad0;
      this.liftEffect = liftEffect;
  };
  var monadEffectEffect = new MonadEffect(function () {
      return Effect.monadEffect;
  }, Control_Category.identity(Control_Category.categoryFn));
  var liftEffect = function (dict) {
      return dict.liftEffect;
  };
  exports["liftEffect"] = liftEffect;
  exports["MonadEffect"] = MonadEffect;
  exports["monadEffectEffect"] = monadEffectEffect;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafePerformEffect = function (f) {
    return f();
  };
})(PS["Effect.Unsafe"] = PS["Effect.Unsafe"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Effect.Unsafe"] = $PS["Effect.Unsafe"] || {};
  var exports = $PS["Effect.Unsafe"];
  var $foreign = $PS["Effect.Unsafe"];
  exports["unsafePerformEffect"] = $foreign.unsafePerformEffect;
})(PS);
(function(exports) {
  "use strict";

  // module Partial.Unsafe

  exports._unsafePartial = function (f) {
    return f();
  };
})(PS["Partial.Unsafe"] = PS["Partial.Unsafe"] || {});
(function(exports) {
  "use strict";

  // module Partial

  exports._crashWith = function (msg) {
    throw new Error(msg);
  };
})(PS["Partial"] = PS["Partial"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Partial"] = $PS["Partial"] || {};
  var exports = $PS["Partial"];
  var $foreign = $PS["Partial"];         
  var crashWith = function (dictPartial) {
      return $foreign["_crashWith"];
  };
  exports["crashWith"] = crashWith;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Partial.Unsafe"] = $PS["Partial.Unsafe"] || {};
  var exports = $PS["Partial.Unsafe"];
  var $foreign = $PS["Partial.Unsafe"];
  var Partial = $PS["Partial"];                
  var unsafePartial = $foreign["_unsafePartial"];
  var unsafeCrashWith = function (msg) {
      return unsafePartial(function (dictPartial) {
          return Partial.crashWith()(msg);
      });
  };
  exports["unsafeCrashWith"] = unsafeCrashWith;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Effect.Aff"] = $PS["Effect.Aff"] || {};
  var exports = $PS["Effect.Aff"];
  var $foreign = $PS["Effect.Aff"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Control_Parallel = $PS["Control.Parallel"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Unsafe = $PS["Effect.Unsafe"];
  var Partial_Unsafe = $PS["Partial.Unsafe"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Canceler = function (x) {
      return x;
  };
  var suspendAff = $foreign["_fork"](false);
  var functorParAff = new Data_Functor.Functor($foreign["_parAffMap"]);
  var functorAff = new Data_Functor.Functor($foreign["_map"]);
  var forkAff = $foreign["_fork"](true);
  var ffiUtil = (function () {
      var unsafeFromRight = function (v) {
          if (v instanceof Data_Either.Right) {
              return v.value0;
          };
          if (v instanceof Data_Either.Left) {
              return Partial_Unsafe.unsafeCrashWith("unsafeFromRight: Left");
          };
          throw new Error("Failed pattern match at Effect.Aff (line 404, column 21 - line 406, column 54): " + [ v.constructor.name ]);
      };
      var unsafeFromLeft = function (v) {
          if (v instanceof Data_Either.Left) {
              return v.value0;
          };
          if (v instanceof Data_Either.Right) {
              return Partial_Unsafe.unsafeCrashWith("unsafeFromLeft: Right");
          };
          throw new Error("Failed pattern match at Effect.Aff (line 399, column 20 - line 401, column 54): " + [ v.constructor.name ]);
      };
      var isLeft = function (v) {
          if (v instanceof Data_Either.Left) {
              return true;
          };
          if (v instanceof Data_Either.Right) {
              return false;
          };
          throw new Error("Failed pattern match at Effect.Aff (line 394, column 12 - line 396, column 20): " + [ v.constructor.name ]);
      };
      return {
          isLeft: isLeft,
          fromLeft: unsafeFromLeft,
          fromRight: unsafeFromRight,
          left: Data_Either.Left.create,
          right: Data_Either.Right.create
      };
  })();
  var makeFiber = function (aff) {
      return $foreign["_makeFiber"](ffiUtil, aff);
  };
  var launchAff = function (aff) {
      return function __do() {
          var fiber = makeFiber(aff)();
          fiber.run();
          return fiber;
      };
  };
  var launchAff_ = (function () {
      var $40 = Data_Functor["void"](Effect.functorEffect);
      return function ($41) {
          return $40(launchAff($41));
      };
  })();                              
  var delay = function (v) {
      return $foreign["_delay"](Data_Either.Right.create, v);
  };
  var bracket = function (acquire) {
      return function (completed) {
          return $foreign.generalBracket(acquire)({
              killed: Data_Function["const"](completed),
              failed: Data_Function["const"](completed),
              completed: Data_Function["const"](completed)
          });
      };
  };
  var applyParAff = new Control_Apply.Apply(function () {
      return functorParAff;
  }, $foreign["_parAffApply"]);
  var monadAff = new Control_Monad.Monad(function () {
      return applicativeAff;
  }, function () {
      return bindAff;
  });
  var bindAff = new Control_Bind.Bind(function () {
      return applyAff;
  }, $foreign["_bind"]);
  var applyAff = new Control_Apply.Apply(function () {
      return functorAff;
  }, Control_Monad.ap(monadAff));
  var applicativeAff = new Control_Applicative.Applicative(function () {
      return applyAff;
  }, $foreign["_pure"]);
  var $$finally = function (fin) {
      return function (a) {
          return bracket(Control_Applicative.pure(applicativeAff)(Data_Unit.unit))(Data_Function["const"](fin))(Data_Function["const"](a));
      };
  };
  var monadEffectAff = new Effect_Class.MonadEffect(function () {
      return monadAff;
  }, $foreign["_liftEffect"]);
  var effectCanceler = (function () {
      var $42 = Effect_Class.liftEffect(monadEffectAff);
      return function ($43) {
          return Canceler(Data_Function["const"]($42($43)));
      };
  })();
  var joinFiber = function (v) {
      return $foreign.makeAff(function (k) {
          return Data_Functor.map(Effect.functorEffect)(effectCanceler)(v.join(k));
      });
  };
  var functorFiber = new Data_Functor.Functor(function (f) {
      return function (t) {
          return Effect_Unsafe.unsafePerformEffect(makeFiber(Data_Functor.map(functorAff)(f)(joinFiber(t))));
      };
  });
  var killFiber = function (e) {
      return function (v) {
          return Control_Bind.bind(bindAff)(Effect_Class.liftEffect(monadEffectAff)(v.isSuspended))(function (v1) {
              if (v1) {
                  return Effect_Class.liftEffect(monadEffectAff)(Data_Functor["void"](Effect.functorEffect)(v.kill(e, Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)))));
              };
              return $foreign.makeAff(function (k) {
                  return Data_Functor.map(Effect.functorEffect)(effectCanceler)(v.kill(e, k));
              });
          });
      };
  };   
  var monadThrowAff = new Control_Monad_Error_Class.MonadThrow(function () {
      return monadAff;
  }, $foreign["_throwError"]);
  var monadErrorAff = new Control_Monad_Error_Class.MonadError(function () {
      return monadThrowAff;
  }, $foreign["_catchError"]);                                  
  var runAff = function (k) {
      return function (aff) {
          return launchAff(Control_Bind.bindFlipped(bindAff)((function () {
              var $46 = Effect_Class.liftEffect(monadEffectAff);
              return function ($47) {
                  return $46(k($47));
              };
          })())(Control_Monad_Error_Class["try"](monadErrorAff)(aff)));
      };
  };
  var runAff_ = function (k) {
      return function (aff) {
          return Data_Functor["void"](Effect.functorEffect)(runAff(k)(aff));
      };
  };
  var parallelAff = new Control_Parallel_Class.Parallel(function () {
      return applicativeParAff;
  }, function () {
      return monadAff;
  }, Unsafe_Coerce.unsafeCoerce, $foreign["_sequential"]);
  var applicativeParAff = new Control_Applicative.Applicative(function () {
      return applyParAff;
  }, (function () {
      var $50 = Control_Parallel_Class.parallel(parallelAff);
      var $51 = Control_Applicative.pure(applicativeAff);
      return function ($52) {
          return $50($51($52));
      };
  })());
  var semigroupCanceler = new Data_Semigroup.Semigroup(function (v) {
      return function (v1) {
          return function (err) {
              return Control_Parallel.parSequence_(parallelAff)(Data_Foldable.foldableArray)([ v(err), v1(err) ]);
          };
      };
  });
  var monadRecAff = new Control_Monad_Rec_Class.MonadRec(function () {
      return monadAff;
  }, function (k) {
      var go = function (a) {
          return Control_Bind.bind(bindAff)(k(a))(function (res) {
              if (res instanceof Control_Monad_Rec_Class.Done) {
                  return Control_Applicative.pure(applicativeAff)(res.value0);
              };
              if (res instanceof Control_Monad_Rec_Class.Loop) {
                  return go(res.value0);
              };
              throw new Error("Failed pattern match at Effect.Aff (line 102, column 7 - line 104, column 22): " + [ res.constructor.name ]);
          });
      };
      return go;
  });
  var nonCanceler = Data_Function["const"](Control_Applicative.pure(applicativeAff)(Data_Unit.unit));
  var monoidCanceler = new Data_Monoid.Monoid(function () {
      return semigroupCanceler;
  }, nonCanceler);
  exports["launchAff_"] = launchAff_;
  exports["runAff_"] = runAff_;
  exports["forkAff"] = forkAff;
  exports["suspendAff"] = suspendAff;
  exports["delay"] = delay;
  exports["finally"] = $$finally;
  exports["killFiber"] = killFiber;
  exports["joinFiber"] = joinFiber;
  exports["nonCanceler"] = nonCanceler;
  exports["effectCanceler"] = effectCanceler;
  exports["functorAff"] = functorAff;
  exports["applicativeAff"] = applicativeAff;
  exports["bindAff"] = bindAff;
  exports["monadAff"] = monadAff;
  exports["monadRecAff"] = monadRecAff;
  exports["monadThrowAff"] = monadThrowAff;
  exports["monadErrorAff"] = monadErrorAff;
  exports["monadEffectAff"] = monadEffectAff;
  exports["applicativeParAff"] = applicativeParAff;
  exports["parallelAff"] = parallelAff;
  exports["functorFiber"] = functorFiber;
  exports["monoidCanceler"] = monoidCanceler;
  exports["makeAff"] = $foreign.makeAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Monad.Fork.Class"] = $PS["Control.Monad.Fork.Class"] || {};
  var exports = $PS["Control.Monad.Fork.Class"];
  var Effect_Aff = $PS["Effect.Aff"];                
  var MonadFork = function (Functor1, Monad0, fork, join, suspend) {
      this.Functor1 = Functor1;
      this.Monad0 = Monad0;
      this.fork = fork;
      this.join = join;
      this.suspend = suspend;
  };
  var monadForkAff = new MonadFork(function () {
      return Effect_Aff.functorFiber;
  }, function () {
      return Effect_Aff.monadAff;
  }, Effect_Aff.forkAff, Effect_Aff.joinFiber, Effect_Aff.suspendAff);
  var fork = function (dict) {
      return dict.fork;
  };
  exports["fork"] = fork;
  exports["monadForkAff"] = monadForkAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.List"] = $PS["Data.List"] || {};
  var exports = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var reverse = (function () {
      var go = function ($copy_acc) {
          return function ($copy_v) {
              var $tco_var_acc = $copy_acc;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(acc, v) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return acc;
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
                      $copy_v = v.value1;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.List (line 372, column 3 - line 372, column 19): " + [ acc.constructor.name, v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_acc, $copy_v);
              };
              return $tco_result;
          };
      };
      return go(Data_List_Types.Nil.value);
  })();
  var $$null = function (v) {
      if (v instanceof Data_List_Types.Nil) {
          return true;
      };
      return false;
  };
  exports["null"] = $$null;
  exports["reverse"] = reverse;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.CatQueue"] = $PS["Data.CatQueue"] || {};
  var exports = $PS["Data.CatQueue"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];                            
  var CatQueue = (function () {
      function CatQueue(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      CatQueue.create = function (value0) {
          return function (value1) {
              return new CatQueue(value0, value1);
          };
      };
      return CatQueue;
  })();
  var uncons = function ($copy_v) {
      var $tco_done = false;
      var $tco_result;
      function $tco_loop(v) {
          if (v.value0 instanceof Data_List_Types.Nil && v.value1 instanceof Data_List_Types.Nil) {
              $tco_done = true;
              return Data_Maybe.Nothing.value;
          };
          if (v.value0 instanceof Data_List_Types.Nil) {
              $copy_v = new CatQueue(Data_List.reverse(v.value1), Data_List_Types.Nil.value);
              return;
          };
          if (v.value0 instanceof Data_List_Types.Cons) {
              $tco_done = true;
              return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0.value0, new CatQueue(v.value0.value1, v.value1)));
          };
          throw new Error("Failed pattern match at Data.CatQueue (line 83, column 1 - line 83, column 63): " + [ v.constructor.name ]);
      };
      while (!$tco_done) {
          $tco_result = $tco_loop($copy_v);
      };
      return $tco_result;
  };
  var snoc = function (v) {
      return function (a) {
          return new CatQueue(v.value0, new Data_List_Types.Cons(a, v.value1));
      };
  };
  var $$null = function (v) {
      if (v.value0 instanceof Data_List_Types.Nil && v.value1 instanceof Data_List_Types.Nil) {
          return true;
      };
      return false;
  };                                                                                                
  var empty = new CatQueue(Data_List_Types.Nil.value, Data_List_Types.Nil.value);
  exports["empty"] = empty;
  exports["null"] = $$null;
  exports["snoc"] = snoc;
  exports["uncons"] = uncons;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.CatList"] = $PS["Data.CatList"] || {};
  var exports = $PS["Data.CatList"];
  var Data_CatQueue = $PS["Data.CatQueue"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Tuple = $PS["Data.Tuple"];                            
  var CatNil = (function () {
      function CatNil() {

      };
      CatNil.value = new CatNil();
      return CatNil;
  })();
  var CatCons = (function () {
      function CatCons(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      CatCons.create = function (value0) {
          return function (value1) {
              return new CatCons(value0, value1);
          };
      };
      return CatCons;
  })();
  var link = function (v) {
      return function (v1) {
          if (v instanceof CatNil) {
              return v1;
          };
          if (v1 instanceof CatNil) {
              return v;
          };
          if (v instanceof CatCons) {
              return new CatCons(v.value0, Data_CatQueue.snoc(v.value1)(v1));
          };
          throw new Error("Failed pattern match at Data.CatList (line 109, column 1 - line 109, column 54): " + [ v.constructor.name, v1.constructor.name ]);
      };
  };
  var foldr = function (k) {
      return function (b) {
          return function (q) {
              var foldl = function ($copy_v) {
                  return function ($copy_c) {
                      return function ($copy_v1) {
                          var $tco_var_v = $copy_v;
                          var $tco_var_c = $copy_c;
                          var $tco_done = false;
                          var $tco_result;
                          function $tco_loop(v, c, v1) {
                              if (v1 instanceof Data_List_Types.Nil) {
                                  $tco_done = true;
                                  return c;
                              };
                              if (v1 instanceof Data_List_Types.Cons) {
                                  $tco_var_v = v;
                                  $tco_var_c = v(c)(v1.value0);
                                  $copy_v1 = v1.value1;
                                  return;
                              };
                              throw new Error("Failed pattern match at Data.CatList (line 125, column 3 - line 125, column 59): " + [ v.constructor.name, c.constructor.name, v1.constructor.name ]);
                          };
                          while (!$tco_done) {
                              $tco_result = $tco_loop($tco_var_v, $tco_var_c, $copy_v1);
                          };
                          return $tco_result;
                      };
                  };
              };
              var go = function ($copy_xs) {
                  return function ($copy_ys) {
                      var $tco_var_xs = $copy_xs;
                      var $tco_done1 = false;
                      var $tco_result;
                      function $tco_loop(xs, ys) {
                          var v = Data_CatQueue.uncons(xs);
                          if (v instanceof Data_Maybe.Nothing) {
                              $tco_done1 = true;
                              return foldl(function (x) {
                                  return function (i) {
                                      return i(x);
                                  };
                              })(b)(ys);
                          };
                          if (v instanceof Data_Maybe.Just) {
                              $tco_var_xs = v.value0.value1;
                              $copy_ys = new Data_List_Types.Cons(k(v.value0.value0), ys);
                              return;
                          };
                          throw new Error("Failed pattern match at Data.CatList (line 121, column 14 - line 123, column 67): " + [ v.constructor.name ]);
                      };
                      while (!$tco_done1) {
                          $tco_result = $tco_loop($tco_var_xs, $copy_ys);
                      };
                      return $tco_result;
                  };
              };
              return go(q)(Data_List_Types.Nil.value);
          };
      };
  };
  var uncons = function (v) {
      if (v instanceof CatNil) {
          return Data_Maybe.Nothing.value;
      };
      if (v instanceof CatCons) {
          return new Data_Maybe.Just(new Data_Tuple.Tuple(v.value0, (function () {
              var $44 = Data_CatQueue["null"](v.value1);
              if ($44) {
                  return CatNil.value;
              };
              return foldr(link)(CatNil.value)(v.value1);
          })()));
      };
      throw new Error("Failed pattern match at Data.CatList (line 100, column 1 - line 100, column 61): " + [ v.constructor.name ]);
  };
  var empty = CatNil.value;
  var append = link;
  var semigroupCatList = new Data_Semigroup.Semigroup(append);
  var snoc = function (cat) {
      return function (a) {
          return append(cat)(new CatCons(a, Data_CatQueue.empty));
      };
  };
  exports["empty"] = empty;
  exports["snoc"] = snoc;
  exports["uncons"] = uncons;
  exports["semigroupCatList"] = semigroupCatList;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Monad.Free"] = $PS["Control.Monad.Free"] || {};
  var exports = $PS["Control.Monad.Free"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Data_CatList = $PS["Data.CatList"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Free = (function () {
      function Free(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Free.create = function (value0) {
          return function (value1) {
              return new Free(value0, value1);
          };
      };
      return Free;
  })();
  var Return = (function () {
      function Return(value0) {
          this.value0 = value0;
      };
      Return.create = function (value0) {
          return new Return(value0);
      };
      return Return;
  })();
  var Bind = (function () {
      function Bind(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Bind.create = function (value0) {
          return function (value1) {
              return new Bind(value0, value1);
          };
      };
      return Bind;
  })();
  var toView = function ($copy_v) {
      var $tco_done = false;
      var $tco_result;
      function $tco_loop(v) {
          var runExpF = function (v2) {
              return v2;
          };
          var concatF = function (v2) {
              return function (r) {
                  return new Free(v2.value0, Data_Semigroup.append(Data_CatList.semigroupCatList)(v2.value1)(r));
              };
          };
          if (v.value0 instanceof Return) {
              var v2 = Data_CatList.uncons(v.value1);
              if (v2 instanceof Data_Maybe.Nothing) {
                  $tco_done = true;
                  return new Return(v.value0.value0);
              };
              if (v2 instanceof Data_Maybe.Just) {
                  $copy_v = concatF(runExpF(v2.value0.value0)(v.value0.value0))(v2.value0.value1);
                  return;
              };
              throw new Error("Failed pattern match at Control.Monad.Free (line 227, column 7 - line 231, column 64): " + [ v2.constructor.name ]);
          };
          if (v.value0 instanceof Bind) {
              $tco_done = true;
              return new Bind(v.value0.value0, function (a) {
                  return concatF(v.value0.value1(a))(v.value1);
              });
          };
          throw new Error("Failed pattern match at Control.Monad.Free (line 225, column 3 - line 233, column 56): " + [ v.value0.constructor.name ]);
      };
      while (!$tco_done) {
          $tco_result = $tco_loop($copy_v);
      };
      return $tco_result;
  };
  var fromView = function (f) {
      return new Free(f, Data_CatList.empty);
  };
  var freeMonad = new Control_Monad.Monad(function () {
      return freeApplicative;
  }, function () {
      return freeBind;
  });
  var freeFunctor = new Data_Functor.Functor(function (k) {
      return function (f) {
          return Control_Bind.bindFlipped(freeBind)((function () {
              var $120 = Control_Applicative.pure(freeApplicative);
              return function ($121) {
                  return $120(k($121));
              };
          })())(f);
      };
  });
  var freeBind = new Control_Bind.Bind(function () {
      return freeApply;
  }, function (v) {
      return function (k) {
          return new Free(v.value0, Data_CatList.snoc(v.value1)(k));
      };
  });
  var freeApply = new Control_Apply.Apply(function () {
      return freeFunctor;
  }, Control_Monad.ap(freeMonad));
  var freeApplicative = new Control_Applicative.Applicative(function () {
      return freeApply;
  }, function ($122) {
      return fromView(Return.create($122));
  });
  var liftF = function (f) {
      return fromView(new Bind(f, (function () {
          var $123 = Control_Applicative.pure(freeApplicative);
          return function ($124) {
              return $123($124);
          };
      })()));
  };
  var substFree = function (k) {
      var go = function (f) {
          var v = toView(f);
          if (v instanceof Return) {
              return Control_Applicative.pure(freeApplicative)(v.value0);
          };
          if (v instanceof Bind) {
              return Control_Bind.bind(freeBind)(k(v.value0))(Data_Functor.map(Data_Functor.functorFn)(go)(v.value1));
          };
          throw new Error("Failed pattern match at Control.Monad.Free (line 168, column 10 - line 170, column 33): " + [ v.constructor.name ]);
      };
      return go;
  };
  var hoistFree = function (k) {
      return substFree(function ($125) {
          return liftF(k($125));
      });
  };
  var foldFree = function (dictMonadRec) {
      return function (k) {
          var go = function (f) {
              var v = toView(f);
              if (v instanceof Return) {
                  return Data_Functor.map((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0())(Control_Monad_Rec_Class.Done.create)(Control_Applicative.pure((dictMonadRec.Monad0()).Applicative0())(v.value0));
              };
              if (v instanceof Bind) {
                  return Data_Functor.map((((dictMonadRec.Monad0()).Bind1()).Apply0()).Functor0())(function ($136) {
                      return Control_Monad_Rec_Class.Loop.create(v.value1($136));
                  })(k(v.value0));
              };
              throw new Error("Failed pattern match at Control.Monad.Free (line 158, column 10 - line 160, column 37): " + [ v.constructor.name ]);
          };
          return Control_Monad_Rec_Class.tailRecM(dictMonadRec)(go);
      };
  };
  exports["liftF"] = liftF;
  exports["hoistFree"] = hoistFree;
  exports["foldFree"] = foldFree;
  exports["freeFunctor"] = freeFunctor;
  exports["freeBind"] = freeBind;
  exports["freeApplicative"] = freeApplicative;
  exports["freeApply"] = freeApply;
  exports["freeMonad"] = freeMonad;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Monad.Trans.Class"] = $PS["Control.Monad.Trans.Class"] || {};
  var exports = $PS["Control.Monad.Trans.Class"];
  var MonadTrans = function (lift) {
      this.lift = lift;
  };
  var lift = function (dict) {
      return dict.lift;
  };
  exports["lift"] = lift;
  exports["MonadTrans"] = MonadTrans;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Monad.Reader.Trans"] = $PS["Control.Monad.Reader.Trans"] || {};
  var exports = $PS["Control.Monad.Reader.Trans"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Effect_Class = $PS["Effect.Class"];                
  var ReaderT = function (x) {
      return x;
  };
  var runReaderT = function (v) {
      return v;
  }; 
  var monadTransReaderT = new Control_Monad_Trans_Class.MonadTrans(function (dictMonad) {
      return function ($64) {
          return ReaderT(Data_Function["const"]($64));
      };
  });
  var mapReaderT = function (f) {
      return function (v) {
          return function ($65) {
              return f(v($65));
          };
      };
  };
  var functorReaderT = function (dictFunctor) {
      return new Data_Functor.Functor((function () {
          var $66 = Data_Functor.map(dictFunctor);
          return function ($67) {
              return mapReaderT($66($67));
          };
      })());
  };
  var applyReaderT = function (dictApply) {
      return new Control_Apply.Apply(function () {
          return functorReaderT(dictApply.Functor0());
      }, function (v) {
          return function (v1) {
              return function (r) {
                  return Control_Apply.apply(dictApply)(v(r))(v1(r));
              };
          };
      });
  };
  var bindReaderT = function (dictBind) {
      return new Control_Bind.Bind(function () {
          return applyReaderT(dictBind.Apply0());
      }, function (v) {
          return function (k) {
              return function (r) {
                  return Control_Bind.bind(dictBind)(v(r))(function (a) {
                      var v1 = k(a);
                      return v1(r);
                  });
              };
          };
      });
  };
  var applicativeReaderT = function (dictApplicative) {
      return new Control_Applicative.Applicative(function () {
          return applyReaderT(dictApplicative.Apply0());
      }, (function () {
          var $71 = Control_Applicative.pure(dictApplicative);
          return function ($72) {
              return ReaderT(Data_Function["const"]($71($72)));
          };
      })());
  };
  var monadReaderT = function (dictMonad) {
      return new Control_Monad.Monad(function () {
          return applicativeReaderT(dictMonad.Applicative0());
      }, function () {
          return bindReaderT(dictMonad.Bind1());
      });
  };
  var monadEffectReader = function (dictMonadEffect) {
      return new Effect_Class.MonadEffect(function () {
          return monadReaderT(dictMonadEffect.Monad0());
      }, (function () {
          var $74 = Control_Monad_Trans_Class.lift(monadTransReaderT)(dictMonadEffect.Monad0());
          var $75 = Effect_Class.liftEffect(dictMonadEffect);
          return function ($76) {
              return $74($75($76));
          };
      })());
  };
  exports["runReaderT"] = runReaderT;
  exports["applicativeReaderT"] = applicativeReaderT;
  exports["bindReaderT"] = bindReaderT;
  exports["monadReaderT"] = monadReaderT;
  exports["monadTransReaderT"] = monadTransReaderT;
  exports["monadEffectReader"] = monadEffectReader;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Monad.State.Class"] = $PS["Control.Monad.State.Class"] || {};
  var exports = $PS["Control.Monad.State.Class"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];                
  var MonadState = function (Monad0, state) {
      this.Monad0 = Monad0;
      this.state = state;
  };
  var state = function (dict) {
      return dict.state;
  };
  var modify_ = function (dictMonadState) {
      return function (f) {
          return state(dictMonadState)(function (s) {
              return new Data_Tuple.Tuple(Data_Unit.unit, f(s));
          });
      };
  };
  var modify = function (dictMonadState) {
      return function (f) {
          return state(dictMonadState)(function (s) {
              var s$prime = f(s);
              return new Data_Tuple.Tuple(s$prime, s$prime);
          });
      };
  };
  var gets = function (dictMonadState) {
      return function (f) {
          return state(dictMonadState)(function (s) {
              return new Data_Tuple.Tuple(f(s), s);
          });
      };
  };
  var get = function (dictMonadState) {
      return state(dictMonadState)(function (s) {
          return new Data_Tuple.Tuple(s, s);
      });
  };
  exports["MonadState"] = MonadState;
  exports["get"] = get;
  exports["gets"] = gets;
  exports["modify"] = modify;
  exports["modify_"] = modify_;
})(PS);
(function(exports) {
    

  exports.thenImpl = function(promise) {
    return function(errCB) {
      return function(succCB) {
        return function() {
          promise.then(succCB, errCB);
        };
      };
    };
  };
})(PS["Control.Promise"] = PS["Control.Promise"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Identity"] = $PS["Data.Identity"] || {};
  var exports = $PS["Data.Identity"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Data_Functor = $PS["Data.Functor"];          
  var Identity = function (x) {
      return x;
  };
  var functorIdentity = new Data_Functor.Functor(function (f) {
      return function (m) {
          return f(m);
      };
  });
  var applyIdentity = new Control_Apply.Apply(function () {
      return functorIdentity;
  }, function (v) {
      return function (v1) {
          return v(v1);
      };
  });
  var bindIdentity = new Control_Bind.Bind(function () {
      return applyIdentity;
  }, function (v) {
      return function (f) {
          return f(v);
      };
  });
  var applicativeIdentity = new Control_Applicative.Applicative(function () {
      return applyIdentity;
  }, Identity);
  var monadIdentity = new Control_Monad.Monad(function () {
      return applicativeIdentity;
  }, function () {
      return bindIdentity;
  });
  exports["functorIdentity"] = functorIdentity;
  exports["monadIdentity"] = monadIdentity;
})(PS);
(function(exports) {
  "use strict";

  exports.error = function (msg) {
    return new Error(msg);
  };

  exports.message = function (e) {
    return e.message;
  };

  exports.name = function (e) {
    return e.name || "Error";
  };

  exports.throwException = function (e) {
    return function () {
      throw e;
    };
  };
})(PS["Effect.Exception"] = PS["Effect.Exception"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Effect.Exception"] = $PS["Effect.Exception"] || {};
  var exports = $PS["Effect.Exception"];
  var $foreign = $PS["Effect.Exception"];
  var $$throw = function ($2) {
      return $foreign.throwException($foreign.error($2));
  };
  exports["throw"] = $$throw;
  exports["error"] = $foreign.error;
  exports["message"] = $foreign.message;
  exports["name"] = $foreign.name;
  exports["throwException"] = $foreign.throwException;
})(PS);
(function(exports) {
  "use strict";

  exports.typeOf = function (value) {
    return typeof value;
  };

  exports.tagOf = function (value) {
    return Object.prototype.toString.call(value).slice(8, -1);
  };

  exports.isNull = function (value) {
    return value === null;
  };

  exports.isUndefined = function (value) {
    return value === undefined;
  };
})(PS["Foreign"] = PS["Foreign"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Boolean"] = $PS["Data.Boolean"] || {};
  var exports = $PS["Data.Boolean"];
  var otherwise = true;
  exports["otherwise"] = otherwise;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Foreign"] = $PS["Foreign"] || {};
  var exports = $PS["Foreign"];
  var $foreign = $PS["Foreign"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_List_NonEmpty = $PS["Data.List.NonEmpty"];
  var Data_Show = $PS["Data.Show"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var ForeignError = (function () {
      function ForeignError(value0) {
          this.value0 = value0;
      };
      ForeignError.create = function (value0) {
          return new ForeignError(value0);
      };
      return ForeignError;
  })();
  var TypeMismatch = (function () {
      function TypeMismatch(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      TypeMismatch.create = function (value0) {
          return function (value1) {
              return new TypeMismatch(value0, value1);
          };
      };
      return TypeMismatch;
  })();
  var ErrorAtIndex = (function () {
      function ErrorAtIndex(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      ErrorAtIndex.create = function (value0) {
          return function (value1) {
              return new ErrorAtIndex(value0, value1);
          };
      };
      return ErrorAtIndex;
  })();
  var ErrorAtProperty = (function () {
      function ErrorAtProperty(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      ErrorAtProperty.create = function (value0) {
          return function (value1) {
              return new ErrorAtProperty(value0, value1);
          };
      };
      return ErrorAtProperty;
  })();
  var unsafeToForeign = Unsafe_Coerce.unsafeCoerce;
  var unsafeFromForeign = Unsafe_Coerce.unsafeCoerce;
  var showForeignError = new Data_Show.Show(function (v) {
      if (v instanceof ForeignError) {
          return "(ForeignError " + (Data_Show.show(Data_Show.showString)(v.value0) + ")");
      };
      if (v instanceof ErrorAtIndex) {
          return "(ErrorAtIndex " + (Data_Show.show(Data_Show.showInt)(v.value0) + (" " + (Data_Show.show(showForeignError)(v.value1) + ")")));
      };
      if (v instanceof ErrorAtProperty) {
          return "(ErrorAtProperty " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(showForeignError)(v.value1) + ")")));
      };
      if (v instanceof TypeMismatch) {
          return "(TypeMismatch " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(Data_Show.showString)(v.value1) + ")")));
      };
      throw new Error("Failed pattern match at Foreign (line 64, column 1 - line 68, column 89): " + [ v.constructor.name ]);
  });
  var fail = function (dictMonad) {
      var $118 = Control_Monad_Error_Class.throwError(Control_Monad_Except_Trans.monadThrowExceptT(dictMonad));
      return function ($119) {
          return $118(Data_List_NonEmpty.singleton($119));
      };
  };
  var unsafeReadTagged = function (dictMonad) {
      return function (tag) {
          return function (value) {
              if ($foreign.tagOf(value) === tag) {
                  return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(dictMonad))(unsafeFromForeign(value));
              };
              if (Data_Boolean.otherwise) {
                  return fail(dictMonad)(new TypeMismatch(tag, $foreign.tagOf(value)));
              };
              throw new Error("Failed pattern match at Foreign (line 110, column 1 - line 110, column 71): " + [ tag.constructor.name, value.constructor.name ]);
          };
      };
  };
  var readBoolean = function (dictMonad) {
      return unsafeReadTagged(dictMonad)("Boolean");
  };
  var readString = function (dictMonad) {
      return unsafeReadTagged(dictMonad)("String");
  };
  exports["TypeMismatch"] = TypeMismatch;
  exports["ErrorAtProperty"] = ErrorAtProperty;
  exports["unsafeToForeign"] = unsafeToForeign;
  exports["unsafeReadTagged"] = unsafeReadTagged;
  exports["readString"] = readString;
  exports["readBoolean"] = readBoolean;
  exports["fail"] = fail;
  exports["showForeignError"] = showForeignError;
  exports["typeOf"] = $foreign.typeOf;
  exports["isNull"] = $foreign.isNull;
  exports["isUndefined"] = $foreign.isUndefined;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Control.Promise"] = $PS["Control.Promise"] || {};
  var exports = $PS["Control.Promise"];
  var $foreign = $PS["Control.Promise"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Except = $PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Foreign = $PS["Foreign"];                
  var toAff$prime = function (customCoerce) {
      return function (p) {
          return Effect_Aff.makeAff(function (cb) {
              return Data_Functor.voidRight(Effect.functorEffect)(Data_Monoid.mempty(Effect_Aff.monoidCanceler))($foreign.thenImpl(p)(function ($1) {
                  return cb(Data_Either.Left.create(customCoerce($1)))();
              })(function ($2) {
                  return cb(Data_Either.Right.create($2))();
              }));
          });
      };
  };
  var coerce = function (fn) {
      return Data_Either.either(function (v) {
          return Effect_Exception.error("Promise failed, couldn't extract JS Error or String");
      })(Control_Category.identity(Control_Category.categoryFn))(Control_Monad_Except.runExcept(Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(Foreign.unsafeReadTagged(Data_Identity.monadIdentity)("Error")(fn))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Effect_Exception.error)(Foreign.readString(Data_Identity.monadIdentity)(fn)))));
  };
  var toAff = toAff$prime(coerce);
  var toAffE = function (f) {
      return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(f))(toAff);
  };
  exports["toAffE"] = toAffE;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["DOM.HTML.Indexed.InputType"] = $PS["DOM.HTML.Indexed.InputType"] || {};
  var exports = $PS["DOM.HTML.Indexed.InputType"];
  var InputButton = (function () {
      function InputButton() {

      };
      InputButton.value = new InputButton();
      return InputButton;
  })();
  var InputCheckbox = (function () {
      function InputCheckbox() {

      };
      InputCheckbox.value = new InputCheckbox();
      return InputCheckbox;
  })();
  var InputColor = (function () {
      function InputColor() {

      };
      InputColor.value = new InputColor();
      return InputColor;
  })();
  var InputDate = (function () {
      function InputDate() {

      };
      InputDate.value = new InputDate();
      return InputDate;
  })();
  var InputDatetimeLocal = (function () {
      function InputDatetimeLocal() {

      };
      InputDatetimeLocal.value = new InputDatetimeLocal();
      return InputDatetimeLocal;
  })();
  var InputEmail = (function () {
      function InputEmail() {

      };
      InputEmail.value = new InputEmail();
      return InputEmail;
  })();
  var InputFile = (function () {
      function InputFile() {

      };
      InputFile.value = new InputFile();
      return InputFile;
  })();
  var InputHidden = (function () {
      function InputHidden() {

      };
      InputHidden.value = new InputHidden();
      return InputHidden;
  })();
  var InputImage = (function () {
      function InputImage() {

      };
      InputImage.value = new InputImage();
      return InputImage;
  })();
  var InputMonth = (function () {
      function InputMonth() {

      };
      InputMonth.value = new InputMonth();
      return InputMonth;
  })();
  var InputNumber = (function () {
      function InputNumber() {

      };
      InputNumber.value = new InputNumber();
      return InputNumber;
  })();
  var InputPassword = (function () {
      function InputPassword() {

      };
      InputPassword.value = new InputPassword();
      return InputPassword;
  })();
  var InputRadio = (function () {
      function InputRadio() {

      };
      InputRadio.value = new InputRadio();
      return InputRadio;
  })();
  var InputRange = (function () {
      function InputRange() {

      };
      InputRange.value = new InputRange();
      return InputRange;
  })();
  var InputReset = (function () {
      function InputReset() {

      };
      InputReset.value = new InputReset();
      return InputReset;
  })();
  var InputSearch = (function () {
      function InputSearch() {

      };
      InputSearch.value = new InputSearch();
      return InputSearch;
  })();
  var InputSubmit = (function () {
      function InputSubmit() {

      };
      InputSubmit.value = new InputSubmit();
      return InputSubmit;
  })();
  var InputTel = (function () {
      function InputTel() {

      };
      InputTel.value = new InputTel();
      return InputTel;
  })();
  var InputText = (function () {
      function InputText() {

      };
      InputText.value = new InputText();
      return InputText;
  })();
  var InputTime = (function () {
      function InputTime() {

      };
      InputTime.value = new InputTime();
      return InputTime;
  })();
  var InputUrl = (function () {
      function InputUrl() {

      };
      InputUrl.value = new InputUrl();
      return InputUrl;
  })();
  var InputWeek = (function () {
      function InputWeek() {

      };
      InputWeek.value = new InputWeek();
      return InputWeek;
  })();
  var renderInputType = function (v) {
      if (v instanceof InputButton) {
          return "button";
      };
      if (v instanceof InputCheckbox) {
          return "checkbox";
      };
      if (v instanceof InputColor) {
          return "color";
      };
      if (v instanceof InputDate) {
          return "date";
      };
      if (v instanceof InputDatetimeLocal) {
          return "datetime-local";
      };
      if (v instanceof InputEmail) {
          return "email";
      };
      if (v instanceof InputFile) {
          return "file";
      };
      if (v instanceof InputHidden) {
          return "hidden";
      };
      if (v instanceof InputImage) {
          return "image";
      };
      if (v instanceof InputMonth) {
          return "month";
      };
      if (v instanceof InputNumber) {
          return "number";
      };
      if (v instanceof InputPassword) {
          return "password";
      };
      if (v instanceof InputRadio) {
          return "radio";
      };
      if (v instanceof InputRange) {
          return "range";
      };
      if (v instanceof InputReset) {
          return "reset";
      };
      if (v instanceof InputSearch) {
          return "search";
      };
      if (v instanceof InputSubmit) {
          return "submit";
      };
      if (v instanceof InputTel) {
          return "tel";
      };
      if (v instanceof InputText) {
          return "text";
      };
      if (v instanceof InputTime) {
          return "time";
      };
      if (v instanceof InputUrl) {
          return "url";
      };
      if (v instanceof InputWeek) {
          return "week";
      };
      throw new Error("Failed pattern match at DOM.HTML.Indexed.InputType (line 28, column 19 - line 50, column 22): " + [ v.constructor.name ]);
  };
  exports["InputNumber"] = InputNumber;
  exports["renderInputType"] = renderInputType;
})(PS);
(function(exports) {
  /* eslint-disable no-eq-null, eqeqeq */
  "use strict";

  function id(x) {
    return x;
  }                        
  exports.fromNumber = id;
  exports.fromString = id;
  exports.fromArray = id;
  exports.fromObject = id;

  exports.stringify = function (j) {
    return JSON.stringify(j);
  };

  function isArray(a) {
    return Object.prototype.toString.call(a) === "[object Array]";
  }

  exports._caseJson = function (isNull, isBool, isNum, isStr, isArr, isObj, j) {
    if (j == null) return isNull();
    else if (typeof j === "boolean") return isBool(j);
    else if (typeof j === "number") return isNum(j);
    else if (typeof j === "string") return isStr(j);
    else if (Object.prototype.toString.call(j) === "[object Array]")
      return isArr(j);
    else return isObj(j);
  };

  exports._compare = function _compare(EQ, GT, LT, a, b) {
    if (a == null) {
      if (b == null) return EQ;
      else return LT;
    } else if (typeof a === "boolean") {
      if (typeof b === "boolean") {
        // boolean / boolean
        if (a === b) return EQ;
        else if (a === false) return LT;
        else return GT;
      } else if (b == null) return GT;
      else return LT;
    } else if (typeof a === "number") {
      if (typeof b === "number") {
        if (a === b) return EQ;
        else if (a < b) return LT;
        else return GT;
      } else if (b == null) return GT;
      else if (typeof b === "boolean") return GT;
      else return LT;
    } else if (typeof a === "string") {
      if (typeof b === "string") {
        if (a === b) return EQ;
        else if (a < b) return LT;
        else return GT;
      } else if (b == null) return GT;
      else if (typeof b === "boolean") return GT;
      else if (typeof b === "number") return GT;
      else return LT;
    } else if (isArray(a)) {
      if (isArray(b)) {
        for (var i = 0; i < Math.min(a.length, b.length); i++) {
          var ca = _compare(EQ, GT, LT, a[i], b[i]);
          if (ca !== EQ) return ca;
        }
        if (a.length === b.length) return EQ;
        else if (a.length < b.length) return LT;
        else return GT;
      } else if (b == null) return GT;
      else if (typeof b === "boolean") return GT;
      else if (typeof b === "number") return GT;
      else if (typeof b === "string") return GT;
      else return LT;
    } else {
      if (b == null) return GT;
      else if (typeof b === "boolean") return GT;
      else if (typeof b === "number") return GT;
      else if (typeof b === "string") return GT;
      else if (isArray(b)) return GT;
      else {
        var akeys = Object.keys(a);
        var bkeys = Object.keys(b);
        if (akeys.length < bkeys.length) return LT;
        else if (akeys.length > bkeys.length) return GT;
        var keys = akeys.concat(bkeys).sort();
        for (var j = 0; j < keys.length; j++) {
          var k = keys[j];
          if (a[k] === undefined) return LT;
          else if (b[k] === undefined) return GT;
          var ck = _compare(EQ, GT, LT, a[k], b[k]);
          if (ck !== EQ) return ck;
        }
        return EQ;
      }
    }
  };
})(PS["Data.Argonaut.Core"] = PS["Data.Argonaut.Core"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Argonaut.Core"] = $PS["Data.Argonaut.Core"] || {};
  var exports = $PS["Data.Argonaut.Core"];
  var $foreign = $PS["Data.Argonaut.Core"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];                  
  var verbJsonType = function (def) {
      return function (f) {
          return function (g) {
              return g(def)(f);
          };
      };
  };
  var toJsonType = verbJsonType(Data_Maybe.Nothing.value)(Data_Maybe.Just.create);
  var isJsonType = verbJsonType(false)(Data_Function["const"](true));
  var ordJson = new Data_Ord.Ord(function () {
      return eqJson;
  }, function (a) {
      return function (b) {
          return $foreign["_compare"](Data_Ordering.EQ.value, Data_Ordering.GT.value, Data_Ordering.LT.value, a, b);
      };
  });
  var eqJson = new Data_Eq.Eq(function (j1) {
      return function (j2) {
          return Data_Eq.eq(Data_Ordering.eqOrdering)(Data_Ord.compare(ordJson)(j1)(j2))(Data_Ordering.EQ.value);
      };
  });
  var caseJsonString = function (d) {
      return function (f) {
          return function (j) {
              return $foreign["_caseJson"](Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), f, Data_Function["const"](d), Data_Function["const"](d), j);
          };
      };
  };                                        
  var toString = toJsonType(caseJsonString);
  var caseJsonObject = function (d) {
      return function (f) {
          return function (j) {
              return $foreign["_caseJson"](Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), f, j);
          };
      };
  };                                        
  var toObject = toJsonType(caseJsonObject);
  var caseJsonNumber = function (d) {
      return function (f) {
          return function (j) {
              return $foreign["_caseJson"](Data_Function["const"](d), Data_Function["const"](d), f, Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), j);
          };
      };
  };                                        
  var caseJsonNull = function (d) {
      return function (f) {
          return function (j) {
              return $foreign["_caseJson"](f, Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), Data_Function["const"](d), j);
          };
      };
  };
  var isNull = isJsonType(caseJsonNull);
  exports["caseJsonNumber"] = caseJsonNumber;
  exports["caseJsonString"] = caseJsonString;
  exports["isNull"] = isNull;
  exports["toString"] = toString;
  exports["toObject"] = toObject;
  exports["eqJson"] = eqJson;
  exports["fromNumber"] = $foreign.fromNumber;
  exports["fromString"] = $foreign.fromString;
  exports["fromArray"] = $foreign.fromArray;
  exports["fromObject"] = $foreign.fromObject;
  exports["stringify"] = $foreign.stringify;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Argonaut.Decode.Error"] = $PS["Data.Argonaut.Decode.Error"] || {};
  var exports = $PS["Data.Argonaut.Decode.Error"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Show = $PS["Data.Show"];                
  var TypeMismatch = (function () {
      function TypeMismatch(value0) {
          this.value0 = value0;
      };
      TypeMismatch.create = function (value0) {
          return new TypeMismatch(value0);
      };
      return TypeMismatch;
  })();
  var UnexpectedValue = (function () {
      function UnexpectedValue(value0) {
          this.value0 = value0;
      };
      UnexpectedValue.create = function (value0) {
          return new UnexpectedValue(value0);
      };
      return UnexpectedValue;
  })();
  var AtIndex = (function () {
      function AtIndex(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      AtIndex.create = function (value0) {
          return function (value1) {
              return new AtIndex(value0, value1);
          };
      };
      return AtIndex;
  })();
  var AtKey = (function () {
      function AtKey(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      AtKey.create = function (value0) {
          return function (value1) {
              return new AtKey(value0, value1);
          };
      };
      return AtKey;
  })();
  var Named = (function () {
      function Named(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Named.create = function (value0) {
          return function (value1) {
              return new Named(value0, value1);
          };
      };
      return Named;
  })();
  var MissingValue = (function () {
      function MissingValue() {

      };
      MissingValue.value = new MissingValue();
      return MissingValue;
  })();
  var showJsonDecodeError = new Data_Show.Show(function (v) {
      if (v instanceof TypeMismatch) {
          return "(TypeMismatch " + (Data_Show.show(Data_Show.showString)(v.value0) + ")");
      };
      if (v instanceof UnexpectedValue) {
          return "(UnexpectedValue " + (Data_Argonaut_Core.stringify(v.value0) + ")");
      };
      if (v instanceof AtIndex) {
          return "(AtIndex " + (Data_Show.show(Data_Show.showInt)(v.value0) + (" " + (Data_Show.show(showJsonDecodeError)(v.value1) + ")")));
      };
      if (v instanceof AtKey) {
          return "(AtKey " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(showJsonDecodeError)(v.value1) + ")")));
      };
      if (v instanceof Named) {
          return "(Named " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(showJsonDecodeError)(v.value1) + ")")));
      };
      if (v instanceof MissingValue) {
          return "MissingValue";
      };
      throw new Error("Failed pattern match at Data.Argonaut.Decode.Error (line 24, column 10 - line 30, column 35): " + [ v.constructor.name ]);
  });
  exports["TypeMismatch"] = TypeMismatch;
  exports["UnexpectedValue"] = UnexpectedValue;
  exports["AtKey"] = AtKey;
  exports["Named"] = Named;
  exports["MissingValue"] = MissingValue;
  exports["showJsonDecodeError"] = showJsonDecodeError;
})(PS);
(function(exports) {
  "use strict";

  exports.fromNumberImpl = function (just) {
    return function (nothing) {
      return function (n) {
        /* jshint bitwise: false */
        return (n | 0) === n ? just(n) : nothing;
      };
    };
  };

  exports.toNumber = function (n) {
    return n;
  };

  exports.fromStringAsImpl = function (just) {
    return function (nothing) {
      return function (radix) {
        var digits;
        if (radix < 11) {
          digits = "[0-" + (radix - 1).toString() + "]";
        } else if (radix === 11) {
          digits = "[0-9a]";
        } else {
          digits = "[0-9a-" + String.fromCharCode(86 + radix) + "]";
        }
        var pattern = new RegExp("^[\\+\\-]?" + digits + "+$", "i");

        return function (s) {
          /* jshint bitwise: false */
          if (pattern.test(s)) {
            var i = parseInt(s, radix);
            return (i | 0) === i ? just(i) : nothing;
          } else {
            return nothing;
          }
        };
      };
    };
  };
})(PS["Data.Int"] = PS["Data.Int"] || {});
(function(exports) {
  "use strict";

  exports.topInt = 2147483647;
  exports.bottomInt = -2147483648;

  exports.topChar = String.fromCharCode(65535);
  exports.bottomChar = String.fromCharCode(0);
})(PS["Data.Bounded"] = PS["Data.Bounded"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Bounded"] = $PS["Data.Bounded"] || {};
  var exports = $PS["Data.Bounded"];
  var $foreign = $PS["Data.Bounded"];
  var Data_Ord = $PS["Data.Ord"];
  var Bounded = function (Ord0, bottom, top) {
      this.Ord0 = Ord0;
      this.bottom = bottom;
      this.top = top;
  };
  var top = function (dict) {
      return dict.top;
  };                                            
  var boundedInt = new Bounded(function () {
      return Data_Ord.ordInt;
  }, $foreign.bottomInt, $foreign.topInt);
  var boundedChar = new Bounded(function () {
      return Data_Ord.ordChar;
  }, $foreign.bottomChar, $foreign.topChar);
  var bottom = function (dict) {
      return dict.bottom;
  };
  exports["bottom"] = bottom;
  exports["top"] = top;
  exports["boundedInt"] = boundedInt;
  exports["boundedChar"] = boundedChar;
})(PS);
(function(exports) {
  /* globals exports */
  "use strict";               

  exports.isFinite = isFinite;
})(PS["Data.Number"] = PS["Data.Number"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Number"] = $PS["Data.Number"] || {};
  var exports = $PS["Data.Number"];
  var $foreign = $PS["Data.Number"];
  exports["isFinite"] = $foreign["isFinite"];
})(PS);
(function(exports) {
  "use strict";          

  exports.floor = Math.floor;

  function nativeImul(a) {
    return function (b) {
      return Math.imul(a, b);
    };
  }

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/imul
  function emulatedImul(a) {
    /*jshint bitwise: false*/
    return function (b) {
      var ah = a >>> 16 & 0xffff;
      var al = a & 0xffff;
      var bh = b >>> 16 & 0xffff;
      var bl = b & 0xffff;
      // the shift by 0 fixes the sign on the high part
      // the final |0 converts the unsigned value into a signed value
      return al * bl + (ah * bl + al * bh << 16 >>> 0) | 0;
    };
  }
})(PS["Math"] = PS["Math"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Math"] = $PS["Math"] || {};
  var exports = $PS["Math"];
  var $foreign = $PS["Math"];
  exports["floor"] = $foreign.floor;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Int"] = $PS["Data.Int"] || {};
  var exports = $PS["Data.Int"];
  var $foreign = $PS["Data.Int"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Bounded = $PS["Data.Bounded"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Number = $PS["Data.Number"];
  var $$Math = $PS["Math"];
  var fromStringAs = $foreign.fromStringAsImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var fromString = fromStringAs(10);
  var fromNumber = $foreign.fromNumberImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var unsafeClamp = function (x) {
      if (!Data_Number["isFinite"](x)) {
          return 0;
      };
      if (x >= $foreign.toNumber(Data_Bounded.top(Data_Bounded.boundedInt))) {
          return Data_Bounded.top(Data_Bounded.boundedInt);
      };
      if (x <= $foreign.toNumber(Data_Bounded.bottom(Data_Bounded.boundedInt))) {
          return Data_Bounded.bottom(Data_Bounded.boundedInt);
      };
      if (Data_Boolean.otherwise) {
          return Data_Maybe.fromMaybe(0)(fromNumber(x));
      };
      throw new Error("Failed pattern match at Data.Int (line 66, column 1 - line 66, column 29): " + [ x.constructor.name ]);
  };
  var floor = function ($24) {
      return unsafeClamp($$Math.floor($24));
  };
  exports["fromNumber"] = fromNumber;
  exports["floor"] = floor;
  exports["fromString"] = fromString;
  exports["toNumber"] = $foreign.toNumber;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Argonaut.Decode.Decoders"] = $PS["Data.Argonaut.Decode.Decoders"] || {};
  var exports = $PS["Data.Argonaut.Decode.Decoders"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Argonaut_Decode_Error = $PS["Data.Argonaut.Decode.Error"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Int = $PS["Data.Int"];
  var Data_Maybe = $PS["Data.Maybe"];
  var decodeString = Data_Argonaut_Core.caseJsonString(Data_Either.Left.create(new Data_Argonaut_Decode_Error.TypeMismatch("String")))(Data_Either.Right.create);
  var decodeNumber = Data_Argonaut_Core.caseJsonNumber(Data_Either.Left.create(new Data_Argonaut_Decode_Error.TypeMismatch("Number")))(Data_Either.Right.create);
  var decodeMaybe = function (decoder) {
      return function (json) {
          if (Data_Argonaut_Core.isNull(json)) {
              return Control_Applicative.pure(Data_Either.applicativeEither)(Data_Maybe.Nothing.value);
          };
          if (Data_Boolean.otherwise) {
              return Data_Functor.map(Data_Either.functorEither)(Data_Maybe.Just.create)(decoder(json));
          };
          throw new Error("Failed pattern match at Data.Argonaut.Decode.Decoders (line 37, column 1 - line 41, column 38): " + [ decoder.constructor.name, json.constructor.name ]);
      };
  };
  var decodeInt = Control_Bind.composeKleisliFlipped(Data_Either.bindEither)((function () {
      var $54 = Data_Either.note(new Data_Argonaut_Decode_Error.TypeMismatch("Integer"));
      return function ($55) {
          return $54(Data_Int.fromNumber($55));
      };
  })())(decodeNumber);
  exports["decodeMaybe"] = decodeMaybe;
  exports["decodeInt"] = decodeInt;
  exports["decodeString"] = decodeString;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Bifunctor"] = $PS["Data.Bifunctor"] || {};
  var exports = $PS["Data.Bifunctor"];
  var Control_Category = $PS["Control.Category"];
  var Data_Either = $PS["Data.Either"];
  var Data_Tuple = $PS["Data.Tuple"];                
  var Bifunctor = function (bimap) {
      this.bimap = bimap;
  };
  var bimap = function (dict) {
      return dict.bimap;
  };
  var lmap = function (dictBifunctor) {
      return function (f) {
          return bimap(dictBifunctor)(f)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  var bifunctorTuple = new Bifunctor(function (f) {
      return function (g) {
          return function (v) {
              return new Data_Tuple.Tuple(f(v.value0), g(v.value1));
          };
      };
  });
  var bifunctorEither = new Bifunctor(function (v) {
      return function (v1) {
          return function (v2) {
              if (v2 instanceof Data_Either.Left) {
                  return new Data_Either.Left(v(v2.value0));
              };
              if (v2 instanceof Data_Either.Right) {
                  return new Data_Either.Right(v1(v2.value0));
              };
              throw new Error("Failed pattern match at Data.Bifunctor (line 32, column 1 - line 34, column 36): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name ]);
          };
      };
  });
  exports["bimap"] = bimap;
  exports["Bifunctor"] = Bifunctor;
  exports["lmap"] = lmap;
  exports["bifunctorEither"] = bifunctorEither;
  exports["bifunctorTuple"] = bifunctorTuple;
})(PS);
(function(exports) {
  "use strict";

  exports._copyST = function (m) {
    return function () {
      var r = {};
      for (var k in m) {
        if (hasOwnProperty.call(m, k)) {
          r[k] = m[k];
        }
      }
      return r;
    };
  };

  exports.empty = {};

  exports.runST = function (f) {
    return f();
  };

  exports._lookup = function (no, yes, k, m) {
    return k in m ? yes(m[k]) : no;
  };

  function toArrayWithKey(f) {
    return function (m) {
      var r = [];
      for (var k in m) {
        if (hasOwnProperty.call(m, k)) {
          r.push(f(k)(m[k]));
        }
      }
      return r;
    };
  }
})(PS["Foreign.Object"] = PS["Foreign.Object"] || {});
(function(exports) {
  "use strict";

  exports.runFn3 = function (fn) {
    return function (a) {
      return function (b) {
        return function (c) {
          return fn(a, b, c);
        };
      };
    };
  };

  exports.runFn4 = function (fn) {
    return function (a) {
      return function (b) {
        return function (c) {
          return function (d) {
            return fn(a, b, c, d);
          };
        };
      };
    };
  };
})(PS["Data.Function.Uncurried"] = PS["Data.Function.Uncurried"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Function.Uncurried"] = $PS["Data.Function.Uncurried"] || {};
  var exports = $PS["Data.Function.Uncurried"];
  var $foreign = $PS["Data.Function.Uncurried"];
  exports["runFn3"] = $foreign.runFn3;
  exports["runFn4"] = $foreign.runFn4;
})(PS);
(function(exports) {
  "use strict";

  exports["new"] = function () {
    return {};
  };

  exports.poke = function (k) {
    return function (v) {
      return function (m) {
        return function () {
          m[k] = v;
          return m;
        };
      };
    };
  };
})(PS["Foreign.Object.ST"] = PS["Foreign.Object.ST"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Foreign.Object.ST"] = $PS["Foreign.Object.ST"] || {};
  var exports = $PS["Foreign.Object.ST"];
  var $foreign = $PS["Foreign.Object.ST"];
  exports["new"] = $foreign["new"];
  exports["poke"] = $foreign.poke;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Foreign.Object"] = $PS["Foreign.Object"] || {};
  var exports = $PS["Foreign.Object"];
  var $foreign = $PS["Foreign.Object"];
  var Data_Function_Uncurried = $PS["Data.Function.Uncurried"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Foreign_Object_ST = $PS["Foreign.Object.ST"];              
  var thawST = $foreign["_copyST"];
  var mutate = function (f) {
      return function (m) {
          return $foreign.runST(function __do() {
              var s = thawST(m)();
              f(s)();
              return s;
          });
      };
  };
  var lookup = Data_Function_Uncurried.runFn4($foreign["_lookup"])(Data_Maybe.Nothing.value)(Data_Maybe.Just.create);
  var insert = function (k) {
      return function (v) {
          return mutate(Foreign_Object_ST.poke(k)(v));
      };
  };
  exports["insert"] = insert;
  exports["lookup"] = lookup;
  exports["empty"] = $foreign.empty;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Record"] = $PS["Record"] || {};
  var exports = $PS["Record"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Record_Unsafe = $PS["Record.Unsafe"];
  var set = function (dictIsSymbol) {
      return function (dictCons) {
          return function (dictCons1) {
              return function (l) {
                  return function (b) {
                      return function (r) {
                          return Record_Unsafe.unsafeSet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(b)(r);
                      };
                  };
              };
          };
      };
  };
  var insert = function (dictIsSymbol) {
      return function (dictLacks) {
          return function (dictCons) {
              return function (l) {
                  return function (a) {
                      return function (r) {
                          return Record_Unsafe.unsafeSet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(a)(r);
                      };
                  };
              };
          };
      };
  };
  var get = function (dictIsSymbol) {
      return function (dictCons) {
          return function (l) {
              return function (r) {
                  return Record_Unsafe.unsafeGet(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(r);
              };
          };
      };
  };
  exports["get"] = get;
  exports["set"] = set;
  exports["insert"] = insert;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Argonaut.Decode.Class"] = $PS["Data.Argonaut.Decode.Class"] || {};
  var exports = $PS["Data.Argonaut.Decode.Class"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Argonaut_Decode_Decoders = $PS["Data.Argonaut.Decode.Decoders"];
  var Data_Argonaut_Decode_Error = $PS["Data.Argonaut.Decode.Error"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Record = $PS["Record"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var GDecodeJson = function (gDecodeJson) {
      this.gDecodeJson = gDecodeJson;
  };
  var DecodeJsonField = function (decodeJsonField) {
      this.decodeJsonField = decodeJsonField;
  };
  var DecodeJson = function (decodeJson) {
      this.decodeJson = decodeJson;
  };
  var gDecodeJsonNil = new GDecodeJson(function (v) {
      return function (v1) {
          return new Data_Either.Right({});
      };
  });
  var gDecodeJson = function (dict) {
      return dict.gDecodeJson;
  };                                                                        
  var decodeRecord = function (dictGDecodeJson) {
      return function (dictRowToList) {
          return new DecodeJson(function (json) {
              var v = Data_Argonaut_Core.toObject(json);
              if (v instanceof Data_Maybe.Just) {
                  return gDecodeJson(dictGDecodeJson)(v.value0)(Type_Proxy["Proxy"].value);
              };
              if (v instanceof Data_Maybe.Nothing) {
                  return Data_Either.Left.create(new Data_Argonaut_Decode_Error.TypeMismatch("Object"));
              };
              throw new Error("Failed pattern match at Data.Argonaut.Decode.Class (line 103, column 5 - line 105, column 46): " + [ v.constructor.name ]);
          });
      };
  };
  var decodeJsonString = new DecodeJson(Data_Argonaut_Decode_Decoders.decodeString);
  var decodeJsonInt = new DecodeJson(Data_Argonaut_Decode_Decoders.decodeInt);
  var decodeJsonField = function (dict) {
      return dict.decodeJsonField;
  };
  var gDecodeJsonCons = function (dictDecodeJsonField) {
      return function (dictGDecodeJson) {
          return function (dictIsSymbol) {
              return function (dictCons) {
                  return function (dictLacks) {
                      return new GDecodeJson(function (object) {
                          return function (v) {
                              var fieldName = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
                              var fieldValue = Foreign_Object.lookup(fieldName)(object);
                              var v1 = decodeJsonField(dictDecodeJsonField)(fieldValue);
                              if (v1 instanceof Data_Maybe.Just) {
                                  return Control_Bind.bind(Data_Either.bindEither)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Argonaut_Decode_Error.AtKey.create(fieldName))(v1.value0))(function (val) {
                                      return Control_Bind.bind(Data_Either.bindEither)(gDecodeJson(dictGDecodeJson)(object)(Type_Proxy["Proxy"].value))(function (rest) {
                                          return Data_Either.Right.create(Record.insert(dictIsSymbol)()()(Type_Proxy["Proxy"].value)(val)(rest));
                                      });
                                  });
                              };
                              if (v1 instanceof Data_Maybe.Nothing) {
                                  return Data_Either.Left.create(new Data_Argonaut_Decode_Error.AtKey(fieldName, Data_Argonaut_Decode_Error.MissingValue.value));
                              };
                              throw new Error("Failed pattern match at Data.Argonaut.Decode.Class (line 127, column 5 - line 134, column 44): " + [ v1.constructor.name ]);
                          };
                      });
                  };
              };
          };
      };
  };                                                                                  
  var decodeJson = function (dict) {
      return dict.decodeJson;
  };
  var decodeJsonMaybe = function (dictDecodeJson) {
      return new DecodeJson(Data_Argonaut_Decode_Decoders.decodeMaybe(decodeJson(dictDecodeJson)));
  };
  var decodeFieldId = function (dictDecodeJson) {
      return new DecodeJsonField(function (j) {
          return Data_Functor.map(Data_Maybe.functorMaybe)(decodeJson(dictDecodeJson))(j);
      });
  };
  exports["decodeJson"] = decodeJson;
  exports["DecodeJson"] = DecodeJson;
  exports["decodeJsonMaybe"] = decodeJsonMaybe;
  exports["decodeJsonInt"] = decodeJsonInt;
  exports["decodeJsonString"] = decodeJsonString;
  exports["decodeRecord"] = decodeRecord;
  exports["gDecodeJsonNil"] = gDecodeJsonNil;
  exports["gDecodeJsonCons"] = gDecodeJsonCons;
  exports["decodeFieldId"] = decodeFieldId;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Argonaut.Types.Generic"] = $PS["Data.Argonaut.Types.Generic"] || {};
  var exports = $PS["Data.Argonaut.Types.Generic"];
  var defaultEncoding = {
      tagKey: "tag",
      valuesKey: "values",
      unwrapSingleArguments: false
  };
  exports["defaultEncoding"] = defaultEncoding;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Generic.Rep"] = $PS["Data.Generic.Rep"] || {};
  var exports = $PS["Data.Generic.Rep"];             
  var Inl = (function () {
      function Inl(value0) {
          this.value0 = value0;
      };
      Inl.create = function (value0) {
          return new Inl(value0);
      };
      return Inl;
  })();
  var Inr = (function () {
      function Inr(value0) {
          this.value0 = value0;
      };
      Inr.create = function (value0) {
          return new Inr(value0);
      };
      return Inr;
  })();
  var NoArguments = (function () {
      function NoArguments() {

      };
      NoArguments.value = new NoArguments();
      return NoArguments;
  })();
  var Generic = function (from, to) {
      this.from = from;
      this.to = to;
  };
  var Constructor = function (x) {
      return x;
  };
  var Argument = function (x) {
      return x;
  };
  var to = function (dict) {
      return dict.to;
  };
  var from = function (dict) {
      return dict.from;
  };
  exports["Generic"] = Generic;
  exports["to"] = to;
  exports["from"] = from;
  exports["NoArguments"] = NoArguments;
  exports["Inl"] = Inl;
  exports["Inr"] = Inr;
  exports["Constructor"] = Constructor;
  exports["Argument"] = Argument;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Argonaut.Decode.Generic"] = $PS["Data.Argonaut.Decode.Generic"] || {};
  var exports = $PS["Data.Argonaut.Decode.Generic"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Argonaut_Decode_Error = $PS["Data.Argonaut.Decode.Error"];
  var Data_Argonaut_Types_Generic = $PS["Data.Argonaut.Types.Generic"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var DecodeRepArgs = function (decodeRepArgs) {
      this.decodeRepArgs = decodeRepArgs;
  };
  var DecodeRep = function (decodeRepWith) {
      this.decodeRepWith = decodeRepWith;
  };
  var withTag = function (e) {
      return function (j) {
          return function (name) {
              var decodingErr = Data_Argonaut_Decode_Error.Named.create(name);
              return Control_Bind.bind(Data_Either.bindEither)(Data_Either.note(decodingErr(new Data_Argonaut_Decode_Error.TypeMismatch("Object")))(Data_Argonaut_Core.toObject(j)))(function (jObj) {
                  return Control_Bind.bind(Data_Either.bindEither)(Data_Either.note(decodingErr(new Data_Argonaut_Decode_Error.AtKey(e.tagKey, Data_Argonaut_Decode_Error.MissingValue.value)))(Foreign_Object.lookup(e.tagKey)(jObj)))(function (jTag) {
                      return Control_Bind.bind(Data_Either.bindEither)(Data_Either.note(decodingErr(Data_Argonaut_Decode_Error.AtKey.create(e.tagKey)(new Data_Argonaut_Decode_Error.TypeMismatch("String"))))(Data_Argonaut_Core.toString(jTag)))(function (tag) {
                          return Control_Bind.discard(Control_Bind.discardUnit)(Data_Either.bindEither)(Control_Applicative.when(Data_Either.applicativeEither)(tag !== name)(Data_Either.Left.create(decodingErr(Data_Argonaut_Decode_Error.AtKey.create(e.tagKey)(Data_Argonaut_Decode_Error.UnexpectedValue.create(Data_Argonaut_Core.fromString(tag)))))))(function () {
                              return Control_Applicative.pure(Data_Either.applicativeEither)({
                                  tag: tag,
                                  decodingErr: decodingErr
                              });
                          });
                      });
                  });
              });
          };
      };
  };
  var decodeRepWith = function (dict) {
      return dict.decodeRepWith;
  };
  var genericDecodeJsonWith = function (dictGeneric) {
      return function (dictDecodeRep) {
          return function (e) {
              var $63 = Data_Functor.map(Data_Either.functorEither)(Data_Generic_Rep.to(dictGeneric));
              var $64 = decodeRepWith(dictDecodeRep)(e);
              return function ($65) {
                  return $63($64($65));
              };
          };
      };
  };
  var genericDecodeJson = function (dictGeneric) {
      return function (dictDecodeRep) {
          return genericDecodeJsonWith(dictGeneric)(dictDecodeRep)(Data_Argonaut_Types_Generic.defaultEncoding);
      };
  }; 
  var decodeRepArgsNoArguments = new DecodeRepArgs(function (js) {
      return new Data_Either.Right({
          init: Data_Generic_Rep.NoArguments.value,
          rest: js
      });
  });
  var decodeRepArgs = function (dict) {
      return dict.decodeRepArgs;
  };
  var construct = function (dictDecodeRepArgs) {
      return function (e) {
          return function (valuesArray) {
              return function (decodingErr) {
                  return Control_Bind.bind(Data_Either.bindEither)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(decodingErr)(decodeRepArgs(dictDecodeRepArgs)(valuesArray)))(function (v) {
                      return Control_Bind.discard(Control_Bind.discardUnit)(Data_Either.bindEither)(Control_Applicative.when(Data_Either.applicativeEither)(Data_Eq.notEq(Data_Eq.eqArray(Data_Argonaut_Core.eqJson))(v.rest)([  ]))(Data_Either.Left.create(decodingErr(Data_Argonaut_Decode_Error.AtKey.create(e.valuesKey)(new Data_Argonaut_Decode_Error.UnexpectedValue(Data_Argonaut_Core.fromArray(v.rest)))))))(function () {
                          return Control_Applicative.pure(Data_Either.applicativeEither)(v.init);
                      });
                  });
              };
          };
      };
  };
  var decodeRepConstructorNoArgs = function (dictIsSymbol) {
      return new DecodeRep(function (e) {
          return function (j) {
              var name = Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value);
              return Control_Bind.bind(Data_Either.bindEither)(withTag(e)(j)(name))(function (v) {
                  return construct(decodeRepArgsNoArguments)(e)([  ])(v.decodingErr);
              });
          };
      });
  };
  exports["genericDecodeJson"] = genericDecodeJson;
  exports["decodeRepConstructorNoArgs"] = decodeRepConstructorNoArgs;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Argonaut.Encode.Encoders"] = $PS["Data.Argonaut.Encode.Encoders"] || {};
  var exports = $PS["Data.Argonaut.Encode.Encoders"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Int = $PS["Data.Int"];
  var encodeString = Data_Argonaut_Core.fromString;
  var encodeInt = function ($48) {
      return Data_Argonaut_Core.fromNumber(Data_Int.toNumber($48));
  };
  exports["encodeInt"] = encodeInt;
  exports["encodeString"] = encodeString;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Argonaut.Encode.Class"] = $PS["Data.Argonaut.Encode.Class"] || {};
  var exports = $PS["Data.Argonaut.Encode.Class"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Argonaut_Encode_Encoders = $PS["Data.Argonaut.Encode.Encoders"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Record = $PS["Record"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var GEncodeJson = function (gEncodeJson) {
      this.gEncodeJson = gEncodeJson;
  };
  var EncodeJson = function (encodeJson) {
      this.encodeJson = encodeJson;
  };
  var gEncodeJsonNil = new GEncodeJson(function (v) {
      return function (v1) {
          return Foreign_Object.empty;
      };
  });
  var gEncodeJson = function (dict) {
      return dict.gEncodeJson;
  };                                                                        
  var encodeRecord = function (dictGEncodeJson) {
      return function (dictRowToList) {
          return new EncodeJson(function (rec) {
              return Data_Argonaut_Core.fromObject(gEncodeJson(dictGEncodeJson)(rec)(Type_Proxy["Proxy"].value));
          });
      };
  };                                                                                          
  var encodeJsonJString = new EncodeJson(Data_Argonaut_Encode_Encoders.encodeString);  
  var encodeJsonInt = new EncodeJson(Data_Argonaut_Encode_Encoders.encodeInt);  
  var encodeJson = function (dict) {
      return dict.encodeJson;
  };
  var gEncodeJsonCons = function (dictEncodeJson) {
      return function (dictGEncodeJson) {
          return function (dictIsSymbol) {
              return function (dictCons) {
                  return new GEncodeJson(function (row) {
                      return function (v) {
                          return Foreign_Object.insert(Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value))(encodeJson(dictEncodeJson)(Record.get(dictIsSymbol)()(Type_Proxy["Proxy"].value)(row)))(gEncodeJson(dictGEncodeJson)(row)(Type_Proxy["Proxy"].value));
                      };
                  });
              };
          };
      };
  };
  exports["encodeJson"] = encodeJson;
  exports["EncodeJson"] = EncodeJson;
  exports["encodeJsonInt"] = encodeJsonInt;
  exports["encodeJsonJString"] = encodeJsonJString;
  exports["encodeRecord"] = encodeRecord;
  exports["gEncodeJsonNil"] = gEncodeJsonNil;
  exports["gEncodeJsonCons"] = gEncodeJsonCons;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Argonaut.Encode.Generic"] = $PS["Data.Argonaut.Encode.Generic"] || {};
  var exports = $PS["Data.Argonaut.Encode.Generic"];
  var Data_Argonaut_Core = $PS["Data.Argonaut.Core"];
  var Data_Argonaut_Types_Generic = $PS["Data.Argonaut.Types.Generic"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var EncodeRepArgs = function (encodeRepArgs) {
      this.encodeRepArgs = encodeRepArgs;
  };
  var EncodeRep = function (encodeRepWith) {
      this.encodeRepWith = encodeRepWith;
  };
  var encodeRepWith = function (dict) {
      return dict.encodeRepWith;
  };
  var genericEncodeJsonWith = function (dictGeneric) {
      return function (dictEncodeRep) {
          return function (e) {
              var $47 = encodeRepWith(dictEncodeRep)(e);
              var $48 = Data_Generic_Rep.from(dictGeneric);
              return function ($49) {
                  return $47($48($49));
              };
          };
      };
  };
  var genericEncodeJson = function (dictGeneric) {
      return function (dictEncodeRep) {
          return genericEncodeJsonWith(dictGeneric)(dictEncodeRep)(Data_Argonaut_Types_Generic.defaultEncoding);
      };
  }; 
  var encodeRepArgsNoArguments = new EncodeRepArgs(function (v) {
      return [  ];
  });
  var encodeRepArgs = function (dict) {
      return dict.encodeRepArgs;
  };
  var encodeRepConstructor = function (dictIsSymbol) {
      return function (dictEncodeRepArgs) {
          return new EncodeRep(function (e) {
              return function (v) {
                  var values = (function () {
                      var vs = encodeRepArgs(dictEncodeRepArgs)(v);
                      if (e.unwrapSingleArguments) {
                          if (vs.length === 1) {
                              return vs[0];
                          };
                          return Data_Argonaut_Core.fromArray(vs);
                      };
                      return Data_Argonaut_Core.fromArray(vs);
                  })();
                  return Data_Argonaut_Core.fromObject(Foreign_Object.insert(e.tagKey)(Data_Argonaut_Core.fromString(Data_Symbol.reflectSymbol(dictIsSymbol)(Type_Proxy["Proxy"].value)))(Foreign_Object.insert(e.valuesKey)(values)(Foreign_Object.empty)));
              };
          });
      };
  };
  exports["genericEncodeJson"] = genericEncodeJson;
  exports["encodeRepConstructor"] = encodeRepConstructor;
  exports["encodeRepArgsNoArguments"] = encodeRepArgsNoArguments;
})(PS);
(function(exports) {
  "use strict";

  //------------------------------------------------------------------------------
  // Array size ------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.length = function (xs) {
    return xs.length;
  };

  //------------------------------------------------------------------------------
  // Non-indexed reads -----------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.unconsImpl = function (empty) {
    return function (next) {
      return function (xs) {
        return xs.length === 0 ? empty({}) : next(xs[0])(xs.slice(1));
      };
    };
  };

  //------------------------------------------------------------------------------
  // Indexed operations ----------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.indexImpl = function (just) {
    return function (nothing) {
      return function (xs) {
        return function (i) {
          return i < 0 || i >= xs.length ? nothing :  just(xs[i]);
        };
      };
    };
  };

  exports.findIndexImpl = function (just) {
    return function (nothing) {
      return function (f) {
        return function (xs) {
          for (var i = 0, l = xs.length; i < l; i++) {
            if (f(xs[i])) return just(i);
          }
          return nothing;
        };
      };
    };
  };

  exports._deleteAt = function (just) {
    return function (nothing) {
      return function (i) {
        return function (l) {
          if (i < 0 || i >= l.length) return nothing;
          var l1 = l.slice();
          l1.splice(i, 1);
          return just(l1);
        };
      };
    };
  };   

  //------------------------------------------------------------------------------
  // Subarrays -------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.slice = function (s) {
    return function (e) {
      return function (l) {
        return l.slice(s, e);
      };
    };
  };

  //------------------------------------------------------------------------------
  // Partial ---------------------------------------------------------------------
  //------------------------------------------------------------------------------

  exports.unsafeIndexImpl = function (xs) {
    return function (n) {
      return xs[n];
    };
  };
})(PS["Data.Array"] = PS["Data.Array"] || {});
(function(exports) {
  "use strict";

  exports.pushAll = function (as) {
    return function (xs) {
      return function () {
        return xs.push.apply(xs, as);
      };
    };
  };

  exports.unsafeFreeze = function (xs) {
    return function () {
      return xs;
    };
  };

  function copyImpl(xs) {
    return function () {
      return xs.slice();
    };
  }                         

  exports.thaw = copyImpl;
})(PS["Data.Array.ST"] = PS["Data.Array.ST"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Array.ST"] = $PS["Data.Array.ST"] || {};
  var exports = $PS["Data.Array.ST"];
  var $foreign = $PS["Data.Array.ST"];                     
  var withArray = function (f) {
      return function (xs) {
          return function __do() {
              var result = $foreign.thaw(xs)();
              f(result)();
              return $foreign.unsafeFreeze(result)();
          };
      };
  };
  var push = function (a) {
      return $foreign.pushAll([ a ]);
  };
  exports["withArray"] = withArray;
  exports["push"] = push;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Array"] = $PS["Data.Array"] || {};
  var exports = $PS["Data.Array"];
  var $foreign = $PS["Data.Array"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Data_Array_ST = $PS["Data.Array.ST"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];                                            
  var unsafeIndex = function (dictPartial) {
      return $foreign.unsafeIndexImpl;
  };
  var uncons = $foreign.unconsImpl(Data_Function["const"](Data_Maybe.Nothing.value))(function (x) {
      return function (xs) {
          return new Data_Maybe.Just({
              head: x,
              tail: xs
          });
      };
  });
  var tail = $foreign.unconsImpl(Data_Function["const"](Data_Maybe.Nothing.value))(function (v) {
      return function (xs) {
          return new Data_Maybe.Just(xs);
      };
  });
  var snoc = function (xs) {
      return function (x) {
          return Data_Array_ST.withArray(Data_Array_ST.push(x))(xs)();
      };
  };
  var singleton = function (a) {
      return [ a ];
  };
  var $$null = function (xs) {
      return $foreign.length(xs) === 0;
  };                                                                                     
  var init = function (xs) {
      if ($$null(xs)) {
          return Data_Maybe.Nothing.value;
      };
      if (Data_Boolean.otherwise) {
          return new Data_Maybe.Just($foreign.slice(0)($foreign.length(xs) - 1 | 0)(xs));
      };
      throw new Error("Failed pattern match at Data.Array (line 340, column 1 - line 340, column 45): " + [ xs.constructor.name ]);
  };
  var index = $foreign.indexImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var last = function (xs) {
      return index(xs)($foreign.length(xs) - 1 | 0);
  };
  var head = function (xs) {
      return index(xs)(0);
  };
  var findIndex = $foreign.findIndexImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var find = function (f) {
      return function (xs) {
          return Data_Functor.map(Data_Maybe.functorMaybe)(unsafeIndex()(xs))(findIndex(f)(xs));
      };
  };
  var drop = function (n) {
      return function (xs) {
          var $89 = n < 1;
          if ($89) {
              return xs;
          };
          return $foreign.slice(n)($foreign.length(xs))(xs);
      };
  };
  var deleteAt = $foreign["_deleteAt"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  var deleteBy = function (v) {
      return function (v1) {
          return function (v2) {
              if (v2.length === 0) {
                  return [  ];
              };
              return Data_Maybe.maybe(v2)(function (i) {
                  return Data_Maybe.fromJust()(deleteAt(i)(v2));
              })(findIndex(v(v1))(v2));
          };
      };
  };
  var cons = function (x) {
      return function (xs) {
          return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ x ])(xs);
      };
  };
  var concatMap = Data_Function.flip(Control_Bind.bind(Control_Bind.bindArray));
  var mapMaybe = function (f) {
      return concatMap((function () {
          var $109 = Data_Maybe.maybe([  ])(singleton);
          return function ($110) {
              return $109(f($110));
          };
      })());
  };
  var catMaybes = mapMaybe(Control_Category.identity(Control_Category.categoryFn));
  exports["singleton"] = singleton;
  exports["cons"] = cons;
  exports["snoc"] = snoc;
  exports["head"] = head;
  exports["last"] = last;
  exports["tail"] = tail;
  exports["init"] = init;
  exports["uncons"] = uncons;
  exports["find"] = find;
  exports["catMaybes"] = catMaybes;
  exports["drop"] = drop;
  exports["deleteBy"] = deleteBy;
  exports["length"] = $foreign.length;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Array.NonEmpty.Internal"] = $PS["Data.Array.NonEmpty.Internal"] || {};
  var exports = $PS["Data.Array.NonEmpty.Internal"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Semigroup = $PS["Data.Semigroup"];                    
  var NonEmptyArray = function (x) {
      return x;
  };
  var semigroupNonEmptyArray = Data_Semigroup.semigroupArray;                     
  var functorNonEmptyArray = Data_Functor.functorArray;                              
  var foldableNonEmptyArray = Data_Foldable.foldableArray;
  exports["NonEmptyArray"] = NonEmptyArray;
  exports["semigroupNonEmptyArray"] = semigroupNonEmptyArray;
  exports["functorNonEmptyArray"] = functorNonEmptyArray;
  exports["foldableNonEmptyArray"] = foldableNonEmptyArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Array.NonEmpty"] = $PS["Data.Array.NonEmpty"] || {};
  var exports = $PS["Data.Array.NonEmpty"];
  var Data_Array = $PS["Data.Array"];
  var Data_Array_NonEmpty_Internal = $PS["Data.Array.NonEmpty.Internal"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Maybe = $PS["Data.Maybe"];               
  var unsafeFromArray = Data_Array_NonEmpty_Internal.NonEmptyArray;
  var toArray = function (v) {
      return v;
  };
  var snoc$prime = function (xs) {
      return function (x) {
          return unsafeFromArray(Data_Array.snoc(xs)(x));
      };
  };
  var snoc = function (xs) {
      return function (x) {
          return unsafeFromArray(Data_Array.snoc(toArray(xs))(x));
      };
  };
  var singleton = function ($60) {
      return unsafeFromArray(Data_Array.singleton($60));
  };
  var fromArray = function (xs) {
      if (Data_Array.length(xs) > 0) {
          return new Data_Maybe.Just(unsafeFromArray(xs));
      };
      if (Data_Boolean.otherwise) {
          return Data_Maybe.Nothing.value;
      };
      throw new Error("Failed pattern match at Data.Array.NonEmpty (line 159, column 1 - line 159, column 58): " + [ xs.constructor.name ]);
  };
  var cons$prime = function (x) {
      return function (xs) {
          return unsafeFromArray(Data_Array.cons(x)(xs));
      };
  };
  var adaptMaybe = function (f) {
      var $75 = Data_Maybe.fromJust();
      return function ($76) {
          return $75(f(toArray($76)));
      };
  };
  var head = adaptMaybe(Data_Array.head);
  var init = adaptMaybe(Data_Array.init);
  var last = adaptMaybe(Data_Array.last);
  var tail = adaptMaybe(Data_Array.tail);    
  var adaptAny = function (f) {
      return function ($78) {
          return f(toArray($78));
      };
  };                                     
  var length = adaptAny(Data_Array.length);
  var unsafeAdapt = function (f) {
      var $79 = adaptAny(f);
      return function ($80) {
          return unsafeFromArray($79($80));
      };
  };
  var cons = function (x) {
      return unsafeAdapt(Data_Array.cons(x));
  };
  exports["fromArray"] = fromArray;
  exports["singleton"] = singleton;
  exports["cons"] = cons;
  exports["cons'"] = cons$prime;
  exports["snoc"] = snoc;
  exports["snoc'"] = snoc$prime;
  exports["head"] = head;
  exports["last"] = last;
  exports["tail"] = tail;
  exports["init"] = init;
})(PS);
(function(exports) {
  "use strict";

  exports.intSub = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x - y | 0;
    };
  };
})(PS["Data.Ring"] = PS["Data.Ring"] || {});
(function(exports) {
  "use strict";

  exports.intAdd = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x + y | 0;
    };
  };

  exports.intMul = function (x) {
    return function (y) {
      /* jshint bitwise: false */
      return x * y | 0;
    };
  };
})(PS["Data.Semiring"] = PS["Data.Semiring"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Semiring"] = $PS["Data.Semiring"] || {};
  var exports = $PS["Data.Semiring"];
  var $foreign = $PS["Data.Semiring"];
  var Semiring = function (add, mul, one, zero) {
      this.add = add;
      this.mul = mul;
      this.one = one;
      this.zero = zero;
  };                                                                            
  var semiringInt = new Semiring($foreign.intAdd, $foreign.intMul, 1, 0);
  exports["semiringInt"] = semiringInt;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Ring"] = $PS["Data.Ring"] || {};
  var exports = $PS["Data.Ring"];
  var $foreign = $PS["Data.Ring"];
  var Data_Semiring = $PS["Data.Semiring"];
  var Ring = function (Semiring0, sub) {
      this.Semiring0 = Semiring0;
      this.sub = sub;
  };                  
  var ringInt = new Ring(function () {
      return Data_Semiring.semiringInt;
  }, $foreign.intSub);
  exports["ringInt"] = ringInt;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.CommutativeRing"] = $PS["Data.CommutativeRing"] || {};
  var exports = $PS["Data.CommutativeRing"];
  var Data_Ring = $PS["Data.Ring"];
  var CommutativeRing = function (Ring0) {
      this.Ring0 = Ring0;
  }; 
  var commutativeRingInt = new CommutativeRing(function () {
      return Data_Ring.ringInt;
  });
  exports["commutativeRingInt"] = commutativeRingInt;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Const"] = $PS["Data.Const"] || {};
  var exports = $PS["Data.Const"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];      
  var Const = function (x) {
      return x;
  };
  var functorConst = new Data_Functor.Functor(function (f) {
      return function (m) {
          return m;
      };
  });
  var applyConst = function (dictSemigroup) {
      return new Control_Apply.Apply(function () {
          return functorConst;
      }, function (v) {
          return function (v1) {
              return Data_Semigroup.append(dictSemigroup)(v)(v1);
          };
      });
  };
  var applicativeConst = function (dictMonoid) {
      return new Control_Applicative.Applicative(function () {
          return applyConst(dictMonoid.Semigroup0());
      }, function (v) {
          return Data_Monoid.mempty(dictMonoid);
      });
  };
  exports["Const"] = Const;
  exports["applicativeConst"] = applicativeConst;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Exists"] = $PS["Data.Exists"] || {};
  var exports = $PS["Data.Exists"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var runExists = Unsafe_Coerce.unsafeCoerce;
  var mkExists = Unsafe_Coerce.unsafeCoerce;
  exports["mkExists"] = mkExists;
  exports["runExists"] = runExists;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Coyoneda"] = $PS["Data.Coyoneda"] || {};
  var exports = $PS["Data.Coyoneda"];
  var Control_Category = $PS["Control.Category"];
  var Data_Exists = $PS["Data.Exists"];
  var Data_Functor = $PS["Data.Functor"];                        
  var CoyonedaF = (function () {
      function CoyonedaF(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      CoyonedaF.create = function (value0) {
          return function (value1) {
              return new CoyonedaF(value0, value1);
          };
      };
      return CoyonedaF;
  })();
  var Coyoneda = function (x) {
      return x;
  };
  var unCoyoneda = function (f) {
      return function (v) {
          return Data_Exists.runExists(function (v1) {
              return f(v1.value0)(v1.value1);
          })(v);
      };
  };
  var coyoneda = function (k) {
      return function (fi) {
          return Coyoneda(Data_Exists.mkExists(new CoyonedaF(k, fi)));
      };
  };
  var functorCoyoneda = new Data_Functor.Functor(function (f) {
      return function (v) {
          return Data_Exists.runExists(function (v1) {
              return coyoneda(function ($84) {
                  return f(v1.value0($84));
              })(v1.value1);
          })(v);
      };
  });
  var liftCoyoneda = coyoneda(Control_Category.identity(Control_Category.categoryFn));
  exports["unCoyoneda"] = unCoyoneda;
  exports["liftCoyoneda"] = liftCoyoneda;
  exports["functorCoyoneda"] = functorCoyoneda;
})(PS);
(function(exports) {
  "use strict";

  exports.toCharCode = function (c) {
    return c.charCodeAt(0);
  };

  exports.fromCharCode = function (c) {
    return String.fromCharCode(c);
  };
})(PS["Data.Enum"] = PS["Data.Enum"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Enum"] = $PS["Data.Enum"] || {};
  var exports = $PS["Data.Enum"];
  var $foreign = $PS["Data.Enum"];
  var Data_Bounded = $PS["Data.Bounded"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];                  
  var Enum = function (Ord0, pred, succ) {
      this.Ord0 = Ord0;
      this.pred = pred;
      this.succ = succ;
  };
  var BoundedEnum = function (Bounded0, Enum1, cardinality, fromEnum, toEnum) {
      this.Bounded0 = Bounded0;
      this.Enum1 = Enum1;
      this.cardinality = cardinality;
      this.fromEnum = fromEnum;
      this.toEnum = toEnum;
  };
  var toEnum = function (dict) {
      return dict.toEnum;
  }; 
  var fromEnum = function (dict) {
      return dict.fromEnum;
  };
  var toEnumWithDefaults = function (dictBoundedEnum) {
      return function (low) {
          return function (high) {
              return function (x) {
                  var v = toEnum(dictBoundedEnum)(x);
                  if (v instanceof Data_Maybe.Just) {
                      return v.value0;
                  };
                  if (v instanceof Data_Maybe.Nothing) {
                      var $51 = x < fromEnum(dictBoundedEnum)(Data_Bounded.bottom(dictBoundedEnum.Bounded0()));
                      if ($51) {
                          return low;
                      };
                      return high;
                  };
                  throw new Error("Failed pattern match at Data.Enum (line 158, column 33 - line 160, column 62): " + [ v.constructor.name ]);
              };
          };
      };
  };
  var defaultSucc = function (toEnum$prime) {
      return function (fromEnum$prime) {
          return function (a) {
              return toEnum$prime(fromEnum$prime(a) + 1 | 0);
          };
      };
  };
  var defaultPred = function (toEnum$prime) {
      return function (fromEnum$prime) {
          return function (a) {
              return toEnum$prime(fromEnum$prime(a) - 1 | 0);
          };
      };
  };
  var charToEnum = function (v) {
      if (v >= Data_Bounded.bottom(Data_Bounded.boundedInt) && v <= Data_Bounded.top(Data_Bounded.boundedInt)) {
          return new Data_Maybe.Just($foreign.fromCharCode(v));
      };
      return Data_Maybe.Nothing.value;
  };
  var enumChar = new Enum(function () {
      return Data_Ord.ordChar;
  }, defaultPred(charToEnum)($foreign.toCharCode), defaultSucc(charToEnum)($foreign.toCharCode));
  var boundedEnumChar = new BoundedEnum(function () {
      return Data_Bounded.boundedChar;
  }, function () {
      return enumChar;
  }, $foreign.toCharCode(Data_Bounded.top(Data_Bounded.boundedChar)) - $foreign.toCharCode(Data_Bounded.bottom(Data_Bounded.boundedChar)) | 0, $foreign.toCharCode, charToEnum);
  exports["fromEnum"] = fromEnum;
  exports["toEnumWithDefaults"] = toEnumWithDefaults;
  exports["boundedEnumChar"] = boundedEnumChar;
})(PS);
(function(exports) {
  "use strict";

  exports.intDegree = function (x) {
    return Math.min(Math.abs(x), 2147483647);
  };

  // See the Euclidean definition in
  // https://en.m.wikipedia.org/wiki/Modulo_operation.
  exports.intDiv = function (x) {
    return function (y) {
      if (y === 0) return 0;
      return y > 0 ? Math.floor(x / y) : -Math.floor(x / -y);
    };
  };

  exports.intMod = function (x) {
    return function (y) {
      if (y === 0) return 0;
      var yy = Math.abs(y);
      return ((x % yy) + yy) % yy;
    };
  };
})(PS["Data.EuclideanRing"] = PS["Data.EuclideanRing"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.EuclideanRing"] = $PS["Data.EuclideanRing"] || {};
  var exports = $PS["Data.EuclideanRing"];
  var $foreign = $PS["Data.EuclideanRing"];
  var Data_CommutativeRing = $PS["Data.CommutativeRing"];  
  var EuclideanRing = function (CommutativeRing0, degree, div, mod) {
      this.CommutativeRing0 = CommutativeRing0;
      this.degree = degree;
      this.div = div;
      this.mod = mod;
  };
  var mod = function (dict) {
      return dict.mod;
  }; 
  var euclideanRingInt = new EuclideanRing(function () {
      return Data_CommutativeRing.commutativeRingInt;
  }, $foreign.intDegree, $foreign.intDiv, $foreign.intMod);
  var div = function (dict) {
      return dict.div;
  };
  exports["div"] = div;
  exports["mod"] = mod;
  exports["euclideanRingInt"] = euclideanRingInt;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Functor.Variant"] = $PS["Data.Functor.Variant"] || {};
  var exports = $PS["Data.Functor.Variant"];
  var Partial_Unsafe = $PS["Partial.Unsafe"];
  var Record_Unsafe = $PS["Record.Unsafe"];
  var onMatch = function (dictRowToList) {
      return function (dictVariantFMatchCases) {
          return function (dictUnion) {
              return function (r) {
                  return function (k) {
                      return function (v) {
                          if (Record_Unsafe.unsafeHas(v.type)(r)) {
                              return Record_Unsafe.unsafeGet(v.type)(r)(v.value);
                          };
                          return k(v);
                      };
                  };
              };
          };
      };
  };
  var case_ = function (r) {
      return Partial_Unsafe.unsafeCrashWith("Data.Functor.Variant: pattern match failure [" + (r.type + "]"));
  }; 
  var match = function (dictRowToList) {
      return function (dictVariantFMatchCases) {
          return function (dictUnion) {
              return function (r) {
                  return onMatch()()()(r)(case_);
              };
          };
      };
  };
  exports["match"] = match;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Lens.Internal.Wander"] = $PS["Data.Lens.Internal.Wander"] || {};
  var exports = $PS["Data.Lens.Internal.Wander"];                            
  var Wander = function (Choice1, Strong0, wander) {
      this.Choice1 = Choice1;
      this.Strong0 = Strong0;
      this.wander = wander;
  };
  exports["Wander"] = Wander;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Profunctor"] = $PS["Data.Profunctor"] || {};
  var exports = $PS["Data.Profunctor"];
  var Control_Category = $PS["Control.Category"];        
  var Profunctor = function (dimap) {
      this.dimap = dimap;
  }; 
  var dimap = function (dict) {
      return dict.dimap;
  };
  var rmap = function (dictProfunctor) {
      return function (b2c) {
          return dimap(dictProfunctor)(Control_Category.identity(Control_Category.categoryFn))(b2c);
      };
  };
  exports["dimap"] = dimap;
  exports["Profunctor"] = Profunctor;
  exports["rmap"] = rmap;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Profunctor.Choice"] = $PS["Data.Profunctor.Choice"] || {};
  var exports = $PS["Data.Profunctor.Choice"];                 
  var Choice = function (Profunctor0, left, right) {
      this.Profunctor0 = Profunctor0;
      this.left = left;
      this.right = right;
  };
  var right = function (dict) {
      return dict.right;
  };
  exports["right"] = right;
  exports["Choice"] = Choice;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Profunctor.Strong"] = $PS["Data.Profunctor.Strong"] || {};
  var exports = $PS["Data.Profunctor.Strong"];       
  var Strong = function (Profunctor0, first, second) {
      this.Profunctor0 = Profunctor0;
      this.first = first;
      this.second = second;
  };
  var first = function (dict) {
      return dict.first;
  };
  exports["first"] = first;
  exports["Strong"] = Strong;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Lens.Internal.Forget"] = $PS["Data.Lens.Internal.Forget"] || {};
  var exports = $PS["Data.Lens.Internal.Forget"];
  var Data_Const = $PS["Data.Const"];
  var Data_Either = $PS["Data.Either"];
  var Data_Lens_Internal_Wander = $PS["Data.Lens.Internal.Wander"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Profunctor = $PS["Data.Profunctor"];
  var Data_Profunctor_Choice = $PS["Data.Profunctor.Choice"];
  var Data_Profunctor_Strong = $PS["Data.Profunctor.Strong"];
  var Data_Tuple = $PS["Data.Tuple"];                
  var Forget = function (x) {
      return x;
  };
  var profunctorForget = new Data_Profunctor.Profunctor(function (f) {
      return function (v) {
          return function (v1) {
              return function ($24) {
                  return v1(f($24));
              };
          };
      };
  });
  var strongForget = new Data_Profunctor_Strong.Strong(function () {
      return profunctorForget;
  }, function (v) {
      return function ($25) {
          return v(Data_Tuple.fst($25));
      };
  }, function (v) {
      return function ($26) {
          return v(Data_Tuple.snd($26));
      };
  });
  var choiceForget = function (dictMonoid) {
      return new Data_Profunctor_Choice.Choice(function () {
          return profunctorForget;
      }, function (v) {
          return Data_Either.either(v)(Data_Monoid.mempty(Data_Monoid.monoidFn(dictMonoid)));
      }, function (v) {
          return Data_Either.either(Data_Monoid.mempty(Data_Monoid.monoidFn(dictMonoid)))(v);
      });
  };
  var wanderForget = function (dictMonoid) {
      return new Data_Lens_Internal_Wander.Wander(function () {
          return choiceForget(dictMonoid);
      }, function () {
          return strongForget;
      }, function (f) {
          return function (v) {
              return Data_Newtype.alaF()()()()(Data_Const.Const)(f(Data_Const.applicativeConst(dictMonoid)))(v);
          };
      });
  };
  exports["Forget"] = Forget;
  exports["strongForget"] = strongForget;
  exports["wanderForget"] = wanderForget;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Maybe.First"] = $PS["Data.Maybe.First"] || {};
  var exports = $PS["Data.Maybe.First"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Semigroup = $PS["Data.Semigroup"];      
  var First = function (x) {
      return x;
  };
  var semigroupFirst = new Data_Semigroup.Semigroup(function (v) {
      return function (v1) {
          if (v instanceof Data_Maybe.Just) {
              return v;
          };
          return v1;
      };
  });
  var monoidFirst = new Data_Monoid.Monoid(function () {
      return semigroupFirst;
  }, Data_Maybe.Nothing.value);
  exports["First"] = First;
  exports["monoidFirst"] = monoidFirst;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Lens.Fold"] = $PS["Data.Lens.Fold"] || {};
  var exports = $PS["Data.Lens.Fold"];
  var Data_Lens_Internal_Forget = $PS["Data.Lens.Internal.Forget"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Maybe_First = $PS["Data.Maybe.First"];
  var Data_Newtype = $PS["Data.Newtype"];
  var foldMapOf = Data_Newtype.under()()(Data_Lens_Internal_Forget.Forget);
  var preview = function (p) {
      var $98 = Data_Newtype.unwrap();
      var $99 = foldMapOf(p)(function ($101) {
          return Data_Maybe_First.First(Data_Maybe.Just.create($101));
      });
      return function ($100) {
          return $98($99($100));
      };
  };
  exports["preview"] = preview;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Lens.Getter"] = $PS["Data.Lens.Getter"] || {};
  var exports = $PS["Data.Lens.Getter"];
  var Control_Category = $PS["Control.Category"];
  var Data_Newtype = $PS["Data.Newtype"];                                    
  var view = function (l) {
      return Data_Newtype.unwrap()(l(Control_Category.identity(Control_Category.categoryFn)));
  };
  exports["view"] = view;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Lens.Internal.Market"] = $PS["Data.Lens.Internal.Market"] || {};
  var exports = $PS["Data.Lens.Internal.Market"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Either = $PS["Data.Either"];
  var Data_Profunctor = $PS["Data.Profunctor"];
  var Data_Profunctor_Choice = $PS["Data.Profunctor.Choice"];                
  var Market = (function () {
      function Market(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Market.create = function (value0) {
          return function (value1) {
              return new Market(value0, value1);
          };
      };
      return Market;
  })();
  var profunctorMarket = new Data_Profunctor.Profunctor(function (f) {
      return function (g) {
          return function (v) {
              return new Market(function ($19) {
                  return g(v.value0($19));
              }, (function () {
                  var $20 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(g);
                  return function ($21) {
                      return $20(v.value1(f($21)));
                  };
              })());
          };
      };
  });
  var choiceMarket = new Data_Profunctor_Choice.Choice(function () {
      return profunctorMarket;
  }, function (v) {
      return new Market(function ($25) {
          return Data_Either.Left.create(v.value0($25));
      }, Data_Either.either((function () {
          var $26 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Either.Left.create);
          return function ($27) {
              return $26(v.value1($27));
          };
      })())(function ($28) {
          return Data_Either.Left.create(Data_Either.Right.create($28));
      }));
  }, function (v) {
      return new Market(function ($29) {
          return Data_Either.Right.create(v.value0($29));
      }, Data_Either.either(function ($30) {
          return Data_Either.Left.create(Data_Either.Left.create($30));
      })((function () {
          var $31 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Either.Right.create);
          return function ($32) {
              return $31(v.value1($32));
          };
      })()));
  });
  exports["Market"] = Market;
  exports["choiceMarket"] = choiceMarket;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Lens.Iso"] = $PS["Data.Lens.Iso"] || {};
  var exports = $PS["Data.Lens.Iso"];
  var Data_Profunctor = $PS["Data.Profunctor"];
  var iso = function (f) {
      return function (g) {
          return function (dictProfunctor) {
              return function (pab) {
                  return Data_Profunctor.dimap(dictProfunctor)(f)(g)(pab);
              };
          };
      };
  };
  exports["iso"] = iso;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Lens.Iso.Newtype"] = $PS["Data.Lens.Iso.Newtype"] || {};
  var exports = $PS["Data.Lens.Iso.Newtype"];
  var Data_Lens_Iso = $PS["Data.Lens.Iso"];
  var Data_Newtype = $PS["Data.Newtype"];                
  var _Newtype = function (dictNewtype) {
      return function (dictNewtype1) {
          return function (dictProfunctor) {
              return Data_Lens_Iso.iso(Data_Newtype.unwrap())(Data_Newtype.wrap())(dictProfunctor);
          };
      };
  };
  exports["_Newtype"] = _Newtype;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Lens.Lens"] = $PS["Data.Lens.Lens"] || {};
  var exports = $PS["Data.Lens.Lens"];
  var Data_Profunctor = $PS["Data.Profunctor"];
  var Data_Profunctor_Strong = $PS["Data.Profunctor.Strong"];
  var Data_Tuple = $PS["Data.Tuple"];
  var lens$prime = function (to) {
      return function (dictStrong) {
          return function (pab) {
              return Data_Profunctor.dimap(dictStrong.Profunctor0())(to)(function (v) {
                  return v.value1(v.value0);
              })(Data_Profunctor_Strong.first(dictStrong)(pab));
          };
      };
  };
  var lens = function (get) {
      return function (set) {
          return function (dictStrong) {
              return lens$prime(function (s) {
                  return new Data_Tuple.Tuple(get(s), function (b) {
                      return set(s)(b);
                  });
              })(dictStrong);
          };
      };
  };
  exports["lens"] = lens;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Lens.Prism"] = $PS["Data.Lens.Prism"] || {};
  var exports = $PS["Data.Lens.Prism"];
  var Control_Category = $PS["Control.Category"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Lens_Internal_Market = $PS["Data.Lens.Internal.Market"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Profunctor = $PS["Data.Profunctor"];
  var Data_Profunctor_Choice = $PS["Data.Profunctor.Choice"];                
  var withPrism = function (l) {
      return function (f) {
          var v = l(new Data_Lens_Internal_Market.Market(Control_Category.identity(Control_Category.categoryFn), Data_Either.Right.create));
          return f(v.value0)(v.value1);
      };
  };                                                                    
  var prism = function (to) {
      return function (fro) {
          return function (dictChoice) {
              return function (pab) {
                  return Data_Profunctor.dimap(dictChoice.Profunctor0())(fro)(Data_Either.either(Control_Category.identity(Control_Category.categoryFn))(Control_Category.identity(Control_Category.categoryFn)))(Data_Profunctor_Choice.right(dictChoice)(Data_Profunctor.rmap(dictChoice.Profunctor0())(to)(pab)));
              };
          };
      };
  };
  var prism$prime = function (to) {
      return function (fro) {
          return function (dictChoice) {
              return prism(to)(function (s) {
                  return Data_Maybe.maybe(new Data_Either.Left(s))(Data_Either.Right.create)(fro(s));
              })(dictChoice);
          };
      };
  };
  var matching = function (l) {
      return withPrism(l)(function (v) {
          return function (f) {
              return f;
          };
      });
  };
  var is = function (dictHeytingAlgebra) {
      return function (l) {
          var $16 = Data_Either.either(Data_Function["const"](Data_HeytingAlgebra.ff(dictHeytingAlgebra)))(Data_Function["const"](Data_HeytingAlgebra.tt(dictHeytingAlgebra)));
          var $17 = matching(l);
          return function ($18) {
              return $16($17($18));
          };
      };
  };
  exports["prism'"] = prism$prime;
  exports["prism"] = prism;
  exports["is"] = is;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Lens.Record"] = $PS["Data.Lens.Record"] || {};
  var exports = $PS["Data.Lens.Record"];
  var Data_Function = $PS["Data.Function"];
  var Data_Lens_Lens = $PS["Data.Lens.Lens"];
  var Record = $PS["Record"];                
  var prop = function (dictIsSymbol) {
      return function (dictCons) {
          return function (dictCons1) {
              return function (l) {
                  return function (dictStrong) {
                      return Data_Lens_Lens.lens(Record.get(dictIsSymbol)()(l))(Data_Function.flip(Record.set(dictIsSymbol)()()(l)))(dictStrong);
                  };
              };
          };
      };
  };
  exports["prop"] = prop;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Map.Internal"] = $PS["Data.Map.Internal"] || {};
  var exports = $PS["Data.Map.Internal"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Ordering = $PS["Data.Ordering"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Tuple = $PS["Data.Tuple"];                          
  var Leaf = (function () {
      function Leaf() {

      };
      Leaf.value = new Leaf();
      return Leaf;
  })();
  var Two = (function () {
      function Two(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Two.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Two(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Two;
  })();
  var Three = (function () {
      function Three(value0, value1, value2, value3, value4, value5, value6) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
          this.value6 = value6;
      };
      Three.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return function (value6) {
                                  return new Three(value0, value1, value2, value3, value4, value5, value6);
                              };
                          };
                      };
                  };
              };
          };
      };
      return Three;
  })();
  var TwoLeft = (function () {
      function TwoLeft(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      TwoLeft.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new TwoLeft(value0, value1, value2);
              };
          };
      };
      return TwoLeft;
  })();
  var TwoRight = (function () {
      function TwoRight(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      TwoRight.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new TwoRight(value0, value1, value2);
              };
          };
      };
      return TwoRight;
  })();
  var ThreeLeft = (function () {
      function ThreeLeft(value0, value1, value2, value3, value4, value5) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
      };
      ThreeLeft.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return new ThreeLeft(value0, value1, value2, value3, value4, value5);
                          };
                      };
                  };
              };
          };
      };
      return ThreeLeft;
  })();
  var ThreeMiddle = (function () {
      function ThreeMiddle(value0, value1, value2, value3, value4, value5) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
      };
      ThreeMiddle.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return new ThreeMiddle(value0, value1, value2, value3, value4, value5);
                          };
                      };
                  };
              };
          };
      };
      return ThreeMiddle;
  })();
  var ThreeRight = (function () {
      function ThreeRight(value0, value1, value2, value3, value4, value5) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
          this.value4 = value4;
          this.value5 = value5;
      };
      ThreeRight.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return function (value4) {
                          return function (value5) {
                              return new ThreeRight(value0, value1, value2, value3, value4, value5);
                          };
                      };
                  };
              };
          };
      };
      return ThreeRight;
  })();
  var KickUp = (function () {
      function KickUp(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      KickUp.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new KickUp(value0, value1, value2, value3);
                  };
              };
          };
      };
      return KickUp;
  })();
  var values = function (v) {
      if (v instanceof Leaf) {
          return Data_List_Types.Nil.value;
      };
      if (v instanceof Two) {
          return Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value0))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value2))(values(v.value3)));
      };
      if (v instanceof Three) {
          return Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value0))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value2))(Data_Semigroup.append(Data_List_Types.semigroupList)(values(v.value3))(Data_Semigroup.append(Data_List_Types.semigroupList)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value5))(values(v.value6)))));
      };
      throw new Error("Failed pattern match at Data.Map.Internal (line 626, column 1 - line 626, column 40): " + [ v.constructor.name ]);
  };
  var lookup = function (dictOrd) {
      return function (k) {
          var comp = Data_Ord.compare(dictOrd);
          var go = function ($copy_v) {
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(v) {
                  if (v instanceof Leaf) {
                      $tco_done = true;
                      return Data_Maybe.Nothing.value;
                  };
                  if (v instanceof Two) {
                      var v2 = comp(k)(v.value1);
                      if (v2 instanceof Data_Ordering.EQ) {
                          $tco_done = true;
                          return new Data_Maybe.Just(v.value2);
                      };
                      if (v2 instanceof Data_Ordering.LT) {
                          $copy_v = v.value0;
                          return;
                      };
                      $copy_v = v.value3;
                      return;
                  };
                  if (v instanceof Three) {
                      var v3 = comp(k)(v.value1);
                      if (v3 instanceof Data_Ordering.EQ) {
                          $tco_done = true;
                          return new Data_Maybe.Just(v.value2);
                      };
                      var v4 = comp(k)(v.value4);
                      if (v4 instanceof Data_Ordering.EQ) {
                          $tco_done = true;
                          return new Data_Maybe.Just(v.value5);
                      };
                      if (v3 instanceof Data_Ordering.LT) {
                          $copy_v = v.value0;
                          return;
                      };
                      if (v4 instanceof Data_Ordering.GT) {
                          $copy_v = v.value6;
                          return;
                      };
                      $copy_v = v.value3;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 211, column 5 - line 211, column 22): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($copy_v);
              };
              return $tco_result;
          };
          return go;
      };
  }; 
  var fromZipper = function ($copy_dictOrd) {
      return function ($copy_v) {
          return function ($copy_tree) {
              var $tco_var_dictOrd = $copy_dictOrd;
              var $tco_var_v = $copy_v;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(dictOrd, v, tree) {
                  if (v instanceof Data_List_Types.Nil) {
                      $tco_done = true;
                      return tree;
                  };
                  if (v instanceof Data_List_Types.Cons) {
                      if (v.value0 instanceof TwoLeft) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Two(tree, v.value0.value0, v.value0.value1, v.value0.value2);
                          return;
                      };
                      if (v.value0 instanceof TwoRight) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Two(v.value0.value0, v.value0.value1, v.value0.value2, tree);
                          return;
                      };
                      if (v.value0 instanceof ThreeLeft) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Three(tree, v.value0.value0, v.value0.value1, v.value0.value2, v.value0.value3, v.value0.value4, v.value0.value5);
                          return;
                      };
                      if (v.value0 instanceof ThreeMiddle) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Three(v.value0.value0, v.value0.value1, v.value0.value2, tree, v.value0.value3, v.value0.value4, v.value0.value5);
                          return;
                      };
                      if (v.value0 instanceof ThreeRight) {
                          $tco_var_dictOrd = dictOrd;
                          $tco_var_v = v.value1;
                          $copy_tree = new Three(v.value0.value0, v.value0.value1, v.value0.value2, v.value0.value3, v.value0.value4, v.value0.value5, tree);
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 432, column 3 - line 437, column 88): " + [ v.value0.constructor.name ]);
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 429, column 1 - line 429, column 80): " + [ v.constructor.name, tree.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_dictOrd, $tco_var_v, $copy_tree);
              };
              return $tco_result;
          };
      };
  };
  var insert = function (dictOrd) {
      return function (k) {
          return function (v) {
              var up = function ($copy_v1) {
                  return function ($copy_v2) {
                      var $tco_var_v1 = $copy_v1;
                      var $tco_done = false;
                      var $tco_result;
                      function $tco_loop(v1, v2) {
                          if (v1 instanceof Data_List_Types.Nil) {
                              $tco_done = true;
                              return new Two(v2.value0, v2.value1, v2.value2, v2.value3);
                          };
                          if (v1 instanceof Data_List_Types.Cons) {
                              if (v1.value0 instanceof TwoLeft) {
                                  $tco_done = true;
                                  return fromZipper(dictOrd)(v1.value1)(new Three(v2.value0, v2.value1, v2.value2, v2.value3, v1.value0.value0, v1.value0.value1, v1.value0.value2));
                              };
                              if (v1.value0 instanceof TwoRight) {
                                  $tco_done = true;
                                  return fromZipper(dictOrd)(v1.value1)(new Three(v1.value0.value0, v1.value0.value1, v1.value0.value2, v2.value0, v2.value1, v2.value2, v2.value3));
                              };
                              if (v1.value0 instanceof ThreeLeft) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_v2 = new KickUp(new Two(v2.value0, v2.value1, v2.value2, v2.value3), v1.value0.value0, v1.value0.value1, new Two(v1.value0.value2, v1.value0.value3, v1.value0.value4, v1.value0.value5));
                                  return;
                              };
                              if (v1.value0 instanceof ThreeMiddle) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_v2 = new KickUp(new Two(v1.value0.value0, v1.value0.value1, v1.value0.value2, v2.value0), v2.value1, v2.value2, new Two(v2.value3, v1.value0.value3, v1.value0.value4, v1.value0.value5));
                                  return;
                              };
                              if (v1.value0 instanceof ThreeRight) {
                                  $tco_var_v1 = v1.value1;
                                  $copy_v2 = new KickUp(new Two(v1.value0.value0, v1.value0.value1, v1.value0.value2, v1.value0.value3), v1.value0.value4, v1.value0.value5, new Two(v2.value0, v2.value1, v2.value2, v2.value3));
                                  return;
                              };
                              throw new Error("Failed pattern match at Data.Map.Internal (line 468, column 5 - line 473, column 108): " + [ v1.value0.constructor.name, v2.constructor.name ]);
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 465, column 3 - line 465, column 56): " + [ v1.constructor.name, v2.constructor.name ]);
                      };
                      while (!$tco_done) {
                          $tco_result = $tco_loop($tco_var_v1, $copy_v2);
                      };
                      return $tco_result;
                  };
              };
              var comp = Data_Ord.compare(dictOrd);
              var down = function ($copy_ctx) {
                  return function ($copy_v1) {
                      var $tco_var_ctx = $copy_ctx;
                      var $tco_done1 = false;
                      var $tco_result;
                      function $tco_loop(ctx, v1) {
                          if (v1 instanceof Leaf) {
                              $tco_done1 = true;
                              return up(ctx)(new KickUp(Leaf.value, k, v, Leaf.value));
                          };
                          if (v1 instanceof Two) {
                              var v2 = comp(k)(v1.value1);
                              if (v2 instanceof Data_Ordering.EQ) {
                                  $tco_done1 = true;
                                  return fromZipper(dictOrd)(ctx)(new Two(v1.value0, k, v, v1.value3));
                              };
                              if (v2 instanceof Data_Ordering.LT) {
                                  $tco_var_ctx = new Data_List_Types.Cons(new TwoLeft(v1.value1, v1.value2, v1.value3), ctx);
                                  $copy_v1 = v1.value0;
                                  return;
                              };
                              $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(v1.value0, v1.value1, v1.value2), ctx);
                              $copy_v1 = v1.value3;
                              return;
                          };
                          if (v1 instanceof Three) {
                              var v3 = comp(k)(v1.value1);
                              if (v3 instanceof Data_Ordering.EQ) {
                                  $tco_done1 = true;
                                  return fromZipper(dictOrd)(ctx)(new Three(v1.value0, k, v, v1.value3, v1.value4, v1.value5, v1.value6));
                              };
                              var v4 = comp(k)(v1.value4);
                              if (v4 instanceof Data_Ordering.EQ) {
                                  $tco_done1 = true;
                                  return fromZipper(dictOrd)(ctx)(new Three(v1.value0, v1.value1, v1.value2, v1.value3, k, v, v1.value6));
                              };
                              if (v3 instanceof Data_Ordering.LT) {
                                  $tco_var_ctx = new Data_List_Types.Cons(new ThreeLeft(v1.value1, v1.value2, v1.value3, v1.value4, v1.value5, v1.value6), ctx);
                                  $copy_v1 = v1.value0;
                                  return;
                              };
                              if (v3 instanceof Data_Ordering.GT && v4 instanceof Data_Ordering.LT) {
                                  $tco_var_ctx = new Data_List_Types.Cons(new ThreeMiddle(v1.value0, v1.value1, v1.value2, v1.value4, v1.value5, v1.value6), ctx);
                                  $copy_v1 = v1.value3;
                                  return;
                              };
                              $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(v1.value0, v1.value1, v1.value2, v1.value3, v1.value4, v1.value5), ctx);
                              $copy_v1 = v1.value6;
                              return;
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 448, column 3 - line 448, column 55): " + [ ctx.constructor.name, v1.constructor.name ]);
                      };
                      while (!$tco_done1) {
                          $tco_result = $tco_loop($tco_var_ctx, $copy_v1);
                      };
                      return $tco_result;
                  };
              };
              return down(Data_List_Types.Nil.value);
          };
      };
  };
  var pop = function (dictOrd) {
      return function (k) {
          var up = function ($copy_ctxs) {
              return function ($copy_tree) {
                  var $tco_var_ctxs = $copy_ctxs;
                  var $tco_done = false;
                  var $tco_result;
                  function $tco_loop(ctxs, tree) {
                      if (ctxs instanceof Data_List_Types.Nil) {
                          $tco_done = true;
                          return tree;
                      };
                      if (ctxs instanceof Data_List_Types.Cons) {
                          if (ctxs.value0 instanceof TwoLeft && (ctxs.value0.value2 instanceof Leaf && tree instanceof Leaf)) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(Leaf.value, ctxs.value0.value0, ctxs.value0.value1, Leaf.value));
                          };
                          if (ctxs.value0 instanceof TwoRight && (ctxs.value0.value0 instanceof Leaf && tree instanceof Leaf)) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value));
                          };
                          if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Two) {
                              $tco_var_ctxs = ctxs.value1;
                              $copy_tree = new Three(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0, ctxs.value0.value2.value1, ctxs.value0.value2.value2, ctxs.value0.value2.value3);
                              return;
                          };
                          if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Two) {
                              $tco_var_ctxs = ctxs.value1;
                              $copy_tree = new Three(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3, ctxs.value0.value1, ctxs.value0.value2, tree);
                              return;
                          };
                          if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Two(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0), ctxs.value0.value2.value1, ctxs.value0.value2.value2, new Two(ctxs.value0.value2.value3, ctxs.value0.value2.value4, ctxs.value0.value2.value5, ctxs.value0.value2.value6)));
                          };
                          if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Two(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3), ctxs.value0.value0.value4, ctxs.value0.value0.value5, new Two(ctxs.value0.value0.value6, ctxs.value0.value1, ctxs.value0.value2, tree)));
                          };
                          if (ctxs.value0 instanceof ThreeLeft && (ctxs.value0.value2 instanceof Leaf && (ctxs.value0.value5 instanceof Leaf && tree instanceof Leaf))) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value0, ctxs.value0.value1, Leaf.value, ctxs.value0.value3, ctxs.value0.value4, Leaf.value));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && (ctxs.value0.value0 instanceof Leaf && (ctxs.value0.value5 instanceof Leaf && tree instanceof Leaf))) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value, ctxs.value0.value3, ctxs.value0.value4, Leaf.value));
                          };
                          if (ctxs.value0 instanceof ThreeRight && (ctxs.value0.value0 instanceof Leaf && (ctxs.value0.value3 instanceof Leaf && tree instanceof Leaf))) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value, ctxs.value0.value4, ctxs.value0.value5, Leaf.value));
                          };
                          if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Three(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0, ctxs.value0.value2.value1, ctxs.value0.value2.value2, ctxs.value0.value2.value3), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(new Three(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3, ctxs.value0.value1, ctxs.value0.value2, tree), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value5 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Three(tree, ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5.value0, ctxs.value0.value5.value1, ctxs.value0.value5.value2, ctxs.value0.value5.value3)));
                          };
                          if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value3 instanceof Two) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Two(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Three(ctxs.value0.value3.value0, ctxs.value0.value3.value1, ctxs.value0.value3.value2, ctxs.value0.value3.value3, ctxs.value0.value4, ctxs.value0.value5, tree)));
                          };
                          if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(new Two(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0), ctxs.value0.value2.value1, ctxs.value0.value2.value2, new Two(ctxs.value0.value2.value3, ctxs.value0.value2.value4, ctxs.value0.value2.value5, ctxs.value0.value2.value6), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(new Two(ctxs.value0.value0.value0, ctxs.value0.value0.value1, ctxs.value0.value0.value2, ctxs.value0.value0.value3), ctxs.value0.value0.value4, ctxs.value0.value0.value5, new Two(ctxs.value0.value0.value6, ctxs.value0.value1, ctxs.value0.value2, tree), ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5));
                          };
                          if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value5 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Two(tree, ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5.value0), ctxs.value0.value5.value1, ctxs.value0.value5.value2, new Two(ctxs.value0.value5.value3, ctxs.value0.value5.value4, ctxs.value0.value5.value5, ctxs.value0.value5.value6)));
                          };
                          if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value3 instanceof Three) {
                              $tco_done = true;
                              return fromZipper(dictOrd)(ctxs.value1)(new Three(ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2, new Two(ctxs.value0.value3.value0, ctxs.value0.value3.value1, ctxs.value0.value3.value2, ctxs.value0.value3.value3), ctxs.value0.value3.value4, ctxs.value0.value3.value5, new Two(ctxs.value0.value3.value6, ctxs.value0.value4, ctxs.value0.value5, tree)));
                          };
                          throw new Error("Failed pattern match at Data.Map.Internal (line 525, column 9 - line 542, column 136): " + [ ctxs.value0.constructor.name, tree.constructor.name ]);
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 522, column 5 - line 542, column 136): " + [ ctxs.constructor.name ]);
                  };
                  while (!$tco_done) {
                      $tco_result = $tco_loop($tco_var_ctxs, $copy_tree);
                  };
                  return $tco_result;
              };
          };
          var removeMaxNode = function ($copy_ctx) {
              return function ($copy_m) {
                  var $tco_var_ctx = $copy_ctx;
                  var $tco_done1 = false;
                  var $tco_result;
                  function $tco_loop(ctx, m) {
                      if (m instanceof Two && (m.value0 instanceof Leaf && m.value3 instanceof Leaf)) {
                          $tco_done1 = true;
                          return up(ctx)(Leaf.value);
                      };
                      if (m instanceof Two) {
                          $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(m.value0, m.value1, m.value2), ctx);
                          $copy_m = m.value3;
                          return;
                      };
                      if (m instanceof Three && (m.value0 instanceof Leaf && (m.value3 instanceof Leaf && m.value6 instanceof Leaf))) {
                          $tco_done1 = true;
                          return up(new Data_List_Types.Cons(new TwoRight(Leaf.value, m.value1, m.value2), ctx))(Leaf.value);
                      };
                      if (m instanceof Three) {
                          $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(m.value0, m.value1, m.value2, m.value3, m.value4, m.value5), ctx);
                          $copy_m = m.value6;
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 554, column 5 - line 558, column 107): " + [ m.constructor.name ]);
                  };
                  while (!$tco_done1) {
                      $tco_result = $tco_loop($tco_var_ctx, $copy_m);
                  };
                  return $tco_result;
              };
          };
          var maxNode = function ($copy_m) {
              var $tco_done2 = false;
              var $tco_result;
              function $tco_loop(m) {
                  if (m instanceof Two && m.value3 instanceof Leaf) {
                      $tco_done2 = true;
                      return {
                          key: m.value1,
                          value: m.value2
                      };
                  };
                  if (m instanceof Two) {
                      $copy_m = m.value3;
                      return;
                  };
                  if (m instanceof Three && m.value6 instanceof Leaf) {
                      $tco_done2 = true;
                      return {
                          key: m.value4,
                          value: m.value5
                      };
                  };
                  if (m instanceof Three) {
                      $copy_m = m.value6;
                      return;
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 545, column 33 - line 549, column 45): " + [ m.constructor.name ]);
              };
              while (!$tco_done2) {
                  $tco_result = $tco_loop($copy_m);
              };
              return $tco_result;
          };
          var comp = Data_Ord.compare(dictOrd);
          var down = function ($copy_ctx) {
              return function ($copy_m) {
                  var $tco_var_ctx = $copy_ctx;
                  var $tco_done3 = false;
                  var $tco_result;
                  function $tco_loop(ctx, m) {
                      if (m instanceof Leaf) {
                          $tco_done3 = true;
                          return Data_Maybe.Nothing.value;
                      };
                      if (m instanceof Two) {
                          var v = comp(k)(m.value1);
                          if (m.value3 instanceof Leaf && v instanceof Data_Ordering.EQ) {
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, up(ctx)(Leaf.value)));
                          };
                          if (v instanceof Data_Ordering.EQ) {
                              var max = maxNode(m.value0);
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, removeMaxNode(new Data_List_Types.Cons(new TwoLeft(max.key, max.value, m.value3), ctx))(m.value0)));
                          };
                          if (v instanceof Data_Ordering.LT) {
                              $tco_var_ctx = new Data_List_Types.Cons(new TwoLeft(m.value1, m.value2, m.value3), ctx);
                              $copy_m = m.value0;
                              return;
                          };
                          $tco_var_ctx = new Data_List_Types.Cons(new TwoRight(m.value0, m.value1, m.value2), ctx);
                          $copy_m = m.value3;
                          return;
                      };
                      if (m instanceof Three) {
                          var leaves = (function () {
                              if (m.value0 instanceof Leaf && (m.value3 instanceof Leaf && m.value6 instanceof Leaf)) {
                                  return true;
                              };
                              return false;
                          })();
                          var v = comp(k)(m.value4);
                          var v3 = comp(k)(m.value1);
                          if (leaves && v3 instanceof Data_Ordering.EQ) {
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, fromZipper(dictOrd)(ctx)(new Two(Leaf.value, m.value4, m.value5, Leaf.value))));
                          };
                          if (leaves && v instanceof Data_Ordering.EQ) {
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value5, fromZipper(dictOrd)(ctx)(new Two(Leaf.value, m.value1, m.value2, Leaf.value))));
                          };
                          if (v3 instanceof Data_Ordering.EQ) {
                              var max = maxNode(m.value0);
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value2, removeMaxNode(new Data_List_Types.Cons(new ThreeLeft(max.key, max.value, m.value3, m.value4, m.value5, m.value6), ctx))(m.value0)));
                          };
                          if (v instanceof Data_Ordering.EQ) {
                              var max = maxNode(m.value3);
                              $tco_done3 = true;
                              return new Data_Maybe.Just(new Data_Tuple.Tuple(m.value5, removeMaxNode(new Data_List_Types.Cons(new ThreeMiddle(m.value0, m.value1, m.value2, max.key, max.value, m.value6), ctx))(m.value3)));
                          };
                          if (v3 instanceof Data_Ordering.LT) {
                              $tco_var_ctx = new Data_List_Types.Cons(new ThreeLeft(m.value1, m.value2, m.value3, m.value4, m.value5, m.value6), ctx);
                              $copy_m = m.value0;
                              return;
                          };
                          if (v3 instanceof Data_Ordering.GT && v instanceof Data_Ordering.LT) {
                              $tco_var_ctx = new Data_List_Types.Cons(new ThreeMiddle(m.value0, m.value1, m.value2, m.value4, m.value5, m.value6), ctx);
                              $copy_m = m.value3;
                              return;
                          };
                          $tco_var_ctx = new Data_List_Types.Cons(new ThreeRight(m.value0, m.value1, m.value2, m.value3, m.value4, m.value5), ctx);
                          $copy_m = m.value6;
                          return;
                      };
                      throw new Error("Failed pattern match at Data.Map.Internal (line 495, column 34 - line 518, column 80): " + [ m.constructor.name ]);
                  };
                  while (!$tco_done3) {
                      $tco_result = $tco_loop($tco_var_ctx, $copy_m);
                  };
                  return $tco_result;
              };
          };
          return down(Data_List_Types.Nil.value);
      };
  };
  var foldableMap = new Data_Foldable.Foldable(function (dictMonoid) {
      return function (f) {
          return function (m) {
              return Data_Foldable.foldMap(Data_List_Types.foldableList)(dictMonoid)(f)(values(m));
          };
      };
  }, function (f) {
      return function (z) {
          return function (m) {
              return Data_Foldable.foldl(Data_List_Types.foldableList)(f)(z)(values(m));
          };
      };
  }, function (f) {
      return function (z) {
          return function (m) {
              return Data_Foldable.foldr(Data_List_Types.foldableList)(f)(z)(values(m));
          };
      };
  });
  var empty = Leaf.value;
  var $$delete = function (dictOrd) {
      return function (k) {
          return function (m) {
              return Data_Maybe.maybe(m)(Data_Tuple.snd)(pop(dictOrd)(k)(m));
          };
      };
  };
  var alter = function (dictOrd) {
      return function (f) {
          return function (k) {
              return function (m) {
                  var v = f(lookup(dictOrd)(k)(m));
                  if (v instanceof Data_Maybe.Nothing) {
                      return $$delete(dictOrd)(k)(m);
                  };
                  if (v instanceof Data_Maybe.Just) {
                      return insert(dictOrd)(k)(v.value0)(m);
                  };
                  throw new Error("Failed pattern match at Data.Map.Internal (line 563, column 15 - line 565, column 25): " + [ v.constructor.name ]);
              };
          };
      };
  };
  exports["empty"] = empty;
  exports["insert"] = insert;
  exports["lookup"] = lookup;
  exports["delete"] = $$delete;
  exports["pop"] = pop;
  exports["alter"] = alter;
  exports["foldableMap"] = foldableMap;
})(PS);
(function(exports) {
  /* eslint-disable no-eq-null, eqeqeq */

  "use strict";

  exports["null"] = null;

  exports.nullable = function (a, r, f) {
    return a == null ? r : f(a);
  };

  exports.notNull = function (x) {
    return x;
  };
})(PS["Data.Nullable"] = PS["Data.Nullable"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Nullable"] = $PS["Data.Nullable"] || {};
  var exports = $PS["Data.Nullable"];
  var $foreign = $PS["Data.Nullable"];
  var Data_Maybe = $PS["Data.Maybe"];              
  var toNullable = Data_Maybe.maybe($foreign["null"])($foreign.notNull);
  var toMaybe = function (n) {
      return $foreign.nullable(n, Data_Maybe.Nothing.value, Data_Maybe.Just.create);
  };
  exports["toMaybe"] = toMaybe;
  exports["toNullable"] = toNullable;
  exports["null"] = $foreign["null"];
})(PS);
(function(exports) {
  "use strict";
  /* global Symbol */

  var hasArrayFrom = typeof Array.from === "function";
  var hasStringIterator =
    typeof Symbol !== "undefined" &&
    Symbol != null &&
    typeof Symbol.iterator !== "undefined" &&
    typeof String.prototype[Symbol.iterator] === "function";
  var hasFromCodePoint = typeof String.prototype.fromCodePoint === "function";
  var hasCodePointAt = typeof String.prototype.codePointAt === "function";

  exports._unsafeCodePointAt0 = function (fallback) {
    return hasCodePointAt
      ? function (str) { return str.codePointAt(0); }
      : fallback;
  };

  exports._codePointAt = function (fallback) {
    return function (Just) {
      return function (Nothing) {
        return function (unsafeCodePointAt0) {
          return function (index) {
            return function (str) {
              var length = str.length;
              if (index < 0 || index >= length) return Nothing;
              if (hasStringIterator) {
                var iter = str[Symbol.iterator]();
                for (var i = index;; --i) {
                  var o = iter.next();
                  if (o.done) return Nothing;
                  if (i === 0) return Just(unsafeCodePointAt0(o.value));
                }
              }
              return fallback(index)(str);
            };
          };
        };
      };
    };
  };

  exports._fromCodePointArray = function (singleton) {
    return hasFromCodePoint
      ? function (cps) {
        // Function.prototype.apply will fail for very large second parameters,
        // so we don't use it for arrays with 10,000 or more entries.
        if (cps.length < 10e3) {
          return String.fromCodePoint.apply(String, cps);
        }
        return cps.map(singleton).join("");
      }
      : function (cps) {
        return cps.map(singleton).join("");
      };
  };

  exports._singleton = function (fallback) {
    return hasFromCodePoint ? String.fromCodePoint : fallback;
  };

  exports._take = function (fallback) {
    return function (n) {
      if (hasStringIterator) {
        return function (str) {
          var accum = "";
          var iter = str[Symbol.iterator]();
          for (var i = 0; i < n; ++i) {
            var o = iter.next();
            if (o.done) return accum;
            accum += o.value;
          }
          return accum;
        };
      }
      return fallback(n);
    };
  };

  exports._toCodePointArray = function (fallback) {
    return function (unsafeCodePointAt0) {
      if (hasArrayFrom) {
        return function (str) {
          return Array.from(str, unsafeCodePointAt0);
        };
      }
      return fallback;
    };
  };
})(PS["Data.String.CodePoints"] = PS["Data.String.CodePoints"] || {});
(function(exports) {
  "use strict";

  exports.singleton = function (c) {
    return c;
  };

  exports.length = function (s) {
    return s.length;
  };

  exports._indexOf = function (just) {
    return function (nothing) {
      return function (x) {
        return function (s) {
          var i = s.indexOf(x);
          return i === -1 ? nothing : just(i);
        };
      };
    };
  };

  exports.take = function (n) {
    return function (s) {
      return s.substr(0, n);
    };
  };

  exports.drop = function (n) {
    return function (s) {
      return s.substring(n);
    };
  };

  exports.splitAt = function (i) {
    return function (s) {
      return { before: s.substring(0, i), after: s.substring(i) };
    };
  };
})(PS["Data.String.CodeUnits"] = PS["Data.String.CodeUnits"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.String.CodeUnits"] = $PS["Data.String.CodeUnits"] || {};
  var exports = $PS["Data.String.CodeUnits"];
  var $foreign = $PS["Data.String.CodeUnits"];
  var Data_Maybe = $PS["Data.Maybe"];
  var stripPrefix = function (v) {
      return function (str) {
          var v1 = $foreign.splitAt($foreign.length(v))(str);
          var $15 = v1.before === v;
          if ($15) {
              return new Data_Maybe.Just(v1.after);
          };
          return Data_Maybe.Nothing.value;
      };
  };                                                                                                   
  var indexOf = $foreign["_indexOf"](Data_Maybe.Just.create)(Data_Maybe.Nothing.value);
  exports["stripPrefix"] = stripPrefix;
  exports["indexOf"] = indexOf;
  exports["singleton"] = $foreign.singleton;
  exports["length"] = $foreign.length;
  exports["take"] = $foreign.take;
  exports["drop"] = $foreign.drop;
})(PS);
(function(exports) {
  "use strict";

  exports.charAt = function (i) {
    return function (s) {
      if (i >= 0 && i < s.length) return s.charAt(i);
      throw new Error("Data.String.Unsafe.charAt: Invalid index.");
    };
  };
})(PS["Data.String.Unsafe"] = PS["Data.String.Unsafe"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.String.Unsafe"] = $PS["Data.String.Unsafe"] || {};
  var exports = $PS["Data.String.Unsafe"];
  var $foreign = $PS["Data.String.Unsafe"];
  exports["charAt"] = $foreign.charAt;
})(PS);
(function(exports) {
  "use strict";

  exports.unfoldrArrayImpl = function (isNothing) {
    return function (fromJust) {
      return function (fst) {
        return function (snd) {
          return function (f) {
            return function (b) {
              var result = [];
              var value = b;
              while (true) { // eslint-disable-line no-constant-condition
                var maybe = f(value);
                if (isNothing(maybe)) return result;
                var tuple = fromJust(maybe);
                result.push(fst(tuple));
                value = snd(tuple);
              }
            };
          };
        };
      };
    };
  };
})(PS["Data.Unfoldable"] = PS["Data.Unfoldable"] || {});
(function(exports) {
  "use strict";

  // jshint maxparams: 3

  exports.traverseArrayImpl = (function () {
    function array1(a) {
      return [a];
    }

    function array2(a) {
      return function (b) {
        return [a, b];
      };
    }

    function array3(a) {
      return function (b) {
        return function (c) {
          return [a, b, c];
        };
      };
    }

    function concat2(xs) {
      return function (ys) {
        return xs.concat(ys);
      };
    }

    return function (apply) {
      return function (map) {
        return function (pure) {
          return function (f) {
            return function (array) {
              function go(bot, top) {
                switch (top - bot) {
                case 0: return pure([]);
                case 1: return map(array1)(f(array[bot]));
                case 2: return apply(map(array2)(f(array[bot])))(f(array[bot + 1]));
                case 3: return apply(apply(map(array3)(f(array[bot])))(f(array[bot + 1])))(f(array[bot + 2]));
                default:
                  // This slightly tricky pivot selection aims to produce two
                  // even-length partitions where possible.
                  var pivot = bot + Math.floor((top - bot) / 4) * 2;
                  return apply(map(concat2)(go(bot, pivot)))(go(pivot, top));
                }
              }
              return go(0, array.length);
            };
          };
        };
      };
    };
  })();
})(PS["Data.Traversable"] = PS["Data.Traversable"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Traversable"] = $PS["Data.Traversable"] || {};
  var exports = $PS["Data.Traversable"];
  var $foreign = $PS["Data.Traversable"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Category = $PS["Control.Category"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];                
  var Traversable = function (Foldable1, Functor0, sequence, traverse) {
      this.Foldable1 = Foldable1;
      this.Functor0 = Functor0;
      this.sequence = sequence;
      this.traverse = traverse;
  };
  var traverse = function (dict) {
      return dict.traverse;
  }; 
  var traversableMaybe = new Traversable(function () {
      return Data_Foldable.foldableMaybe;
  }, function () {
      return Data_Maybe.functorMaybe;
  }, function (dictApplicative) {
      return function (v) {
          if (v instanceof Data_Maybe.Nothing) {
              return Control_Applicative.pure(dictApplicative)(Data_Maybe.Nothing.value);
          };
          if (v instanceof Data_Maybe.Just) {
              return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Maybe.Just.create)(v.value0);
          };
          throw new Error("Failed pattern match at Data.Traversable (line 115, column 1 - line 119, column 33): " + [ v.constructor.name ]);
      };
  }, function (dictApplicative) {
      return function (v) {
          return function (v1) {
              if (v1 instanceof Data_Maybe.Nothing) {
                  return Control_Applicative.pure(dictApplicative)(Data_Maybe.Nothing.value);
              };
              if (v1 instanceof Data_Maybe.Just) {
                  return Data_Functor.map((dictApplicative.Apply0()).Functor0())(Data_Maybe.Just.create)(v(v1.value0));
              };
              throw new Error("Failed pattern match at Data.Traversable (line 115, column 1 - line 119, column 33): " + [ v.constructor.name, v1.constructor.name ]);
          };
      };
  });
  var sequenceDefault = function (dictTraversable) {
      return function (dictApplicative) {
          return traverse(dictTraversable)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
      };
  };
  var traversableArray = new Traversable(function () {
      return Data_Foldable.foldableArray;
  }, function () {
      return Data_Functor.functorArray;
  }, function (dictApplicative) {
      return sequenceDefault(traversableArray)(dictApplicative);
  }, function (dictApplicative) {
      return $foreign.traverseArrayImpl(Control_Apply.apply(dictApplicative.Apply0()))(Data_Functor.map((dictApplicative.Apply0()).Functor0()))(Control_Applicative.pure(dictApplicative));
  });
  var sequence = function (dict) {
      return dict.sequence;
  };
  exports["traverse"] = traverse;
  exports["sequence"] = sequence;
  exports["traversableArray"] = traversableArray;
  exports["traversableMaybe"] = traversableMaybe;
})(PS);
(function(exports) {
  "use strict";

  exports.unfoldr1ArrayImpl = function (isNothing) {
    return function (fromJust) {
      return function (fst) {
        return function (snd) {
          return function (f) {
            return function (b) {
              var result = [];
              var value = b;
              while (true) { // eslint-disable-line no-constant-condition
                var tuple = f(value);
                result.push(fst(tuple));
                var maybe = snd(tuple);
                if (isNothing(maybe)) return result;
                value = fromJust(maybe);
              }
            };
          };
        };
      };
    };
  };
})(PS["Data.Unfoldable1"] = PS["Data.Unfoldable1"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Unfoldable1"] = $PS["Data.Unfoldable1"] || {};
  var exports = $PS["Data.Unfoldable1"];
  var $foreign = $PS["Data.Unfoldable1"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Tuple = $PS["Data.Tuple"];                
  var Unfoldable1 = function (unfoldr1) {
      this.unfoldr1 = unfoldr1;
  }; 
  var unfoldable1Array = new Unfoldable1($foreign.unfoldr1ArrayImpl(Data_Maybe.isNothing)(Data_Maybe.fromJust())(Data_Tuple.fst)(Data_Tuple.snd));
  exports["unfoldable1Array"] = unfoldable1Array;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Unfoldable"] = $PS["Data.Unfoldable"] || {};
  var exports = $PS["Data.Unfoldable"];
  var $foreign = $PS["Data.Unfoldable"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Traversable = $PS["Data.Traversable"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable1 = $PS["Data.Unfoldable1"];  
  var Unfoldable = function (Unfoldable10, unfoldr) {
      this.Unfoldable10 = Unfoldable10;
      this.unfoldr = unfoldr;
  };
  var unfoldr = function (dict) {
      return dict.unfoldr;
  }; 
  var unfoldableArray = new Unfoldable(function () {
      return Data_Unfoldable1.unfoldable1Array;
  }, $foreign.unfoldrArrayImpl(Data_Maybe.isNothing)(Data_Maybe.fromJust())(Data_Tuple.fst)(Data_Tuple.snd));
  var replicate = function (dictUnfoldable) {
      return function (n) {
          return function (v) {
              var step = function (i) {
                  var $7 = i <= 0;
                  if ($7) {
                      return Data_Maybe.Nothing.value;
                  };
                  return new Data_Maybe.Just(new Data_Tuple.Tuple(v, i - 1 | 0));
              };
              return unfoldr(dictUnfoldable)(step)(n);
          };
      };
  };
  var replicateA = function (dictApplicative) {
      return function (dictUnfoldable) {
          return function (dictTraversable) {
              return function (n) {
                  return function (m) {
                      return Data_Traversable.sequence(dictTraversable)(dictApplicative)(replicate(dictUnfoldable)(n)(m));
                  };
              };
          };
      };
  };
  exports["unfoldr"] = unfoldr;
  exports["replicateA"] = replicateA;
  exports["unfoldableArray"] = unfoldableArray;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.String.CodePoints"] = $PS["Data.String.CodePoints"] || {};
  var exports = $PS["Data.String.CodePoints"];
  var $foreign = $PS["Data.String.CodePoints"];
  var Data_Array = $PS["Data.Array"];
  var Data_Bounded = $PS["Data.Bounded"];
  var Data_Enum = $PS["Data.Enum"];
  var Data_EuclideanRing = $PS["Data.EuclideanRing"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_String_CodeUnits = $PS["Data.String.CodeUnits"];
  var Data_String_Unsafe = $PS["Data.String.Unsafe"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];                
  var CodePoint = function (x) {
      return x;
  };
  var unsurrogate = function (lead) {
      return function (trail) {
          return (((lead - 55296 | 0) * 1024 | 0) + (trail - 56320 | 0) | 0) + 65536 | 0;
      };
  }; 
  var isTrail = function (cu) {
      return 56320 <= cu && cu <= 57343;
  };
  var isLead = function (cu) {
      return 55296 <= cu && cu <= 56319;
  };
  var uncons = function (s) {
      var v = Data_String_CodeUnits.length(s);
      if (v === 0) {
          return Data_Maybe.Nothing.value;
      };
      if (v === 1) {
          return new Data_Maybe.Just({
              head: Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s)),
              tail: ""
          });
      };
      var cu1 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(1)(s));
      var cu0 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s));
      var $21 = isLead(cu0) && isTrail(cu1);
      if ($21) {
          return new Data_Maybe.Just({
              head: unsurrogate(cu0)(cu1),
              tail: Data_String_CodeUnits.drop(2)(s)
          });
      };
      return new Data_Maybe.Just({
          head: cu0,
          tail: Data_String_CodeUnits.drop(1)(s)
      });
  };
  var unconsButWithTuple = function (s) {
      return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
          return new Data_Tuple.Tuple(v.head, v.tail);
      })(uncons(s));
  };
  var toCodePointArrayFallback = function (s) {
      return Data_Unfoldable.unfoldr(Data_Unfoldable.unfoldableArray)(unconsButWithTuple)(s);
  };
  var unsafeCodePointAt0Fallback = function (s) {
      var cu0 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(0)(s));
      var $25 = isLead(cu0) && Data_String_CodeUnits.length(s) > 1;
      if ($25) {
          var cu1 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar)(Data_String_Unsafe.charAt(1)(s));
          var $26 = isTrail(cu1);
          if ($26) {
              return unsurrogate(cu0)(cu1);
          };
          return cu0;
      };
      return cu0;
  };
  var unsafeCodePointAt0 = $foreign["_unsafeCodePointAt0"](unsafeCodePointAt0Fallback);
  var toCodePointArray = $foreign["_toCodePointArray"](toCodePointArrayFallback)(unsafeCodePointAt0);
  var length = function ($52) {
      return Data_Array.length(toCodePointArray($52));
  };
  var fromCharCode = (function () {
      var $53 = Data_Enum.toEnumWithDefaults(Data_Enum.boundedEnumChar)(Data_Bounded.bottom(Data_Bounded.boundedChar))(Data_Bounded.top(Data_Bounded.boundedChar));
      return function ($54) {
          return Data_String_CodeUnits.singleton($53($54));
      };
  })();
  var singletonFallback = function (v) {
      if (v <= 65535) {
          return fromCharCode(v);
      };
      var lead = Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(v - 65536 | 0)(1024) + 55296 | 0;
      var trail = Data_EuclideanRing.mod(Data_EuclideanRing.euclideanRingInt)(v - 65536 | 0)(1024) + 56320 | 0;
      return fromCharCode(lead) + fromCharCode(trail);
  };
  var fromCodePointArray = $foreign["_fromCodePointArray"](singletonFallback);
  var singleton = $foreign["_singleton"](singletonFallback);
  var takeFallback = function (n) {
      return function (v) {
          if (n < 1) {
              return "";
          };
          var v1 = uncons(v);
          if (v1 instanceof Data_Maybe.Just) {
              return singleton(v1.value0.head) + takeFallback(n - 1 | 0)(v1.value0.tail);
          };
          return v;
      };
  };
  var take = $foreign["_take"](takeFallback);
  var drop = function (n) {
      return function (s) {
          return Data_String_CodeUnits.drop(Data_String_CodeUnits.length(take(n)(s)))(s);
      };
  };
  var codePointFromChar = (function () {
      var $55 = Data_Enum.fromEnum(Data_Enum.boundedEnumChar);
      return function ($56) {
          return CodePoint($55($56));
      };
  })();
  var codePointAtFallback = function ($copy_n) {
      return function ($copy_s) {
          var $tco_var_n = $copy_n;
          var $tco_done = false;
          var $tco_result;
          function $tco_loop(n, s) {
              var v = uncons(s);
              if (v instanceof Data_Maybe.Just) {
                  var $44 = n === 0;
                  if ($44) {
                      $tco_done = true;
                      return new Data_Maybe.Just(v.value0.head);
                  };
                  $tco_var_n = n - 1 | 0;
                  $copy_s = v.value0.tail;
                  return;
              };
              $tco_done = true;
              return Data_Maybe.Nothing.value;
          };
          while (!$tco_done) {
              $tco_result = $tco_loop($tco_var_n, $copy_s);
          };
          return $tco_result;
      };
  };
  var codePointAt = function (v) {
      return function (v1) {
          if (v < 0) {
              return Data_Maybe.Nothing.value;
          };
          if (v === 0 && v1 === "") {
              return Data_Maybe.Nothing.value;
          };
          if (v === 0) {
              return new Data_Maybe.Just(unsafeCodePointAt0(v1));
          };
          return $foreign["_codePointAt"](codePointAtFallback)(Data_Maybe.Just.create)(Data_Maybe.Nothing.value)(unsafeCodePointAt0)(v)(v1);
      };
  };
  exports["codePointFromChar"] = codePointFromChar;
  exports["fromCodePointArray"] = fromCodePointArray;
  exports["codePointAt"] = codePointAt;
  exports["length"] = length;
})(PS);
(function(exports) {
  "use strict";

  exports.split = function (sep) {
    return function (s) {
      return s.split(sep);
    };
  };

  exports.trim = function (s) {
    return s.trim();
  };

  exports.joinWith = function (s) {
    return function (xs) {
      return xs.join(s);
    };
  };
})(PS["Data.String.Common"] = PS["Data.String.Common"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.String.Common"] = $PS["Data.String.Common"] || {};
  var exports = $PS["Data.String.Common"];
  var $foreign = $PS["Data.String.Common"];
  exports["split"] = $foreign.split;
  exports["trim"] = $foreign.trim;
  exports["joinWith"] = $foreign.joinWith;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Variant"] = $PS["Data.Variant"] || {};
  var exports = $PS["Data.Variant"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Partial_Unsafe = $PS["Partial.Unsafe"];
  var Record_Unsafe = $PS["Record.Unsafe"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var onMatch = function (dictRowToList) {
      return function (dictVariantMatchCases) {
          return function (dictUnion) {
              return function (r) {
                  return function (k) {
                      return function (v) {
                          if (Record_Unsafe.unsafeHas(v.type)(r)) {
                              return Record_Unsafe.unsafeGet(v.type)(r)(v.value);
                          };
                          return k(v);
                      };
                  };
              };
          };
      };
  };
  var inj = function (dictCons) {
      return function (dictIsSymbol) {
          return function (p) {
              return function (value) {
                  return {
                      type: Data_Symbol.reflectSymbol(dictIsSymbol)(p),
                      value: value
                  };
              };
          };
      };
  };
  var expand = function (dictUnion) {
      return Unsafe_Coerce.unsafeCoerce;
  };
  var case_ = function (r) {
      return Partial_Unsafe.unsafeCrashWith("Data.Variant: pattern match failure [" + (r.type + "]"));
  };
  var match = function (dictRowToList) {
      return function (dictVariantMatchCases) {
          return function (dictUnion) {
              return function (r) {
                  return onMatch()()()(r)(case_);
              };
          };
      };
  };
  exports["inj"] = inj;
  exports["match"] = match;
  exports["expand"] = expand;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Data.Void"] = $PS["Data.Void"] || {};
  var exports = $PS["Data.Void"];
  var absurd = function (a) {
      var spin = function ($copy_v) {
          var $tco_result;
          function $tco_loop(v) {
              $copy_v = v;
              return;
          };
          while (!false) {
              $tco_result = $tco_loop($copy_v);
          };
          return $tco_result;
      };
      return spin(a);
  };
  exports["absurd"] = absurd;
})(PS);
(function(exports) {
  "use strict";

  var AVar = function () {

    function MutableQueue () {
      this.head = null;
      this.last = null;
      this.size = 0;
    }

    function MutableCell (queue, value) {
      this.queue = queue;
      this.value = value;
      this.next  = null;
      this.prev  = null;
    }

    function AVar (value) {
      this.draining = false;
      this.error    = null;
      this.value    = value;
      this.takes    = new MutableQueue();
      this.reads    = new MutableQueue();
      this.puts     = new MutableQueue();
    }

    var EMPTY = {};

    function runEff(eff) {
      try {
        eff();
      } catch (error) {
        setTimeout(function () {
          throw error;
        }, 0);
      }
    }

    function putLast (queue, value) {
      var cell = new MutableCell(queue, value);
      switch (queue.size) {
        case 0:
          queue.head = cell;
          break;
        case 1:
          cell.prev = queue.head;
          queue.head.next = cell;
          queue.last = cell;
          break;
        default:
          cell.prev = queue.last;
          queue.last.next = cell;
          queue.last = cell;
      }
      queue.size++;
      return cell;
    }

    function takeLast (queue) {
      var cell;
      switch (queue.size) {
        case 0:
          return null;
        case 1:
          cell = queue.head;
          queue.head = null;
          break;
        case 2:
          cell = queue.last;
          queue.head.next = null;
          queue.last = null;
          break;
        default:
          cell = queue.last;
          queue.last = cell.prev;
          queue.last.next = null;
      }
      cell.prev = null;
      cell.queue = null;
      queue.size--;
      return cell.value;
    }

    function takeHead (queue) {
      var cell;
      switch (queue.size) {
        case 0:
          return null;
        case 1:
          cell = queue.head;
          queue.head = null;
          break;
        case 2:
          cell = queue.head;
          queue.last.prev = null;
          queue.head = queue.last;
          queue.last = null;
          break;
        default:
          cell = queue.head;
          queue.head = cell.next;
          queue.head.prev = null;
      }
      cell.next = null;
      cell.queue = null;
      queue.size--;
      return cell.value;
    }

    function deleteCell (cell) {
      if (cell.queue === null) {
        return;
      }
      if (cell.queue.last === cell) {
        takeLast(cell.queue);
        return;
      }
      if (cell.queue.head === cell) {
        takeHead(cell.queue);
        return;
      }
      if (cell.prev) {
        cell.prev.next = cell.next;
      }
      if (cell.next) {
        cell.next.prev = cell.prev;
      }
      cell.queue.size--;
      cell.queue = null;
      cell.value = null;
      cell.next  = null;
      cell.prev  = null;
    }

    function drainVar (util, avar) {
      if (avar.draining) {
        return;
      }

      var ps = avar.puts;
      var ts = avar.takes;
      var rs = avar.reads;
      var p, r, t, value, rsize;

      avar.draining = true;

      while (1) { // eslint-disable-line no-constant-condition
        p = null;
        r = null;
        t = null;
        value = avar.value;
        rsize = rs.size;

        if (avar.error !== null) {
          value = util.left(avar.error);
          while (p = takeHead(ps)) { // eslint-disable-line no-cond-assign
            runEff(p.cb(value));
          }
          while (r = takeHead(rs)) { // eslint-disable-line no-cond-assign
            runEff(r(value));
          }
          while (t = takeHead(ts)) { // eslint-disable-line no-cond-assign
            runEff(t(value));
          }
          break;
        }

        // Process the next put. We do not immediately invoke the callback
        // because we want to preserve ordering. If there are takes/reads
        // we want to run those first.
        if (value === EMPTY && (p = takeHead(ps))) {
          avar.value = value = p.value;
        }

        if (value !== EMPTY) {
          // We go ahead and queue up the next take for the same reasons as
          // above. Invoking the read callbacks can affect the mutable queue.
          t = takeHead(ts);
          // We only want to process the reads queued up before running these
          // callbacks so we guard on rsize.
          while (rsize-- && (r = takeHead(rs))) {
            runEff(r(util.right(value)));
          }
          if (t !== null) {
            avar.value = EMPTY;
            runEff(t(util.right(value)));
          }
        }

        if (p !== null) {
          runEff(p.cb(util.right(void 0)));
        }

        // Callbacks could have queued up more items so we need to guard on the
        // actual mutable properties.
        if (avar.value === EMPTY && ps.size === 0 || avar.value !== EMPTY && ts.size === 0) {
          break;
        }
      }
      avar.draining = false;
    }

    AVar.EMPTY      = EMPTY;
    AVar.putLast    = putLast;
    AVar.takeLast   = takeLast;
    AVar.takeHead   = takeHead;
    AVar.deleteCell = deleteCell;
    AVar.drainVar   = drainVar;

    return AVar;
  }();

  exports.empty = function () {
    return new AVar(AVar.EMPTY);
  };

  exports._putVar = function (util, value, avar, cb) {
    return function () {
      var cell = AVar.putLast(avar.puts, { cb: cb, value: value });
      AVar.drainVar(util, avar);
      return function () {
        AVar.deleteCell(cell);
      };
    };
  };

  exports._takeVar = function (util, avar, cb) {
    return function () {
      var cell = AVar.putLast(avar.takes, cb);
      AVar.drainVar(util, avar);
      return function () {
        AVar.deleteCell(cell);
      };
    };
  };
})(PS["Effect.AVar"] = PS["Effect.AVar"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Effect.AVar"] = $PS["Effect.AVar"] || {};
  var exports = $PS["Effect.AVar"];
  var $foreign = $PS["Effect.AVar"];
  var Data_Either = $PS["Data.Either"];
  var Data_Maybe = $PS["Data.Maybe"];                
  var Killed = (function () {
      function Killed(value0) {
          this.value0 = value0;
      };
      Killed.create = function (value0) {
          return new Killed(value0);
      };
      return Killed;
  })();
  var Filled = (function () {
      function Filled(value0) {
          this.value0 = value0;
      };
      Filled.create = function (value0) {
          return new Filled(value0);
      };
      return Filled;
  })();
  var Empty = (function () {
      function Empty() {

      };
      Empty.value = new Empty();
      return Empty;
  })();
  var ffiUtil = {
      left: Data_Either.Left.create,
      right: Data_Either.Right.create,
      nothing: Data_Maybe.Nothing.value,
      just: Data_Maybe.Just.create,
      killed: Killed.create,
      filled: Filled.create,
      empty: Empty.value
  };
  var put = function (value) {
      return function (avar) {
          return function (cb) {
              return $foreign["_putVar"](ffiUtil, value, avar, cb);
          };
      };
  };
  var take = function (avar) {
      return function (cb) {
          return $foreign["_takeVar"](ffiUtil, avar, cb);
      };
  };
  exports["take"] = take;
  exports["put"] = put;
  exports["empty"] = $foreign.empty;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Effect.Aff.AVar"] = $PS["Effect.Aff.AVar"] || {};
  var exports = $PS["Effect.Aff.AVar"];
  var Effect_AVar = $PS["Effect.AVar"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var take = function (avar) {
      return Effect_Aff.makeAff(function (k) {
          return function __do() {
              var c = Effect_AVar.take(avar)(k)();
              return Effect_Aff.effectCanceler(c);
          };
      });
  };
  var put = function (value) {
      return function (avar) {
          return Effect_Aff.makeAff(function (k) {
              return function __do() {
                  var c = Effect_AVar.put(value)(avar)(k)();
                  return Effect_Aff.effectCanceler(c);
              };
          });
      };
  };
  var empty = Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_AVar.empty);
  exports["empty"] = empty;
  exports["take"] = take;
  exports["put"] = put;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Effect.Aff.Class"] = $PS["Effect.Aff.Class"] || {};
  var exports = $PS["Effect.Aff.Class"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Reader_Trans = $PS["Control.Monad.Reader.Trans"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Effect_Aff = $PS["Effect.Aff"];                
  var MonadAff = function (MonadEffect0, liftAff) {
      this.MonadEffect0 = MonadEffect0;
      this.liftAff = liftAff;
  };
  var monadAffAff = new MonadAff(function () {
      return Effect_Aff.monadEffectAff;
  }, Control_Category.identity(Control_Category.categoryFn));
  var liftAff = function (dict) {
      return dict.liftAff;
  };
  var monadAffReader = function (dictMonadAff) {
      return new MonadAff(function () {
          return Control_Monad_Reader_Trans.monadEffectReader(dictMonadAff.MonadEffect0());
      }, (function () {
          var $25 = Control_Monad_Trans_Class.lift(Control_Monad_Reader_Trans.monadTransReaderT)((dictMonadAff.MonadEffect0()).Monad0());
          var $26 = liftAff(dictMonadAff);
          return function ($27) {
              return $25($26($27));
          };
      })());
  };
  exports["liftAff"] = liftAff;
  exports["MonadAff"] = MonadAff;
  exports["monadAffAff"] = monadAffAff;
  exports["monadAffReader"] = monadAffReader;
})(PS);
(function(exports) {
  "use strict";

  exports.warn = function (s) {
    return function () {
      console.warn(s);
    };
  };

  exports.error = function (s) {
    return function () {
      console.error(s);
    };
  };
})(PS["Effect.Console"] = PS["Effect.Console"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Effect.Console"] = $PS["Effect.Console"] || {};
  var exports = $PS["Effect.Console"];
  var $foreign = $PS["Effect.Console"];
  var Data_Show = $PS["Data.Show"];
  var errorShow = function (dictShow) {
      return function (a) {
          return $foreign.error(Data_Show.show(dictShow)(a));
      };
  };
  exports["errorShow"] = errorShow;
  exports["warn"] = $foreign.warn;
  exports["error"] = $foreign.error;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Effect.Class.Console"] = $PS["Effect.Class.Console"] || {};
  var exports = $PS["Effect.Class.Console"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Console = $PS["Effect.Console"];
  var error = function (dictMonadEffect) {
      var $40 = Effect_Class.liftEffect(dictMonadEffect);
      return function ($41) {
          return $40(Effect_Console.error($41));
      };
  };
  exports["error"] = error;
})(PS);
(function(exports) {
  "use strict";

  exports.random = Math.random;
})(PS["Effect.Random"] = PS["Effect.Random"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Effect.Random"] = $PS["Effect.Random"] || {};
  var exports = $PS["Effect.Random"];
  var $foreign = $PS["Effect.Random"];
  var Data_Int = $PS["Data.Int"];
  var randomInt = function (low) {
      return function (high) {
          return function __do() {
              var n = $foreign.random();
              var asNumber = ((Data_Int.toNumber(high) - Data_Int.toNumber(low)) + 1) * n + Data_Int.toNumber(low);
              return Data_Int.floor(asNumber);
          };
      };
  };
  exports["randomInt"] = randomInt;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafeReadPropImpl = function (f, s, key, value) {
    return value == null ? f : s(value[key]);
  };

  exports.unsafeHasOwnProperty = function (prop, value) {
    return Object.prototype.hasOwnProperty.call(value, prop);
  };

  exports.unsafeHasProperty = function (prop, value) {
    return prop in value;
  };
})(PS["Foreign.Index"] = PS["Foreign.Index"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Foreign.Index"] = $PS["Foreign.Index"] || {};
  var exports = $PS["Foreign.Index"];
  var $foreign = $PS["Foreign.Index"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Function = $PS["Data.Function"];
  var Foreign = $PS["Foreign"];                
  var Index = function (errorAt, hasOwnProperty, hasProperty, index) {
      this.errorAt = errorAt;
      this.hasOwnProperty = hasOwnProperty;
      this.hasProperty = hasProperty;
      this.index = index;
  };
  var Indexable = function (ix) {
      this.ix = ix;
  };
  var unsafeReadProp = function (dictMonad) {
      return function (k) {
          return function (value) {
              return $foreign.unsafeReadPropImpl(Foreign.fail(dictMonad)(new Foreign.TypeMismatch("object", Foreign.typeOf(value))), Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(dictMonad)), k, value);
          };
      };
  };
  var readProp = function (dictMonad) {
      return unsafeReadProp(dictMonad);
  };
  var ix = function (dict) {
      return dict.ix;
  };
  var index = function (dict) {
      return dict.index;
  };
  var indexableForeign = function (dictMonad) {
      return new Indexable(function (dictIndex) {
          return index(dictIndex);
      });
  };
  var hasPropertyImpl = function (v) {
      return function (value) {
          if (Foreign.isNull(value)) {
              return false;
          };
          if (Foreign.isUndefined(value)) {
              return false;
          };
          if (Foreign.typeOf(value) === "object" || Foreign.typeOf(value) === "function") {
              return $foreign.unsafeHasProperty(v, value);
          };
          return false;
      };
  };
  var hasOwnPropertyImpl = function (v) {
      return function (value) {
          if (Foreign.isNull(value)) {
              return false;
          };
          if (Foreign.isUndefined(value)) {
              return false;
          };
          if (Foreign.typeOf(value) === "object" || Foreign.typeOf(value) === "function") {
              return $foreign.unsafeHasOwnProperty(v, value);
          };
          return false;
      };
  };
  var indexString = function (dictMonad) {
      return new Index(Foreign.ErrorAtProperty.create, hasOwnPropertyImpl, hasPropertyImpl, Data_Function.flip(readProp(dictMonad)));
  };
  exports["readProp"] = readProp;
  exports["ix"] = ix;
  exports["indexString"] = indexString;
  exports["indexableForeign"] = indexableForeign;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Formless.Action"] = $PS["Formless.Action"] || {};
  var exports = $PS["Formless.Action"];
  var Data_Function = $PS["Data.Function"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];
  var Data_Variant = $PS["Data.Variant"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var validateAll = Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
      return "validateAll";
  }))(Type_Proxy["Proxy"].value)(Data_Unit.unit);
  var submit = Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
      return "submit";
  }))(Type_Proxy["Proxy"].value)(Data_Unit.unit);
  var setValidate = function (dictIsSymbol) {
      return function (dictNewtype) {
          return function (dictCons) {
              return function (sym) {
                  return function (i) {
                      return Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
                          return "modifyValidate";
                      }))(Type_Proxy["Proxy"].value)(new Data_Tuple.Tuple(Data_Maybe.Nothing.value, Data_Newtype.wrap()(Data_Variant.inj()(dictIsSymbol)(sym)(Data_Newtype.wrap()(Data_Function["const"](i))))));
                  };
              };
          };
      };
  };                             
  var injAction = Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
      return "userAction";
  }))(Type_Proxy["Proxy"].value);
  exports["injAction"] = injAction;
  exports["setValidate"] = setValidate;
  exports["validateAll"] = validateAll;
  exports["submit"] = submit;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Formless.Class.Initial"] = $PS["Formless.Class.Initial"] || {};
  var exports = $PS["Formless.Class.Initial"];
  var Data_Monoid = $PS["Data.Monoid"];              
  var Initial = function (initial) {
      this.initial = initial;
  };                                                                        
  var initialString = new Initial(Data_Monoid.mempty(Data_Monoid.monoidString));
  var initial = function (dict) {
      return dict.initial;
  };
  exports["initial"] = initial;
  exports["initialString"] = initialString;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Formless.Data.FormFieldResult"] = $PS["Formless.Data.FormFieldResult"] || {};
  var exports = $PS["Formless.Data.FormFieldResult"];
  var Data_Either = $PS["Data.Either"];
  var Data_Lens_Prism = $PS["Data.Lens.Prism"];
  var Data_Maybe = $PS["Data.Maybe"];                
  var NotValidated = (function () {
      function NotValidated() {

      };
      NotValidated.value = new NotValidated();
      return NotValidated;
  })();
  var Validating = (function () {
      function Validating() {

      };
      Validating.value = new Validating();
      return Validating;
  })();
  var $$Error = (function () {
      function $$Error(value0) {
          this.value0 = value0;
      };
      $$Error.create = function (value0) {
          return new $$Error(value0);
      };
      return $$Error;
  })();
  var Success = (function () {
      function Success(value0) {
          this.value0 = value0;
      };
      Success.create = function (value0) {
          return new Success(value0);
      };
      return Success;
  })();
  var toMaybe = function (v) {
      if (v instanceof Success) {
          return new Data_Maybe.Just(v.value0);
      };
      return Data_Maybe.Nothing.value;
  }; 
  var fromEither = function (v) {
      if (v instanceof Data_Either.Left) {
          return new $$Error(v.value0);
      };
      if (v instanceof Data_Either.Right) {
          return new Success(v.value0);
      };
      throw new Error("Failed pattern match at Formless.Data.FormFieldResult (line 38, column 14 - line 40, column 23): " + [ v.constructor.name ]);
  };
  var _Error = function (dictChoice) {
      return Data_Lens_Prism["prism'"]($$Error.create)(function (v) {
          if (v instanceof $$Error) {
              return new Data_Maybe.Just(v.value0);
          };
          return Data_Maybe.Nothing.value;
      })(dictChoice);
  };
  exports["NotValidated"] = NotValidated;
  exports["Validating"] = Validating;
  exports["Error"] = $$Error;
  exports["fromEither"] = fromEither;
  exports["toMaybe"] = toMaybe;
  exports["_Error"] = _Error;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Formless.Types.Form"] = $PS["Formless.Types.Form"] || {};
  var exports = $PS["Formless.Types.Form"];
  var OutputField = function (x) {
      return x;
  };
  var FormField = function (x) {
      return x;
  };
  var eqInputField = function (dictEq) {
      return dictEq;
  };
  exports["OutputField"] = OutputField;
  exports["FormField"] = FormField;
  exports["eqInputField"] = eqInputField;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Formless.Validation"] = $PS["Formless.Validation"] || {};
  var exports = $PS["Formless.Validation"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Newtype = $PS["Data.Newtype"];                                  
  var Validation = function (x) {
      return x;
  }; 
  var runValidation = function (dictMonad) {
      return Data_Newtype.unwrap();
  };
  var hoistFn_ = function (dictMonad) {
      return function (f) {
          return Validation(Data_Function["const"]((function () {
              var $41 = Control_Applicative.pure(dictMonad.Applicative0());
              var $42 = Control_Applicative.pure(Data_Either.applicativeEither);
              return function ($43) {
                  return $41($42(f($43)));
              };
          })()));
      };
  };
  var hoistFnE_ = function (dictMonad) {
      return function (f) {
          return Validation(Data_Function["const"]((function () {
              var $45 = Control_Applicative.pure(dictMonad.Applicative0());
              return function ($46) {
                  return $45(f($46));
              };
          })()));
      };
  };
  exports["runValidation"] = runValidation;
  exports["hoistFn_"] = hoistFn_;
  exports["hoistFnE_"] = hoistFnE_;
})(PS);
(function(exports) {
  "use strict";

  exports.copyRecord = function(rec) {
    var copy = {};
    for (var key in rec) {
      if ({}.hasOwnProperty.call(rec, key)) {
        copy[key] = rec[key];
      }
    }
    return copy;
  };

  exports.unsafeInsert = function(l) {
    return function(a) {
      return function(rec) {
        rec[l] = a;
        return rec;
      };
    };
  };

  exports.unsafeModify = function(l) {
    return function (f) {
      return function(rec) {
        rec[l] = f(rec[l]);
        return rec;
      };
    };
  };

  exports.unsafeDelete = function(l) {
    return function(rec) {
      delete rec[l];
      return rec;
    };
  };
})(PS["Record.Builder"] = PS["Record.Builder"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Record.Builder"] = $PS["Record.Builder"] || {};
  var exports = $PS["Record.Builder"];
  var $foreign = $PS["Record.Builder"];
  var Control_Category = $PS["Control.Category"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Symbol = $PS["Data.Symbol"];
  var semigroupoidBuilder = Control_Semigroupoid.semigroupoidFn;
  var modify = function (dictCons) {
      return function (dictCons1) {
          return function (dictIsSymbol) {
              return function (l) {
                  return function (f) {
                      return function (r1) {
                          return $foreign.unsafeModify(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(f)(r1);
                      };
                  };
              };
          };
      };
  };
  var insert = function (dictCons) {
      return function (dictLacks) {
          return function (dictIsSymbol) {
              return function (l) {
                  return function (a) {
                      return function (r1) {
                          return $foreign.unsafeInsert(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(a)(r1);
                      };
                  };
              };
          };
      };
  };
  var $$delete = function (dictIsSymbol) {
      return function (dictLacks) {
          return function (dictCons) {
              return function (l) {
                  return function (r2) {
                      return $foreign.unsafeDelete(Data_Symbol.reflectSymbol(dictIsSymbol)(l))(r2);
                  };
              };
          };
      };
  };
  var categoryBuilder = Control_Category.categoryFn;
  var build = function (v) {
      return function (r1) {
          return v($foreign.copyRecord(r1));
      };
  };
  exports["build"] = build;
  exports["insert"] = insert;
  exports["modify"] = modify;
  exports["delete"] = $$delete;
  exports["semigroupoidBuilder"] = semigroupoidBuilder;
  exports["categoryBuilder"] = categoryBuilder;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Formless.Internal.Transform"] = $PS["Formless.Internal.Transform"] || {};
  var exports = $PS["Formless.Internal.Transform"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Formless_Data_FormFieldResult = $PS["Formless.Data.FormFieldResult"];
  var Formless_Types_Form = $PS["Formless.Types.Form"];
  var Formless_Validation = $PS["Formless.Validation"];
  var Record = $PS["Record"];
  var Record_Builder = $PS["Record.Builder"];
  var Record_Unsafe = $PS["Record.Unsafe"];
  var Type_Proxy = $PS["Type.Proxy"];
  var InputFieldsToFormFields = function (inputFieldsToFormFieldsBuilder) {
      this.inputFieldsToFormFieldsBuilder = inputFieldsToFormFieldsBuilder;
  };
  var ModifyAll = function (modifyAllBuilder) {
      this.modifyAllBuilder = modifyAllBuilder;
  };
  var ReplaceFormFieldInputs = function (replaceFormFieldInputsBuilder) {
      this.replaceFormFieldInputsBuilder = replaceFormFieldInputsBuilder;
  };
  var SetFormFieldsTouched = function (setFormFieldsTouchedBuilder) {
      this.setFormFieldsTouchedBuilder = setFormFieldsTouchedBuilder;
  };
  var ValidateAll = function (validateAllBuilder) {
      this.validateAllBuilder = validateAllBuilder;
  };
  var FormFieldsToInputFields = function (formFieldsToInputFieldsBuilder) {
      this.formFieldsToInputFieldsBuilder = formFieldsToInputFieldsBuilder;
  };
  var FormFieldToMaybeOutput = function (formFieldsToMaybeOutputBuilder) {
      this.formFieldsToMaybeOutputBuilder = formFieldsToMaybeOutputBuilder;
  };
  var CountErrors = function (countErrorsImpl) {
      this.countErrorsImpl = countErrorsImpl;
  };
  var AllTouched = function (allTouchedImpl) {
      this.allTouchedImpl = allTouchedImpl;
  };
  var validateAllBuilder = function (dict) {
      return dict.validateAllBuilder;
  };
  var unsafeRunValidationVariant = function (dictMonad) {
      return function (dictNewtype) {
          return function (dictNewtype1) {
              return function (dictNewtype2) {
                  return function ($$var) {
                      return function (vs) {
                          return function (rec) {
                              var label = (function () {
                                  var v = Data_Newtype.unwrap()($$var);
                                  return v.type;
                              })();
                              var rec2 = (function () {
                                  var v = Record_Unsafe.unsafeGet(label)(Data_Newtype.unwrap()(rec));
                                  return Control_Bind.bind(dictMonad.Bind1())(Formless_Validation.runValidation(dictMonad)(Record_Unsafe.unsafeGet(label)(Data_Newtype.unwrap()(vs)))(rec)(v.input))(function (res) {
                                      return Control_Applicative.pure(dictMonad.Applicative0())(function (newRec) {
                                          return Data_Newtype.wrap()(Record_Unsafe.unsafeSet(label)(Formless_Types_Form.FormField({
                                              input: v.input,
                                              touched: v.touched,
                                              result: Formless_Data_FormFieldResult.fromEither(res)
                                          }))(Data_Newtype.unwrap()(newRec)));
                                      });
                                  });
                              })();
                              return rec2;
                          };
                      };
                  };
              };
          };
      };
  };
  var unsafeModifyInputVariant = function (dictNewtype) {
      return function (dictNewtype1) {
          return function (f) {
              return function ($$var) {
                  return function (rec) {
                      var rep = (function () {
                          var v = Data_Newtype.unwrap()($$var);
                          return new Data_Tuple.Tuple(v.type, v.value);
                      })();
                      var val = (function () {
                          var v = Record_Unsafe.unsafeGet(Data_Tuple.fst(rep))(Data_Newtype.unwrap()(rec));
                          return Formless_Types_Form.FormField({
                              input: Data_Newtype.unwrap()(Data_Tuple.snd(rep))(v.input),
                              touched: true,
                              result: f(v.result)
                          });
                      })();
                      return Data_Newtype.wrap()(Record_Unsafe.unsafeSet(Data_Tuple.fst(rep))(val)(Data_Newtype.unwrap()(rec)));
                  };
              };
          };
      };
  };
  var setFormFieldsTouchedNil = new SetFormFieldsTouched(function (v) {
      return function (v1) {
          return Control_Category.identity(Record_Builder.categoryBuilder);
      };
  });
  var setFormFieldsTouchedBuilder = function (dict) {
      return dict.setFormFieldsTouchedBuilder;
  };
  var setFormFieldsTouchedCons = function (dictIsSymbol) {
      return function (dictCons) {
          return function (dictSetFormFieldsTouched) {
              return function (dictRow1Cons) {
                  return new SetFormFieldsTouched(function (v) {
                      return function (r) {
                          var rest = setFormFieldsTouchedBuilder(dictSetFormFieldsTouched)(Type_Proxy["Proxy"].value)(r);
                          var val = Data_Newtype.over()()(Formless_Types_Form.FormField)(function (v1) {
                              return {
                                  touched: true,
                                  input: v1.input,
                                  result: v1.result
                              };
                          })(Record.get(dictIsSymbol)()(Type_Proxy["Proxy"].value)(r));
                          var first = Record_Builder.insert()()(dictIsSymbol)(Type_Proxy["Proxy"].value)(val);
                          return Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder)(first)(rest);
                      };
                  });
              };
          };
      };
  };
  var replaceFormFieldInputsTouchedNil = new ReplaceFormFieldInputs(function (v) {
      return function (v1) {
          return function (v2) {
              return Control_Category.identity(Record_Builder.categoryBuilder);
          };
      };
  });
  var replaceFormFieldInputsBuilder = function (dict) {
      return dict.replaceFormFieldInputsBuilder;
  };
  var replaceFormFieldInputsTouchedCons = function (dictIsSymbol) {
      return function (dictNewtype) {
          return function (dictNewtype1) {
              return function (dictCons) {
                  return function (dictCons1) {
                      return function (dictRow1Cons) {
                          return function (dictReplaceFormFieldInputs) {
                              return new ReplaceFormFieldInputs(function (ir) {
                                  return function (v) {
                                      return function (fr) {
                                          var rest = replaceFormFieldInputsBuilder(dictReplaceFormFieldInputs)(ir)(Type_Proxy["Proxy"].value)(fr);
                                          var f = Data_Newtype.unwrap()(Record.get(dictIsSymbol)()(Type_Proxy["Proxy"].value)(fr));
                                          var i = Record.get(dictIsSymbol)()(Type_Proxy["Proxy"].value)(ir);
                                          var first = Record_Builder.insert()()(dictIsSymbol)(Type_Proxy["Proxy"].value)(Formless_Types_Form.FormField({
                                              input: Data_Newtype.unwrap()(i),
                                              touched: false,
                                              result: Formless_Data_FormFieldResult.NotValidated.value
                                          }));
                                          return Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder)(first)(rest);
                                      };
                                  };
                              });
                          };
                      };
                  };
              };
          };
      };
  };
  var nilCountErrors = new CountErrors(function (v) {
      return function (v1) {
          return 0;
      };
  });
  var nilAllTouched = new AllTouched(function (v) {
      return function (v1) {
          return true;
      };
  });
  var modifyAllNil = new ModifyAll(function (v) {
      return function (v1) {
          return function (v2) {
              return Control_Category.identity(Record_Builder.categoryBuilder);
          };
      };
  });
  var modifyAllBuilder = function (dict) {
      return dict.modifyAllBuilder;
  };
  var modifyAllCons = function (dictIsSymbol) {
      return function (dictNewtype) {
          return function (dictNewtype1) {
              return function (dictCons) {
                  return function (dictCons1) {
                      return function (dictRow1Cons) {
                          return function (dictModifyAll) {
                              return new ModifyAll(function (ifs) {
                                  return function (v) {
                                      return function (r) {
                                          var rest = modifyAllBuilder(dictModifyAll)(ifs)(Type_Proxy["Proxy"].value)(r);
                                          var f = Data_Newtype.unwrap()(Record.get(dictIsSymbol)()(Type_Proxy["Proxy"].value)(ifs));
                                          var field = Record.get(dictIsSymbol)()(Type_Proxy["Proxy"].value)(r);
                                          var first = Record_Builder.insert()()(dictIsSymbol)(Type_Proxy["Proxy"].value)(Data_Newtype.over()()(Formless_Types_Form.FormField)(function (x) {
                                              return {
                                                  input: f(x.input),
                                                  result: x.result,
                                                  touched: x.touched
                                              };
                                          })(field));
                                          return Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder)(first)(rest);
                                      };
                                  };
                              });
                          };
                      };
                  };
              };
          };
      };
  };
  var inputFieldsToInputNil = new FormFieldsToInputFields(function (v) {
      return function (v1) {
          return Control_Category.identity(Record_Builder.categoryBuilder);
      };
  });
  var inputFieldsToFormFieldsNil = new InputFieldsToFormFields(function (v) {
      return function (v1) {
          return Control_Category.identity(Record_Builder.categoryBuilder);
      };
  });
  var inputFieldsToFormFieldsBuilder = function (dict) {
      return dict.inputFieldsToFormFieldsBuilder;
  };
  var inputFieldsToFormFieldsCons = function (dictIsSymbol) {
      return function (dictCons) {
          return function (dictInputFieldsToFormFields) {
              return function (dictRow1Cons) {
                  return new InputFieldsToFormFields(function (v) {
                      return function (r) {
                          var transform = function (v1) {
                              return {
                                  input: v1,
                                  touched: false,
                                  result: Formless_Data_FormFieldResult.NotValidated.value
                              };
                          };
                          var rest = inputFieldsToFormFieldsBuilder(dictInputFieldsToFormFields)(Type_Proxy["Proxy"].value)(r);
                          var val = transform(Record.get(dictIsSymbol)()(Type_Proxy["Proxy"].value)(r));
                          var first = Record_Builder.insert()()(dictIsSymbol)(Type_Proxy["Proxy"].value)(val);
                          return Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder)(first)(rest);
                      };
                  });
              };
          };
      };
  };
  var fromScratch = Data_Functor.flap(Data_Functor.functorFn)(Record_Builder.build)({});
  var inputFieldsToFormFields = function (dictRowToList) {
      return function (dictInputFieldsToFormFields) {
          return function (dictNewtype) {
              return function (dictNewtype1) {
                  return function (r) {
                      var builder = inputFieldsToFormFieldsBuilder(dictInputFieldsToFormFields)(Type_Proxy["Proxy"].value)(Data_Newtype.unwrap()(r));
                      return Data_Newtype.wrap()(fromScratch(builder));
                  };
              };
          };
      };
  };
  var modifyAll = function (dictRowToList) {
      return function (dictModifyAll) {
          return function (dictNewtype) {
              return function (dictNewtype1) {
                  return function (ifs) {
                      return function (fs) {
                          var builder = modifyAllBuilder(dictModifyAll)(Data_Newtype.unwrap()(ifs))(Type_Proxy["Proxy"].value)(Data_Newtype.unwrap()(fs));
                          return Data_Newtype.wrap()(fromScratch(builder));
                      };
                  };
              };
          };
      };
  };
  var replaceFormFieldInputs = function (dictRowToList) {
      return function (dictReplaceFormFieldInputs) {
          return function (dictNewtype) {
              return function (dictNewtype1) {
                  return function (is) {
                      return function (fs) {
                          var builder = replaceFormFieldInputsBuilder(dictReplaceFormFieldInputs)(Data_Newtype.unwrap()(is))(Type_Proxy["Proxy"].value)(Data_Newtype.unwrap()(fs));
                          return Data_Newtype.wrap()(fromScratch(builder));
                      };
                  };
              };
          };
      };
  };
  var setFormFieldsTouched = function (dictRowToList) {
      return function (dictSetFormFieldsTouched) {
          return function (dictNewtype) {
              return function (r) {
                  var builder = setFormFieldsTouchedBuilder(dictSetFormFieldsTouched)(Type_Proxy["Proxy"].value)(Data_Newtype.unwrap()(r));
                  return Data_Newtype.wrap()(fromScratch(builder));
              };
          };
      };
  };
  var validateAll = function (dictRowToList) {
      return function (dictMonad) {
          return function (dictValidateAll) {
              return function (dictNewtype) {
                  return function (dictNewtype1) {
                      return function (vs) {
                          return function (fs) {
                              var builder = validateAllBuilder(dictValidateAll)(Data_Newtype.unwrap()(vs))(Type_Proxy["Proxy"].value)(Data_Newtype.unwrap()(fs));
                              return Data_Functor.map(((dictMonad.Bind1()).Apply0()).Functor0())(Data_Newtype.wrap())(Data_Functor.map(((dictMonad.Bind1()).Apply0()).Functor0())(fromScratch)(builder));
                          };
                      };
                  };
              };
          };
      };
  };
  var formFieldsToMaybeOutputNil = new FormFieldToMaybeOutput(function (v) {
      return function (v1) {
          return new Data_Maybe.Just(Control_Category.identity(Record_Builder.categoryBuilder));
      };
  });
  var formFieldsToMaybeOutputBuilder = function (dict) {
      return dict.formFieldsToMaybeOutputBuilder;
  };
  var formFieldsToMaybeOutputCons = function (dictIsSymbol) {
      return function (dictCons) {
          return function (dictFormFieldToMaybeOutput) {
              return function (dictRow1Cons) {
                  return new FormFieldToMaybeOutput(function (v) {
                      return function (r) {
                          var rest = formFieldsToMaybeOutputBuilder(dictFormFieldToMaybeOutput)(Type_Proxy["Proxy"].value)(r);
                          var transform = function (v1) {
                              return function (builder$prime) {
                                  return Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder)(Record_Builder.insert()()(dictIsSymbol)(Type_Proxy["Proxy"].value)(v1))(builder$prime);
                              };
                          };
                          var val = Data_Functor.map(Data_Maybe.functorMaybe)(Formless_Types_Form.OutputField)(Formless_Data_FormFieldResult.toMaybe((Data_Newtype.unwrap()(Record.get(dictIsSymbol)()(Type_Proxy["Proxy"].value)(r))).result));
                          return Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(transform)(val))(rest);
                      };
                  });
              };
          };
      };
  };
  var formFieldsToMaybeOutputFields = function (dictRowToList) {
      return function (dictNewtype) {
          return function (dictNewtype1) {
              return function (dictFormFieldToMaybeOutput) {
                  return function (r) {
                      var builder = formFieldsToMaybeOutputBuilder(dictFormFieldToMaybeOutput)(Type_Proxy["Proxy"].value)(Data_Newtype.unwrap()(r));
                      return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.wrap())(Data_Functor.map(Data_Maybe.functorMaybe)(fromScratch)(builder));
                  };
              };
          };
      };
  };
  var formFieldsToInputFieldsBuilder = function (dict) {
      return dict.formFieldsToInputFieldsBuilder;
  };
  var inputFieldsToInputCons = function (dictIsSymbol) {
      return function (dictCons) {
          return function (dictFormFieldsToInputFields) {
              return function (dictRow1Cons) {
                  return new FormFieldsToInputFields(function (v) {
                      return function (r) {
                          var transform = function (v1) {
                              return v1.input;
                          };
                          var rest = formFieldsToInputFieldsBuilder(dictFormFieldsToInputFields)(Type_Proxy["Proxy"].value)(r);
                          var val = transform(Record.get(dictIsSymbol)()(Type_Proxy["Proxy"].value)(r));
                          var first = Record_Builder.insert()()(dictIsSymbol)(Type_Proxy["Proxy"].value)(val);
                          return Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder)(first)(rest);
                      };
                  });
              };
          };
      };
  };
  var formFieldsToInputFields = function (dictRowToList) {
      return function (dictFormFieldsToInputFields) {
          return function (dictNewtype) {
              return function (dictNewtype1) {
                  return function (r) {
                      var builder = formFieldsToInputFieldsBuilder(dictFormFieldsToInputFields)(Type_Proxy["Proxy"].value)(Data_Newtype.unwrap()(r));
                      return Data_Newtype.wrap()(fromScratch(builder));
                  };
              };
          };
      };
  };
  var countErrorsImpl = function (dict) {
      return dict.countErrorsImpl;
  };
  var countErrors = function (dictRowToList) {
      return function (dictCountErrors) {
          return function (dictNewtype) {
              var $142 = countErrorsImpl(dictCountErrors)(Type_Proxy["Proxy"].value);
              var $143 = Data_Newtype.unwrap();
              return function ($144) {
                  return $142($143($144));
              };
          };
      };
  };
  var consCountErrors = function (dictIsSymbol) {
      return function (dictCons) {
          return function (dictCountErrors) {
              return new CountErrors(function (v) {
                  return function (r) {
                      var res = (function () {
                          var v1 = (Data_Newtype.unwrap()(Record.get(dictIsSymbol)()(Type_Proxy["Proxy"].value)(r))).result;
                          if (v1 instanceof Formless_Data_FormFieldResult["Error"]) {
                              return 1;
                          };
                          return 0;
                      })();
                      return res + countErrorsImpl(dictCountErrors)(Type_Proxy["Proxy"].value)(r) | 0;
                  };
              });
          };
      };
  };
  var applyToValidationNil = function (dictMonad) {
      return new ValidateAll(function (v) {
          return function (v1) {
              return function (v2) {
                  return Control_Applicative.pure(dictMonad.Applicative0())(Control_Category.identity(Record_Builder.categoryBuilder));
              };
          };
      });
  };
  var applyToValidationCons = function (dictIsSymbol) {
      return function (dictMonad) {
          return function (dictCons) {
              return function (dictNewtype) {
                  return function (dictCons1) {
                      return function (dictRow1Cons) {
                          return function (dictValidateAll) {
                              return new ValidateAll(function (vs) {
                                  return function (v) {
                                      return function (r) {
                                          var rest = validateAllBuilder(dictValidateAll)(vs)(Type_Proxy["Proxy"].value)(r);
                                          var fn = function (val$prime) {
                                              return function (rest$prime) {
                                                  return Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder)(Record_Builder.insert()()(dictIsSymbol)(Type_Proxy["Proxy"].value)(val$prime))(rest$prime);
                                              };
                                          };
                                          var val = (function () {
                                              var validator = Data_Newtype.unwrap()(Record.get(dictIsSymbol)()(Type_Proxy["Proxy"].value)(vs));
                                              var formField = Data_Newtype.unwrap()(Record.get(dictIsSymbol)()(Type_Proxy["Proxy"].value)(r));
                                              return Control_Bind.bind(dictMonad.Bind1())(validator(Data_Newtype.wrap()(r))(formField.input))(function (res) {
                                                  return Control_Applicative.pure(dictMonad.Applicative0())(Data_Newtype.wrap()((function () {
                                                      var $138 = {};
                                                      for (var $139 in formField) {
                                                          if ({}.hasOwnProperty.call(formField, $139)) {
                                                              $138[$139] = formField[$139];
                                                          };
                                                      };
                                                      $138.result = Formless_Data_FormFieldResult.fromEither(res);
                                                      return $138;
                                                  })()));
                                              });
                                          })();
                                          return Control_Apply.apply((dictMonad.Bind1()).Apply0())(Data_Functor.map(((dictMonad.Bind1()).Apply0()).Functor0())(fn)(val))(rest);
                                      };
                                  };
                              });
                          };
                      };
                  };
              };
          };
      };
  };
  var allTouchedImpl = function (dict) {
      return dict.allTouchedImpl;
  };
  var consAllTouched = function (dictIsSymbol) {
      return function (dictCons) {
          return function (dictAllTouched) {
              return new AllTouched(function (v) {
                  return function (r) {
                      var $141 = (Data_Newtype.unwrap()(Record.get(dictIsSymbol)()(Type_Proxy["Proxy"].value)(r))).touched;
                      if ($141) {
                          return allTouchedImpl(dictAllTouched)(Type_Proxy["Proxy"].value)(r);
                      };
                      return false;
                  };
              });
          };
      };
  };
  var allTouched = function (dictRowToList) {
      return function (dictAllTouched) {
          return function (dictNewtype) {
              var $145 = allTouchedImpl(dictAllTouched)(Type_Proxy["Proxy"].value);
              var $146 = Data_Newtype.unwrap();
              return function ($147) {
                  return $145($146($147));
              };
          };
      };
  };
  exports["fromScratch"] = fromScratch;
  exports["allTouched"] = allTouched;
  exports["countErrors"] = countErrors;
  exports["setFormFieldsTouched"] = setFormFieldsTouched;
  exports["formFieldsToInputFields"] = formFieldsToInputFields;
  exports["inputFieldsToFormFields"] = inputFieldsToFormFields;
  exports["formFieldsToMaybeOutputFields"] = formFieldsToMaybeOutputFields;
  exports["replaceFormFieldInputs"] = replaceFormFieldInputs;
  exports["modifyAll"] = modifyAll;
  exports["validateAll"] = validateAll;
  exports["unsafeModifyInputVariant"] = unsafeModifyInputVariant;
  exports["unsafeRunValidationVariant"] = unsafeRunValidationVariant;
  exports["setFormFieldsTouchedNil"] = setFormFieldsTouchedNil;
  exports["setFormFieldsTouchedCons"] = setFormFieldsTouchedCons;
  exports["inputFieldsToInputNil"] = inputFieldsToInputNil;
  exports["inputFieldsToInputCons"] = inputFieldsToInputCons;
  exports["inputFieldsToFormFieldsNil"] = inputFieldsToFormFieldsNil;
  exports["inputFieldsToFormFieldsCons"] = inputFieldsToFormFieldsCons;
  exports["formFieldsToMaybeOutputNil"] = formFieldsToMaybeOutputNil;
  exports["formFieldsToMaybeOutputCons"] = formFieldsToMaybeOutputCons;
  exports["nilCountErrors"] = nilCountErrors;
  exports["consCountErrors"] = consCountErrors;
  exports["nilAllTouched"] = nilAllTouched;
  exports["consAllTouched"] = consAllTouched;
  exports["applyToValidationNil"] = applyToValidationNil;
  exports["applyToValidationCons"] = applyToValidationCons;
  exports["modifyAllNil"] = modifyAllNil;
  exports["modifyAllCons"] = modifyAllCons;
  exports["replaceFormFieldInputsTouchedNil"] = replaceFormFieldInputsTouchedNil;
  exports["replaceFormFieldInputsTouchedCons"] = replaceFormFieldInputsTouchedCons;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Formless.Types.Component"] = $PS["Formless.Types.Component"] || {};
  var exports = $PS["Formless.Types.Component"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var Invalid = (function () {
      function Invalid() {

      };
      Invalid.value = new Invalid();
      return Invalid;
  })();
  var Incomplete = (function () {
      function Incomplete() {

      };
      Incomplete.value = new Incomplete();
      return Incomplete;
  })();
  var Valid = (function () {
      function Valid() {

      };
      Valid.value = new Valid();
      return Valid;
  })();
  var SubmitReply = (function () {
      function SubmitReply(value0) {
          this.value0 = value0;
      };
      SubmitReply.create = function (value0) {
          return new SubmitReply(value0);
      };
      return SubmitReply;
  })();
  var SendQuery = (function () {
      function SendQuery(value0) {
          this.value0 = value0;
      };
      SendQuery.create = function (value0) {
          return new SendQuery(value0);
      };
      return SendQuery;
  })();
  var AsQuery = (function () {
      function AsQuery(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      AsQuery.create = function (value0) {
          return function (value1) {
              return new AsQuery(value0, value1);
          };
      };
      return AsQuery;
  })();
  var Submitted = (function () {
      function Submitted(value0) {
          this.value0 = value0;
      };
      Submitted.create = function (value0) {
          return new Submitted(value0);
      };
      return Submitted;
  })();
  var Changed = (function () {
      function Changed(value0) {
          this.value0 = value0;
      };
      Changed.create = function (value0) {
          return new Changed(value0);
      };
      return Changed;
  })();
  var InternalState = function (x) {
      return x;
  }; 
  var _formless = Type_Proxy["Proxy"].value;
  exports["SubmitReply"] = SubmitReply;
  exports["SendQuery"] = SendQuery;
  exports["AsQuery"] = AsQuery;
  exports["InternalState"] = InternalState;
  exports["Invalid"] = Invalid;
  exports["Incomplete"] = Incomplete;
  exports["Valid"] = Valid;
  exports["Submitted"] = Submitted;
  exports["Changed"] = Changed;
  exports["_formless"] = _formless;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.Query.HalogenM"] = $PS["Halogen.Query.HalogenM"] || {};
  var exports = $PS["Halogen.Query.HalogenM"];
  var Control_Applicative_Free = $PS["Control.Applicative.Free"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Free = $PS["Control.Monad.Free"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Aff_Class = $PS["Effect.Aff.Class"];
  var Effect_Class = $PS["Effect.Class"];                                
  var SubscriptionId = function (x) {
      return x;
  };
  var ForkId = function (x) {
      return x;
  };
  var State = (function () {
      function State(value0) {
          this.value0 = value0;
      };
      State.create = function (value0) {
          return new State(value0);
      };
      return State;
  })();
  var Subscribe = (function () {
      function Subscribe(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Subscribe.create = function (value0) {
          return function (value1) {
              return new Subscribe(value0, value1);
          };
      };
      return Subscribe;
  })();
  var Unsubscribe = (function () {
      function Unsubscribe(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Unsubscribe.create = function (value0) {
          return function (value1) {
              return new Unsubscribe(value0, value1);
          };
      };
      return Unsubscribe;
  })();
  var Lift = (function () {
      function Lift(value0) {
          this.value0 = value0;
      };
      Lift.create = function (value0) {
          return new Lift(value0);
      };
      return Lift;
  })();
  var ChildQuery = (function () {
      function ChildQuery(value0) {
          this.value0 = value0;
      };
      ChildQuery.create = function (value0) {
          return new ChildQuery(value0);
      };
      return ChildQuery;
  })();
  var Raise = (function () {
      function Raise(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Raise.create = function (value0) {
          return function (value1) {
              return new Raise(value0, value1);
          };
      };
      return Raise;
  })();
  var Par = (function () {
      function Par(value0) {
          this.value0 = value0;
      };
      Par.create = function (value0) {
          return new Par(value0);
      };
      return Par;
  })();
  var Fork = (function () {
      function Fork(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Fork.create = function (value0) {
          return function (value1) {
              return new Fork(value0, value1);
          };
      };
      return Fork;
  })();
  var Kill = (function () {
      function Kill(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Kill.create = function (value0) {
          return function (value1) {
              return new Kill(value0, value1);
          };
      };
      return Kill;
  })();
  var GetRef = (function () {
      function GetRef(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      GetRef.create = function (value0) {
          return function (value1) {
              return new GetRef(value0, value1);
          };
      };
      return GetRef;
  })();
  var HalogenAp = function (x) {
      return x;
  };
  var HalogenM = function (x) {
      return x;
  };
  var raise = function (o) {
      return HalogenM(Control_Monad_Free.liftF(new Raise(o, Data_Unit.unit)));
  };
  var ordSubscriptionId = Data_Ord.ordInt;
  var ordForkId = Data_Ord.ordInt;
  var monadTransHalogenM = new Control_Monad_Trans_Class.MonadTrans(function (dictMonad) {
      return function ($135) {
          return HalogenM(Control_Monad_Free.liftF(Lift.create($135)));
      };
  });
  var monadHalogenM = Control_Monad_Free.freeMonad;
  var monadStateHalogenM = new Control_Monad_State_Class.MonadState(function () {
      return monadHalogenM;
  }, function ($136) {
      return HalogenM(Control_Monad_Free.liftF(State.create($136)));
  });
  var monadEffectHalogenM = function (dictMonadEffect) {
      return new Effect_Class.MonadEffect(function () {
          return monadHalogenM;
      }, (function () {
          var $141 = Effect_Class.liftEffect(dictMonadEffect);
          return function ($142) {
              return HalogenM(Control_Monad_Free.liftF(Lift.create($141($142))));
          };
      })());
  };
  var monadAffHalogenM = function (dictMonadAff) {
      return new Effect_Aff_Class.MonadAff(function () {
          return monadEffectHalogenM(dictMonadAff.MonadEffect0());
      }, (function () {
          var $143 = Effect_Aff_Class.liftAff(dictMonadAff);
          return function ($144) {
              return HalogenM(Control_Monad_Free.liftF(Lift.create($143($144))));
          };
      })());
  };
  var kill = function (fid) {
      return HalogenM(Control_Monad_Free.liftF(new Kill(fid, Data_Unit.unit)));
  };
  var hoist = function (dictFunctor) {
      return function (nat) {
          return function (v) {
              var go = function (v1) {
                  if (v1 instanceof State) {
                      return new State(v1.value0);
                  };
                  if (v1 instanceof Subscribe) {
                      return new Subscribe(v1.value0, v1.value1);
                  };
                  if (v1 instanceof Unsubscribe) {
                      return new Unsubscribe(v1.value0, v1.value1);
                  };
                  if (v1 instanceof Lift) {
                      return new Lift(nat(v1.value0));
                  };
                  if (v1 instanceof ChildQuery) {
                      return new ChildQuery(v1.value0);
                  };
                  if (v1 instanceof Raise) {
                      return new Raise(v1.value0, v1.value1);
                  };
                  if (v1 instanceof Par) {
                      return new Par(Data_Newtype.over()()(HalogenAp)(Control_Applicative_Free.hoistFreeAp(hoist(dictFunctor)(nat)))(v1.value0));
                  };
                  if (v1 instanceof Fork) {
                      return new Fork(hoist(dictFunctor)(nat)(v1.value0), v1.value1);
                  };
                  if (v1 instanceof Kill) {
                      return new Kill(v1.value0, v1.value1);
                  };
                  if (v1 instanceof GetRef) {
                      return new GetRef(v1.value0, v1.value1);
                  };
                  throw new Error("Failed pattern match at Halogen.Query.HalogenM (line 300, column 8 - line 310, column 29): " + [ v1.constructor.name ]);
              };
              return Control_Monad_Free.hoistFree(go)(v);
          };
      };
  };
  var functorHalogenM = Control_Monad_Free.freeFunctor;
  var fork = function (hmu) {
      return HalogenM(Control_Monad_Free.liftF(new Fork(hmu, Control_Category.identity(Control_Category.categoryFn))));
  };                           
  var bindHalogenM = Control_Monad_Free.freeBind;
  var applyHalogenM = Control_Monad_Free.freeApply;         
  var applicativeHalogenM = Control_Monad_Free.freeApplicative;
  exports["State"] = State;
  exports["Subscribe"] = Subscribe;
  exports["Unsubscribe"] = Unsubscribe;
  exports["Lift"] = Lift;
  exports["ChildQuery"] = ChildQuery;
  exports["Raise"] = Raise;
  exports["Par"] = Par;
  exports["Fork"] = Fork;
  exports["Kill"] = Kill;
  exports["GetRef"] = GetRef;
  exports["HalogenM"] = HalogenM;
  exports["raise"] = raise;
  exports["SubscriptionId"] = SubscriptionId;
  exports["ForkId"] = ForkId;
  exports["fork"] = fork;
  exports["kill"] = kill;
  exports["hoist"] = hoist;
  exports["functorHalogenM"] = functorHalogenM;
  exports["applyHalogenM"] = applyHalogenM;
  exports["applicativeHalogenM"] = applicativeHalogenM;
  exports["bindHalogenM"] = bindHalogenM;
  exports["monadHalogenM"] = monadHalogenM;
  exports["monadEffectHalogenM"] = monadEffectHalogenM;
  exports["monadAffHalogenM"] = monadAffHalogenM;
  exports["monadTransHalogenM"] = monadTransHalogenM;
  exports["monadStateHalogenM"] = monadStateHalogenM;
  exports["ordSubscriptionId"] = ordSubscriptionId;
  exports["ordForkId"] = ordForkId;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Formless.Internal.Component"] = $PS["Formless.Internal.Component"] || {};
  var exports = $PS["Formless.Internal.Component"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Formless_Internal_Transform = $PS["Formless.Internal.Transform"];
  var Formless_Types_Component = $PS["Formless.Types.Component"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Record_Builder = $PS["Record.Builder"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var submit = function (dictMonadAff) {
      return function (dictRowToList) {
          return function (dictAllTouched) {
              return function (dictSetFormFieldsTouched) {
                  return function (dictValidateAll) {
                      return function (dictFormFieldToMaybeOutput) {
                          return function (dictValidateAll1) {
                              return function (dictNewtype) {
                                  return function (dictNewtype1) {
                                      return function (dictNewtype2) {
                                          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (validated) {
                                              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v) {
                                                  var $25 = {};
                                                  for (var $26 in v) {
                                                      if ({}.hasOwnProperty.call(v, $26)) {
                                                          $25[$26] = v[$26];
                                                      };
                                                  };
                                                  $25.submitting = false;
                                                  return $25;
                                              }))(function () {
                                                  return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)((function () {
                                                      if (validated.validity instanceof Formless_Types_Component.Valid) {
                                                          return Formless_Internal_Transform.formFieldsToMaybeOutputFields()()()(dictFormFieldToMaybeOutput)(validated.form);
                                                      };
                                                      return Data_Maybe.Nothing.value;
                                                  })());
                                              });
                                          });
                                      };
                                  };
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  var preSubmit = function (dictMonadAff) {
      return function (dictRowToList) {
          return function (dictAllTouched) {
              return function (dictSetFormFieldsTouched) {
                  return function (dictValidateAll) {
                      return function (dictFormFieldToMaybeOutput) {
                          return function (dictValidateAll1) {
                              return function (dictNewtype) {
                                  return function (dictNewtype1) {
                                      return function (dictNewtype2) {
                                          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify(Halogen_Query_HalogenM.monadStateHalogenM)(function (st) {
                                              var $29 = {};
                                              for (var $30 in st) {
                                                  if ({}.hasOwnProperty.call(st, $30)) {
                                                      $29[$30] = st[$30];
                                                  };
                                              };
                                              $29.submitAttempts = st.submitAttempts + 1 | 0;
                                              $29.submitting = true;
                                              return $29;
                                          }))(function (init) {
                                              var internal = Data_Newtype.unwrap()(init.internal);
                                              return Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM)(!internal.allTouched)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v) {
                                                  var $32 = {};
                                                  for (var $33 in v) {
                                                      if ({}.hasOwnProperty.call(v, $33)) {
                                                          $32[$33] = v[$33];
                                                      };
                                                  };
                                                  $32.form = Formless_Internal_Transform.setFormFieldsTouched()(dictSetFormFieldsTouched)()(init.form);
                                                  $32.internal = Data_Newtype.over()()(Formless_Types_Component.InternalState)(function (v1) {
                                                      return {
                                                          allTouched: true,
                                                          debounceRef: v1.debounceRef,
                                                          initialInputs: v1.initialInputs,
                                                          validationRef: v1.validationRef,
                                                          validators: v1.validators
                                                      };
                                                  })(init.internal);
                                                  return $32;
                                              }));
                                          });
                                      };
                                  };
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  var getPublicState = function (dictLacks) {
      return Record_Builder.build(Record_Builder["delete"](new Data_Symbol.IsSymbol(function () {
          return "internal";
      }))()()(Type_Proxy["Proxy"].value));
  };
  exports["getPublicState"] = getPublicState;
  exports["preSubmit"] = preSubmit;
  exports["submit"] = submit;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Formless.Internal.Debounce"] = $PS["Formless.Internal.Debounce"] || {};
  var exports = $PS["Formless.Internal.Debounce"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Traversable = $PS["Data.Traversable"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Aff_AVar = $PS["Effect.Aff.AVar"];
  var Effect_Aff_Class = $PS["Effect.Aff.Class"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];                
  var debounceForm = function (dictMonadAff) {
      return function (ms) {
          return function (pre) {
              return function (post) {
                  return function (last) {
                      var readRef = function (dictMonadAff1) {
                          var $10 = Effect_Class.liftEffect(dictMonadAff1.MonadEffect0());
                          var $11 = Data_Functor.map(Effect.functorEffect)(Control_Bind.join(Data_Maybe.bindMaybe));
                          var $12 = Data_Traversable.traverse(Data_Traversable.traversableMaybe)(Effect.applicativeEffect)(Effect_Ref.read);
                          return function ($13) {
                              return $10($11($12($13)));
                          };
                      };
                      var mkFiber = function (v) {
                          return Effect_Aff_Class.liftAff(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Effect_Aff.forkAff(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Aff.delay(ms))(function () {
                              return Effect_Aff_AVar.put(Data_Unit.unit)(v);
                          })));
                      };
                      var killFiber$prime = function (dictMonadAff1) {
                          var $14 = Effect_Aff_Class.liftAff(dictMonadAff1);
                          var $15 = Effect_Aff.killFiber(Effect_Exception.error("time's up!"));
                          return function ($16) {
                              return $14($15($16));
                          };
                      };
                      var atomic = function (dictMonadAff1) {
                          return function (process) {
                              return function (maybeLast) {
                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                                      var ref = (Data_Newtype.unwrap()(state.internal)).validationRef;
                                      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(readRef(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff1))(ref))(function (mbRef) {
                                          return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Data_Foldable.for_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(mbRef)(Halogen_Query_HalogenM.kill))(function () {
                                              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff1.MonadEffect0()))(Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(ref)(Effect_Ref.write(Data_Maybe.Nothing.value))))(function () {
                                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Halogen_Query_HalogenM.fork(Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(process)(function (form) {
                                                      return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v) {
                                                          var $5 = {};
                                                          for (var $6 in v) {
                                                              if ({}.hasOwnProperty.call(v, $6)) {
                                                                  $5[$6] = v[$6];
                                                              };
                                                          };
                                                          $5.form = form;
                                                          return $5;
                                                      }))(function () {
                                                          return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff1.MonadEffect0()))(Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(ref)(Effect_Ref.write(Data_Maybe.Nothing.value))))(function () {
                                                              return Data_Foldable.for_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(maybeLast)(Control_Category.identity(Control_Category.categoryFn));
                                                          });
                                                      });
                                                  })))(function (forkId) {
                                                      return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff1.MonadEffect0()))(Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(ref)(Effect_Ref.write(new Data_Maybe.Just(forkId))));
                                                  });
                                              });
                                          });
                                      });
                                  });
                              };
                          };
                      };
                      var processAfterDelay = function ($$var) {
                          return function (dbRef) {
                              return Halogen_Query_HalogenM.fork(Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Effect_Aff_Class.liftAff(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Effect_Aff_AVar.take($$var))))(function () {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Effect_Ref.write(Data_Maybe.Nothing.value))(dbRef)))(function () {
                                      return atomic(dictMonadAff)(post)(new Data_Maybe.Just(last));
                                  });
                              }));
                          };
                      };
                      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (state) {
                          var vdRef = (Data_Newtype.unwrap()(state.internal)).validationRef;
                          var dbRef = (Data_Newtype.unwrap()(state.internal)).debounceRef;
                          return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(readRef(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(vdRef))(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(Halogen_Query_HalogenM.kill)))(function () {
                              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Data_Functor.map(Effect.functorEffect)(Control_Bind.join(Data_Maybe.bindMaybe))(Data_Traversable.traverse(Data_Traversable.traversableMaybe)(Effect.applicativeEffect)(Effect_Ref.read)(dbRef))))(function (debouncer) {
                                  if (debouncer instanceof Data_Maybe.Nothing) {
                                      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Aff_Class.liftAff(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(Effect_Aff_AVar.empty))(function ($$var) {
                                          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(mkFiber($$var))(function (fiber) {
                                              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(processAfterDelay($$var)(dbRef))(function (forkId) {
                                                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(dbRef)(Effect_Ref.write(new Data_Maybe.Just({
                                                      "var": $$var,
                                                      fiber: fiber,
                                                      forkId: forkId
                                                  })))))(function () {
                                                      return atomic(dictMonadAff)(pre)(Data_Maybe.Nothing.value);
                                                  });
                                              });
                                          });
                                      });
                                  };
                                  if (debouncer instanceof Data_Maybe.Just) {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(killFiber$prime(Halogen_Query_HalogenM.monadAffHalogenM(dictMonadAff))(debouncer.value0.fiber)))(function () {
                                          return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Data_Functor["void"](Halogen_Query_HalogenM.functorHalogenM)(Halogen_Query_HalogenM.kill(debouncer.value0.forkId)))(function () {
                                              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(mkFiber(debouncer["value0"]["var"]))(function (fiber) {
                                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(processAfterDelay(debouncer["value0"]["var"])(dbRef))(function (forkId) {
                                                      return Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(dbRef)(Effect_Ref.write(new Data_Maybe.Just({
                                                          "var": debouncer["value0"]["var"],
                                                          fiber: fiber,
                                                          forkId: forkId
                                                      }))));
                                                  });
                                              });
                                          });
                                      });
                                  };
                                  throw new Error("Failed pattern match at Formless.Internal.Debounce (line 41, column 3 - line 58, column 74): " + [ debouncer.constructor.name ]);
                              });
                          });
                      });
                  };
              };
          };
      };
  };
  exports["debounceForm"] = debounceForm;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Heterogeneous.Mapping"] = $PS["Heterogeneous.Mapping"] || {};
  var exports = $PS["Heterogeneous.Mapping"];
  var Control_Category = $PS["Control.Category"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Record_Builder = $PS["Record.Builder"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var MappingWithIndex = function (mappingWithIndex) {
      this.mappingWithIndex = mappingWithIndex;
  };
  var Mapping = function (mapping) {
      this.mapping = mapping;
  };
  var MapRecordWithIndex = function (mapRecordWithIndexBuilder) {
      this.mapRecordWithIndexBuilder = mapRecordWithIndexBuilder;
  };
  var HMap = function (hmap) {
      this.hmap = hmap;
  };
  var ConstMapping = function (x) {
      return x;
  };
  var mappingWithIndex = function (dict) {
      return dict.mappingWithIndex;
  }; 
  var mapping = function (dict) {
      return dict.mapping;
  };
  var mapRecordWithIndexNil = new MapRecordWithIndex(function (v) {
      return function (v1) {
          return Control_Category.identity(Record_Builder.categoryBuilder);
      };
  });
  var mapRecordWithIndexBuilder = function (dict) {
      return dict.mapRecordWithIndexBuilder;
  };
  var mapRecordWithIndexCons = function (dictIsSymbol) {
      return function (dictMappingWithIndex) {
          return function (dictMapRecordWithIndex) {
              return function (dictCons) {
                  return function (dictCons1) {
                      return new MapRecordWithIndex(function (v) {
                          return function (f) {
                              return Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder)(Record_Builder.modify()()(dictIsSymbol)(Type_Proxy["Proxy"].value)(mappingWithIndex(dictMappingWithIndex)(f)(Type_Proxy["Proxy"].value)))(mapRecordWithIndexBuilder(dictMapRecordWithIndex)(Type_Proxy["Proxy"].value)(f));
                          };
                      });
                  };
              };
          };
      };
  };
  var hmapRecord = function (dictRowToList) {
      return function (dictMapRecordWithIndex) {
          return new HMap((function () {
              var $77 = mapRecordWithIndexBuilder(dictMapRecordWithIndex)(Type_Proxy["Proxy"].value);
              return function ($78) {
                  return Record_Builder.build($77(ConstMapping($78)));
              };
          })());
      };
  };
  var hmap = function (dict) {
      return dict.hmap;
  };
  var constMapping = function (dictMapping) {
      return new MappingWithIndex(function (v) {
          return function (v1) {
              return mapping(dictMapping)(v);
          };
      });
  };
  exports["hmap"] = hmap;
  exports["Mapping"] = Mapping;
  exports["constMapping"] = constMapping;
  exports["hmapRecord"] = hmapRecord;
  exports["mapRecordWithIndexCons"] = mapRecordWithIndexCons;
  exports["mapRecordWithIndexNil"] = mapRecordWithIndexNil;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Formless.Transform.Record"] = $PS["Formless.Transform.Record"] || {};
  var exports = $PS["Formless.Transform.Record"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Heterogeneous_Mapping = $PS["Heterogeneous.Mapping"];                
  var WrapField = (function () {
      function WrapField() {

      };
      WrapField.value = new WrapField();
      return WrapField;
  })();
  var UnwrapField = (function () {
      function UnwrapField() {

      };
      UnwrapField.value = new UnwrapField();
      return UnwrapField;
  })();
  var wrapRecord = function (dictHMap) {
      return Heterogeneous_Mapping.hmap(dictHMap)(WrapField.value);
  };
  var wrapInputFields = function (dictNewtype) {
      return function (dictHMap) {
          var $17 = Data_Newtype.wrap();
          var $18 = wrapRecord(dictHMap);
          return function ($19) {
              return $17($18($19));
          };
      };
  };
  var wrapField = function (dictNewtype) {
      return new Heterogeneous_Mapping.Mapping(function (v) {
          return Data_Newtype.wrap();
      });
  };
  var unwrapRecord = function (dictHMap) {
      return Heterogeneous_Mapping.hmap(dictHMap)(UnwrapField.value);
  };
  var unwrapOutputFields = function (dictNewtype) {
      return function (dictHMap) {
          var $20 = unwrapRecord(dictHMap);
          var $21 = Data_Newtype.unwrap();
          return function ($22) {
              return $20($21($22));
          };
      };
  };
  var unwrapField = function (dictNewtype) {
      return new Heterogeneous_Mapping.Mapping(function (v) {
          return Data_Newtype.unwrap();
      });
  };
  exports["unwrapOutputFields"] = unwrapOutputFields;
  exports["wrapInputFields"] = wrapInputFields;
  exports["unwrapField"] = unwrapField;
  exports["wrapField"] = wrapField;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Formless.Transform.Row"] = $PS["Formless.Transform.Row"] || {};
  var exports = $PS["Formless.Transform.Row"];
  var Control_Category = $PS["Control.Category"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Formless_Class_Initial = $PS["Formless.Class.Initial"];
  var Formless_Internal_Transform = $PS["Formless.Internal.Transform"];
  var Record_Builder = $PS["Record.Builder"];
  var Type_Proxy = $PS["Type.Proxy"];
  var MakeInputFieldsFromRow = function (mkInputFieldsFromRowBuilder) {
      this.mkInputFieldsFromRowBuilder = mkInputFieldsFromRowBuilder;
  };
  var mkInputFieldsFromRowNil = new MakeInputFieldsFromRow(function (v) {
      return function (v1) {
          return Control_Category.identity(Record_Builder.categoryBuilder);
      };
  });
  var mkInputFieldsFromRowBuilder = function (dict) {
      return dict.mkInputFieldsFromRowBuilder;
  };
  var mkInputFieldsFromRowCons = function (dictIsSymbol) {
      return function (dictInitial) {
          return function (dictCons) {
              return function (dictMakeInputFieldsFromRow) {
                  return function (dictRow1Cons) {
                      return new MakeInputFieldsFromRow(function (v) {
                          return function (r) {
                              var val = Formless_Class_Initial.initial(dictInitial);
                              var rest = mkInputFieldsFromRowBuilder(dictMakeInputFieldsFromRow)(Type_Proxy["Proxy"].value)(r);
                              var first = Record_Builder.insert()()(dictIsSymbol)(Type_Proxy["Proxy"].value)(val);
                              return Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder)(first)(rest);
                          };
                      });
                  };
              };
          };
      };
  };
  var mkInputFields = function (dictRowToList) {
      return function (dictNewtype) {
          return function (dictMakeInputFieldsFromRow) {
              var builder = mkInputFieldsFromRowBuilder(dictMakeInputFieldsFromRow)(Type_Proxy["Proxy"].value)(Type_Proxy["Proxy"].value);
              return Data_Newtype.wrap()(Formless_Internal_Transform.fromScratch(builder));
          };
      };
  };
  exports["mkInputFields"] = mkInputFields;
  exports["mkInputFieldsFromRowNil"] = mkInputFieldsFromRowNil;
  exports["mkInputFieldsFromRowCons"] = mkInputFieldsFromRowCons;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.Data.OrdBox"] = $PS["Halogen.Data.OrdBox"] || {};
  var exports = $PS["Halogen.Data.OrdBox"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Ord = $PS["Data.Ord"];                
  var OrdBox = (function () {
      function OrdBox(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      OrdBox.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new OrdBox(value0, value1, value2);
              };
          };
      };
      return OrdBox;
  })();
  var mkOrdBox = function (dictOrd) {
      return OrdBox.create(Data_Eq.eq(dictOrd.Eq0()))(Data_Ord.compare(dictOrd));
  };
  var eqOrdBox = new Data_Eq.Eq(function (v) {
      return function (v1) {
          return v.value0(v.value2)(v1.value2);
      };
  });
  var ordOrdBox = new Data_Ord.Ord(function () {
      return eqOrdBox;
  }, function (v) {
      return function (v1) {
          return v.value1(v.value2)(v1.value2);
      };
  });
  exports["mkOrdBox"] = mkOrdBox;
  exports["ordOrdBox"] = ordOrdBox;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.Data.Slot"] = $PS["Halogen.Data.Slot"] || {};
  var exports = $PS["Halogen.Data.Slot"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Halogen_Data_OrdBox = $PS["Halogen.Data.OrdBox"];
  var pop = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (sym) {
                  return function (key) {
                      return function (v) {
                          return Data_Map_Internal.pop(Data_Tuple.ordTuple(Data_Ord.ordString)(Halogen_Data_OrdBox.ordOrdBox))(new Data_Tuple.Tuple(Data_Symbol.reflectSymbol(dictIsSymbol)(sym), Halogen_Data_OrdBox.mkOrdBox(dictOrd)(key)))(v);
                      };
                  };
              };
          };
      };
  };
  var lookup = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (sym) {
                  return function (key) {
                      return function (v) {
                          return Data_Map_Internal.lookup(Data_Tuple.ordTuple(Data_Ord.ordString)(Halogen_Data_OrdBox.ordOrdBox))(new Data_Tuple.Tuple(Data_Symbol.reflectSymbol(dictIsSymbol)(sym), Halogen_Data_OrdBox.mkOrdBox(dictOrd)(key)))(v);
                      };
                  };
              };
          };
      };
  };
  var insert = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (sym) {
                  return function (key) {
                      return function (val) {
                          return function (v) {
                              return Data_Map_Internal.insert(Data_Tuple.ordTuple(Data_Ord.ordString)(Halogen_Data_OrdBox.ordOrdBox))(new Data_Tuple.Tuple(Data_Symbol.reflectSymbol(dictIsSymbol)(sym), Halogen_Data_OrdBox.mkOrdBox(dictOrd)(key)))(val)(v);
                          };
                      };
                  };
              };
          };
      };
  };
  var foreachSlot = function (dictApplicative) {
      return function (v) {
          return function (k) {
              return Data_Foldable.traverse_(dictApplicative)(Data_Map_Internal.foldableMap)(function ($37) {
                  return k($37);
              })(v);
          };
      };
  };
  var empty = Data_Map_Internal.empty;
  exports["empty"] = empty;
  exports["lookup"] = lookup;
  exports["insert"] = insert;
  exports["pop"] = pop;
  exports["foreachSlot"] = foreachSlot;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.Query.Input"] = $PS["Halogen.Query.Input"] || {};
  var exports = $PS["Halogen.Query.Input"];
  var Data_Functor = $PS["Data.Functor"];
  var RefUpdate = (function () {
      function RefUpdate(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      RefUpdate.create = function (value0) {
          return function (value1) {
              return new RefUpdate(value0, value1);
          };
      };
      return RefUpdate;
  })();
  var Action = (function () {
      function Action(value0) {
          this.value0 = value0;
      };
      Action.create = function (value0) {
          return new Action(value0);
      };
      return Action;
  })();
  var functorInput = new Data_Functor.Functor(function (f) {
      return function (m) {
          if (m instanceof RefUpdate) {
              return new RefUpdate(m.value0, m.value1);
          };
          if (m instanceof Action) {
              return new Action(f(m.value0));
          };
          throw new Error("Failed pattern match at Halogen.Query.Input (line 19, column 1 - line 19, column 46): " + [ m.constructor.name ]);
      };
  });
  exports["RefUpdate"] = RefUpdate;
  exports["Action"] = Action;
  exports["functorInput"] = functorInput;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.VDom.Machine"] = $PS["Halogen.VDom.Machine"] || {};
  var exports = $PS["Halogen.VDom.Machine"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var Step = (function () {
      function Step(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Step.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Step(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Step;
  })();
  var unStep = Unsafe_Coerce.unsafeCoerce;
  var step = function (v, a) {
      return v.value2(v.value1, a);
  };
  var mkStep = Unsafe_Coerce.unsafeCoerce;
  var halt = function (v) {
      return v.value3(v.value1);
  };
  var extract = unStep(function (v) {
      return v.value0;
  });
  exports["Step"] = Step;
  exports["mkStep"] = mkStep;
  exports["unStep"] = unStep;
  exports["extract"] = extract;
  exports["step"] = step;
  exports["halt"] = halt;
})(PS);
(function(exports) {
  "use strict";

  exports.unsafeGetAny = function (key, obj) {
    return obj[key];
  };

  exports.unsafeHasAny = function (key, obj) {
    return obj.hasOwnProperty(key);
  };

  exports.unsafeSetAny = function (key, val, obj) {
      obj[key] = val;
  };

  exports.forE = function (a, f) {
    var b = [];
    for (var i = 0; i < a.length; i++) {
      b.push(f(i, a[i]));
    }
    return b;
  };

  exports.forEachE = function (a, f) {
    for (var i = 0; i < a.length; i++) {
      f(a[i]);
    }
  };

  exports.forInE = function (o, f) {
    var ks = Object.keys(o);
    for (var i = 0; i < ks.length; i++) {
      var k = ks[i];
      f(k, o[k]);
    }
  };

  exports.diffWithIxE = function (a1, a2, f1, f2, f3) {
    var a3 = [];
    var l1 = a1.length;
    var l2 = a2.length;
    var i  = 0;
    while (1) {
      if (i < l1) {
        if (i < l2) {
          a3.push(f1(i, a1[i], a2[i]));
        } else {
          f2(i, a1[i]);
        }
      } else if (i < l2) {
        a3.push(f3(i, a2[i]));
      } else {
        break;
      }
      i++;
    }
    return a3;
  };

  exports.strMapWithIxE = function (as, fk, f) {
    var o = {};
    for (var i = 0; i < as.length; i++) {
      var a = as[i];
      var k = fk(a);
      o[k] = f(k, i, a);
    }
    return o;
  };

  exports.diffWithKeyAndIxE = function (o1, as, fk, f1, f2, f3) {
    var o2 = {};
    for (var i = 0; i < as.length; i++) {
      var a = as[i];
      var k = fk(a);
      if (o1.hasOwnProperty(k)) {
        o2[k] = f1(k, i, o1[k], a);
      } else {
        o2[k] = f3(k, i, a);
      }
    }
    for (var k in o1) {
      if (k in o2) {
        continue;
      }
      f2(k, o1[k]);
    }
    return o2;
  };

  exports.refEq = function (a, b) {
    return a === b;
  };

  exports.createTextNode = function (s, doc) {
    return doc.createTextNode(s);
  };

  exports.setTextContent = function (s, n) {
    n.textContent = s;
  };

  exports.createElement = function (ns, name, doc) {
    if (ns != null) {
      return doc.createElementNS(ns, name);
    } else {
      return doc.createElement(name)
    }
  };

  exports.insertChildIx = function (i, a, b) {
    var n = b.childNodes.item(i) || null;
    if (n !== a) {
      b.insertBefore(a, n);
    }
  };

  exports.removeChild = function (a, b) {
    if (b && a.parentNode === b) {
      b.removeChild(a);
    }
  };

  exports.parentNode = function (a) {
    return a.parentNode;
  };

  exports.setAttribute = function (ns, attr, val, el) {
    if (ns != null) {
      el.setAttributeNS(ns, attr, val);
    } else {
      el.setAttribute(attr, val);
    }
  };

  exports.removeAttribute = function (ns, attr, el) {
    if (ns != null) {
      el.removeAttributeNS(ns, attr);
    } else {
      el.removeAttribute(attr);
    }
  };

  exports.hasAttribute = function (ns, attr, el) {
    if (ns != null) {
      return el.hasAttributeNS(ns, attr);
    } else {
      return el.hasAttribute(attr);
    }
  };

  exports.addEventListener = function (ev, listener, el) {
    el.addEventListener(ev, listener, false);
  };

  exports.removeEventListener = function (ev, listener, el) {
    el.removeEventListener(ev, listener, false);
  };

  exports.jsUndefined = void 0;
})(PS["Halogen.VDom.Util"] = PS["Halogen.VDom.Util"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.VDom.Util"] = $PS["Halogen.VDom.Util"] || {};
  var exports = $PS["Halogen.VDom.Util"];
  var $foreign = $PS["Halogen.VDom.Util"];
  var Foreign_Object_ST = $PS["Foreign.Object.ST"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var unsafeLookup = $foreign.unsafeGetAny;
  var unsafeFreeze = Unsafe_Coerce.unsafeCoerce;
  var pokeMutMap = $foreign.unsafeSetAny;
  var newMutMap = Foreign_Object_ST["new"];
  exports["newMutMap"] = newMutMap;
  exports["pokeMutMap"] = pokeMutMap;
  exports["unsafeFreeze"] = unsafeFreeze;
  exports["unsafeLookup"] = unsafeLookup;
  exports["unsafeGetAny"] = $foreign.unsafeGetAny;
  exports["unsafeHasAny"] = $foreign.unsafeHasAny;
  exports["unsafeSetAny"] = $foreign.unsafeSetAny;
  exports["forE"] = $foreign.forE;
  exports["forEachE"] = $foreign.forEachE;
  exports["forInE"] = $foreign.forInE;
  exports["diffWithIxE"] = $foreign.diffWithIxE;
  exports["diffWithKeyAndIxE"] = $foreign.diffWithKeyAndIxE;
  exports["strMapWithIxE"] = $foreign.strMapWithIxE;
  exports["refEq"] = $foreign.refEq;
  exports["createTextNode"] = $foreign.createTextNode;
  exports["setTextContent"] = $foreign.setTextContent;
  exports["createElement"] = $foreign.createElement;
  exports["insertChildIx"] = $foreign.insertChildIx;
  exports["removeChild"] = $foreign.removeChild;
  exports["parentNode"] = $foreign.parentNode;
  exports["setAttribute"] = $foreign.setAttribute;
  exports["removeAttribute"] = $foreign.removeAttribute;
  exports["hasAttribute"] = $foreign.hasAttribute;
  exports["addEventListener"] = $foreign.addEventListener;
  exports["removeEventListener"] = $foreign.removeEventListener;
  exports["jsUndefined"] = $foreign.jsUndefined;
})(PS);
(function(exports) {
  "use strict";

  exports.eventListener = function (fn) {
    return function () {
      return function (event) {
        return fn(event)();
      };
    };
  };

  exports.addEventListener = function (type) {
    return function (listener) {
      return function (useCapture) {
        return function (target) {
          return function () {
            return target.addEventListener(type, listener, useCapture);
          };
        };
      };
    };
  };

  exports.removeEventListener = function (type) {
    return function (listener) {
      return function (useCapture) {
        return function (target) {
          return function () {
            return target.removeEventListener(type, listener, useCapture);
          };
        };
      };
    };
  };
})(PS["Web.Event.EventTarget"] = PS["Web.Event.EventTarget"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Web.Event.EventTarget"] = $PS["Web.Event.EventTarget"] || {};
  var exports = $PS["Web.Event.EventTarget"];
  var $foreign = $PS["Web.Event.EventTarget"];
  exports["eventListener"] = $foreign.eventListener;
  exports["addEventListener"] = $foreign.addEventListener;
  exports["removeEventListener"] = $foreign.removeEventListener;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.VDom.DOM.Prop"] = $PS["Halogen.VDom.DOM.Prop"] || {};
  var exports = $PS["Halogen.VDom.DOM.Prop"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Foreign = $PS["Foreign"];
  var Foreign_Object = $PS["Foreign.Object"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Util = $PS["Halogen.VDom.Util"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Web_Event_EventTarget = $PS["Web.Event.EventTarget"];                
  var Created = (function () {
      function Created(value0) {
          this.value0 = value0;
      };
      Created.create = function (value0) {
          return new Created(value0);
      };
      return Created;
  })();
  var Removed = (function () {
      function Removed(value0) {
          this.value0 = value0;
      };
      Removed.create = function (value0) {
          return new Removed(value0);
      };
      return Removed;
  })();
  var Attribute = (function () {
      function Attribute(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      Attribute.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new Attribute(value0, value1, value2);
              };
          };
      };
      return Attribute;
  })();
  var Property = (function () {
      function Property(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Property.create = function (value0) {
          return function (value1) {
              return new Property(value0, value1);
          };
      };
      return Property;
  })();
  var Handler = (function () {
      function Handler(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Handler.create = function (value0) {
          return function (value1) {
              return new Handler(value0, value1);
          };
      };
      return Handler;
  })();
  var Ref = (function () {
      function Ref(value0) {
          this.value0 = value0;
      };
      Ref.create = function (value0) {
          return new Ref(value0);
      };
      return Ref;
  })();
  var unsafeGetProperty = Halogen_VDom_Util.unsafeGetAny;
  var setProperty = Halogen_VDom_Util.unsafeSetAny;
  var removeProperty = function (key, el) {
      var v = Halogen_VDom_Util.hasAttribute(Data_Nullable["null"], key, el);
      if (v) {
          return Halogen_VDom_Util.removeAttribute(Data_Nullable["null"], key, el);
      };
      var v1 = Foreign.typeOf(Halogen_VDom_Util.unsafeGetAny(key, el));
      if (v1 === "string") {
          return Halogen_VDom_Util.unsafeSetAny(key, "", el);
      };
      if (key === "rowSpan") {
          return Halogen_VDom_Util.unsafeSetAny(key, 1, el);
      };
      if (key === "colSpan") {
          return Halogen_VDom_Util.unsafeSetAny(key, 1, el);
      };
      return Halogen_VDom_Util.unsafeSetAny(key, Halogen_VDom_Util.jsUndefined, el);
  };
  var propToStrKey = function (v) {
      if (v instanceof Attribute && v.value0 instanceof Data_Maybe.Just) {
          return "attr/" + (v.value0.value0 + (":" + v.value1));
      };
      if (v instanceof Attribute) {
          return "attr/:" + v.value1;
      };
      if (v instanceof Property) {
          return "prop/" + v.value0;
      };
      if (v instanceof Handler) {
          return "handler/" + v.value0;
      };
      if (v instanceof Ref) {
          return "ref";
      };
      throw new Error("Failed pattern match at Halogen.VDom.DOM.Prop (line 182, column 16 - line 187, column 16): " + [ v.constructor.name ]);
  };
  var propFromString = Unsafe_Coerce.unsafeCoerce;
  var propFromNumber = Unsafe_Coerce.unsafeCoerce;
  var propFromBoolean = Unsafe_Coerce.unsafeCoerce;
  var functorProp = new Data_Functor.Functor(function (f) {
      return function (v) {
          if (v instanceof Handler) {
              return new Handler(v.value0, Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Maybe.functorMaybe)(f))(v.value1));
          };
          if (v instanceof Ref) {
              return new Ref(Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Maybe.functorMaybe)(f))(v.value0));
          };
          return v;
      };
  });
  var buildProp = function (emit) {
      return function (el) {
          var removeProp = function (prevEvents) {
              return function (v, v1) {
                  if (v1 instanceof Attribute) {
                      return Halogen_VDom_Util.removeAttribute(Data_Nullable.toNullable(v1.value0), v1.value1, el);
                  };
                  if (v1 instanceof Property) {
                      return removeProperty(v1.value0, el);
                  };
                  if (v1 instanceof Handler) {
                      var handler = Halogen_VDom_Util.unsafeLookup(v1.value0, prevEvents);
                      return Halogen_VDom_Util.removeEventListener(v1.value0, Data_Tuple.fst(handler), el);
                  };
                  if (v1 instanceof Ref) {
                      return Data_Unit.unit;
                  };
                  throw new Error("Failed pattern match at Halogen.VDom.DOM.Prop (line 169, column 5 - line 179, column 18): " + [ v1.constructor.name ]);
              };
          };
          var mbEmit = function (v) {
              if (v instanceof Data_Maybe.Just) {
                  return emit(v.value0)();
              };
              return Data_Unit.unit;
          };
          var haltProp = function (state) {
              var v = Foreign_Object.lookup("ref")(state.props);
              if (v instanceof Data_Maybe.Just && v.value0 instanceof Ref) {
                  return mbEmit(v.value0.value0(new Removed(el)));
              };
              return Data_Unit.unit;
          };
          var diffProp = function (prevEvents, events) {
              return function (v, v1, v11, v2) {
                  if (v11 instanceof Attribute && v2 instanceof Attribute) {
                      var $56 = v11.value2 === v2.value2;
                      if ($56) {
                          return v2;
                      };
                      Halogen_VDom_Util.setAttribute(Data_Nullable.toNullable(v2.value0), v2.value1, v2.value2, el);
                      return v2;
                  };
                  if (v11 instanceof Property && v2 instanceof Property) {
                      var v4 = Halogen_VDom_Util.refEq(v11.value1, v2.value1);
                      if (v4) {
                          return v2;
                      };
                      if (v2.value0 === "value") {
                          var elVal = unsafeGetProperty("value", el);
                          var $65 = Halogen_VDom_Util.refEq(elVal, v2.value1);
                          if ($65) {
                              return v2;
                          };
                          setProperty(v2.value0, v2.value1, el);
                          return v2;
                      };
                      setProperty(v2.value0, v2.value1, el);
                      return v2;
                  };
                  if (v11 instanceof Handler && v2 instanceof Handler) {
                      var handler = Halogen_VDom_Util.unsafeLookup(v2.value0, prevEvents);
                      Effect_Ref.write(v2.value1)(Data_Tuple.snd(handler))();
                      Halogen_VDom_Util.pokeMutMap(v2.value0, handler, events);
                      return v2;
                  };
                  return v2;
              };
          };
          var applyProp = function (events) {
              return function (v, v1, v2) {
                  if (v2 instanceof Attribute) {
                      Halogen_VDom_Util.setAttribute(Data_Nullable.toNullable(v2.value0), v2.value1, v2.value2, el);
                      return v2;
                  };
                  if (v2 instanceof Property) {
                      setProperty(v2.value0, v2.value1, el);
                      return v2;
                  };
                  if (v2 instanceof Handler) {
                      var v3 = Halogen_VDom_Util.unsafeGetAny(v2.value0, events);
                      if (Halogen_VDom_Util.unsafeHasAny(v2.value0, events)) {
                          Effect_Ref.write(v2.value1)(Data_Tuple.snd(v3))();
                          return v2;
                      };
                      var ref = Effect_Ref["new"](v2.value1)();
                      var listener = Web_Event_EventTarget.eventListener(function (ev) {
                          return function __do() {
                              var f$prime = Effect_Ref.read(ref)();
                              return mbEmit(f$prime(ev));
                          };
                      })();
                      Halogen_VDom_Util.pokeMutMap(v2.value0, new Data_Tuple.Tuple(listener, ref), events);
                      Halogen_VDom_Util.addEventListener(v2.value0, listener, el);
                      return v2;
                  };
                  if (v2 instanceof Ref) {
                      mbEmit(v2.value0(new Created(el)));
                      return v2;
                  };
                  throw new Error("Failed pattern match at Halogen.VDom.DOM.Prop (line 113, column 5 - line 135, column 15): " + [ v2.constructor.name ]);
              };
          };
          var patchProp = function (state, ps2) {
              var events = Halogen_VDom_Util.newMutMap();
              var onThis = removeProp(state.events);
              var onThese = diffProp(state.events, events);
              var onThat = applyProp(events);
              var props = Halogen_VDom_Util.diffWithKeyAndIxE(state.props, ps2, propToStrKey, onThese, onThis, onThat);
              var nextState = {
                  events: Halogen_VDom_Util.unsafeFreeze(events),
                  props: props
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Data_Unit.unit, nextState, patchProp, haltProp));
          };
          var renderProp = function (ps1) {
              var events = Halogen_VDom_Util.newMutMap();
              var ps1$prime = Halogen_VDom_Util.strMapWithIxE(ps1, propToStrKey, applyProp(events));
              var state = {
                  events: Halogen_VDom_Util.unsafeFreeze(events),
                  props: ps1$prime
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Data_Unit.unit, state, patchProp, haltProp));
          };
          return renderProp;
      };
  };
  exports["Property"] = Property;
  exports["Handler"] = Handler;
  exports["propFromString"] = propFromString;
  exports["propFromBoolean"] = propFromBoolean;
  exports["propFromNumber"] = propFromNumber;
  exports["buildProp"] = buildProp;
  exports["functorProp"] = functorProp;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.VDom.Types"] = $PS["Halogen.VDom.Types"] || {};
  var exports = $PS["Halogen.VDom.Types"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Text = (function () {
      function Text(value0) {
          this.value0 = value0;
      };
      Text.create = function (value0) {
          return new Text(value0);
      };
      return Text;
  })();
  var Elem = (function () {
      function Elem(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Elem.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Elem(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Elem;
  })();
  var Keyed = (function () {
      function Keyed(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Keyed.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Keyed(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Keyed;
  })();
  var Widget = (function () {
      function Widget(value0) {
          this.value0 = value0;
      };
      Widget.create = function (value0) {
          return new Widget(value0);
      };
      return Widget;
  })();
  var Grafted = (function () {
      function Grafted(value0) {
          this.value0 = value0;
      };
      Grafted.create = function (value0) {
          return new Grafted(value0);
      };
      return Grafted;
  })();
  var Graft = (function () {
      function Graft(value0, value1, value2) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
      };
      Graft.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return new Graft(value0, value1, value2);
              };
          };
      };
      return Graft;
  })();
  var unGraft = function (f) {
      return function ($52) {
          return f($52);
      };
  }; 
  var graft = Unsafe_Coerce.unsafeCoerce;
  var bifunctorGraft = new Data_Bifunctor.Bifunctor(function (f) {
      return function (g) {
          return unGraft(function (v) {
              return graft(new Graft(function ($54) {
                  return f(v.value0($54));
              }, function ($55) {
                  return g(v.value1($55));
              }, v.value2));
          });
      };
  });
  var bifunctorVDom = new Data_Bifunctor.Bifunctor(function (f) {
      return function (g) {
          return function (v) {
              if (v instanceof Text) {
                  return new Text(v.value0);
              };
              if (v instanceof Grafted) {
                  return new Grafted(Data_Bifunctor.bimap(bifunctorGraft)(f)(g)(v.value0));
              };
              return new Grafted(graft(new Graft(f, g, v)));
          };
      };
  });
  var runGraft = unGraft(function (v) {
      var go = function (v2) {
          if (v2 instanceof Text) {
              return new Text(v2.value0);
          };
          if (v2 instanceof Elem) {
              return new Elem(v2.value0, v2.value1, v.value0(v2.value2), Data_Functor.map(Data_Functor.functorArray)(go)(v2.value3));
          };
          if (v2 instanceof Keyed) {
              return new Keyed(v2.value0, v2.value1, v.value0(v2.value2), Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Tuple.functorTuple)(go))(v2.value3));
          };
          if (v2 instanceof Widget) {
              return new Widget(v.value1(v2.value0));
          };
          if (v2 instanceof Grafted) {
              return new Grafted(Data_Bifunctor.bimap(bifunctorGraft)(v.value0)(v.value1)(v2.value0));
          };
          throw new Error("Failed pattern match at Halogen.VDom.Types (line 86, column 7 - line 86, column 27): " + [ v2.constructor.name ]);
      };
      return go(v.value2);
  });
  exports["Text"] = Text;
  exports["Elem"] = Elem;
  exports["Keyed"] = Keyed;
  exports["Widget"] = Widget;
  exports["Grafted"] = Grafted;
  exports["runGraft"] = runGraft;
  exports["bifunctorVDom"] = bifunctorVDom;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.HTML.Core"] = $PS["Halogen.HTML.Core"] || {};
  var exports = $PS["Halogen.HTML.Core"];
  var DOM_HTML_Indexed_InputType = $PS["DOM.HTML.Indexed.InputType"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Functor = $PS["Data.Functor"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var Halogen_VDom_DOM_Prop = $PS["Halogen.VDom.DOM.Prop"];
  var Halogen_VDom_Types = $PS["Halogen.VDom.Types"];          
  var IsProp = function (toPropValue) {
      this.toPropValue = toPropValue;
  };
  var HTML = function (x) {
      return x;
  };
  var widget = function ($18) {
      return HTML(Halogen_VDom_Types.Widget.create($18));
  };
  var toPropValue = function (dict) {
      return dict.toPropValue;
  };
  var text = function ($19) {
      return HTML(Halogen_VDom_Types.Text.create($19));
  };
  var prop = function (dictIsProp) {
      return function (v) {
          var $21 = Halogen_VDom_DOM_Prop.Property.create(v);
          var $22 = toPropValue(dictIsProp);
          return function ($23) {
              return $21($22($23));
          };
      };
  }; 
  var isPropString = new IsProp(Halogen_VDom_DOM_Prop.propFromString);
  var isPropNumber = new IsProp(Halogen_VDom_DOM_Prop.propFromNumber);
  var isPropInputType = new IsProp(function ($37) {
      return Halogen_VDom_DOM_Prop.propFromString(DOM_HTML_Indexed_InputType.renderInputType($37));
  });
  var isPropBoolean = new IsProp(Halogen_VDom_DOM_Prop.propFromBoolean);
  var handler = Halogen_VDom_DOM_Prop.Handler.create;
  var element = function (ns) {
      return function (name) {
          return function (props) {
              return function (children) {
                  return new Halogen_VDom_Types.Elem(ns, name, props, children);
              };
          };
      };
  };
  var bifunctorHTML = new Data_Bifunctor.Bifunctor(function (f) {
      return function (g) {
          return function (v) {
              return Data_Bifunctor.bimap(Halogen_VDom_Types.bifunctorVDom)(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Halogen_VDom_DOM_Prop.functorProp)(Data_Functor.map(Halogen_Query_Input.functorInput)(g))))(f)(v);
          };
      };
  });
  exports["widget"] = widget;
  exports["text"] = text;
  exports["element"] = element;
  exports["prop"] = prop;
  exports["handler"] = handler;
  exports["bifunctorHTML"] = bifunctorHTML;
  exports["isPropString"] = isPropString;
  exports["isPropNumber"] = isPropNumber;
  exports["isPropBoolean"] = isPropBoolean;
  exports["isPropInputType"] = isPropInputType;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.Query.HalogenQ"] = $PS["Halogen.Query.HalogenQ"] || {};
  var exports = $PS["Halogen.Query.HalogenQ"];           
  var Initialize = (function () {
      function Initialize(value0) {
          this.value0 = value0;
      };
      Initialize.create = function (value0) {
          return new Initialize(value0);
      };
      return Initialize;
  })();
  var Finalize = (function () {
      function Finalize(value0) {
          this.value0 = value0;
      };
      Finalize.create = function (value0) {
          return new Finalize(value0);
      };
      return Finalize;
  })();
  var Receive = (function () {
      function Receive(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Receive.create = function (value0) {
          return function (value1) {
              return new Receive(value0, value1);
          };
      };
      return Receive;
  })();
  var Action = (function () {
      function Action(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Action.create = function (value0) {
          return function (value1) {
              return new Action(value0, value1);
          };
      };
      return Action;
  })();
  var Query = (function () {
      function Query(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Query.create = function (value0) {
          return function (value1) {
              return new Query(value0, value1);
          };
      };
      return Query;
  })();
  exports["Initialize"] = Initialize;
  exports["Finalize"] = Finalize;
  exports["Receive"] = Receive;
  exports["Action"] = Action;
  exports["Query"] = Query;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Web.DOM.Element"] = $PS["Web.DOM.Element"] || {};
  var exports = $PS["Web.DOM.Element"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                   
  var toNode = Unsafe_Coerce.unsafeCoerce;
  exports["toNode"] = toNode;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.VDom.DOM"] = $PS["Halogen.VDom.DOM"] || {};
  var exports = $PS["Halogen.VDom.DOM"];
  var Data_Array = $PS["Data.Array"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Types = $PS["Halogen.VDom.Types"];
  var Halogen_VDom_Util = $PS["Halogen.VDom.Util"];
  var Web_DOM_Element = $PS["Web.DOM.Element"];
  var haltWidget = function (v) {
      return Halogen_VDom_Machine.halt(v.widget);
  };
  var patchWidget = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchWidget(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Widget) {
          var res = Halogen_VDom_Machine.step(state.widget, vdom.value0);
          var res$prime = Halogen_VDom_Machine.unStep(function (v) {
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(v.value0, {
                  build: state.build,
                  widget: res
              }, patchWidget, haltWidget));
          })(res);
          return res$prime;
      };
      haltWidget(state);
      return state.build(vdom);
  };
  var haltText = function (v) {
      var parent = Halogen_VDom_Util.parentNode(v.node);
      return Halogen_VDom_Util.removeChild(v.node, parent);
  };
  var patchText = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchText(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Text) {
          if (state.value === vdom.value0) {
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, state, patchText, haltText));
          };
          if (Data_Boolean.otherwise) {
              var nextState = {
                  build: state.build,
                  node: state.node,
                  value: vdom.value0
              };
              Halogen_VDom_Util.setTextContent(vdom.value0, state.node);
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchText, haltText));
          };
      };
      haltText(state);
      return state.build(vdom);
  };
  var haltKeyed = function (v) {
      var parent = Halogen_VDom_Util.parentNode(v.node);
      Halogen_VDom_Util.removeChild(v.node, parent);
      Halogen_VDom_Util.forInE(v.children, function (v1, s) {
          return Halogen_VDom_Machine.halt(s);
      });
      return Halogen_VDom_Machine.halt(v.attrs);
  };
  var haltElem = function (v) {
      var parent = Halogen_VDom_Util.parentNode(v.node);
      Halogen_VDom_Util.removeChild(v.node, parent);
      Halogen_VDom_Util.forEachE(v.children, Halogen_VDom_Machine.halt);
      return Halogen_VDom_Machine.halt(v.attrs);
  };
  var eqElemSpec = function (ns1, v, ns2, v1) {
      var $56 = v === v1;
      if ($56) {
          if (ns1 instanceof Data_Maybe.Just && (ns2 instanceof Data_Maybe.Just && ns1.value0 === ns2.value0)) {
              return true;
          };
          if (ns1 instanceof Data_Maybe.Nothing && ns2 instanceof Data_Maybe.Nothing) {
              return true;
          };
          return false;
      };
      return false;
  };
  var patchElem = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchElem(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Elem && eqElemSpec(state.ns, state.name, vdom.value0, vdom.value1)) {
          var v = Data_Array.length(vdom.value3);
          var v1 = Data_Array.length(state.children);
          if (v1 === 0 && v === 0) {
              var attrs2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
              var nextState = {
                  build: state.build,
                  node: state.node,
                  attrs: attrs2,
                  ns: vdom.value0,
                  name: vdom.value1,
                  children: state.children
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchElem, haltElem));
          };
          var onThis = function (ix, s) {
              return Halogen_VDom_Machine.halt(s);
          };
          var onThese = function (ix, s, v2) {
              var res = Halogen_VDom_Machine.step(s, v2);
              Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), state.node);
              return res;
          };
          var onThat = function (ix, v2) {
              var res = state.build(v2);
              Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), state.node);
              return res;
          };
          var children2 = Halogen_VDom_Util.diffWithIxE(state.children, vdom.value3, onThese, onThis, onThat);
          var attrs2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
          var nextState = {
              build: state.build,
              node: state.node,
              attrs: attrs2,
              ns: vdom.value0,
              name: vdom.value1,
              children: children2
          };
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchElem, haltElem));
      };
      haltElem(state);
      return state.build(vdom);
  };
  var patchKeyed = function (state, vdom) {
      if (vdom instanceof Halogen_VDom_Types.Grafted) {
          return patchKeyed(state, Halogen_VDom_Types.runGraft(vdom.value0));
      };
      if (vdom instanceof Halogen_VDom_Types.Keyed && eqElemSpec(state.ns, state.name, vdom.value0, vdom.value1)) {
          var v = Data_Array.length(vdom.value3);
          if (state.length === 0 && v === 0) {
              var attrs2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
              var nextState = {
                  build: state.build,
                  node: state.node,
                  attrs: attrs2,
                  ns: vdom.value0,
                  name: vdom.value1,
                  children: state.children,
                  length: 0
              };
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchKeyed, haltKeyed));
          };
          var onThis = function (v2, s) {
              return Halogen_VDom_Machine.halt(s);
          };
          var onThese = function (v2, ix$prime, s, v3) {
              var res = Halogen_VDom_Machine.step(s, v3.value1);
              Halogen_VDom_Util.insertChildIx(ix$prime, Halogen_VDom_Machine.extract(res), state.node);
              return res;
          };
          var onThat = function (v2, ix, v3) {
              var res = state.build(v3.value1);
              Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), state.node);
              return res;
          };
          var children2 = Halogen_VDom_Util.diffWithKeyAndIxE(state.children, vdom.value3, Data_Tuple.fst, onThese, onThis, onThat);
          var attrs2 = Halogen_VDom_Machine.step(state.attrs, vdom.value2);
          var nextState = {
              build: state.build,
              node: state.node,
              attrs: attrs2,
              ns: vdom.value0,
              name: vdom.value1,
              children: children2,
              length: v
          };
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(state.node, nextState, patchKeyed, haltKeyed));
      };
      haltKeyed(state);
      return state.build(vdom);
  };
  var buildWidget = function (v, build, w) {
      var res = v.buildWidget(v)(w);
      var res$prime = Halogen_VDom_Machine.unStep(function (v1) {
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(v1.value0, {
              build: build,
              widget: res
          }, patchWidget, haltWidget));
      })(res);
      return res$prime;
  };
  var buildText = function (v, build, s) {
      var node = Halogen_VDom_Util.createTextNode(s, v.document);
      var state = {
          build: build,
          node: node,
          value: s
      };
      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, state, patchText, haltText));
  };
  var buildKeyed = function (v, build, ns1, name1, as1, ch1) {
      var el = Halogen_VDom_Util.createElement(Data_Nullable.toNullable(ns1), name1, v.document);
      var node = Web_DOM_Element.toNode(el);
      var onChild = function (k, ix, v1) {
          var res = build(v1.value1);
          Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), node);
          return res;
      };
      var children = Halogen_VDom_Util.strMapWithIxE(ch1, Data_Tuple.fst, onChild);
      var attrs = v.buildAttributes(el)(as1);
      var state = {
          build: build,
          node: node,
          attrs: attrs,
          ns: ns1,
          name: name1,
          children: children,
          length: Data_Array.length(ch1)
      };
      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, state, patchKeyed, haltKeyed));
  };
  var buildElem = function (v, build, ns1, name1, as1, ch1) {
      var el = Halogen_VDom_Util.createElement(Data_Nullable.toNullable(ns1), name1, v.document);
      var node = Web_DOM_Element.toNode(el);
      var onChild = function (ix, child) {
          var res = build(child);
          Halogen_VDom_Util.insertChildIx(ix, Halogen_VDom_Machine.extract(res), node);
          return res;
      };
      var children = Halogen_VDom_Util.forE(ch1, onChild);
      var attrs = v.buildAttributes(el)(as1);
      var state = {
          build: build,
          node: node,
          attrs: attrs,
          ns: ns1,
          name: name1,
          children: children
      };
      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, state, patchElem, haltElem));
  };
  var buildVDom = function (spec) {
      var build = function (v) {
          if (v instanceof Halogen_VDom_Types.Text) {
              return buildText(spec, build, v.value0);
          };
          if (v instanceof Halogen_VDom_Types.Elem) {
              return buildElem(spec, build, v.value0, v.value1, v.value2, v.value3);
          };
          if (v instanceof Halogen_VDom_Types.Keyed) {
              return buildKeyed(spec, build, v.value0, v.value1, v.value2, v.value3);
          };
          if (v instanceof Halogen_VDom_Types.Widget) {
              return buildWidget(spec, build, v.value0);
          };
          if (v instanceof Halogen_VDom_Types.Grafted) {
              return build(Halogen_VDom_Types.runGraft(v.value0));
          };
          throw new Error("Failed pattern match at Halogen.VDom.DOM (line 58, column 27 - line 63, column 52): " + [ v.constructor.name ]);
      };
      return build;
  };
  exports["buildVDom"] = buildVDom;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.VDom.Thunk"] = $PS["Halogen.VDom.Thunk"] || {};
  var exports = $PS["Halogen.VDom.Thunk"];
  var Halogen_VDom_DOM = $PS["Halogen.VDom.DOM"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Util = $PS["Halogen.VDom.Util"];        
  var Thunk = (function () {
      function Thunk(value0, value1, value2, value3) {
          this.value0 = value0;
          this.value1 = value1;
          this.value2 = value2;
          this.value3 = value3;
      };
      Thunk.create = function (value0) {
          return function (value1) {
              return function (value2) {
                  return function (value3) {
                      return new Thunk(value0, value1, value2, value3);
                  };
              };
          };
      };
      return Thunk;
  })();                                          
  var unsafeEqThunk = function (v, v1) {
      return Halogen_VDom_Util.refEq(v.value0, v1.value0) && (Halogen_VDom_Util.refEq(v.value1, v1.value1) && v.value1(v.value3, v1.value3));
  };
  var thunk = function (tid, eqFn, f, a) {
      return new Thunk(tid, eqFn, f, a);
  };
  var runThunk = function (v) {
      return v.value2(v.value3);
  };
  var mapThunk = function (k) {
      return function (v) {
          return new Thunk(v.value0, v.value1, function ($46) {
              return k(v.value2($46));
          }, v.value3);
      };
  };
  var hoist = mapThunk;
  var buildThunk = function (toVDom) {
      var haltThunk = function (state) {
          return Halogen_VDom_Machine.halt(state.vdom);
      };
      var patchThunk = function (state, t2) {
          var $43 = unsafeEqThunk(state.thunk, t2);
          if ($43) {
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(state.vdom), state, patchThunk, haltThunk));
          };
          var vdom = Halogen_VDom_Machine.step(state.vdom, toVDom(runThunk(t2)));
          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(vdom), {
              vdom: vdom,
              thunk: t2
          }, patchThunk, haltThunk));
      };
      var renderThunk = function (spec) {
          return function (t) {
              var vdom = Halogen_VDom_DOM.buildVDom(spec)(toVDom(runThunk(t)));
              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(vdom), {
                  thunk: t,
                  vdom: vdom
              }, patchThunk, haltThunk));
          };
      };
      return renderThunk;
  };
  exports["buildThunk"] = buildThunk;
  exports["hoist"] = hoist;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.Component"] = $PS["Halogen.Component"] || {};
  var exports = $PS["Halogen.Component"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Coyoneda = $PS["Data.Coyoneda"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unit = $PS["Data.Unit"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Halogen_Query_HalogenQ = $PS["Halogen.Query.HalogenQ"];
  var Halogen_VDom_Thunk = $PS["Halogen.VDom.Thunk"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                
  var ComponentSlot = (function () {
      function ComponentSlot(value0) {
          this.value0 = value0;
      };
      ComponentSlot.create = function (value0) {
          return new ComponentSlot(value0);
      };
      return ComponentSlot;
  })();
  var ThunkSlot = (function () {
      function ThunkSlot(value0) {
          this.value0 = value0;
      };
      ThunkSlot.create = function (value0) {
          return new ThunkSlot(value0);
      };
      return ThunkSlot;
  })();
  var unComponentSlot = Unsafe_Coerce.unsafeCoerce;
  var unComponent = Unsafe_Coerce.unsafeCoerce;
  var mkEval = function (args) {
      return function (v) {
          if (v instanceof Halogen_Query_HalogenQ.Initialize) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(args.handleAction)(args.initialize))(v.value0);
          };
          if (v instanceof Halogen_Query_HalogenQ.Finalize) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(args.handleAction)(args.finalize))(v.value0);
          };
          if (v instanceof Halogen_Query_HalogenQ.Receive) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(args.handleAction)(args.receive(v.value0)))(v.value1);
          };
          if (v instanceof Halogen_Query_HalogenQ.Action) {
              return Data_Functor.voidLeft(Halogen_Query_HalogenM.functorHalogenM)(args.handleAction(v.value0))(v.value1);
          };
          if (v instanceof Halogen_Query_HalogenQ.Query) {
              return Data_Coyoneda.unCoyoneda(function (g) {
                  var $25 = Data_Functor.map(Halogen_Query_HalogenM.functorHalogenM)(Data_Maybe.maybe(v.value1(Data_Unit.unit))(g));
                  return function ($26) {
                      return $25(args.handleQuery($26));
                  };
              })(v.value0);
          };
          throw new Error("Failed pattern match at Halogen.Component (line 182, column 15 - line 192, column 70): " + [ v.constructor.name ]);
      };
  };
  var mkComponentSlot = Unsafe_Coerce.unsafeCoerce;
  var mkComponent = Unsafe_Coerce.unsafeCoerce;
  var hoistSlot = function (dictFunctor) {
      return function (nat) {
          return function (v) {
              if (v instanceof ComponentSlot) {
                  return unComponentSlot(function (slot) {
                      return ComponentSlot.create(mkComponentSlot({
                          get: slot.get,
                          pop: slot.pop,
                          set: slot.set,
                          component: hoist(dictFunctor)(nat)(slot.component),
                          input: slot.input,
                          output: slot.output
                      }));
                  })(v.value0);
              };
              if (v instanceof ThunkSlot) {
                  return ThunkSlot.create(Halogen_VDom_Thunk.hoist(Data_Bifunctor.lmap(Halogen_HTML_Core.bifunctorHTML)(hoistSlot(dictFunctor)(nat)))(v.value0));
              };
              throw new Error("Failed pattern match at Halogen.Component (line 279, column 17 - line 284, column 53): " + [ v.constructor.name ]);
          };
      };
  };
  var hoist = function (dictFunctor) {
      return function (nat) {
          return unComponent(function (c) {
              return mkComponent({
                  initialState: c.initialState,
                  render: (function () {
                      var $27 = Data_Bifunctor.lmap(Halogen_HTML_Core.bifunctorHTML)(hoistSlot(dictFunctor)(nat));
                      return function ($28) {
                          return $27(c.render($28));
                      };
                  })(),
                  "eval": (function () {
                      var $29 = Halogen_Query_HalogenM.hoist(dictFunctor)(nat);
                      return function ($30) {
                          return $29(c["eval"]($30));
                      };
                  })()
              });
          });
      };
  }; 
  var defaultEval = {
      handleAction: Data_Function["const"](Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit)),
      handleQuery: Data_Function["const"](Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Maybe.Nothing.value)),
      receive: Data_Function["const"](Data_Maybe.Nothing.value),
      initialize: Data_Maybe.Nothing.value,
      finalize: Data_Maybe.Nothing.value
  };
  var componentSlot = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (label) {
                  return function (p) {
                      return function (comp) {
                          return function (input) {
                              return function (output) {
                                  return mkComponentSlot({
                                      get: Halogen_Data_Slot.lookup()(dictIsSymbol)(dictOrd)(label)(p),
                                      pop: Halogen_Data_Slot.pop()(dictIsSymbol)(dictOrd)(label)(p),
                                      set: Halogen_Data_Slot.insert()(dictIsSymbol)(dictOrd)(label)(p),
                                      component: comp,
                                      input: input,
                                      output: output
                                  });
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  exports["mkComponent"] = mkComponent;
  exports["unComponent"] = unComponent;
  exports["hoist"] = hoist;
  exports["mkEval"] = mkEval;
  exports["defaultEval"] = defaultEval;
  exports["ComponentSlot"] = ComponentSlot;
  exports["ThunkSlot"] = ThunkSlot;
  exports["componentSlot"] = componentSlot;
  exports["unComponentSlot"] = unComponentSlot;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Formless.Component"] = $PS["Formless.Component"] || {};
  var exports = $PS["Formless.Component"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Free = $PS["Control.Monad.Free"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Control_Semigroupoid = $PS["Control.Semigroupoid"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Functor_Variant = $PS["Data.Functor.Variant"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Unit = $PS["Data.Unit"];
  var Data_Variant = $PS["Data.Variant"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Formless_Action = $PS["Formless.Action"];
  var Formless_Data_FormFieldResult = $PS["Formless.Data.FormFieldResult"];
  var Formless_Internal_Component = $PS["Formless.Internal.Component"];
  var Formless_Internal_Debounce = $PS["Formless.Internal.Debounce"];
  var Formless_Internal_Transform = $PS["Formless.Internal.Transform"];
  var Formless_Transform_Record = $PS["Formless.Transform.Record"];
  var Formless_Transform_Row = $PS["Formless.Transform.Row"];
  var Formless_Types_Component = $PS["Formless.Types.Component"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Record_Builder = $PS["Record.Builder"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var raiseResult = function (dictNewtype) {
      return function (dictHMap) {
          return function (v) {
              if (v instanceof Formless_Types_Component.Submitted) {
                  return Halogen_Query_HalogenM.raise(Formless_Transform_Record.unwrapOutputFields()(dictHMap)(v.value0));
              };
              return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
          };
      };
  };
  var handleAction = function (dictMonadAff) {
      return function (dictRowToList) {
          return function (dictRowToList1) {
              return function (dictEqRecord) {
                  return function (dictInputFieldsToFormFields) {
                      return function (dictFormFieldsToInputFields) {
                          return function (dictCountErrors) {
                              return function (dictAllTouched) {
                                  return function (dictSetFormFieldsTouched) {
                                      return function (dictReplaceFormFieldInputs) {
                                          return function (dictModifyAll) {
                                              return function (dictValidateAll) {
                                                  return function (dictFormFieldToMaybeOutput) {
                                                      return function (dictNewtype) {
                                                          return function (dictNewtype1) {
                                                              return function (dictNewtype2) {
                                                                  return function (dictNewtype3) {
                                                                      return function (dictNewtype4) {
                                                                          return function (dictNewtype5) {
                                                                              return function (dictNewtype6) {
                                                                                  return function (dictNewtype7) {
                                                                                      return function (dictLacks) {
                                                                                          return function (handleAction$prime) {
                                                                                              return function (handleEvent) {
                                                                                                  return function (action) {
                                                                                                      var sync = Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
                                                                                                          return "syncFormData";
                                                                                                      }))(Type_Proxy["Proxy"].value)(Data_Unit.unit);
                                                                                                      return Data_Function.flip(Data_Variant.match()()())(action)({
                                                                                                          initialize: function (mbAction) {
                                                                                                              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Effect_Ref["new"](Data_Maybe.Nothing.value)))(function (dr) {
                                                                                                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadAff.MonadEffect0()))(Effect_Ref["new"](Data_Maybe.Nothing.value)))(function (vr) {
                                                                                                                      var setFields = function (rec) {
                                                                                                                          return {
                                                                                                                              debounceRef: new Data_Maybe.Just(dr),
                                                                                                                              validationRef: new Data_Maybe.Just(vr),
                                                                                                                              allTouched: rec.allTouched,
                                                                                                                              initialInputs: rec.initialInputs,
                                                                                                                              validators: rec.validators
                                                                                                                          };
                                                                                                                      };
                                                                                                                      return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (st) {
                                                                                                                          var $109 = {};
                                                                                                                          for (var $110 in st) {
                                                                                                                              if ({}.hasOwnProperty.call(st, $110)) {
                                                                                                                                  $109[$110] = st[$110];
                                                                                                                              };
                                                                                                                          };
                                                                                                                          $109.internal = Data_Newtype.over()()(Formless_Types_Component.InternalState)(setFields)(st.internal);
                                                                                                                          return $109;
                                                                                                                      }))(function () {
                                                                                                                          return Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(handleAction$prime)(mbAction);
                                                                                                                      });
                                                                                                                  });
                                                                                                              });
                                                                                                          },
                                                                                                          syncFormData: function (v) {
                                                                                                              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st) {
                                                                                                                  var errors = Formless_Internal_Transform.countErrors()(dictCountErrors)()(st.form);
                                                                                                                  var dirty = !Data_Eq.eq(Data_Eq.eqRec()(dictEqRecord))(Data_Newtype.unwrap()(Formless_Internal_Transform.formFieldsToInputFields()(dictFormFieldsToInputFields)()()(st.form)))(Data_Newtype.unwrap()((Data_Newtype.unwrap()(st.internal)).initialInputs));
                                                                                                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)((function () {
                                                                                                                      var v1 = (Data_Newtype.unwrap()(st.internal)).allTouched;
                                                                                                                      if (v1) {
                                                                                                                          return Control_Monad_State_Class.modify(Halogen_Query_HalogenM.monadStateHalogenM)(function (v2) {
                                                                                                                              var $114 = {};
                                                                                                                              for (var $115 in v2) {
                                                                                                                                  if ({}.hasOwnProperty.call(v2, $115)) {
                                                                                                                                      $114[$115] = v2[$115];
                                                                                                                                  };
                                                                                                                              };
                                                                                                                              $114.validity = (function () {
                                                                                                                                  var $113 = errors === 0;
                                                                                                                                  if ($113) {
                                                                                                                                      return Formless_Types_Component.Valid.value;
                                                                                                                                  };
                                                                                                                                  return Formless_Types_Component.Invalid.value;
                                                                                                                              })();
                                                                                                                              $114.errors = errors;
                                                                                                                              $114.dirty = dirty;
                                                                                                                              return $114;
                                                                                                                          });
                                                                                                                      };
                                                                                                                      var v2 = Formless_Internal_Transform.allTouched()(dictAllTouched)()(st.form);
                                                                                                                      if (v2) {
                                                                                                                          return Control_Monad_State_Class.modify(Halogen_Query_HalogenM.monadStateHalogenM)(function (v3) {
                                                                                                                              var $119 = {};
                                                                                                                              for (var $120 in v3) {
                                                                                                                                  if ({}.hasOwnProperty.call(v3, $120)) {
                                                                                                                                      $119[$120] = v3[$120];
                                                                                                                                  };
                                                                                                                              };
                                                                                                                              $119.validity = (function () {
                                                                                                                                  var $118 = errors === 0;
                                                                                                                                  if ($118) {
                                                                                                                                      return Formless_Types_Component.Valid.value;
                                                                                                                                  };
                                                                                                                                  return Formless_Types_Component.Invalid.value;
                                                                                                                              })();
                                                                                                                              $119.internal = Data_Newtype.over()()(Formless_Types_Component.InternalState)(function (v4) {
                                                                                                                                  return {
                                                                                                                                      allTouched: true,
                                                                                                                                      debounceRef: v4.debounceRef,
                                                                                                                                      initialInputs: v4.initialInputs,
                                                                                                                                      validationRef: v4.validationRef,
                                                                                                                                      validators: v4.validators
                                                                                                                                  };
                                                                                                                              })(st.internal);
                                                                                                                              $119.errors = errors;
                                                                                                                              $119.dirty = dirty;
                                                                                                                              return $119;
                                                                                                                          });
                                                                                                                      };
                                                                                                                      return Control_Monad_State_Class.modify(Halogen_Query_HalogenM.monadStateHalogenM)(function (v3) {
                                                                                                                          var $122 = {};
                                                                                                                          for (var $123 in v3) {
                                                                                                                              if ({}.hasOwnProperty.call(v3, $123)) {
                                                                                                                                  $122[$123] = v3[$123];
                                                                                                                              };
                                                                                                                          };
                                                                                                                          $122.validity = Formless_Types_Component.Incomplete.value;
                                                                                                                          $122.errors = errors;
                                                                                                                          $122.dirty = dirty;
                                                                                                                          return $122;
                                                                                                                      });
                                                                                                                  })())(function (newState) {
                                                                                                                      return handleEvent(Formless_Types_Component.Changed.create(Formless_Internal_Component.getPublicState()(newState)));
                                                                                                                  });
                                                                                                              });
                                                                                                          },
                                                                                                          userAction: function (act) {
                                                                                                              return handleAction$prime(act);
                                                                                                          },
                                                                                                          modify: function (variant) {
                                                                                                              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (st) {
                                                                                                                  var $125 = {};
                                                                                                                  for (var $126 in st) {
                                                                                                                      if ({}.hasOwnProperty.call(st, $126)) {
                                                                                                                          $125[$126] = st[$126];
                                                                                                                      };
                                                                                                                  };
                                                                                                                  $125.form = Formless_Internal_Transform.unsafeModifyInputVariant()()(Control_Category.identity(Control_Category.categoryFn))(variant)(st.form);
                                                                                                                  return $125;
                                                                                                              }))(function () {
                                                                                                                  return handleAction(dictMonadAff)()()(dictEqRecord)(dictInputFieldsToFormFields)(dictFormFieldsToInputFields)(dictCountErrors)(dictAllTouched)(dictSetFormFieldsTouched)(dictReplaceFormFieldInputs)(dictModifyAll)(dictValidateAll)(dictFormFieldToMaybeOutput)()()()()()()()()()(handleAction$prime)(handleEvent)(sync);
                                                                                                              });
                                                                                                          },
                                                                                                          validate: function (variant) {
                                                                                                              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st) {
                                                                                                                  var validators = (Data_Newtype.unwrap()(st.internal)).validators;
                                                                                                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM)((dictMonadAff.MonadEffect0()).Monad0())(Formless_Internal_Transform.unsafeRunValidationVariant((dictMonadAff.MonadEffect0()).Monad0())()()()(variant)(validators)(st.form)))(function (formProcessor) {
                                                                                                                      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st$prime) {
                                                                                                                          return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v) {
                                                                                                                              var $128 = {};
                                                                                                                              for (var $129 in v) {
                                                                                                                                  if ({}.hasOwnProperty.call(v, $129)) {
                                                                                                                                      $128[$129] = v[$129];
                                                                                                                                  };
                                                                                                                              };
                                                                                                                              $128.form = formProcessor(st$prime.form);
                                                                                                                              return $128;
                                                                                                                          }))(function () {
                                                                                                                              return handleAction(dictMonadAff)()()(dictEqRecord)(dictInputFieldsToFormFields)(dictFormFieldsToInputFields)(dictCountErrors)(dictAllTouched)(dictSetFormFieldsTouched)(dictReplaceFormFieldInputs)(dictModifyAll)(dictValidateAll)(dictFormFieldToMaybeOutput)()()()()()()()()()(handleAction$prime)(handleEvent)(sync);
                                                                                                                          });
                                                                                                                      });
                                                                                                                  });
                                                                                                              });
                                                                                                          },
                                                                                                          modifyValidate: function (v) {
                                                                                                              var validate = Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st) {
                                                                                                                  var vs = (Data_Newtype.unwrap()(st.internal)).validators;
                                                                                                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM)((dictMonadAff.MonadEffect0()).Monad0())(Formless_Internal_Transform.unsafeRunValidationVariant((dictMonadAff.MonadEffect0()).Monad0())()()()(v.value1)(vs)(st.form)))(function (formProcessor) {
                                                                                                                      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st$prime) {
                                                                                                                          var newForm = formProcessor(st$prime.form);
                                                                                                                          return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                                                                                                                              var $132 = {};
                                                                                                                              for (var $133 in v1) {
                                                                                                                                  if ({}.hasOwnProperty.call(v1, $133)) {
                                                                                                                                      $132[$133] = v1[$133];
                                                                                                                                  };
                                                                                                                              };
                                                                                                                              $132.form = newForm;
                                                                                                                              return $132;
                                                                                                                          }))(function () {
                                                                                                                              return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(newForm);
                                                                                                                          });
                                                                                                                      });
                                                                                                                  });
                                                                                                              });
                                                                                                              var modifyWith = function (f) {
                                                                                                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify(Halogen_Query_HalogenM.monadStateHalogenM)(function (s) {
                                                                                                                      var $135 = {};
                                                                                                                      for (var $136 in s) {
                                                                                                                          if ({}.hasOwnProperty.call(s, $136)) {
                                                                                                                              $135[$136] = s[$136];
                                                                                                                          };
                                                                                                                      };
                                                                                                                      $135.form = Formless_Internal_Transform.unsafeModifyInputVariant()()(f)(v.value1)(s.form);
                                                                                                                      return $135;
                                                                                                                  }))(function (st) {
                                                                                                                      return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(st.form);
                                                                                                                  });
                                                                                                              };
                                                                                                              if (v.value0 instanceof Data_Maybe.Nothing) {
                                                                                                                  return Control_Apply.applySecond(Halogen_Query_HalogenM.applyHalogenM)(Control_Apply.applySecond(Halogen_Query_HalogenM.applyHalogenM)(modifyWith(Control_Category.identity(Control_Category.categoryFn)))(validate))(handleAction(dictMonadAff)()()(dictEqRecord)(dictInputFieldsToFormFields)(dictFormFieldsToInputFields)(dictCountErrors)(dictAllTouched)(dictSetFormFieldsTouched)(dictReplaceFormFieldInputs)(dictModifyAll)(dictValidateAll)(dictFormFieldToMaybeOutput)()()()()()()()()()(handleAction$prime)(handleEvent)(sync));
                                                                                                              };
                                                                                                              if (v.value0 instanceof Data_Maybe.Just) {
                                                                                                                  return Formless_Internal_Debounce.debounceForm(dictMonadAff)(v.value0.value0)(modifyWith(Control_Category.identity(Control_Category.categoryFn)))(Control_Apply.applySecond(Halogen_Query_HalogenM.applyHalogenM)(modifyWith(Data_Function["const"](Formless_Data_FormFieldResult.Validating.value)))(validate))(handleAction(dictMonadAff)()()(dictEqRecord)(dictInputFieldsToFormFields)(dictFormFieldsToInputFields)(dictCountErrors)(dictAllTouched)(dictSetFormFieldsTouched)(dictReplaceFormFieldInputs)(dictModifyAll)(dictValidateAll)(dictFormFieldToMaybeOutput)()()()()()()()()()(handleAction$prime)(handleEvent)(sync));
                                                                                                              };
                                                                                                              throw new Error("Failed pattern match at Formless.Component (line 266, column 7 - line 274, column 58): " + [ v.value0.constructor.name ]);
                                                                                                          },
                                                                                                          reset: function (variant) {
                                                                                                              return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (st) {
                                                                                                                  var $142 = {};
                                                                                                                  for (var $143 in st) {
                                                                                                                      if ({}.hasOwnProperty.call(st, $143)) {
                                                                                                                          $142[$143] = st[$143];
                                                                                                                      };
                                                                                                                  };
                                                                                                                  $142.form = Formless_Internal_Transform.unsafeModifyInputVariant()()(Control_Category.identity(Control_Category.categoryFn))(variant)(st.form);
                                                                                                                  $142.internal = Data_Newtype.over()()(Formless_Types_Component.InternalState)(function (v1) {
                                                                                                                      return {
                                                                                                                          allTouched: false,
                                                                                                                          debounceRef: v1.debounceRef,
                                                                                                                          initialInputs: v1.initialInputs,
                                                                                                                          validationRef: v1.validationRef,
                                                                                                                          validators: v1.validators
                                                                                                                      };
                                                                                                                  })(st.internal);
                                                                                                                  return $142;
                                                                                                              }))(function () {
                                                                                                                  return handleAction(dictMonadAff)()()(dictEqRecord)(dictInputFieldsToFormFields)(dictFormFieldsToInputFields)(dictCountErrors)(dictAllTouched)(dictSetFormFieldsTouched)(dictReplaceFormFieldInputs)(dictModifyAll)(dictValidateAll)(dictFormFieldToMaybeOutput)()()()()()()()()()(handleAction$prime)(handleEvent)(sync);
                                                                                                              });
                                                                                                          },
                                                                                                          setAll: function (v) {
                                                                                                              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify(Halogen_Query_HalogenM.monadStateHalogenM)(function (st) {
                                                                                                                  var $146 = {};
                                                                                                                  for (var $147 in st) {
                                                                                                                      if ({}.hasOwnProperty.call(st, $147)) {
                                                                                                                          $146[$147] = st[$147];
                                                                                                                      };
                                                                                                                  };
                                                                                                                  $146.form = Formless_Internal_Transform.replaceFormFieldInputs()(dictReplaceFormFieldInputs)()()(v.value0)(st.form);
                                                                                                                  return $146;
                                                                                                              }))(function ($$new) {
                                                                                                                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(handleEvent(Formless_Types_Component.Changed.create(Formless_Internal_Component.getPublicState()($$new))))(function () {
                                                                                                                      if (v.value1) {
                                                                                                                          return handleAction(dictMonadAff)()()(dictEqRecord)(dictInputFieldsToFormFields)(dictFormFieldsToInputFields)(dictCountErrors)(dictAllTouched)(dictSetFormFieldsTouched)(dictReplaceFormFieldInputs)(dictModifyAll)(dictValidateAll)(dictFormFieldToMaybeOutput)()()()()()()()()()(handleAction$prime)(handleEvent)(Formless_Action.validateAll);
                                                                                                                      };
                                                                                                                      return handleAction(dictMonadAff)()()(dictEqRecord)(dictInputFieldsToFormFields)(dictFormFieldsToInputFields)(dictCountErrors)(dictAllTouched)(dictSetFormFieldsTouched)(dictReplaceFormFieldInputs)(dictModifyAll)(dictValidateAll)(dictFormFieldToMaybeOutput)()()()()()()()()()(handleAction$prime)(handleEvent)(sync);
                                                                                                                  });
                                                                                                              });
                                                                                                          },
                                                                                                          modifyAll: function (v) {
                                                                                                              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify(Halogen_Query_HalogenM.monadStateHalogenM)(function (st) {
                                                                                                                  var $153 = {};
                                                                                                                  for (var $154 in st) {
                                                                                                                      if ({}.hasOwnProperty.call(st, $154)) {
                                                                                                                          $153[$154] = st[$154];
                                                                                                                      };
                                                                                                                  };
                                                                                                                  $153.form = Formless_Internal_Transform.modifyAll()(dictModifyAll)()()(v.value0)(st.form);
                                                                                                                  return $153;
                                                                                                              }))(function ($$new) {
                                                                                                                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(handleEvent(Formless_Types_Component.Changed.create(Formless_Internal_Component.getPublicState()($$new))))(function () {
                                                                                                                      if (v.value1) {
                                                                                                                          return handleAction(dictMonadAff)()()(dictEqRecord)(dictInputFieldsToFormFields)(dictFormFieldsToInputFields)(dictCountErrors)(dictAllTouched)(dictSetFormFieldsTouched)(dictReplaceFormFieldInputs)(dictModifyAll)(dictValidateAll)(dictFormFieldToMaybeOutput)()()()()()()()()()(handleAction$prime)(handleEvent)(Formless_Action.validateAll);
                                                                                                                      };
                                                                                                                      return handleAction(dictMonadAff)()()(dictEqRecord)(dictInputFieldsToFormFields)(dictFormFieldsToInputFields)(dictCountErrors)(dictAllTouched)(dictSetFormFieldsTouched)(dictReplaceFormFieldInputs)(dictModifyAll)(dictValidateAll)(dictFormFieldToMaybeOutput)()()()()()()()()()(handleAction$prime)(handleEvent)(sync);
                                                                                                                  });
                                                                                                              });
                                                                                                          },
                                                                                                          validateAll: function (v) {
                                                                                                              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st) {
                                                                                                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM)((dictMonadAff.MonadEffect0()).Monad0())(Formless_Internal_Transform.validateAll()((dictMonadAff.MonadEffect0()).Monad0())(dictValidateAll)()()((Data_Newtype.unwrap()(st.internal)).validators)(st.form)))(function (form) {
                                                                                                                      return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                                                                                                                          var $159 = {};
                                                                                                                          for (var $160 in v1) {
                                                                                                                              if ({}.hasOwnProperty.call(v1, $160)) {
                                                                                                                                  $159[$160] = v1[$160];
                                                                                                                              };
                                                                                                                          };
                                                                                                                          $159.form = form;
                                                                                                                          return $159;
                                                                                                                      }))(function () {
                                                                                                                          return handleAction(dictMonadAff)()()(dictEqRecord)(dictInputFieldsToFormFields)(dictFormFieldsToInputFields)(dictCountErrors)(dictAllTouched)(dictSetFormFieldsTouched)(dictReplaceFormFieldInputs)(dictModifyAll)(dictValidateAll)(dictFormFieldToMaybeOutput)()()()()()()()()()(handleAction$prime)(handleEvent)(sync);
                                                                                                                      });
                                                                                                                  });
                                                                                                              });
                                                                                                          },
                                                                                                          resetAll: function (v) {
                                                                                                              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify(Halogen_Query_HalogenM.monadStateHalogenM)(function (st) {
                                                                                                                  var $162 = {};
                                                                                                                  for (var $163 in st) {
                                                                                                                      if ({}.hasOwnProperty.call(st, $163)) {
                                                                                                                          $162[$163] = st[$163];
                                                                                                                      };
                                                                                                                  };
                                                                                                                  $162.validity = Formless_Types_Component.Incomplete.value;
                                                                                                                  $162.dirty = false;
                                                                                                                  $162.errors = 0;
                                                                                                                  $162.submitAttempts = 0;
                                                                                                                  $162.submitting = false;
                                                                                                                  $162.form = Formless_Internal_Transform.replaceFormFieldInputs()(dictReplaceFormFieldInputs)()()((Data_Newtype.unwrap()(st.internal)).initialInputs)(st.form);
                                                                                                                  $162.internal = Data_Newtype.over()()(Formless_Types_Component.InternalState)(function (v2) {
                                                                                                                      return {
                                                                                                                          allTouched: false,
                                                                                                                          debounceRef: v2.debounceRef,
                                                                                                                          initialInputs: v2.initialInputs,
                                                                                                                          validationRef: v2.validationRef,
                                                                                                                          validators: v2.validators
                                                                                                                      };
                                                                                                                  })(st.internal);
                                                                                                                  return $162;
                                                                                                              }))(function ($$new) {
                                                                                                                  return handleEvent(Formless_Types_Component.Changed.create(Formless_Internal_Component.getPublicState()($$new)));
                                                                                                              });
                                                                                                          },
                                                                                                          submit: function (v) {
                                                                                                              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Formless_Internal_Component.preSubmit(dictMonadAff)()(dictAllTouched)(dictSetFormFieldsTouched)(dictValidateAll)(dictFormFieldToMaybeOutput)(dictValidateAll)()()())(function () {
                                                                                                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(handleAction(dictMonadAff)()()(dictEqRecord)(dictInputFieldsToFormFields)(dictFormFieldsToInputFields)(dictCountErrors)(dictAllTouched)(dictSetFormFieldsTouched)(dictReplaceFormFieldInputs)(dictModifyAll)(dictValidateAll)(dictFormFieldToMaybeOutput)()()()()()()()()()(handleAction$prime)(handleEvent)(Formless_Action.validateAll))(function () {
                                                                                                                      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Formless_Internal_Component.submit(dictMonadAff)()(dictAllTouched)(dictSetFormFieldsTouched)(dictValidateAll)(dictFormFieldToMaybeOutput)(dictValidateAll)()()())(Data_Foldable.traverse_(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Foldable.foldableMaybe)(function ($175) {
                                                                                                                          return handleEvent(Formless_Types_Component.Submitted.create($175));
                                                                                                                      }));
                                                                                                                  });
                                                                                                              });
                                                                                                          },
                                                                                                          loadForm: function (formInputs) {
                                                                                                              var setFields = function (rec) {
                                                                                                                  return {
                                                                                                                      allTouched: false,
                                                                                                                      initialInputs: formInputs,
                                                                                                                      debounceRef: rec.debounceRef,
                                                                                                                      validationRef: rec.validationRef,
                                                                                                                      validators: rec.validators
                                                                                                                  };
                                                                                                              };
                                                                                                              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (st) {
                                                                                                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify(Halogen_Query_HalogenM.monadStateHalogenM)(function (v) {
                                                                                                                      var $165 = {};
                                                                                                                      for (var $166 in v) {
                                                                                                                          if ({}.hasOwnProperty.call(v, $166)) {
                                                                                                                              $165[$166] = v[$166];
                                                                                                                          };
                                                                                                                      };
                                                                                                                      $165.validity = Formless_Types_Component.Incomplete.value;
                                                                                                                      $165.dirty = false;
                                                                                                                      $165.errors = 0;
                                                                                                                      $165.submitAttempts = 0;
                                                                                                                      $165.submitting = false;
                                                                                                                      $165.form = Formless_Internal_Transform.replaceFormFieldInputs()(dictReplaceFormFieldInputs)()()(formInputs)(st.form);
                                                                                                                      $165.internal = Data_Newtype.over()()(Formless_Types_Component.InternalState)(setFields)(st.internal);
                                                                                                                      return $165;
                                                                                                                  }))(function ($$new) {
                                                                                                                      return handleEvent(Formless_Types_Component.Changed.create(Formless_Internal_Component.getPublicState()($$new)));
                                                                                                                  });
                                                                                                              });
                                                                                                          }
                                                                                                      });
                                                                                                  };
                                                                                              };
                                                                                          };
                                                                                      };
                                                                                  };
                                                                              };
                                                                          };
                                                                      };
                                                                  };
                                                              };
                                                          };
                                                      };
                                                  };
                                              };
                                          };
                                      };
                                  };
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  var handleQuery = function (dictMonadAff) {
      return function (dictRowToList) {
          return function (dictRowToList1) {
              return function (dictEqRecord) {
                  return function (dictInputFieldsToFormFields) {
                      return function (dictFormFieldsToInputFields) {
                          return function (dictCountErrors) {
                              return function (dictAllTouched) {
                                  return function (dictSetFormFieldsTouched) {
                                      return function (dictReplaceFormFieldInputs) {
                                          return function (dictModifyAll) {
                                              return function (dictValidateAll) {
                                                  return function (dictFormFieldToMaybeOutput) {
                                                      return function (dictNewtype) {
                                                          return function (dictNewtype1) {
                                                              return function (dictNewtype2) {
                                                                  return function (dictNewtype3) {
                                                                      return function (dictNewtype4) {
                                                                          return function (dictNewtype5) {
                                                                              return function (dictNewtype6) {
                                                                                  return function (dictNewtype7) {
                                                                                      return function (dictLacks) {
                                                                                          return function (handleQuery$prime) {
                                                                                              return function (handleEvent) {
                                                                                                  return Data_Functor_Variant.match()()()({
                                                                                                      query: function (v) {
                                                                                                          if (v instanceof Formless_Types_Component.SubmitReply) {
                                                                                                              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Formless_Internal_Component.preSubmit(dictMonadAff)()(dictAllTouched)(dictSetFormFieldsTouched)(dictValidateAll)(dictFormFieldToMaybeOutput)(dictValidateAll)()()())(function () {
                                                                                                                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(handleAction(dictMonadAff)()()(dictEqRecord)(dictInputFieldsToFormFields)(dictFormFieldsToInputFields)(dictCountErrors)(dictAllTouched)(dictSetFormFieldsTouched)(dictReplaceFormFieldInputs)(dictModifyAll)(dictValidateAll)(dictFormFieldToMaybeOutput)()()()()()()()()()(Data_Function["const"](Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit)))(handleEvent)(Formless_Action.validateAll))(function () {
                                                                                                                      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Formless_Internal_Component.submit(dictMonadAff)()(dictAllTouched)(dictSetFormFieldsTouched)(dictValidateAll)(dictFormFieldToMaybeOutput)(dictValidateAll)()()())(function (mbForm) {
                                                                                                                          return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Maybe.Just.create(v.value0(mbForm)));
                                                                                                                      });
                                                                                                                  });
                                                                                                              });
                                                                                                          };
                                                                                                          if (v instanceof Formless_Types_Component.SendQuery) {
                                                                                                              return Halogen_Query_HalogenM.HalogenM(Control_Monad_Free.liftF(new Halogen_Query_HalogenM.ChildQuery(v.value0)));
                                                                                                          };
                                                                                                          if (v instanceof Formless_Types_Component.AsQuery) {
                                                                                                              return Data_Functor.voidRight(Halogen_Query_HalogenM.functorHalogenM)(new Data_Maybe.Just(v.value1))(handleAction(dictMonadAff)()()(dictEqRecord)(dictInputFieldsToFormFields)(dictFormFieldsToInputFields)(dictCountErrors)(dictAllTouched)(dictSetFormFieldsTouched)(dictReplaceFormFieldInputs)(dictModifyAll)(dictValidateAll)(dictFormFieldToMaybeOutput)()()()()()()()()()(Data_Function["const"](Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit)))(handleEvent)(Data_Variant.expand()(v.value0)));
                                                                                                          };
                                                                                                          throw new Error("Failed pattern match at Formless.Component (line 371, column 12 - line 385, column 44): " + [ v.constructor.name ]);
                                                                                                      },
                                                                                                      userQuery: function (q) {
                                                                                                          return handleQuery$prime(q);
                                                                                                      }
                                                                                                  });
                                                                                              };
                                                                                          };
                                                                                      };
                                                                                  };
                                                                              };
                                                                          };
                                                                      };
                                                                  };
                                                              };
                                                          };
                                                      };
                                                  };
                                              };
                                          };
                                      };
                                  };
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  var defaultSpec = {
      render: Data_Function["const"](Halogen_HTML_Core.text(Data_Monoid.mempty(Data_Monoid.monoidString))),
      handleAction: Data_Function["const"](Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit)),
      handleQuery: Data_Function["const"](Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Maybe.Nothing.value)),
      handleEvent: Data_Function["const"](Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit)),
      receive: Data_Function["const"](Data_Maybe.Nothing.value),
      initialize: Data_Maybe.Nothing.value,
      finalize: Data_Maybe.Nothing.value
  };
  var component = function (dictMonadAff) {
      return function (dictRowToList) {
          return function (dictRowToList1) {
              return function (dictEqRecord) {
                  return function (dictInputFieldsToFormFields) {
                      return function (dictFormFieldsToInputFields) {
                          return function (dictCountErrors) {
                              return function (dictAllTouched) {
                                  return function (dictSetFormFieldsTouched) {
                                      return function (dictReplaceFormFieldInputs) {
                                          return function (dictModifyAll) {
                                              return function (dictValidateAll) {
                                                  return function (dictFormFieldToMaybeOutput) {
                                                      return function (dictMakeInputFieldsFromRow) {
                                                          return function (dictNewtype) {
                                                              return function (dictNewtype1) {
                                                                  return function (dictNewtype2) {
                                                                      return function (dictNewtype3) {
                                                                          return function (dictNewtype4) {
                                                                              return function (dictNewtype5) {
                                                                                  return function (dictNewtype6) {
                                                                                      return function (dictNewtype7) {
                                                                                          return function (dictLacks) {
                                                                                              return function (dictLacks1) {
                                                                                                  return function (dictLacks2) {
                                                                                                      return function (dictLacks3) {
                                                                                                          return function (dictLacks4) {
                                                                                                              return function (dictLacks5) {
                                                                                                                  return function (dictLacks6) {
                                                                                                                      return function (dictLacks7) {
                                                                                                                          return function (dictLacks8) {
                                                                                                                              return function (mkInput) {
                                                                                                                                  return function (spec) {
                                                                                                                                      var initialState = function (input) {
                                                                                                                                          var initialInputs = (function () {
                                                                                                                                              if (input.initialInputs instanceof Data_Maybe.Nothing) {
                                                                                                                                                  return Formless_Transform_Row.mkInputFields()()(dictMakeInputFieldsFromRow);
                                                                                                                                              };
                                                                                                                                              if (input.initialInputs instanceof Data_Maybe.Just) {
                                                                                                                                                  return input.initialInputs.value0;
                                                                                                                                              };
                                                                                                                                              throw new Error("Failed pattern match at Formless.Component (line 138, column 21 - line 140, column 28): " + [ input.initialInputs.constructor.name ]);
                                                                                                                                          })();
                                                                                                                                          var internalState = {
                                                                                                                                              allTouched: false,
                                                                                                                                              initialInputs: initialInputs,
                                                                                                                                              validators: input.validators,
                                                                                                                                              debounceRef: Data_Maybe.Nothing.value,
                                                                                                                                              validationRef: Data_Maybe.Nothing.value
                                                                                                                                          };
                                                                                                                                          var initialForm = Formless_Internal_Transform.inputFieldsToFormFields()(dictInputFieldsToFormFields)()()(initialInputs);
                                                                                                                                          var pipeline = Control_Semigroupoid.composeFlipped(Record_Builder.semigroupoidBuilder)(Record_Builder["delete"](new Data_Symbol.IsSymbol(function () {
                                                                                                                                              return "validators";
                                                                                                                                          }))()()(Type_Proxy["Proxy"].value))(Control_Semigroupoid.composeFlipped(Record_Builder.semigroupoidBuilder)(Record_Builder["delete"](new Data_Symbol.IsSymbol(function () {
                                                                                                                                              return "initialInputs";
                                                                                                                                          }))()()(Type_Proxy["Proxy"].value))(Control_Semigroupoid.composeFlipped(Record_Builder.semigroupoidBuilder)(Record_Builder.insert()()(new Data_Symbol.IsSymbol(function () {
                                                                                                                                              return "validity";
                                                                                                                                          }))(Type_Proxy["Proxy"].value)(Formless_Types_Component.Incomplete.value))(Control_Semigroupoid.composeFlipped(Record_Builder.semigroupoidBuilder)(Record_Builder.insert()()(new Data_Symbol.IsSymbol(function () {
                                                                                                                                              return "dirty";
                                                                                                                                          }))(Type_Proxy["Proxy"].value)(false))(Control_Semigroupoid.composeFlipped(Record_Builder.semigroupoidBuilder)(Record_Builder.insert()()(new Data_Symbol.IsSymbol(function () {
                                                                                                                                              return "errors";
                                                                                                                                          }))(Type_Proxy["Proxy"].value)(0))(Control_Semigroupoid.composeFlipped(Record_Builder.semigroupoidBuilder)(Record_Builder.insert()()(new Data_Symbol.IsSymbol(function () {
                                                                                                                                              return "submitAttempts";
                                                                                                                                          }))(Type_Proxy["Proxy"].value)(0))(Control_Semigroupoid.composeFlipped(Record_Builder.semigroupoidBuilder)(Record_Builder.insert()()(new Data_Symbol.IsSymbol(function () {
                                                                                                                                              return "submitting";
                                                                                                                                          }))(Type_Proxy["Proxy"].value)(false))(Control_Semigroupoid.composeFlipped(Record_Builder.semigroupoidBuilder)(Record_Builder.insert()()(new Data_Symbol.IsSymbol(function () {
                                                                                                                                              return "form";
                                                                                                                                          }))(Type_Proxy["Proxy"].value)(initialForm))(Record_Builder.insert()()(new Data_Symbol.IsSymbol(function () {
                                                                                                                                              return "internal";
                                                                                                                                          }))(Type_Proxy["Proxy"].value)(internalState)))))))));
                                                                                                                                          return Record_Builder.build(pipeline)(input);
                                                                                                                                      };
                                                                                                                                      return Halogen_Component.mkComponent({
                                                                                                                                          initialState: function ($176) {
                                                                                                                                              return initialState(mkInput($176));
                                                                                                                                          },
                                                                                                                                          render: (function () {
                                                                                                                                              var $177 = Formless_Internal_Component.getPublicState();
                                                                                                                                              return function ($178) {
                                                                                                                                                  return spec.render($177($178));
                                                                                                                                              };
                                                                                                                                          })(),
                                                                                                                                          "eval": Halogen_Component.mkEval({
                                                                                                                                              handleQuery: function (q) {
                                                                                                                                                  return handleQuery(dictMonadAff)()()(dictEqRecord)(dictInputFieldsToFormFields)(dictFormFieldsToInputFields)(dictCountErrors)(dictAllTouched)(dictSetFormFieldsTouched)(dictReplaceFormFieldInputs)(dictModifyAll)(dictValidateAll)(dictFormFieldToMaybeOutput)()()()()()()()()()(spec.handleQuery)(spec.handleEvent)(q);
                                                                                                                                              },
                                                                                                                                              handleAction: function (act) {
                                                                                                                                                  return handleAction(dictMonadAff)()()(dictEqRecord)(dictInputFieldsToFormFields)(dictFormFieldsToInputFields)(dictCountErrors)(dictAllTouched)(dictSetFormFieldsTouched)(dictReplaceFormFieldInputs)(dictModifyAll)(dictValidateAll)(dictFormFieldToMaybeOutput)()()()()()()()()()(spec.handleAction)(spec.handleEvent)(act);
                                                                                                                                              },
                                                                                                                                              initialize: new Data_Maybe.Just(Data_Variant.inj()(new Data_Symbol.IsSymbol(function () {
                                                                                                                                                  return "initialize";
                                                                                                                                              }))(Type_Proxy["Proxy"].value)(spec.initialize)),
                                                                                                                                              receive: Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Maybe.functorMaybe)(Formless_Action.injAction))(spec.receive),
                                                                                                                                              finalize: Data_Functor.map(Data_Maybe.functorMaybe)(Formless_Action.injAction)(spec.finalize)
                                                                                                                                          })
                                                                                                                                      });
                                                                                                                                  };
                                                                                                                              };
                                                                                                                          };
                                                                                                                      };
                                                                                                                  };
                                                                                                              };
                                                                                                          };
                                                                                                      };
                                                                                                  };
                                                                                              };
                                                                                          };
                                                                                      };
                                                                                  };
                                                                              };
                                                                          };
                                                                      };
                                                                  };
                                                              };
                                                          };
                                                      };
                                                  };
                                              };
                                          };
                                      };
                                  };
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  exports["defaultSpec"] = defaultSpec;
  exports["raiseResult"] = raiseResult;
  exports["component"] = component;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Formless.Retrieve"] = $PS["Formless.Retrieve"] || {};
  var exports = $PS["Formless.Retrieve"];
  var Data_Lens_Fold = $PS["Data.Lens.Fold"];
  var Data_Lens_Getter = $PS["Data.Lens.Getter"];
  var Data_Lens_Internal_Forget = $PS["Data.Lens.Internal.Forget"];
  var Data_Lens_Iso_Newtype = $PS["Data.Lens.Iso.Newtype"];
  var Data_Lens_Record = $PS["Data.Lens.Record"];
  var Data_Maybe_First = $PS["Data.Maybe.First"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Formless_Data_FormFieldResult = $PS["Formless.Data.FormFieldResult"];
  var Type_Proxy = $PS["Type.Proxy"];
  var _Field = function (dictIsSymbol) {
      return function (dictNewtype) {
          return function (dictCons) {
              return function (sym) {
                  return function (dictStrong) {
                      var $94 = Data_Lens_Iso_Newtype["_Newtype"]()()(dictStrong.Profunctor0());
                      var $95 = Data_Lens_Record.prop(dictIsSymbol)()()(sym)(dictStrong);
                      var $96 = Data_Lens_Iso_Newtype["_Newtype"]()()(dictStrong.Profunctor0());
                      return function ($97) {
                          return $94($95($96($97)));
                      };
                  };
              };
          };
      };
  };
  var _FieldInput = function (dictIsSymbol) {
      return function (dictNewtype) {
          return function (dictCons) {
              return function (sym) {
                  return function (dictStrong) {
                      var $98 = _Field(dictIsSymbol)()()(sym)(dictStrong);
                      var $99 = Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
                          return "input";
                      }))()()(Type_Proxy["Proxy"].value)(dictStrong);
                      return function ($100) {
                          return $98($99($100));
                      };
                  };
              };
          };
      };
  };
  var getInput = function (dictIsSymbol) {
      return function (dictNewtype) {
          return function (dictCons) {
              return function (sym) {
                  return Data_Lens_Getter.view(_FieldInput(dictIsSymbol)()()(sym)(Data_Lens_Internal_Forget.strongForget));
              };
          };
      };
  };
  var _FieldResult = function (dictIsSymbol) {
      return function (dictNewtype) {
          return function (dictCons) {
              return function (sym) {
                  return function (dictStrong) {
                      var $101 = _Field(dictIsSymbol)()()(sym)(dictStrong);
                      var $102 = Data_Lens_Record.prop(new Data_Symbol.IsSymbol(function () {
                          return "result";
                      }))()()(Type_Proxy["Proxy"].value)(dictStrong);
                      return function ($103) {
                          return $101($102($103));
                      };
                  };
              };
          };
      };
  };
  var _FieldError = function (dictIsSymbol) {
      return function (dictNewtype) {
          return function (dictCons) {
              return function (sym) {
                  return function (dictWander) {
                      var $104 = _FieldResult(dictIsSymbol)()()(sym)(dictWander.Strong0());
                      var $105 = Formless_Data_FormFieldResult["_Error"](dictWander.Choice1());
                      return function ($106) {
                          return $104($105($106));
                      };
                  };
              };
          };
      };
  };
  var getError = function (dictIsSymbol) {
      return function (dictNewtype) {
          return function (dictCons) {
              return function (sym) {
                  return Data_Lens_Fold.preview(_FieldError(dictIsSymbol)()()(sym)(Data_Lens_Internal_Forget.wanderForget(Data_Maybe_First.monoidFirst)));
              };
          };
      };
  };
  exports["getInput"] = getInput;
  exports["getError"] = getError;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.Aff.Driver.State"] = $PS["Halogen.Aff.Driver.State"] || {};
  var exports = $PS["Halogen.Aff.Driver.State"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var unRenderStateX = Unsafe_Coerce.unsafeCoerce;
  var unDriverStateX = Unsafe_Coerce.unsafeCoerce;
  var renderStateX_ = function (dictApplicative) {
      return function (f) {
          return unDriverStateX(function (st) {
              return Data_Foldable.traverse_(dictApplicative)(Data_Foldable.foldableMaybe)(f)(st.rendering);
          });
      };
  };
  var mkRenderStateX = Unsafe_Coerce.unsafeCoerce;
  var renderStateX = function (dictFunctor) {
      return function (f) {
          return unDriverStateX(function (st) {
              return mkRenderStateX(f(st.rendering));
          });
      };
  };
  var mkDriverStateXRef = Unsafe_Coerce.unsafeCoerce;
  var mapDriverState = function (f) {
      return function (v) {
          return f(v);
      };
  };
  var initDriverState = function (component) {
      return function (input) {
          return function (handler) {
              return function (lchs) {
                  return function __do() {
                      var selfRef = Effect_Ref["new"]({})();
                      var childrenIn = Effect_Ref["new"](Halogen_Data_Slot.empty)();
                      var childrenOut = Effect_Ref["new"](Halogen_Data_Slot.empty)();
                      var handlerRef = Effect_Ref["new"](handler)();
                      var pendingQueries = Effect_Ref["new"](new Data_Maybe.Just(Data_List_Types.Nil.value))();
                      var pendingOuts = Effect_Ref["new"](new Data_Maybe.Just(Data_List_Types.Nil.value))();
                      var pendingHandlers = Effect_Ref["new"](Data_Maybe.Nothing.value)();
                      var fresh = Effect_Ref["new"](1)();
                      var subscriptions = Effect_Ref["new"](new Data_Maybe.Just(Data_Map_Internal.empty))();
                      var forks = Effect_Ref["new"](Data_Map_Internal.empty)();
                      var ds = {
                          component: component,
                          state: component.initialState(input),
                          refs: Data_Map_Internal.empty,
                          children: Halogen_Data_Slot.empty,
                          childrenIn: childrenIn,
                          childrenOut: childrenOut,
                          selfRef: selfRef,
                          handlerRef: handlerRef,
                          pendingQueries: pendingQueries,
                          pendingOuts: pendingOuts,
                          pendingHandlers: pendingHandlers,
                          rendering: Data_Maybe.Nothing.value,
                          fresh: fresh,
                          subscriptions: subscriptions,
                          forks: forks,
                          lifecycleHandlers: lchs
                      };
                      Effect_Ref.write(ds)(selfRef)();
                      return mkDriverStateXRef(selfRef);
                  };
              };
          };
      };
  };
  exports["mapDriverState"] = mapDriverState;
  exports["unDriverStateX"] = unDriverStateX;
  exports["renderStateX"] = renderStateX;
  exports["renderStateX_"] = renderStateX_;
  exports["unRenderStateX"] = unRenderStateX;
  exports["initDriverState"] = initDriverState;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.Query.ChildQuery"] = $PS["Halogen.Query.ChildQuery"] || {};
  var exports = $PS["Halogen.Query.ChildQuery"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var unChildQueryBox = Unsafe_Coerce.unsafeCoerce;
  exports["unChildQueryBox"] = unChildQueryBox;
})(PS);
(function(exports) {
  "use strict";

  exports.reallyUnsafeRefEq = function (a) {
    return function (b) {
      return a === b;
    };
  };
})(PS["Unsafe.Reference"] = PS["Unsafe.Reference"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Unsafe.Reference"] = $PS["Unsafe.Reference"] || {};
  var exports = $PS["Unsafe.Reference"];
  var $foreign = $PS["Unsafe.Reference"];
  var unsafeRefEq = $foreign.reallyUnsafeRefEq;
  exports["unsafeRefEq"] = unsafeRefEq;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.Subscription"] = $PS["Halogen.Subscription"] || {};
  var exports = $PS["Halogen.Subscription"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Array = $PS["Data.Array"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Effect = $PS["Effect"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Unsafe_Reference = $PS["Unsafe.Reference"];
  var unsubscribe = function (v) {
      return v;
  };
  var subscribe = function (v) {
      return function (k) {
          return v((function () {
              var $55 = Data_Functor["void"](Effect.functorEffect);
              return function ($56) {
                  return $55(k($56));
              };
          })());
      };
  };                                                                               
  var notify = function (v) {
      return function (a) {
          return v(a);
      };
  };
  var create = function __do() {
      var subscribers = Effect_Ref["new"]([  ])();
      return {
          emitter: function (k) {
              return function __do() {
                  Effect_Ref.modify_(function (v) {
                      return Data_Semigroup.append(Data_Semigroup.semigroupArray)(v)([ k ]);
                  })(subscribers)();
                  return Effect_Ref.modify_(Data_Array.deleteBy(Unsafe_Reference.unsafeRefEq)(k))(subscribers);
              };
          },
          listener: function (a) {
              return Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read(subscribers))(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableArray)(function (k) {
                  return k(a);
              }));
          }
      };
  };
  exports["create"] = create;
  exports["notify"] = notify;
  exports["subscribe"] = subscribe;
  exports["unsubscribe"] = unsubscribe;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.Aff.Driver.Eval"] = $PS["Halogen.Aff.Driver.Eval"] || {};
  var exports = $PS["Halogen.Aff.Driver.Eval"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Applicative_Free = $PS["Control.Applicative.Free"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad = $PS["Control.Monad"];
  var Control_Monad_Fork_Class = $PS["Control.Monad.Fork.Class"];
  var Control_Monad_Free = $PS["Control.Monad.Free"];
  var Control_Parallel = $PS["Control.Parallel"];
  var Control_Parallel_Class = $PS["Control.Parallel.Class"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Coyoneda = $PS["Data.Coyoneda"];
  var Data_Either = $PS["Data.Either"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Aff_Driver_State = $PS["Halogen.Aff.Driver.State"];
  var Halogen_Query_ChildQuery = $PS["Halogen.Query.ChildQuery"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Halogen_Query_HalogenQ = $PS["Halogen.Query.HalogenQ"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var Halogen_Subscription = $PS["Halogen.Subscription"];
  var Unsafe_Reference = $PS["Unsafe.Reference"];                
  var unsubscribe = function (sid) {
      return function (ref) {
          return function __do() {
              var v = Effect_Ref.read(ref)();
              var subs = Effect_Ref.read(v.subscriptions)();
              return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Halogen_Subscription.unsubscribe)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Map_Internal.lookup(Halogen_Query_HalogenM.ordSubscriptionId)(sid))(subs))();
          };
      };
  };
  var queueOrRun = function (ref) {
      return function (au) {
          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
              if (v instanceof Data_Maybe.Nothing) {
                  return au;
              };
              if (v instanceof Data_Maybe.Just) {
                  return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.write(new Data_Maybe.Just(new Data_List_Types.Cons(au, v.value0)))(ref));
              };
              throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 182, column 33 - line 184, column 57): " + [ v.constructor.name ]);
          });
      };
  };
  var handleLifecycle = function (lchs) {
      return function (f) {
          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.write({
              initializers: Data_List_Types.Nil.value,
              finalizers: Data_List_Types.Nil.value
          })(lchs)))(function () {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(f))(function (result) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(lchs)))(function (v) {
                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff))(v.finalizers))(function () {
                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Parallel.parSequence_(Effect_Aff.parallelAff)(Data_List_Types.foldableList)(v.initializers))(function () {
                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(result);
                          });
                      });
                  });
              });
          });
      };
  };
  var handleAff = Effect_Aff.runAff_(Data_Either.either(Effect_Exception.throwException)(Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))));
  var fresh = function (f) {
      return function (ref) {
          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
              return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["modify'"](function (i) {
                  return {
                      state: i + 1 | 0,
                      value: f(i)
                  };
              })(v.fresh));
          });
      };
  };
  var evalQ = function (render) {
      return function (ref) {
          return function (q) {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v) {
                  return evalM(render)(ref)(v["component"]["eval"](new Halogen_Query_HalogenQ.Query(Data_Functor.map(Data_Coyoneda.functorCoyoneda)(Data_Maybe.Just.create)(Data_Coyoneda.liftCoyoneda(q)), Data_Function["const"](Data_Maybe.Nothing.value))));
              });
          };
      };
  };
  var evalM = function (render) {
      return function (initRef) {
          return function (v) {
              var evalChildQuery = function (ref) {
                  return function (cqb) {
                      return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v1) {
                          return Halogen_Query_ChildQuery.unChildQueryBox(function (v2) {
                              var evalChild = function (v3) {
                                  return Control_Parallel_Class.parallel(Effect_Aff.parallelAff)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v3)))(function (dsx) {
                                      return Halogen_Aff_Driver_State.unDriverStateX(function (ds) {
                                          return evalQ(render)(ds.selfRef)(v2.value1);
                                      })(dsx);
                                  }));
                              };
                              return Data_Functor.map(Effect_Aff.functorAff)(v2.value2)(Control_Parallel_Class.sequential(Effect_Aff.parallelAff)(v2.value0(Effect_Aff.applicativeParAff)(evalChild)(v1.children)));
                          })(cqb);
                      });
                  };
              };
              var go = function (ref) {
                  return function (v1) {
                      if (v1 instanceof Halogen_Query_HalogenM.State) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              var v3 = v1.value0(v2.state);
                              if (Unsafe_Reference.unsafeRefEq(v2.state)(v3.value1)) {
                                  return Control_Applicative.pure(Effect_Aff.applicativeAff)(v3.value0);
                              };
                              if (Data_Boolean.otherwise) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.write({
                                      component: v2.component,
                                      state: v3.value1,
                                      refs: v2.refs,
                                      children: v2.children,
                                      childrenIn: v2.childrenIn,
                                      childrenOut: v2.childrenOut,
                                      selfRef: v2.selfRef,
                                      handlerRef: v2.handlerRef,
                                      pendingQueries: v2.pendingQueries,
                                      pendingOuts: v2.pendingOuts,
                                      pendingHandlers: v2.pendingHandlers,
                                      rendering: v2.rendering,
                                      fresh: v2.fresh,
                                      subscriptions: v2.subscriptions,
                                      forks: v2.forks,
                                      lifecycleHandlers: v2.lifecycleHandlers
                                  })(ref)))(function () {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleLifecycle(v2.lifecycleHandlers)(render(v2.lifecycleHandlers)(ref)))(function () {
                                          return Control_Applicative.pure(Effect_Aff.applicativeAff)(v3.value0);
                                      });
                                  });
                              };
                              throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 86, column 7 - line 92, column 21): " + [ v3.constructor.name ]);
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Subscribe) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(fresh(Halogen_Query_HalogenM.SubscriptionId)(ref))(function (sid) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Halogen_Subscription.subscribe(v1.value0(sid))(function (act) {
                                  return handleAff(evalF(render)(ref)(new Halogen_Query_Input.Action(act)));
                              })))(function (finalize) {
                                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.modify_(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Map_Internal.insert(Halogen_Query_HalogenM.ordSubscriptionId)(sid)(finalize)))(v2.subscriptions)))(function () {
                                          return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1(sid));
                                      });
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Unsubscribe) {
                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(unsubscribe(v1.value0)(ref)))(function () {
                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1);
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Lift) {
                          return v1.value0;
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.ChildQuery) {
                          return evalChildQuery(ref)(v1.value0);
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Raise) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v2.handlerRef)))(function (handler) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(queueOrRun(v2.pendingOuts)(handler(v1.value0)))(function () {
                                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1);
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Par) {
                          return Control_Parallel_Class.sequential(Effect_Aff.parallelAff)(Control_Applicative_Free.retractFreeAp(Effect_Aff.applicativeParAff)(Control_Applicative_Free.hoistFreeAp((function () {
                              var $79 = Control_Parallel_Class.parallel(Effect_Aff.parallelAff);
                              var $80 = evalM(render)(ref);
                              return function ($81) {
                                  return $79($80($81));
                              };
                          })())(v1.value0)));
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Fork) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(fresh(Halogen_Query_HalogenM.ForkId)(ref))(function (fid) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["new"](false)))(function (doneRef) {
                                      return Control_Bind.bind(Effect_Aff.bindAff)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff)(Effect_Aff["finally"](Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                                          Effect_Ref.modify_(Data_Map_Internal["delete"](Halogen_Query_HalogenM.ordForkId)(fid))(v2.forks)();
                                          return Effect_Ref.write(true)(doneRef)();
                                      }))(evalM(render)(ref)(v1.value0))))(function (fiber) {
                                          return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Control_Monad.unlessM(Effect.monadEffect)(Effect_Ref.read(doneRef))(Effect_Ref.modify_(Data_Map_Internal.insert(Halogen_Query_HalogenM.ordForkId)(fid)(fiber))(v2.forks))))(function () {
                                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1(fid));
                                          });
                                      });
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.Kill) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(v2.forks)))(function (forkMap) {
                                  return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_Foldable.foldableMaybe)(Effect_Aff.killFiber(Effect_Exception.error("Cancelled")))(Data_Map_Internal.lookup(Halogen_Query_HalogenM.ordForkId)(v1.value0)(forkMap)))(function () {
                                      return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1);
                                  });
                              });
                          });
                      };
                      if (v1 instanceof Halogen_Query_HalogenM.GetRef) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v2) {
                              return Control_Applicative.pure(Effect_Aff.applicativeAff)(v1.value1(Data_Map_Internal.lookup(Data_Ord.ordString)(v1.value0)(v2.refs)));
                          });
                      };
                      throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 83, column 12 - line 133, column 33): " + [ v1.constructor.name ]);
                  };
              };
              return Control_Monad_Free.foldFree(Effect_Aff.monadRecAff)(go(initRef))(v);
          };
      };
  };
  var evalF = function (render) {
      return function (ref) {
          return function (v) {
              if (v instanceof Halogen_Query_Input.RefUpdate) {
                  return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Function.flip(Effect_Ref.modify_)(ref)(Halogen_Aff_Driver_State.mapDriverState(function (st) {
                      return {
                          component: st.component,
                          state: st.state,
                          refs: Data_Map_Internal.alter(Data_Ord.ordString)(Data_Function["const"](v.value1))(v.value0)(st.refs),
                          children: st.children,
                          childrenIn: st.childrenIn,
                          childrenOut: st.childrenOut,
                          selfRef: st.selfRef,
                          handlerRef: st.handlerRef,
                          pendingQueries: st.pendingQueries,
                          pendingOuts: st.pendingOuts,
                          pendingHandlers: st.pendingHandlers,
                          rendering: st.rendering,
                          fresh: st.fresh,
                          subscriptions: st.subscriptions,
                          forks: st.forks,
                          lifecycleHandlers: st.lifecycleHandlers
                      };
                  })));
              };
              if (v instanceof Halogen_Query_Input.Action) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(ref)))(function (v1) {
                      return evalM(render)(ref)(v1["component"]["eval"](new Halogen_Query_HalogenQ.Action(v.value0, Data_Unit.unit)));
                  });
              };
              throw new Error("Failed pattern match at Halogen.Aff.Driver.Eval (line 52, column 20 - line 58, column 62): " + [ v.constructor.name ]);
          };
      };
  };
  exports["evalF"] = evalF;
  exports["evalQ"] = evalQ;
  exports["evalM"] = evalM;
  exports["handleLifecycle"] = handleLifecycle;
  exports["queueOrRun"] = queueOrRun;
  exports["handleAff"] = handleAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.Aff.Driver"] = $PS["Halogen.Aff.Driver"] || {};
  var exports = $PS["Halogen.Aff.Driver"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Control_Monad_Fork_Class = $PS["Control.Monad.Fork.Class"];
  var Control_Monad_Rec_Class = $PS["Control.Monad.Rec.Class"];
  var Control_Parallel = $PS["Control.Parallel"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List = $PS["Data.List"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Console = $PS["Effect.Console"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Aff_Driver_Eval = $PS["Halogen.Aff.Driver.Eval"];
  var Halogen_Aff_Driver_State = $PS["Halogen.Aff.Driver.State"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_Data_Slot = $PS["Halogen.Data.Slot"];
  var Halogen_Query_HalogenQ = $PS["Halogen.Query.HalogenQ"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var Halogen_Subscription = $PS["Halogen.Subscription"];                
  var newLifecycleHandlers = Effect_Ref["new"]({
      initializers: Data_List_Types.Nil.value,
      finalizers: Data_List_Types.Nil.value
  });
  var handlePending = function (ref) {
      return function __do() {
          var queue = Effect_Ref.read(ref)();
          Effect_Ref.write(Data_Maybe.Nothing.value)(ref)();
          return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(queue)((function () {
              var $28 = Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff));
              return function ($29) {
                  return Halogen_Aff_Driver_Eval.handleAff($28(Data_List.reverse($29)));
              };
          })())();
      };
  };
  var cleanupSubscriptionsAndForks = function (v) {
      return function __do() {
          Control_Bind.bindFlipped(Effect.bindEffect)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Map_Internal.foldableMap)(Halogen_Subscription.unsubscribe)))(Effect_Ref.read(v.subscriptions))();
          Effect_Ref.write(Data_Maybe.Nothing.value)(v.subscriptions)();
          Control_Bind.bindFlipped(Effect.bindEffect)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Map_Internal.foldableMap)((function () {
              var $30 = Effect_Aff.killFiber(Effect_Exception.error("finalized"));
              return function ($31) {
                  return Halogen_Aff_Driver_Eval.handleAff($30($31));
              };
          })()))(Effect_Ref.read(v.forks))();
          return Effect_Ref.write(Data_Map_Internal.empty)(v.forks)();
      };
  };
  var runUI = function (renderSpec) {
      return function (component) {
          return function (i) {
              var squashChildInitializers = function (lchs) {
                  return function (preInits) {
                      return Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                          var parentInitializer = Halogen_Aff_Driver_Eval.evalM(render)(st.selfRef)(st["component"]["eval"](new Halogen_Query_HalogenQ.Initialize(Data_Unit.unit)));
                          return Effect_Ref.modify_(function (handlers) {
                              return {
                                  initializers: new Data_List_Types.Cons(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Control_Parallel.parSequence_(Effect_Aff.parallelAff)(Data_List_Types.foldableList)(Data_List.reverse(handlers.initializers)))(function () {
                                      return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(parentInitializer)(function () {
                                          return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                                              handlePending(st.pendingQueries)();
                                              return handlePending(st.pendingOuts)();
                                          });
                                      });
                                  }), preInits),
                                  finalizers: handlers.finalizers
                              };
                          })(lchs);
                      });
                  };
              };
              var runComponent = function (lchs) {
                  return function (handler) {
                      return function (j) {
                          return Halogen_Component.unComponent(function (c) {
                              return function __do() {
                                  var lchs$prime = newLifecycleHandlers();
                                  var $$var = Halogen_Aff_Driver_State.initDriverState(c)(j)(handler)(lchs$prime)();
                                  var pre = Effect_Ref.read(lchs)();
                                  Effect_Ref.write({
                                      initializers: Data_List_Types.Nil.value,
                                      finalizers: pre.finalizers
                                  })(lchs)();
                                  Control_Bind.bindFlipped(Effect.bindEffect)(Halogen_Aff_Driver_State.unDriverStateX((function () {
                                      var $32 = render(lchs);
                                      return function ($33) {
                                          return $32((function (v) {
                                              return v.selfRef;
                                          })($33));
                                      };
                                  })()))(Effect_Ref.read($$var))();
                                  Control_Bind.bindFlipped(Effect.bindEffect)(squashChildInitializers(lchs)(pre.initializers))(Effect_Ref.read($$var))();
                                  return $$var;
                              };
                          });
                      };
                  };
              };
              var renderChild = function (lchs) {
                  return function (handler) {
                      return function (childrenInRef) {
                          return function (childrenOutRef) {
                              return Halogen_Component.unComponentSlot(function (slot) {
                                  return function __do() {
                                      var childrenIn = Data_Functor.map(Effect.functorEffect)(slot.pop)(Effect_Ref.read(childrenInRef))();
                                      var $$var = (function () {
                                          if (childrenIn instanceof Data_Maybe.Just) {
                                              Effect_Ref.write(childrenIn.value0.value1)(childrenInRef)();
                                              var dsx = Effect_Ref.read(childrenIn.value0.value0)();
                                              Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                                                  return function __do() {
                                                      Data_Function.flip(Effect_Ref.write)(st.handlerRef)((function () {
                                                          var $34 = Data_Maybe.maybe(Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit))(handler);
                                                          return function ($35) {
                                                              return $34(slot.output($35));
                                                          };
                                                      })())();
                                                      return Halogen_Aff_Driver_Eval.handleAff(Halogen_Aff_Driver_Eval.evalM(render)(st.selfRef)(st["component"]["eval"](new Halogen_Query_HalogenQ.Receive(slot.input, Data_Unit.unit))))();
                                                  };
                                              })(dsx)();
                                              return childrenIn.value0.value0;
                                          };
                                          if (childrenIn instanceof Data_Maybe.Nothing) {
                                              return runComponent(lchs)((function () {
                                                  var $36 = Data_Maybe.maybe(Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit))(handler);
                                                  return function ($37) {
                                                      return $36(slot.output($37));
                                                  };
                                              })())(slot.input)(slot.component)();
                                          };
                                          throw new Error("Failed pattern match at Halogen.Aff.Driver (line 211, column 14 - line 220, column 98): " + [ childrenIn.constructor.name ]);
                                      })();
                                      var isDuplicate = Data_Functor.map(Effect.functorEffect)(function ($38) {
                                          return Data_Maybe.isJust(slot.get($38));
                                      })(Effect_Ref.read(childrenOutRef))();
                                      Control_Applicative.when(Effect.applicativeEffect)(isDuplicate)(Effect_Console.warn("Halogen: Duplicate slot address was detected during rendering, unexpected results may occur"))();
                                      Effect_Ref.modify_(slot.set($$var))(childrenOutRef)();
                                      return Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read($$var))(Halogen_Aff_Driver_State.renderStateX(Effect.functorEffect)(function (v) {
                                          if (v instanceof Data_Maybe.Nothing) {
                                              return Effect_Exception["throw"]("Halogen internal error: child was not initialized in renderChild");
                                          };
                                          if (v instanceof Data_Maybe.Just) {
                                              return Control_Applicative.pure(Effect.applicativeEffect)(renderSpec.renderChild(v.value0));
                                          };
                                          throw new Error("Failed pattern match at Halogen.Aff.Driver (line 225, column 37 - line 227, column 50): " + [ v.constructor.name ]);
                                      }))();
                                  };
                              });
                          };
                      };
                  };
              };
              var render = function (lchs) {
                  return function ($$var) {
                      return function __do() {
                          var v = Effect_Ref.read($$var)();
                          var shouldProcessHandlers = Data_Functor.map(Effect.functorEffect)(Data_Maybe.isNothing)(Effect_Ref.read(v.pendingHandlers))();
                          Control_Applicative.when(Effect.applicativeEffect)(shouldProcessHandlers)(Effect_Ref.write(new Data_Maybe.Just(Data_List_Types.Nil.value))(v.pendingHandlers))();
                          Effect_Ref.write(Halogen_Data_Slot.empty)(v.childrenOut)();
                          Effect_Ref.write(v.children)(v.childrenIn)();
                          var selfRef = Control_Category.identity(Control_Category.categoryFn)(v.selfRef);
                          var pendingQueries = Control_Category.identity(Control_Category.categoryFn)(v.pendingQueries);
                          var pendingHandlers = Control_Category.identity(Control_Category.categoryFn)(v.pendingHandlers);
                          var handler = (function () {
                              var $39 = Halogen_Aff_Driver_Eval.queueOrRun(pendingHandlers);
                              var $40 = Data_Functor["void"](Effect_Aff.functorAff);
                              var $41 = Halogen_Aff_Driver_Eval.evalF(render)(selfRef);
                              return function ($42) {
                                  return $39($40($41($42)));
                              };
                          })();
                          var childHandler = (function () {
                              var $43 = Halogen_Aff_Driver_Eval.queueOrRun(pendingQueries);
                              return function ($44) {
                                  return $43(handler(Halogen_Query_Input.Action.create($44)));
                              };
                          })();
                          var rendering = renderSpec.render(function ($45) {
                              return Halogen_Aff_Driver_Eval.handleAff(handler($45));
                          })(renderChild(lchs)(childHandler)(v.childrenIn)(v.childrenOut))(v.component.render(v.state))(v.rendering)();
                          var children = Effect_Ref.read(v.childrenOut)();
                          var childrenIn = Effect_Ref.read(v.childrenIn)();
                          Halogen_Data_Slot.foreachSlot(Effect.applicativeEffect)(childrenIn)(function (v1) {
                              return function __do() {
                                  var childDS = Effect_Ref.read(v1)();
                                  Halogen_Aff_Driver_State.renderStateX_(Effect.applicativeEffect)(renderSpec.removeChild)(childDS)();
                                  return finalize(lchs)(childDS)();
                              };
                          })();
                          Data_Function.flip(Effect_Ref.modify_)(v.selfRef)(Halogen_Aff_Driver_State.mapDriverState(function (ds$prime) {
                              return {
                                  component: ds$prime.component,
                                  state: ds$prime.state,
                                  refs: ds$prime.refs,
                                  children: children,
                                  childrenIn: ds$prime.childrenIn,
                                  childrenOut: ds$prime.childrenOut,
                                  selfRef: ds$prime.selfRef,
                                  handlerRef: ds$prime.handlerRef,
                                  pendingQueries: ds$prime.pendingQueries,
                                  pendingOuts: ds$prime.pendingOuts,
                                  pendingHandlers: ds$prime.pendingHandlers,
                                  rendering: new Data_Maybe.Just(rendering),
                                  fresh: ds$prime.fresh,
                                  subscriptions: ds$prime.subscriptions,
                                  forks: ds$prime.forks,
                                  lifecycleHandlers: ds$prime.lifecycleHandlers
                              };
                          }))();
                          return Control_Applicative.when(Effect.applicativeEffect)(shouldProcessHandlers)(Data_Function.flip(Control_Monad_Rec_Class.tailRecM(Control_Monad_Rec_Class.monadRecEffect))(Data_Unit.unit)(function (v1) {
                              return function __do() {
                                  var handlers = Effect_Ref.read(pendingHandlers)();
                                  Effect_Ref.write(new Data_Maybe.Just(Data_List_Types.Nil.value))(pendingHandlers)();
                                  Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)((function () {
                                      var $46 = Data_Foldable.traverse_(Effect_Aff.applicativeAff)(Data_List_Types.foldableList)(Control_Monad_Fork_Class.fork(Control_Monad_Fork_Class.monadForkAff));
                                      return function ($47) {
                                          return Halogen_Aff_Driver_Eval.handleAff($46(Data_List.reverse($47)));
                                      };
                                  })())(handlers)();
                                  var mmore = Effect_Ref.read(pendingHandlers)();
                                  var $21 = Data_Maybe.maybe(false)(Data_List["null"])(mmore);
                                  if ($21) {
                                      return Data_Functor.voidLeft(Effect.functorEffect)(Effect_Ref.write(Data_Maybe.Nothing.value)(pendingHandlers))(new Control_Monad_Rec_Class.Done(Data_Unit.unit))();
                                  };
                                  return new Control_Monad_Rec_Class.Loop(Data_Unit.unit);
                              };
                          }))();
                      };
                  };
              };
              var finalize = function (lchs) {
                  return Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                      return function __do() {
                          cleanupSubscriptionsAndForks(st)();
                          var f = Halogen_Aff_Driver_Eval.evalM(render)(st.selfRef)(st["component"]["eval"](new Halogen_Query_HalogenQ.Finalize(Data_Unit.unit)));
                          Effect_Ref.modify_(function (handlers) {
                              return {
                                  initializers: handlers.initializers,
                                  finalizers: new Data_List_Types.Cons(f, handlers.finalizers)
                              };
                          })(lchs)();
                          return Halogen_Data_Slot.foreachSlot(Effect.applicativeEffect)(st.children)(function (v) {
                              return function __do() {
                                  var dsx = Effect_Ref.read(v)();
                                  return finalize(lchs)(dsx)();
                              };
                          })();
                      };
                  });
              };
              var evalDriver = function (disposed) {
                  return function (ref) {
                      return function (q) {
                          return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref.read(disposed)))(function (v) {
                              if (v) {
                                  return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Maybe.Nothing.value);
                              };
                              return Halogen_Aff_Driver_Eval.evalQ(render)(ref)(q);
                          });
                      };
                  };
              };
              var dispose = function (disposed) {
                  return function (lchs) {
                      return function (dsx) {
                          return Halogen_Aff_Driver_Eval.handleLifecycle(lchs)(function __do() {
                              var v = Effect_Ref.read(disposed)();
                              if (v) {
                                  return Data_Unit.unit;
                              };
                              Effect_Ref.write(true)(disposed)();
                              finalize(lchs)(dsx)();
                              return Halogen_Aff_Driver_State.unDriverStateX(function (v1) {
                                  return function __do() {
                                      var v2 = Effect_Class.liftEffect(Effect_Class.monadEffectEffect)(Effect_Ref.read(v1.selfRef))();
                                      return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(v2.rendering)(renderSpec.dispose)();
                                  };
                              })(dsx)();
                          });
                      };
                  };
              };
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(newLifecycleHandlers))(function (lchs) {
                  return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["new"](0)))(function (fresh) {
                      return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Effect_Ref["new"](false)))(function (disposed) {
                          return Halogen_Aff_Driver_Eval.handleLifecycle(lchs)(function __do() {
                              var sio = Halogen_Subscription.create();
                              var dsx = Control_Bind.bindFlipped(Effect.bindEffect)(Effect_Ref.read)(runComponent(lchs)((function () {
                                  var $48 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
                                  var $49 = Halogen_Subscription.notify(sio.listener);
                                  return function ($50) {
                                      return $48($49($50));
                                  };
                              })())(i)(component))();
                              return Halogen_Aff_Driver_State.unDriverStateX(function (st) {
                                  return Control_Applicative.pure(Effect.applicativeEffect)({
                                      query: evalDriver(disposed)(st.selfRef),
                                      messages: sio.emitter,
                                      dispose: dispose(disposed)(lchs)(dsx)
                                  });
                              })(dsx)();
                          });
                      });
                  });
              });
          };
      };
  };
  exports["runUI"] = runUI;
})(PS);
(function(exports) {
  "use strict";                                               

  exports._querySelector = function (selector) {
    return function (node) {
      return function () {
        return node.querySelector(selector);
      };
    };
  };
})(PS["Web.DOM.ParentNode"] = PS["Web.DOM.ParentNode"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Web.DOM.ParentNode"] = $PS["Web.DOM.ParentNode"] || {};
  var exports = $PS["Web.DOM.ParentNode"];
  var $foreign = $PS["Web.DOM.ParentNode"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Effect = $PS["Effect"];
  var querySelector = function (qs) {
      var $0 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      var $1 = $foreign["_querySelector"](qs);
      return function ($2) {
          return $0($1($2));
      };
  };
  exports["querySelector"] = querySelector;
})(PS);
(function(exports) {
  "use strict";

  exports.window = function () {
    return window;
  };
})(PS["Web.HTML"] = PS["Web.HTML"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Web.HTML"] = $PS["Web.HTML"] || {};
  var exports = $PS["Web.HTML"];
  var $foreign = $PS["Web.HTML"];
  exports["window"] = $foreign.window;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Web.HTML.Event.EventTypes"] = $PS["Web.HTML.Event.EventTypes"] || {};
  var exports = $PS["Web.HTML.Event.EventTypes"];
  var input = "input";
  var domcontentloaded = "DOMContentLoaded";
  exports["domcontentloaded"] = domcontentloaded;
  exports["input"] = input;
})(PS);
(function(exports) {
  "use strict";

  exports._readyState = function (doc) {
    return function () {
      return doc.readyState;
    };
  };
})(PS["Web.HTML.HTMLDocument"] = PS["Web.HTML.HTMLDocument"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Web.HTML.HTMLDocument.ReadyState"] = $PS["Web.HTML.HTMLDocument.ReadyState"] || {};
  var exports = $PS["Web.HTML.HTMLDocument.ReadyState"];
  var Data_Maybe = $PS["Data.Maybe"];              
  var Loading = (function () {
      function Loading() {

      };
      Loading.value = new Loading();
      return Loading;
  })();
  var Interactive = (function () {
      function Interactive() {

      };
      Interactive.value = new Interactive();
      return Interactive;
  })();
  var Complete = (function () {
      function Complete() {

      };
      Complete.value = new Complete();
      return Complete;
  })();
  var parse = function (v) {
      if (v === "loading") {
          return new Data_Maybe.Just(Loading.value);
      };
      if (v === "interactive") {
          return new Data_Maybe.Just(Interactive.value);
      };
      if (v === "complete") {
          return new Data_Maybe.Just(Complete.value);
      };
      return Data_Maybe.Nothing.value;
  };
  exports["Loading"] = Loading;
  exports["parse"] = parse;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Web.HTML.HTMLDocument"] = $PS["Web.HTML.HTMLDocument"] || {};
  var exports = $PS["Web.HTML.HTMLDocument"];
  var $foreign = $PS["Web.HTML.HTMLDocument"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Effect = $PS["Effect"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Web_HTML_HTMLDocument_ReadyState = $PS["Web.HTML.HTMLDocument.ReadyState"];
  var toParentNode = Unsafe_Coerce.unsafeCoerce; 
  var toDocument = Unsafe_Coerce.unsafeCoerce;
  var readyState = (function () {
      var $0 = Data_Functor.map(Effect.functorEffect)((function () {
          var $2 = Data_Maybe.fromMaybe(Web_HTML_HTMLDocument_ReadyState.Loading.value);
          return function ($3) {
              return $2(Web_HTML_HTMLDocument_ReadyState.parse($3));
          };
      })());
      return function ($1) {
          return $0($foreign["_readyState"]($1));
      };
  })();
  exports["toDocument"] = toDocument;
  exports["toParentNode"] = toParentNode;
  exports["readyState"] = readyState;
})(PS);
(function(exports) {
  "use strict";

  exports._read = function (nothing, just, value) {
    var tag = Object.prototype.toString.call(value);
    if (tag.indexOf("[object HTML") === 0 && tag.indexOf("Element]") === tag.length - 8) {
      return just(value);
    } else {
      return nothing;
    }
  };
})(PS["Web.HTML.HTMLElement"] = PS["Web.HTML.HTMLElement"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Web.HTML.HTMLElement"] = $PS["Web.HTML.HTMLElement"] || {};
  var exports = $PS["Web.HTML.HTMLElement"];
  var $foreign = $PS["Web.HTML.HTMLElement"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];                   
  var toNode = Unsafe_Coerce.unsafeCoerce;
  var fromElement = function (x) {
      return $foreign["_read"](Data_Maybe.Nothing.value, Data_Maybe.Just.create, x);
  };
  exports["fromElement"] = fromElement;
  exports["toNode"] = toNode;
})(PS);
(function(exports) {
  "use strict";

  exports.document = function (window) {
    return function () {
      return window.document;
    };
  };

  exports.location = function (window) {
    return function () {
      return window.location;
    };
  };
})(PS["Web.HTML.Window"] = PS["Web.HTML.Window"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Web.HTML.Window"] = $PS["Web.HTML.Window"] || {};
  var exports = $PS["Web.HTML.Window"];
  var $foreign = $PS["Web.HTML.Window"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var toEventTarget = Unsafe_Coerce.unsafeCoerce;
  exports["toEventTarget"] = toEventTarget;
  exports["document"] = $foreign.document;
  exports["location"] = $foreign.location;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.Aff.Util"] = $PS["Halogen.Aff.Util"] || {};
  var exports = $PS["Halogen.Aff.Util"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Exception = $PS["Effect.Exception"];
  var Web_DOM_ParentNode = $PS["Web.DOM.ParentNode"];
  var Web_Event_EventTarget = $PS["Web.Event.EventTarget"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_Event_EventTypes = $PS["Web.HTML.Event.EventTypes"];
  var Web_HTML_HTMLDocument = $PS["Web.HTML.HTMLDocument"];
  var Web_HTML_HTMLDocument_ReadyState = $PS["Web.HTML.HTMLDocument.ReadyState"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];                
  var selectElement = function (query) {
      return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Control_Bind.bindFlipped(Effect.bindEffect)(Control_Bind.composeKleisliFlipped(Effect.bindEffect)((function () {
          var $2 = Web_DOM_ParentNode.querySelector(query);
          return function ($3) {
              return $2(Web_HTML_HTMLDocument.toParentNode($3));
          };
      })())(Web_HTML_Window.document))(Web_HTML.window)))(function (mel) {
          return Control_Applicative.pure(Effect_Aff.applicativeAff)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromElement)(mel));
      });
  };
  var runHalogenAff = Effect_Aff.runAff_(Data_Either.either(Effect_Exception.throwException)(Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))));
  var awaitLoad = Effect_Aff.makeAff(function (callback) {
      return function __do() {
          var rs = Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_HTMLDocument.readyState)(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.document)(Web_HTML.window))();
          if (rs instanceof Web_HTML_HTMLDocument_ReadyState.Loading) {
              var et = Data_Functor.map(Effect.functorEffect)(Web_HTML_Window.toEventTarget)(Web_HTML.window)();
              var listener = Web_Event_EventTarget.eventListener(function (v) {
                  return callback(new Data_Either.Right(Data_Unit.unit));
              })();
              Web_Event_EventTarget.addEventListener(Web_HTML_Event_EventTypes.domcontentloaded)(listener)(false)(et)();
              return Effect_Aff.effectCanceler(Web_Event_EventTarget.removeEventListener(Web_HTML_Event_EventTypes.domcontentloaded)(listener)(false)(et));
          };
          callback(new Data_Either.Right(Data_Unit.unit))();
          return Effect_Aff.nonCanceler;
      };
  });
  var awaitBody = Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(awaitLoad)(function () {
      return Control_Bind.bind(Effect_Aff.bindAff)(selectElement("body"))(function (body) {
          return Data_Maybe.maybe(Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("Could not find body")))(Control_Applicative.pure(Effect_Aff.applicativeAff))(body);
      });
  });
  exports["awaitBody"] = awaitBody;
  exports["runHalogenAff"] = runHalogenAff;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.HTML"] = $PS["Halogen.HTML"] || {};
  var exports = $PS["Halogen.HTML"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var slot = function (dictCons) {
      return function (dictIsSymbol) {
          return function (dictOrd) {
              return function (label) {
                  return function (p) {
                      return function (component) {
                          return function (input) {
                              return function (outputQuery) {
                                  return Halogen_HTML_Core.widget(new Halogen_Component.ComponentSlot(Halogen_Component.componentSlot()(dictIsSymbol)(dictOrd)(label)(p)(component)(input)(function ($6) {
                                      return Data_Maybe.Just.create(outputQuery($6));
                                  })));
                              };
                          };
                      };
                  };
              };
          };
      };
  };
  exports["slot"] = slot;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.HTML.Elements"] = $PS["Halogen.HTML.Elements"] || {};
  var exports = $PS["Halogen.HTML.Elements"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var element = Halogen_HTML_Core.element(Data_Maybe.Nothing.value);
  var h1 = element("h1");
  var h1_ = h1([  ]);
  var h2 = element("h2");
  var h2_ = h2([  ]);
  var h3 = element("h3");
  var h3_ = h3([  ]);    
  var header = element("header");
  var header_ = header([  ]);
  var input = function (props) {
      return element("input")(props)([  ]);
  };                   
  var label = element("label");
  var label_ = label([  ]);  
  var li = element("li");
  var li_ = li([  ]);        
  var p = element("p");
  var p_ = p([  ]);
  var ul = element("ul");
  var ul_ = ul([  ]);
  var div = element("div");
  var div_ = div([  ]);
  var button = element("button");
  var button_ = button([  ]);
  var a = element("a");
  exports["a"] = a;
  exports["button"] = button;
  exports["button_"] = button_;
  exports["div_"] = div_;
  exports["h1_"] = h1_;
  exports["h2_"] = h2_;
  exports["h3_"] = h3_;
  exports["header_"] = header_;
  exports["input"] = input;
  exports["label_"] = label_;
  exports["li_"] = li_;
  exports["p_"] = p_;
  exports["ul_"] = ul_;
})(PS);
(function(exports) {
  "use strict";

  exports._currentTarget = function (e) {
    return e.currentTarget;
  };
})(PS["Web.Event.Event"] = PS["Web.Event.Event"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Web.Event.Event"] = $PS["Web.Event.Event"] || {};
  var exports = $PS["Web.Event.Event"];
  var $foreign = $PS["Web.Event.Event"];
  var Data_Nullable = $PS["Data.Nullable"];
  var currentTarget = function ($5) {
      return Data_Nullable.toMaybe($foreign["_currentTarget"]($5));
  };
  exports["currentTarget"] = currentTarget;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Web.UIEvent.MouseEvent.EventTypes"] = $PS["Web.UIEvent.MouseEvent.EventTypes"] || {};
  var exports = $PS["Web.UIEvent.MouseEvent.EventTypes"];
  var click = "click";
  exports["click"] = click;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.HTML.Events"] = $PS["Halogen.HTML.Events"] || {};
  var exports = $PS["Halogen.HTML.Events"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Except = $PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Foreign = $PS["Foreign"];
  var Foreign_Index = $PS["Foreign.Index"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_Query_Input = $PS["Halogen.Query.Input"];
  var Unsafe_Coerce = $PS["Unsafe.Coerce"];
  var Web_Event_Event = $PS["Web.Event.Event"];
  var Web_HTML_Event_EventTypes = $PS["Web.HTML.Event.EventTypes"];
  var Web_UIEvent_MouseEvent_EventTypes = $PS["Web.UIEvent.MouseEvent.EventTypes"];
  var mouseHandler = Unsafe_Coerce.unsafeCoerce;
  var handler$prime = function (et) {
      return function (f) {
          return Halogen_HTML_Core.handler(et)(function (ev) {
              return Data_Functor.map(Data_Maybe.functorMaybe)(Halogen_Query_Input.Action.create)(f(ev));
          });
      };
  };
  var handler = function (et) {
      return function (f) {
          return Halogen_HTML_Core.handler(et)(function (ev) {
              return new Data_Maybe.Just(new Halogen_Query_Input.Action(f(ev)));
          });
      };
  };                                                       
  var onClick = (function () {
      var $1 = handler(Web_UIEvent_MouseEvent_EventTypes.click);
      return function ($2) {
          return $1(mouseHandler($2));
      };
  })();
  var addForeignPropHandler = function (key) {
      return function (prop) {
          return function (reader) {
              return function (f) {
                  var go = function (a) {
                      return Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(reader)(Foreign_Index.readProp(Data_Identity.monadIdentity)(prop))(Foreign.unsafeToForeign(a));
                  };
                  return handler$prime(key)(Control_Bind.composeKleisli(Data_Maybe.bindMaybe)(Web_Event_Event.currentTarget)(function (e) {
                      return Data_Either.either(Data_Function["const"](Data_Maybe.Nothing.value))(function ($71) {
                          return Data_Maybe.Just.create(f($71));
                      })(Control_Monad_Except.runExcept(go(e)));
                  }));
              };
          };
      };
  };                                                                                                                                    
  var onValueInput = addForeignPropHandler(Web_HTML_Event_EventTypes.input)("value")(Foreign.readString(Data_Identity.monadIdentity));
  exports["onClick"] = onClick;
  exports["onValueInput"] = onValueInput;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.HTML.Properties"] = $PS["Halogen.HTML.Properties"] || {};
  var exports = $PS["Halogen.HTML.Properties"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var prop = function (dictIsProp) {
      return Halogen_HTML_Core.prop(dictIsProp);
  };                                                        
  var type_ = function (dictIsProp) {
      return prop(dictIsProp)("type");
  };
  var value = prop(Halogen_HTML_Core.isPropString)("value");       
  var min = prop(Halogen_HTML_Core.isPropNumber)("min");
  var href = prop(Halogen_HTML_Core.isPropString)("href");        
  var disabled = prop(Halogen_HTML_Core.isPropBoolean)("disabled");
  exports["href"] = href;
  exports["type_"] = type_;
  exports["value"] = value;
  exports["min"] = min;
  exports["disabled"] = disabled;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.Query"] = $PS["Halogen.Query"] || {};
  var exports = $PS["Halogen.Query"];
  var Data_Unit = $PS["Data.Unit"];
  var mkTell = function (act) {
      return act(Data_Unit.unit);
  };
  exports["mkTell"] = mkTell;
})(PS);
(function(exports) {
  "use strict";

  var getEffProp = function (name) {
    return function (node) {
      return function () {
        return node[name];
      };
    };
  };                                                   

  exports._parentNode = getEffProp("parentNode");          

  exports._nextSibling = getEffProp("nextSibling");

  exports.insertBefore = function (node1) {
    return function (node2) {
      return function (parent) {
        return function () {
          parent.insertBefore(node1, node2);
        };
      };
    };
  };

  exports.appendChild = function (node) {
    return function (parent) {
      return function () {
        parent.appendChild(node);
      };
    };
  };

  exports.removeChild = function (node) {
    return function (parent) {
      return function () {
        parent.removeChild(node);
      };
    };
  };
})(PS["Web.DOM.Node"] = PS["Web.DOM.Node"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Web.DOM.Node"] = $PS["Web.DOM.Node"] || {};
  var exports = $PS["Web.DOM.Node"];
  var $foreign = $PS["Web.DOM.Node"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Effect = $PS["Effect"];
  var parentNode = (function () {
      var $3 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      return function ($4) {
          return $3($foreign["_parentNode"]($4));
      };
  })();
  var nextSibling = (function () {
      var $14 = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe);
      return function ($15) {
          return $14($foreign["_nextSibling"]($15));
      };
  })();
  exports["parentNode"] = parentNode;
  exports["nextSibling"] = nextSibling;
  exports["insertBefore"] = $foreign.insertBefore;
  exports["appendChild"] = $foreign.appendChild;
  exports["removeChild"] = $foreign.removeChild;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Halogen.VDom.Driver"] = $PS["Halogen.VDom.Driver"] || {};
  var exports = $PS["Halogen.VDom.Driver"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Category = $PS["Control.Category"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Halogen_Aff_Driver = $PS["Halogen.Aff.Driver"];
  var Halogen_Aff_Driver_State = $PS["Halogen.Aff.Driver.State"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_VDom_DOM = $PS["Halogen.VDom.DOM"];
  var Halogen_VDom_DOM_Prop = $PS["Halogen.VDom.DOM.Prop"];
  var Halogen_VDom_Machine = $PS["Halogen.VDom.Machine"];
  var Halogen_VDom_Thunk = $PS["Halogen.VDom.Thunk"];
  var Unsafe_Reference = $PS["Unsafe.Reference"];
  var Web_DOM_Node = $PS["Web.DOM.Node"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_HTMLDocument = $PS["Web.HTML.HTMLDocument"];
  var Web_HTML_HTMLElement = $PS["Web.HTML.HTMLElement"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];
  var substInParent = function (v) {
      return function (v1) {
          return function (v2) {
              if (v1 instanceof Data_Maybe.Just && v2 instanceof Data_Maybe.Just) {
                  return Data_Functor["void"](Effect.functorEffect)(Web_DOM_Node.insertBefore(v)(v1.value0)(v2.value0));
              };
              if (v1 instanceof Data_Maybe.Nothing && v2 instanceof Data_Maybe.Just) {
                  return Data_Functor["void"](Effect.functorEffect)(Web_DOM_Node.appendChild(v)(v2.value0));
              };
              return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
          };
      };
  };
  var removeChild = function (v) {
      return function __do() {
          var npn = Web_DOM_Node.parentNode(v.node)();
          return Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(function (pn) {
              return Web_DOM_Node.removeChild(v.node)(pn);
          })(npn)();
      };
  };
  var mkSpec = function (handler) {
      return function (renderChildRef) {
          return function (document) {
              var getNode = Halogen_Aff_Driver_State.unRenderStateX(function (v) {
                  return v.node;
              });
              var done = function (st) {
                  if (st instanceof Data_Maybe.Just) {
                      return Halogen_VDom_Machine.halt(st.value0);
                  };
                  return Data_Unit.unit;
              };
              var buildWidget = function (spec) {
                  var buildThunk = Halogen_VDom_Thunk.buildThunk(Data_Newtype.unwrap())(spec);
                  var renderComponentSlot = function (cs) {
                      var renderChild = Effect_Ref.read(renderChildRef)();
                      var rsx = renderChild(cs)();
                      var node = getNode(rsx);
                      return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(node, Data_Maybe.Nothing.value, patch, done));
                  };
                  var render = function (slot) {
                      if (slot instanceof Halogen_Component.ComponentSlot) {
                          return renderComponentSlot(slot.value0);
                      };
                      if (slot instanceof Halogen_Component.ThunkSlot) {
                          var step = buildThunk(slot.value0);
                          return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(step), new Data_Maybe.Just(step), patch, done));
                      };
                      throw new Error("Failed pattern match at Halogen.VDom.Driver (line 85, column 7 - line 90, column 75): " + [ slot.constructor.name ]);
                  };
                  var patch = function (st, slot) {
                      if (st instanceof Data_Maybe.Just) {
                          if (slot instanceof Halogen_Component.ComponentSlot) {
                              Halogen_VDom_Machine.halt(st.value0);
                              return renderComponentSlot(slot.value0);
                          };
                          if (slot instanceof Halogen_Component.ThunkSlot) {
                              var step$prime = Halogen_VDom_Machine.step(st.value0, slot.value0);
                              return Halogen_VDom_Machine.mkStep(new Halogen_VDom_Machine.Step(Halogen_VDom_Machine.extract(step$prime), new Data_Maybe.Just(step$prime), patch, done));
                          };
                          throw new Error("Failed pattern match at Halogen.VDom.Driver (line 98, column 22 - line 104, column 79): " + [ slot.constructor.name ]);
                      };
                      return render(slot);
                  };
                  return render;
              };
              var buildAttributes = Halogen_VDom_DOM_Prop.buildProp(handler);
              return {
                  buildWidget: buildWidget,
                  buildAttributes: buildAttributes,
                  document: document
              };
          };
      };
  };
  var renderSpec = function (document) {
      return function (container) {
          var render = function (handler) {
              return function (child) {
                  return function (v) {
                      return function (v1) {
                          if (v1 instanceof Data_Maybe.Nothing) {
                              return function __do() {
                                  var renderChildRef = Effect_Ref["new"](child)();
                                  var spec = mkSpec(handler)(renderChildRef)(document);
                                  var machine = Halogen_VDom_DOM.buildVDom(spec)(v);
                                  var node = Halogen_VDom_Machine.extract(machine);
                                  Data_Functor["void"](Effect.functorEffect)(Web_DOM_Node.appendChild(node)(Web_HTML_HTMLElement.toNode(container)))();
                                  return {
                                      machine: machine,
                                      node: node,
                                      renderChildRef: renderChildRef
                                  };
                              };
                          };
                          if (v1 instanceof Data_Maybe.Just) {
                              return function __do() {
                                  Effect_Ref.write(child)(v1.value0.renderChildRef)();
                                  var parent = Web_DOM_Node.parentNode(v1.value0.node)();
                                  var nextSib = Web_DOM_Node.nextSibling(v1.value0.node)();
                                  var machine$prime = Halogen_VDom_Machine.step(v1.value0.machine, v);
                                  var newNode = Halogen_VDom_Machine.extract(machine$prime);
                                  Control_Applicative.when(Effect.applicativeEffect)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Unsafe_Reference.unsafeRefEq)(v1.value0.node)(newNode))(substInParent(newNode)(nextSib)(parent))();
                                  return {
                                      machine: machine$prime,
                                      node: newNode,
                                      renderChildRef: v1.value0.renderChildRef
                                  };
                              };
                          };
                          throw new Error("Failed pattern match at Halogen.VDom.Driver (line 159, column 5 - line 175, column 80): " + [ v1.constructor.name ]);
                      };
                  };
              };
          };
          return {
              render: render,
              renderChild: Control_Category.identity(Control_Category.categoryFn),
              removeChild: removeChild,
              dispose: removeChild
          };
      };
  };
  var runUI = function (component) {
      return function (i) {
          return function (element) {
              return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.map(Effect.functorEffect)(Web_HTML_HTMLDocument.toDocument)(Control_Bind.bindFlipped(Effect.bindEffect)(Web_HTML_Window.document)(Web_HTML.window))))(function (document) {
                  return Halogen_Aff_Driver.runUI(renderSpec(document)(element))(component)(i);
              });
          };
      };
  };
  exports["runUI"] = runUI;
})(PS);
(function(exports) {
  "use strict";

  // A helper which transforms the result ofencodeURIComponent to be compliant
  // with RFC3896, as described in the MDN documentation here:
  //
  // https://web.archive.org/web/20201206001047/https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
  function toRFC3896(input) {
    return input.replace(/[!'()*]/g, function (c) {
      return "%" + c.charCodeAt(0).toString(16);
    });
  }

  exports._encodeURIComponent = function encode(fail, succeed, input) {
    try {
      return succeed(toRFC3896(encodeURIComponent(input)));
    } catch (err) {
      return fail(err);
    }
  };

  function _decodeURIComponent(fail, succeed, input) {
    try {
      return succeed(decodeURIComponent(input));
    } catch (err) {
      return fail(err);
    }
  }

  exports._decodeURIComponent = _decodeURIComponent;
})(PS["JSURI"] = PS["JSURI"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["JSURI"] = $PS["JSURI"] || {};
  var exports = $PS["JSURI"];
  var $foreign = $PS["JSURI"];
  var Data_Function = $PS["Data.Function"];
  var Data_Function_Uncurried = $PS["Data.Function.Uncurried"];
  var Data_Maybe = $PS["Data.Maybe"];                
  var $$encodeURIComponent = Data_Function_Uncurried.runFn3($foreign["_encodeURIComponent"])(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);      
  var $$decodeURIComponent = Data_Function_Uncurried.runFn3($foreign["_decodeURIComponent"])(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Maybe.Just.create);
  exports["encodeURIComponent"] = $$encodeURIComponent;
  exports["decodeURIComponent"] = $$decodeURIComponent;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Network.RemoteData"] = $PS["Network.RemoteData"] || {};
  var exports = $PS["Network.RemoteData"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_HeytingAlgebra = $PS["Data.HeytingAlgebra"];
  var Data_Lens_Internal_Market = $PS["Data.Lens.Internal.Market"];
  var Data_Lens_Prism = $PS["Data.Lens.Prism"];
  var Data_Unit = $PS["Data.Unit"];                
  var NotAsked = (function () {
      function NotAsked() {

      };
      NotAsked.value = new NotAsked();
      return NotAsked;
  })();
  var Loading = (function () {
      function Loading() {

      };
      Loading.value = new Loading();
      return Loading;
  })();
  var Failure = (function () {
      function Failure(value0) {
          this.value0 = value0;
      };
      Failure.create = function (value0) {
          return new Failure(value0);
      };
      return Failure;
  })();
  var Success = (function () {
      function Success(value0) {
          this.value0 = value0;
      };
      Success.create = function (value0) {
          return new Success(value0);
      };
      return Success;
  })();
  var fromEither = function (v) {
      if (v instanceof Data_Either.Left) {
          return new Failure(v.value0);
      };
      if (v instanceof Data_Either.Right) {
          return new Success(v.value0);
      };
      throw new Error("Failed pattern match at Network.RemoteData (line 134, column 1 - line 134, column 55): " + [ v.constructor.name ]);
  };                                                                                                                                
  var _Loading = function (dictChoice) {
      var unwrap = function (v) {
          if (v instanceof Loading) {
              return new Data_Either.Right(Data_Unit.unit);
          };
          return new Data_Either.Left(v);
      };
      return Data_Lens_Prism.prism(Data_Function["const"](Loading.value))(unwrap)(dictChoice);
  };
  var isLoading = Data_Lens_Prism.is(Data_HeytingAlgebra.heytingAlgebraBoolean)(_Loading(Data_Lens_Internal_Market.choiceMarket));
  exports["NotAsked"] = NotAsked;
  exports["Loading"] = Loading;
  exports["Failure"] = Failure;
  exports["Success"] = Success;
  exports["fromEither"] = fromEither;
  exports["isLoading"] = isLoading;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Routing.Duplex.Parser"] = $PS["Routing.Duplex.Parser"] || {};
  var exports = $PS["Routing.Duplex.Parser"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Array = $PS["Data.Array"];
  var Data_Array_NonEmpty = $PS["Data.Array.NonEmpty"];
  var Data_Array_NonEmpty_Internal = $PS["Data.Array.NonEmpty.Internal"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Either = $PS["Data.Either"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_String_CodeUnits = $PS["Data.String.CodeUnits"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_Tuple = $PS["Data.Tuple"];
  var Data_Unit = $PS["Data.Unit"];
  var JSURI = $PS["JSURI"];                                              
  var Expected = (function () {
      function Expected(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Expected.create = function (value0) {
          return function (value1) {
              return new Expected(value0, value1);
          };
      };
      return Expected;
  })();
  var ExpectedEndOfPath = (function () {
      function ExpectedEndOfPath(value0) {
          this.value0 = value0;
      };
      ExpectedEndOfPath.create = function (value0) {
          return new ExpectedEndOfPath(value0);
      };
      return ExpectedEndOfPath;
  })();
  var EndOfPath = (function () {
      function EndOfPath() {

      };
      EndOfPath.value = new EndOfPath();
      return EndOfPath;
  })();
  var Fail = (function () {
      function Fail(value0) {
          this.value0 = value0;
      };
      Fail.create = function (value0) {
          return new Fail(value0);
      };
      return Fail;
  })();
  var Success = (function () {
      function Success(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Success.create = function (value0) {
          return function (value1) {
              return new Success(value0, value1);
          };
      };
      return Success;
  })();
  var Alt = (function () {
      function Alt(value0) {
          this.value0 = value0;
      };
      Alt.create = function (value0) {
          return new Alt(value0);
      };
      return Alt;
  })();
  var Chomp = (function () {
      function Chomp(value0) {
          this.value0 = value0;
      };
      Chomp.create = function (value0) {
          return new Chomp(value0);
      };
      return Chomp;
  })();
  var Prefix = (function () {
      function Prefix(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Prefix.create = function (value0) {
          return function (value1) {
              return new Prefix(value0, value1);
          };
      };
      return Prefix;
  })();
  var take = new Chomp(function (state) {
      var v = Data_Array.uncons(state.segments);
      if (v instanceof Data_Maybe.Just) {
          return new Success({
              segments: v.value0.tail,
              params: state.params,
              hash: state.hash
          }, v.value0.head);
      };
      return new Fail(EndOfPath.value);
  });
  var prefix = Prefix.create;
  var parsePath = (function () {
      var unsafeDecodeURIComponent = (function () {
          var $236 = Data_Maybe.fromJust();
          return function ($237) {
              return $236(JSURI["decodeURIComponent"]($237));
          };
      })();
      var toRouteState = function (v) {
          return {
              segments: v.value0.value0,
              params: v.value0.value1,
              hash: v.value1
          };
      };
      var splitNonEmpty = function (v) {
          return function (v1) {
              if (v1 === "") {
                  return [  ];
              };
              return Data_String_Common.split(v)(v1);
          };
      };
      var splitSegments = (function () {
          var $238 = splitNonEmpty("/");
          return function ($239) {
              return (function (v) {
                  if (v.length === 2 && (v[0] === "" && v[1] === "")) {
                      return [ "" ];
                  };
                  return Data_Functor.map(Data_Functor.functorArray)(unsafeDecodeURIComponent)(v);
              })($238($239));
          };
      })();
      var splitAt = function (k) {
          return function (p) {
              return function (str) {
                  var v = Data_String_CodeUnits.indexOf(p)(str);
                  if (v instanceof Data_Maybe.Just) {
                      return new Data_Tuple.Tuple(Data_String_CodeUnits.take(v.value0)(str), Data_String_CodeUnits.drop(v.value0 + Data_String_CodeUnits.length(p) | 0)(str));
                  };
                  if (v instanceof Data_Maybe.Nothing) {
                      return k(str);
                  };
                  throw new Error("Failed pattern match at Routing.Duplex.Parser (line 185, column 5 - line 187, column 23): " + [ v.constructor.name ]);
              };
          };
      };
      var splitKeyValue = (function () {
          var $240 = Data_Bifunctor.bimap(Data_Bifunctor.bifunctorTuple)(unsafeDecodeURIComponent)(unsafeDecodeURIComponent);
          var $241 = splitAt(Data_Function.flip(Data_Tuple.Tuple.create)(""))("=");
          return function ($242) {
              return $240($241($242));
          };
      })();
      var splitParams = (function () {
          var $243 = Data_Functor.map(Data_Functor.functorArray)(splitKeyValue);
          var $244 = splitNonEmpty("&");
          return function ($245) {
              return $243($244($245));
          };
      })();
      var splitPath = (function () {
          var $246 = Data_Bifunctor.bimap(Data_Bifunctor.bifunctorTuple)(splitSegments)(splitParams);
          var $247 = splitAt(Data_Function.flip(Data_Tuple.Tuple.create)(""))("?");
          return function ($248) {
              return $246($247($248));
          };
      })();
      var $249 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorTuple)(splitPath);
      var $250 = splitAt(Data_Function.flip(Data_Tuple.Tuple.create)(""))("#");
      return function ($251) {
          return toRouteState($249($250($251)));
      };
  })();
  var hash = new Chomp(function (state) {
      return new Success(state, state.hash);
  });
  var functorRouteResult = new Data_Functor.Functor(function (f) {
      return function (m) {
          if (m instanceof Fail) {
              return new Fail(m.value0);
          };
          if (m instanceof Success) {
              return new Success(m.value0, f(m.value1));
          };
          throw new Error("Failed pattern match at Routing.Duplex.Parser (line 53, column 1 - line 53, column 58): " + [ m.constructor.name ]);
      };
  });
  var functorRouteParser = new Data_Functor.Functor(function (f) {
      return function (m) {
          if (m instanceof Alt) {
              return new Alt(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(Data_Functor.map(functorRouteParser)(f))(m.value0));
          };
          if (m instanceof Chomp) {
              return new Chomp(Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(functorRouteResult)(f))(m.value0));
          };
          if (m instanceof Prefix) {
              return new Prefix(m.value0, Data_Functor.map(functorRouteParser)(f)(m.value1));
          };
          throw new Error("Failed pattern match at Routing.Duplex.Parser (line 72, column 1 - line 72, column 58): " + [ m.constructor.name ]);
      };
  });
  var end = new Chomp(function (state) {
      var v = Data_Array.head(state.segments);
      if (v instanceof Data_Maybe.Nothing) {
          return new Success(state, Data_Unit.unit);
      };
      if (v instanceof Data_Maybe.Just) {
          return new Fail(new ExpectedEndOfPath(v.value0));
      };
      throw new Error("Failed pattern match at Routing.Duplex.Parser (line 256, column 3 - line 258, column 45): " + [ v.constructor.name ]);
  });
  var chompPrefix = function (pre) {
      return function (state) {
          var v = Data_Array.head(state.segments);
          if (v instanceof Data_Maybe.Just && pre === v.value0) {
              return new Success({
                  segments: Data_Array.drop(1)(state.segments),
                  params: state.params,
                  hash: state.hash
              }, Data_Unit.unit);
          };
          if (v instanceof Data_Maybe.Just) {
              return Fail.create(new Expected(pre, v.value0));
          };
          return Fail.create(EndOfPath.value);
      };
  };
  var runRouteParser = (function () {
      var goAlt = function (v) {
          return function (v1) {
              return function (v2) {
                  if (v1 instanceof Fail) {
                      return runRouteParser(v)(v2);
                  };
                  return v1;
              };
          };
      };
      var go = function ($copy_state) {
          return function ($copy_v) {
              var $tco_var_state = $copy_state;
              var $tco_done = false;
              var $tco_result;
              function $tco_loop(state, v) {
                  if (v instanceof Alt) {
                      $tco_done = true;
                      return Data_Foldable.foldl(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(goAlt(state))(new Fail(EndOfPath.value))(v.value0);
                  };
                  if (v instanceof Chomp) {
                      $tco_done = true;
                      return v.value0(state);
                  };
                  if (v instanceof Prefix) {
                      var v1 = chompPrefix(v.value0)(state);
                      if (v1 instanceof Fail) {
                          $tco_done = true;
                          return new Fail(v1.value0);
                      };
                      if (v1 instanceof Success) {
                          $tco_var_state = v1.value0;
                          $copy_v = v.value1;
                          return;
                      };
                      throw new Error("Failed pattern match at Routing.Duplex.Parser (line 149, column 7 - line 151, column 40): " + [ v1.constructor.name ]);
                  };
                  throw new Error("Failed pattern match at Routing.Duplex.Parser (line 145, column 14 - line 151, column 40): " + [ v.constructor.name ]);
              };
              while (!$tco_done) {
                  $tco_result = $tco_loop($tco_var_state, $copy_v);
              };
              return $tco_result;
          };
      };
      return go;
  })();
  var run = function (p) {
      var $254 = Data_Function.flip(runRouteParser)(p);
      return function ($255) {
          return (function (v) {
              if (v instanceof Fail) {
                  return new Data_Either.Left(v.value0);
              };
              if (v instanceof Success) {
                  return new Data_Either.Right(v.value1);
              };
              throw new Error("Failed pattern match at Routing.Duplex.Parser (line 190, column 49 - line 192, column 29): " + [ v.constructor.name ]);
          })($254(parsePath($255)));
      };
  };
  var applyRouteParser = new Control_Apply.Apply(function () {
      return functorRouteParser;
  }, function (fx) {
      return function (x) {
          return new Chomp(function (state) {
              var v = runRouteParser(state)(fx);
              if (v instanceof Fail) {
                  return new Fail(v.value0);
              };
              if (v instanceof Success) {
                  return Data_Functor.map(functorRouteResult)(v.value1)(runRouteParser(v.value0)(x));
              };
              throw new Error("Failed pattern match at Routing.Duplex.Parser (line 76, column 5 - line 78, column 56): " + [ v.constructor.name ]);
          });
      };
  });
  var applicativeRouteParser = new Control_Applicative.Applicative(function () {
      return applyRouteParser;
  }, (function () {
      var $256 = Data_Function.flip(Success.create);
      return function ($257) {
          return Chomp.create($256($257));
      };
  })());
  var altSnoc = function (ls) {
      return function (v) {
          var v1 = function (v2) {
              return Data_Array_NonEmpty.snoc(ls)(v);
          };
          if (v instanceof Prefix) {
              var $197 = Data_Array_NonEmpty.last(ls);
              if ($197 instanceof Prefix) {
                  var $198 = v.value0 === $197.value0;
                  if ($198) {
                      return Data_Array_NonEmpty["snoc'"](Data_Array_NonEmpty.init(ls))(new Prefix(v.value0, Control_Alt.alt(altRouteParser)($197.value1)(v.value1)));
                  };
                  return v1(true);
              };
              return v1(true);
          };
          return v1(true);
      };
  };
  var altRouteParser = new Control_Alt.Alt(function () {
      return functorRouteParser;
  }, function (v) {
      return function (v1) {
          if (v instanceof Alt && v1 instanceof Alt) {
              return new Alt(altAppend(v.value0)(v1.value0));
          };
          if (v instanceof Alt) {
              return new Alt(altSnoc(v.value0)(v1));
          };
          if (v1 instanceof Alt) {
              return new Alt(altCons(v)(v1.value0));
          };
          if (v instanceof Prefix && (v1 instanceof Prefix && v.value0 === v1.value0)) {
              return new Prefix(v.value0, Control_Alt.alt(altRouteParser)(v.value1)(v1.value1));
          };
          return new Alt(Data_Array_NonEmpty.cons(v)(Data_Array_NonEmpty.singleton(v1)));
      };
  });
  var altCons = function (v) {
      return function (rs) {
          var v1 = function (v2) {
              return Data_Array_NonEmpty.cons(v)(rs);
          };
          if (v instanceof Prefix) {
              var $217 = Data_Array_NonEmpty.head(rs);
              if ($217 instanceof Prefix) {
                  var $218 = v.value0 === $217.value0;
                  if ($218) {
                      return Data_Array_NonEmpty["cons'"](new Prefix(v.value0, Control_Alt.alt(altRouteParser)(v.value1)($217.value1)))(Data_Array_NonEmpty.tail(rs));
                  };
                  return v1(true);
              };
              return v1(true);
          };
          return v1(true);
      };
  };
  var altAppend = function ($copy_ls) {
      return function ($copy_rs) {
          var $tco_var_ls = $copy_ls;
          var $tco_done = false;
          var $tco_result;
          function $tco_loop(ls, rs) {
              var v = function (v1) {
                  if (Data_Boolean.otherwise) {
                      return Data_Semigroup.append(Data_Array_NonEmpty_Internal.semigroupNonEmptyArray)(ls)(rs);
                  };
                  throw new Error("Failed pattern match at Routing.Duplex.Parser (line 98, column 1 - line 101, column 32): " + [ ls.constructor.name, rs.constructor.name ]);
              };
              var $227 = Data_Array_NonEmpty.last(ls);
              if ($227 instanceof Prefix) {
                  var $228 = Data_Array_NonEmpty.head(rs);
                  if ($228 instanceof Prefix) {
                      var $229 = $227.value0 === $228.value0;
                      if ($229) {
                          var rs$prime = Data_Array_NonEmpty["cons'"](new Prefix($227.value0, Control_Alt.alt(altRouteParser)($227.value1)($228.value1)))(Data_Array_NonEmpty.tail(rs));
                          var v1 = Data_Array_NonEmpty.fromArray(Data_Array_NonEmpty.init(ls));
                          if (v1 instanceof Data_Maybe.Just) {
                              $tco_var_ls = v1.value0;
                              $copy_rs = rs$prime;
                              return;
                          };
                          if (v1 instanceof Data_Maybe.Nothing) {
                              $tco_done = true;
                              return rs$prime;
                          };
                          throw new Error("Failed pattern match at Routing.Duplex.Parser (line 110, column 9 - line 112, column 26): " + [ v1.constructor.name ]);
                      };
                      $tco_done = true;
                      return v(true);
                  };
                  $tco_done = true;
                  return v(true);
              };
              $tco_done = true;
              return v(true);
          };
          while (!$tco_done) {
              $tco_result = $tco_loop($tco_var_ls, $copy_rs);
          };
          return $tco_result;
      };
  };
  exports["run"] = run;
  exports["prefix"] = prefix;
  exports["take"] = take;
  exports["end"] = end;
  exports["functorRouteParser"] = functorRouteParser;
  exports["applyRouteParser"] = applyRouteParser;
  exports["applicativeRouteParser"] = applicativeRouteParser;
  exports["altRouteParser"] = altRouteParser;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Routing.Duplex.Types"] = $PS["Routing.Duplex.Types"] || {};
  var exports = $PS["Routing.Duplex.Types"];
  var emptyRouteState = {
      segments: [  ],
      params: [  ],
      hash: ""
  };
  exports["emptyRouteState"] = emptyRouteState;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Routing.Duplex.Printer"] = $PS["Routing.Duplex.Printer"] || {};
  var exports = $PS["Routing.Duplex.Printer"];
  var Control_Category = $PS["Control.Category"];
  var Data_Array = $PS["Data.Array"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Newtype = $PS["Data.Newtype"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_Tuple = $PS["Data.Tuple"];
  var JSURI = $PS["JSURI"];
  var Routing_Duplex_Types = $PS["Routing.Duplex.Types"];
  var semigroupRoutePrinter = new Data_Semigroup.Semigroup(function (v) {
      return function (v1) {
          return function ($25) {
              return v1(v($25));
          };
      };
  });
  var put = function (str) {
      return function (state) {
          return {
              segments: Data_Array.snoc(state.segments)(str),
              params: state.params,
              hash: state.hash
          };
      };
  };
  var printPath = function (v) {
      var unsafeEncodeURIComponent = (function () {
          var $26 = Data_Maybe.fromJust();
          return function ($27) {
              return $26(JSURI["encodeURIComponent"]($27));
          };
      })();
      var printSegments = function (v1) {
          if (v1.length === 1 && v1[0] === "") {
              return "/";
          };
          return Data_String_Common.joinWith("/")(Data_Functor.map(Data_Functor.functorArray)(unsafeEncodeURIComponent)(v1));
      };
      var printParam = function (key) {
          return function (v1) {
              if (v1 === "") {
                  return unsafeEncodeURIComponent(key);
              };
              return unsafeEncodeURIComponent(key) + ("=" + unsafeEncodeURIComponent(v1));
          };
      };
      var printParams = function (v1) {
          if (v1.length === 0) {
              return "";
          };
          return "?" + Data_String_Common.joinWith("&")(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.uncurry(printParam))(v1));
      };
      var printHash = function (v1) {
          if (v1 === "") {
              return "";
          };
          return "#" + v1;
      };
      return printSegments(v.segments) + (printParams(v.params) + printHash(v.hash));
  }; 
  var run = (function () {
      var $28 = Data_Function.applyFlipped(Routing_Duplex_Types.emptyRouteState);
      var $29 = Data_Newtype.unwrap();
      return function ($30) {
          return printPath($28($29($30)));
      };
  })();
  var monoidRoutePRinter = new Data_Monoid.Monoid(function () {
      return semigroupRoutePrinter;
  }, Control_Category.identity(Control_Category.categoryFn));
  var hash = function (h) {
      return function (v) {
          return {
              segments: v.segments,
              params: v.params,
              hash: h
          };
      };
  };
  exports["put"] = put;
  exports["run"] = run;
  exports["semigroupRoutePrinter"] = semigroupRoutePrinter;
  exports["monoidRoutePRinter"] = monoidRoutePRinter;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Routing.Duplex"] = $PS["Routing.Duplex"] || {};
  var exports = $PS["Routing.Duplex"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Monoid = $PS["Data.Monoid"];
  var Data_Profunctor = $PS["Data.Profunctor"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Routing_Duplex_Parser = $PS["Routing.Duplex.Parser"];
  var Routing_Duplex_Printer = $PS["Routing.Duplex.Printer"];      
  var RouteDuplex = (function () {
      function RouteDuplex(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      RouteDuplex.create = function (value0) {
          return function (value1) {
              return new RouteDuplex(value0, value1);
          };
      };
      return RouteDuplex;
  })();                                                               
  var segment = new RouteDuplex(Routing_Duplex_Printer.put, Routing_Duplex_Parser.take);
  var profunctorRouteDuplex = new Data_Profunctor.Profunctor(function (f) {
      return function (g) {
          return function (v) {
              return new RouteDuplex(function ($100) {
                  return v.value0(f($100));
              }, Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(g)(v.value1));
          };
      };
  });
  var print = function (v) {
      return function ($101) {
          return Routing_Duplex_Printer.run(v.value0($101));
      };
  };
  var prefix = function (s) {
      return function (v) {
          return new RouteDuplex(function (a) {
              return Data_Semigroup.append(Routing_Duplex_Printer.semigroupRoutePrinter)(Routing_Duplex_Printer.put(s))(v.value0(a));
          }, Routing_Duplex_Parser.prefix(s)(v.value1));
      };
  };
  var path = (function () {
      var $102 = Data_Function.flip(Data_Foldable.foldr(Data_Foldable.foldableArray)(prefix));
      var $103 = Data_String_Common.split("/");
      return function ($104) {
          return $102($103($104));
      };
  })();
  var root = path("");
  var parse = function (v) {
      return Routing_Duplex_Parser.run(v.value1);
  };
  var functorRouteDuplex = new Data_Functor.Functor(function (f) {
      return function (m) {
          return new RouteDuplex(m.value0, Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(f)(m.value1));
      };
  });
  var end = function (v) {
      return new RouteDuplex(v.value0, Control_Apply.applyFirst(Routing_Duplex_Parser.applyRouteParser)(v.value1)(Routing_Duplex_Parser.end));
  };                                                                              
  var applyRouteDuplex = new Control_Apply.Apply(function () {
      return functorRouteDuplex;
  }, function (v) {
      return function (v1) {
          return new RouteDuplex(Control_Apply.apply(Control_Apply.applyFn)(Data_Functor.map(Data_Functor.functorFn)(Data_Semigroup.append(Routing_Duplex_Printer.semigroupRoutePrinter))(v.value0))(v1.value0), Control_Apply.apply(Routing_Duplex_Parser.applyRouteParser)(v.value1)(v1.value1));
      };
  });
  var applicativeRouteDuplex = new Control_Applicative.Applicative(function () {
      return applyRouteDuplex;
  }, (function () {
      var $106 = RouteDuplex.create(Data_Function["const"](Data_Monoid.mempty(Routing_Duplex_Printer.monoidRoutePRinter)));
      var $107 = Control_Applicative.pure(Routing_Duplex_Parser.applicativeRouteParser);
      return function ($108) {
          return $106($107($108));
      };
  })());
  exports["RouteDuplex"] = RouteDuplex;
  exports["parse"] = parse;
  exports["print"] = print;
  exports["prefix"] = prefix;
  exports["root"] = root;
  exports["end"] = end;
  exports["segment"] = segment;
  exports["applicativeRouteDuplex"] = applicativeRouteDuplex;
  exports["profunctorRouteDuplex"] = profunctorRouteDuplex;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Web.HTML.Event.HashChangeEvent.EventTypes"] = $PS["Web.HTML.Event.HashChangeEvent.EventTypes"] || {};
  var exports = $PS["Web.HTML.Event.HashChangeEvent.EventTypes"];
  var hashchange = "hashchange";
  exports["hashchange"] = hashchange;
})(PS);
(function(exports) {
  "use strict";

  exports.hash = function (location) {
    return function () {
      return location.hash;
    };
  };

  exports.setHash = function (hash) {
    return function (location) {
      return function () {
        location.hash = hash;
      };
    };
  };
})(PS["Web.HTML.Location"] = PS["Web.HTML.Location"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Web.HTML.Location"] = $PS["Web.HTML.Location"] || {};
  var exports = $PS["Web.HTML.Location"];
  var $foreign = $PS["Web.HTML.Location"];
  exports["hash"] = $foreign.hash;
  exports["setHash"] = $foreign.setHash;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Routing.Hash"] = $PS["Routing.Hash"] || {};
  var exports = $PS["Routing.Hash"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Apply = $PS["Control.Apply"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_String_CodeUnits = $PS["Data.String.CodeUnits"];
  var Effect = $PS["Effect"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Web_Event_EventTarget = $PS["Web.Event.EventTarget"];
  var Web_HTML = $PS["Web.HTML"];
  var Web_HTML_Event_HashChangeEvent_EventTypes = $PS["Web.HTML.Event.HashChangeEvent.EventTypes"];
  var Web_HTML_Location = $PS["Web.HTML.Location"];
  var Web_HTML_Window = $PS["Web.HTML.Window"];                
  var setHash = function (h) {
      return Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.location))(Web_HTML_Location.setHash(h));
  };
  var getHash = Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.location))((function () {
      var $2 = Data_Functor.map(Effect.functorEffect)((function () {
          var $4 = Data_Maybe.fromMaybe("");
          var $5 = Data_String_CodeUnits.stripPrefix("#");
          return function ($6) {
              return $4($5($6));
          };
      })());
      return function ($3) {
          return $2(Web_HTML_Location.hash($3));
      };
  })());
  var foldHashes = function (cb) {
      return function (init) {
          return function __do() {
              var ref = Control_Bind.bindFlipped(Effect.bindEffect)(Effect_Ref["new"])(Control_Bind.bindFlipped(Effect.bindEffect)(init)(getHash))();
              var win = Data_Functor.map(Effect.functorEffect)(Web_HTML_Window.toEventTarget)(Web_HTML.window)();
              var listener = Web_Event_EventTarget.eventListener(function (v) {
                  return Control_Bind.bindFlipped(Effect.bindEffect)(Data_Function.flip(Effect_Ref.write)(ref))(Control_Bind.join(Effect.bindEffect)(Control_Apply.apply(Effect.applyEffect)(Data_Functor.map(Effect.functorEffect)(cb)(Effect_Ref.read(ref)))(getHash)));
              })();
              Web_Event_EventTarget.addEventListener(Web_HTML_Event_HashChangeEvent_EventTypes.hashchange)(listener)(false)(win)();
              return Web_Event_EventTarget.removeEventListener(Web_HTML_Event_HashChangeEvent_EventTypes.hashchange)(listener)(false)(win);
          };
      };
  };
  var matchesWith = function (dictFoldable) {
      return function (parser) {
          return function (cb) {
              var go = function (a) {
                  var $7 = Data_Maybe.maybe(Control_Applicative.pure(Effect.applicativeEffect)(a))(function (b) {
                      return Data_Functor.voidRight(Effect.functorEffect)(new Data_Maybe.Just(b))(cb(a)(b));
                  });
                  var $8 = Data_Foldable.indexl(dictFoldable)(0);
                  return function ($9) {
                      return $7($8(parser($9)));
                  };
              };
              return foldHashes(go)(go(Data_Maybe.Nothing.value));
          };
      };
  };
  exports["getHash"] = getHash;
  exports["setHash"] = setHash;
  exports["matchesWith"] = matchesWith;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Capability.ManageSpec"] = $PS["Turing.Capability.ManageSpec"] || {};
  var exports = $PS["Turing.Capability.ManageSpec"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];                
  var ManageSpec = function (Monad0, getSpec, getSpecs, saveSpec) {
      this.Monad0 = Monad0;
      this.getSpec = getSpec;
      this.getSpecs = getSpecs;
      this.saveSpec = saveSpec;
  };
  var saveSpec = function (dict) {
      return dict.saveSpec;
  };
  var getSpecs = function (dict) {
      return dict.getSpecs;
  };
  var getSpec = function (dict) {
      return dict.getSpec;
  };
  var manageSpecHalogenM = function (dictManageSpec) {
      return new ManageSpec(function () {
          return Halogen_Query_HalogenM.monadHalogenM;
      }, (function () {
          var $1 = Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM)(dictManageSpec.Monad0());
          var $2 = getSpec(dictManageSpec);
          return function ($3) {
              return $1($2($3));
          };
      })(), Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM)(dictManageSpec.Monad0())(getSpecs(dictManageSpec)), (function () {
          var $4 = Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM)(dictManageSpec.Monad0());
          var $5 = saveSpec(dictManageSpec);
          return function ($6) {
              return $4($5($6));
          };
      })());
  };
  exports["getSpec"] = getSpec;
  exports["getSpecs"] = getSpecs;
  exports["saveSpec"] = saveSpec;
  exports["ManageSpec"] = ManageSpec;
  exports["manageSpecHalogenM"] = manageSpecHalogenM;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Capability.Navigate"] = $PS["Turing.Capability.Navigate"] || {};
  var exports = $PS["Turing.Capability.Navigate"];
  var Control_Monad_Trans_Class = $PS["Control.Monad.Trans.Class"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];                
  var Navigate = function (Monad0, navigate) {
      this.Monad0 = Monad0;
      this.navigate = navigate;
  };
  var navigate = function (dict) {
      return dict.navigate;
  };
  var navigateHalogenM = function (dictNavigate) {
      return new Navigate(function () {
          return Halogen_Query_HalogenM.monadHalogenM;
      }, (function () {
          var $1 = Control_Monad_Trans_Class.lift(Halogen_Query_HalogenM.monadTransHalogenM)(dictNavigate.Monad0());
          var $2 = navigate(dictNavigate);
          return function ($3) {
              return $1($2($3));
          };
      })());
  };
  exports["navigate"] = navigate;
  exports["Navigate"] = Navigate;
  exports["navigateHalogenM"] = navigateHalogenM;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Routing.Duplex.Generic"] = $PS["Routing.Duplex.Generic"] || {};
  var exports = $PS["Routing.Duplex.Generic"];
  var Control_Alt = $PS["Control.Alt"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Category = $PS["Control.Category"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_Profunctor = $PS["Data.Profunctor"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Record = $PS["Record"];
  var Routing_Duplex = $PS["Routing.Duplex"];
  var Routing_Duplex_Parser = $PS["Routing.Duplex.Parser"];                  
  var GRouteDuplexCtr = function (gRouteDuplexCtr) {
      this.gRouteDuplexCtr = gRouteDuplexCtr;
  };
  var GRouteDuplex = function (gRouteDuplex) {
      this.gRouteDuplex = gRouteDuplex;
  };
  var noArgs = Control_Applicative.pure(Routing_Duplex.applicativeRouteDuplex)(Data_Generic_Rep.NoArguments.value);
  var gRouteNoArguments = new GRouteDuplexCtr(Control_Category.identity(Control_Category.categoryFn));
  var gRouteDuplexCtr = function (dict) {
      return dict.gRouteDuplexCtr;
  };
  var gRouteDuplex = function (dict) {
      return dict.gRouteDuplex;
  };
  var gRouteSum = function (dictGRouteDuplex) {
      return function (dictGRouteDuplex1) {
          return new GRouteDuplex(function (r) {
              var v = gRouteDuplex(dictGRouteDuplex)(r);
              var v1 = gRouteDuplex(dictGRouteDuplex1)(r);
              var enc = function (v2) {
                  if (v2 instanceof Data_Generic_Rep.Inl) {
                      return v.value0(v2.value0);
                  };
                  if (v2 instanceof Data_Generic_Rep.Inr) {
                      return v1.value0(v2.value0);
                  };
                  throw new Error("Failed pattern match at Routing.Duplex.Generic (line 32, column 11 - line 34, column 22): " + [ v2.constructor.name ]);
              };
              var dec = Control_Alt.alt(Routing_Duplex_Parser.altRouteParser)(Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(Data_Generic_Rep.Inl.create)(v.value1))(Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(Data_Generic_Rep.Inr.create)(v1.value1));
              return new Routing_Duplex.RouteDuplex(enc, dec);
          });
      };
  };
  var sum = function (dictGeneric) {
      return function (dictGRouteDuplex) {
          var $48 = Data_Profunctor.dimap(Routing_Duplex.profunctorRouteDuplex)(Data_Generic_Rep.from(dictGeneric))(Data_Generic_Rep.to(dictGeneric));
          var $49 = gRouteDuplex(dictGRouteDuplex);
          return function ($50) {
              return $48($49($50));
          };
      };
  };
  var gRouteConstructor = function (dictIsSymbol) {
      return function (dictCons) {
          return function (dictGRouteDuplexCtr) {
              return new GRouteDuplex(function (r) {
                  var v = Routing_Duplex.end(gRouteDuplexCtr(dictGRouteDuplexCtr)(Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(r)));
                  var enc = function (v1) {
                      return v.value0(v1);
                  };
                  var dec = Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(Data_Generic_Rep.Constructor)(v.value1);
                  return new Routing_Duplex.RouteDuplex(enc, dec);
              });
          };
      };
  };
  var gRouteArgument = new GRouteDuplexCtr(Control_Category.identity(Control_Category.categoryFn));
  var gRouteAll = new GRouteDuplexCtr(function (v) {
      return new Routing_Duplex.RouteDuplex(function (v1) {
          return v.value0(v1);
      }, Data_Functor.map(Routing_Duplex_Parser.functorRouteParser)(Data_Generic_Rep.Argument)(v.value1));
  });
  exports["gRouteDuplexCtr"] = gRouteDuplexCtr;
  exports["sum"] = sum;
  exports["noArgs"] = noArgs;
  exports["gRouteSum"] = gRouteSum;
  exports["gRouteConstructor"] = gRouteConstructor;
  exports["gRouteNoArguments"] = gRouteNoArguments;
  exports["gRouteArgument"] = gRouteArgument;
  exports["gRouteAll"] = gRouteAll;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Routing.Duplex.Generic.Syntax"] = $PS["Routing.Duplex.Generic.Syntax"] || {};
  var exports = $PS["Routing.Duplex.Generic.Syntax"];
  var Routing_Duplex = $PS["Routing.Duplex"];
  var Routing_Duplex_Generic = $PS["Routing.Duplex.Generic"];                
  var GSep = function (gsep) {
      this.gsep = gsep;
  }; 
  var gsepStringRoute = function (dictGRouteDuplexCtr) {
      return new GSep(function (a) {
          var $5 = Routing_Duplex.prefix(a);
          var $6 = Routing_Duplex_Generic.gRouteDuplexCtr(dictGRouteDuplexCtr);
          return function ($7) {
              return $5($6($7));
          };
      });
  };
  var gsep = function (dict) {
      return dict.gsep;
  };
  exports["gsep"] = gsep;
  exports["gsepStringRoute"] = gsepStringRoute;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Data.Route"] = $PS["Turing.Data.Route"] || {};
  var exports = $PS["Turing.Data.Route"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Routing_Duplex = $PS["Routing.Duplex"];
  var Routing_Duplex_Generic = $PS["Routing.Duplex.Generic"];
  var Routing_Duplex_Generic_Syntax = $PS["Routing.Duplex.Generic.Syntax"];                
  var Home = (function () {
      function Home() {

      };
      Home.value = new Home();
      return Home;
  })();
  var Specs = (function () {
      function Specs() {

      };
      Specs.value = new Specs();
      return Specs;
  })();
  var Play = (function () {
      function Play() {

      };
      Play.value = new Play();
      return Play;
  })();
  var Campaign = (function () {
      function Campaign(value0) {
          this.value0 = value0;
      };
      Campaign.create = function (value0) {
          return new Campaign(value0);
      };
      return Campaign;
  })();
  var Settings = (function () {
      function Settings() {

      };
      Settings.value = new Settings();
      return Settings;
  })();
  var SpecEditor = (function () {
      function SpecEditor(value0) {
          this.value0 = value0;
      };
      SpecEditor.create = function (value0) {
          return new SpecEditor(value0);
      };
      return SpecEditor;
  })();
  var Spec = (function () {
      function Spec(value0) {
          this.value0 = value0;
      };
      Spec.create = function (value0) {
          return new Spec(value0);
      };
      return Spec;
  })();
  var genericRoute = new Data_Generic_Rep.Generic(function (x) {
      if (x instanceof Home) {
          return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
      };
      if (x instanceof Specs) {
          return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
      };
      if (x instanceof Play) {
          return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
      };
      if (x instanceof Campaign) {
          return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
      };
      if (x instanceof Settings) {
          return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
      };
      if (x instanceof SpecEditor) {
          return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
      };
      if (x instanceof Spec) {
          return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))))));
      };
      throw new Error("Failed pattern match at Turing.Data.Route (line 22, column 1 - line 22, column 48): " + [ x.constructor.name ]);
  }, function (x) {
      if (x instanceof Data_Generic_Rep.Inl) {
          return Home.value;
      };
      if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
          return Specs.value;
      };
      if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
          return Play.value;
      };
      if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
          return new Campaign(x.value0.value0.value0.value0);
      };
      if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
          return Settings.value;
      };
      if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
          return new SpecEditor(x.value0.value0.value0.value0.value0.value0);
      };
      if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))) {
          return new Spec(x.value0.value0.value0.value0.value0.value0);
      };
      throw new Error("Failed pattern match at Turing.Data.Route (line 22, column 1 - line 22, column 48): " + [ x.constructor.name ]);
  });
  var route = Routing_Duplex.root(Routing_Duplex_Generic.sum(genericRoute)(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
      return "Home";
  }))()(Routing_Duplex_Generic.gRouteNoArguments))(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
      return "Specs";
  }))()(Routing_Duplex_Generic.gRouteNoArguments))(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
      return "Play";
  }))()(Routing_Duplex_Generic.gRouteNoArguments))(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
      return "Campaign";
  }))()(Routing_Duplex_Generic.gRouteArgument))(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
      return "Settings";
  }))()(Routing_Duplex_Generic.gRouteNoArguments))(Routing_Duplex_Generic.gRouteSum(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
      return "SpecEditor";
  }))()(Routing_Duplex_Generic.gRouteArgument))(Routing_Duplex_Generic.gRouteConstructor(new Data_Symbol.IsSymbol(function () {
      return "Spec";
  }))()(Routing_Duplex_Generic.gRouteArgument))))))))({
      Home: Routing_Duplex_Generic.noArgs,
      Specs: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteNoArguments))("specs")(Routing_Duplex_Generic.noArgs),
      Play: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteNoArguments))("play")(Routing_Duplex_Generic.noArgs),
      Campaign: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteAll))("campaign")(Routing_Duplex.segment),
      SpecEditor: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteAll))("specs")(Routing_Duplex.segment),
      Spec: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteAll))("spec")(Routing_Duplex.segment),
      Settings: Routing_Duplex_Generic_Syntax.gsep(Routing_Duplex_Generic_Syntax.gsepStringRoute(Routing_Duplex_Generic.gRouteNoArguments))("settings")(Routing_Duplex_Generic.noArgs)
  }));        
  var eqRoute = new Data_Eq.Eq(function (x) {
      return function (y) {
          if (x instanceof Home && y instanceof Home) {
              return true;
          };
          if (x instanceof Specs && y instanceof Specs) {
              return true;
          };
          if (x instanceof Play && y instanceof Play) {
              return true;
          };
          if (x instanceof Campaign && y instanceof Campaign) {
              return x.value0 === y.value0;
          };
          if (x instanceof Settings && y instanceof Settings) {
              return true;
          };
          if (x instanceof SpecEditor && y instanceof SpecEditor) {
              return x.value0 === y.value0;
          };
          if (x instanceof Spec && y instanceof Spec) {
              return x.value0 === y.value0;
          };
          return false;
      };
  });
  exports["Home"] = Home;
  exports["Specs"] = Specs;
  exports["Play"] = Play;
  exports["Campaign"] = Campaign;
  exports["Settings"] = Settings;
  exports["SpecEditor"] = SpecEditor;
  exports["Spec"] = Spec;
  exports["route"] = route;
  exports["eqRoute"] = eqRoute;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Effect.Random"] = $PS["Turing.Effect.Random"] || {};
  var exports = $PS["Turing.Effect.Random"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_String_CodePoints = $PS["Data.String.CodePoints"];
  var Data_Traversable = $PS["Data.Traversable"];
  var Data_Unfoldable = $PS["Data.Unfoldable"];
  var Effect = $PS["Effect"];
  var Effect_Random = $PS["Effect.Random"];                
  var alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz012345689";
  var randomCodePoint = function __do() {
      var index = Effect_Random.randomInt(0)(Data_String_CodePoints.length(alphabet) - 1 | 0)();
      return Data_Maybe.fromMaybe(Data_String_CodePoints.codePointFromChar("_"))(Data_String_CodePoints.codePointAt(index)(alphabet));
  };
  var randomString = function (length) {
      return function __do() {
          var codePoints = Data_Unfoldable.replicateA(Effect.applicativeEffect)(Data_Unfoldable.unfoldableArray)(Data_Traversable.traversableArray)(length)(randomCodePoint)();
          return Data_String_CodePoints.fromCodePointArray(codePoints);
      };
  };
  var randomId = randomString(7);
  exports["randomString"] = randomString;
  exports["randomId"] = randomId;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Data.Spec"] = $PS["Turing.Data.Spec"] || {};
  var exports = $PS["Turing.Data.Spec"];
  var Data_Argonaut_Decode_Class = $PS["Data.Argonaut.Decode.Class"];
  var Data_Argonaut_Decode_Generic = $PS["Data.Argonaut.Decode.Generic"];
  var Data_Argonaut_Encode_Class = $PS["Data.Argonaut.Encode.Class"];
  var Data_Argonaut_Encode_Generic = $PS["Data.Argonaut.Encode.Generic"];
  var Data_Generic_Rep = $PS["Data.Generic.Rep"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Turing_Effect_Random = $PS["Turing.Effect.Random"];                
  var BusyBeaver = (function () {
      function BusyBeaver() {

      };
      BusyBeaver.value = new BusyBeaver();
      return BusyBeaver;
  })();
  var mkSpec = function __do() {
      var id = Turing_Effect_Random.randomString(7)();
      return {
          id: id,
          name: "New spec",
          maxNumberOfCards: 5,
          goal: BusyBeaver.value
      };
  };
  var genericSpecGoal = new Data_Generic_Rep.Generic(function (x) {
      return Data_Generic_Rep.NoArguments.value;
  }, function (x) {
      return BusyBeaver.value;
  });
  var encodeJsonSpecGoal = new Data_Argonaut_Encode_Class.EncodeJson(Data_Argonaut_Encode_Generic.genericEncodeJson(genericSpecGoal)(Data_Argonaut_Encode_Generic.encodeRepConstructor(new Data_Symbol.IsSymbol(function () {
      return "BusyBeaver";
  }))(Data_Argonaut_Encode_Generic.encodeRepArgsNoArguments)));
  var decodeJsonSpecGoal = new Data_Argonaut_Decode_Class.DecodeJson(Data_Argonaut_Decode_Generic.genericDecodeJson(genericSpecGoal)(Data_Argonaut_Decode_Generic.decodeRepConstructorNoArgs(new Data_Symbol.IsSymbol(function () {
      return "BusyBeaver";
  }))));
  exports["mkSpec"] = mkSpec;
  exports["BusyBeaver"] = BusyBeaver;
  exports["encodeJsonSpecGoal"] = encodeJsonSpecGoal;
  exports["decodeJsonSpecGoal"] = decodeJsonSpecGoal;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Effect.Error"] = $PS["Turing.Effect.Error"] || {};
  var exports = $PS["Turing.Effect.Error"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Data_Either = $PS["Data.Either"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Show = $PS["Data.Show"];
  var Effect = $PS["Effect"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Class_Console = $PS["Effect.Class.Console"];
  var Effect_Exception = $PS["Effect.Exception"];                
  var showError = function (error) {
      return Effect_Exception.name(error) + (": " + Effect_Exception.message(error));
  };
  var hushError = function (dictShow) {
      return function (v) {
          if (v instanceof Data_Either.Left) {
              return function __do() {
                  Effect_Class_Console.error(Effect_Class.monadEffectEffect)(Data_Show.show(dictShow)(v.value0))();
                  return Data_Maybe.Nothing.value;
              };
          };
          if (v instanceof Data_Either.Right) {
              return Control_Applicative.pure(Effect.applicativeEffect)(new Data_Maybe.Just(v.value0));
          };
          throw new Error("Failed pattern match at Turing.Effect.Error (line 18, column 1 - line 18, column 66): " + [ v.constructor.name ]);
      };
  };
  exports["showError"] = showError;
  exports["hushError"] = hushError;
})(PS);
(function(exports) {
  "use strict";

  // See https://firebase.google.com/docs/reference/js/firebase.firestore.CollectionReference#doc
  exports.doc = function (collectionReference) {
      return function (documentPath) {
          return function () {
              return collectionReference.doc(documentPath);
          };
      };
  };

  // See https://firebase.google.com/docs/reference/js/firebase.firestore.CollectionReference#get
  exports.get = function (collectionReference) {
      return function (options) {
          return function () {
              return collectionReference.get(options);
          };
      };
  };
})(PS["Turing.Firebase.CollectionReference"] = PS["Turing.Firebase.CollectionReference"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Firebase.CollectionReference"] = $PS["Turing.Firebase.CollectionReference"] || {};
  var exports = $PS["Turing.Firebase.CollectionReference"];
  var $foreign = $PS["Turing.Firebase.CollectionReference"];
  var Control_Promise = $PS["Control.Promise"];
  var Data_Maybe = $PS["Data.Maybe"];                
  var get_ = function (collectionReference) {
      return $foreign.get(collectionReference)(Data_Maybe.Nothing.value);
  };
  var getAff_ = function (ref) {
      return Control_Promise.toAffE(get_(ref));
  };
  exports["getAff_"] = getAff_;
  exports["doc"] = $foreign.doc;
})(PS);
(function(exports) {
  "use strict";

  // See https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentReference#get
  exports.get = function (documentReference) {
      return function (options) {
          return function () {
              return documentReference.get(options);
          }
      }
  };

  // See https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentReference#set
  exports.setImpl = function (documentReference) {
      return function (data) {
          return function () {
              return documentReference.set(data, {});
          };
      };
  };
})(PS["Turing.Firebase.DocumentReference"] = PS["Turing.Firebase.DocumentReference"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Firebase.DocumentReference"] = $PS["Turing.Firebase.DocumentReference"] || {};
  var exports = $PS["Turing.Firebase.DocumentReference"];
  var $foreign = $PS["Turing.Firebase.DocumentReference"];
  var Control_Promise = $PS["Control.Promise"];
  var Data_Maybe = $PS["Data.Maybe"];                
  var set = function (ref) {
      return function (value) {
          return Control_Promise.toAffE($foreign.setImpl(ref)(value));
      };
  };
  var get_ = function (ref) {
      return Control_Promise.toAffE($foreign.get(ref)(Data_Maybe.Nothing.value));
  };
  exports["set"] = set;
  exports["get_"] = get_;
})(PS);
(function(exports) {
  "use strict";

  // See https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentSnapshot#data
  exports._data = function (documentSnapshot) {
      return function (options) {
          return function () {
              // I found no great way to deal with undefined values,
              // so I'm converting undefined to null for the moment.
              var data = documentSnapshot.data(options);
              if (typeof data !== "undefined") {
                  return data;
              }
              else {
                  return null;
              }
          };
      };
  };
})(PS["Turing.Firebase.DocumentSnapshot"] = PS["Turing.Firebase.DocumentSnapshot"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Firebase.DocumentSnapshot"] = $PS["Turing.Firebase.DocumentSnapshot"] || {};
  var exports = $PS["Turing.Firebase.DocumentSnapshot"];
  var $foreign = $PS["Turing.Firebase.DocumentSnapshot"];
  exports["_data"] = $foreign["_data"];
})(PS);
(function(exports) {
  "use strict";

  // See https://firebase.google.com/docs/reference/js/firebase.firestore#callable
  exports.firestore = function () {
      return firebase.firestore();
  };

  // See https://firebase.google.com/docs/reference/js/firebase.firestore.Firestore#collection
  exports.collection = function (firestore) {
      return function (collectionPath) {
          return function () {
              return firestore.collection(collectionPath);
          };
      };
  };
})(PS["Turing.Firebase.Firestore"] = PS["Turing.Firebase.Firestore"] || {});
(function(exports) {
  "use strict";

  // See https://firebase.google.com/docs/reference/js/firebase.firestore.QueryDocumentSnapshot#data
  exports._data = function (queryDocumentSnapshot) {
      return function (snapshotOptions) {
          return function () {
              return queryDocumentSnapshot.data(snapshotOptions);
          }
      }
  }
})(PS["Turing.Firebase.QueryDocumentSnapshot"] = PS["Turing.Firebase.QueryDocumentSnapshot"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Firebase.QueryDocumentSnapshot"] = $PS["Turing.Firebase.QueryDocumentSnapshot"] || {};
  var exports = $PS["Turing.Firebase.QueryDocumentSnapshot"];
  var $foreign = $PS["Turing.Firebase.QueryDocumentSnapshot"];
  var Data_Maybe = $PS["Data.Maybe"];                
  var _data_ = function (queryDocumentSnapshot) {
      return $foreign["_data"](queryDocumentSnapshot)(Data_Maybe.Nothing.value);
  };
  exports["_data_"] = _data_;
})(PS);
(function(exports) {
  "use strict";

  // See https://firebase.google.com/docs/reference/js/firebase.firestore.QuerySnapshot#docs
  exports.docs = function (querySnapshot) {
      return querySnapshot.docs;
  };
})(PS["Turing.Firebase.QuerySnapshot"] = PS["Turing.Firebase.QuerySnapshot"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Firebase.QuerySnapshot"] = $PS["Turing.Firebase.QuerySnapshot"] || {};
  var exports = $PS["Turing.Firebase.QuerySnapshot"];
  var $foreign = $PS["Turing.Firebase.QuerySnapshot"];
  exports["docs"] = $foreign.docs;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Firebase.Firestore"] = $PS["Turing.Firebase.Firestore"] || {};
  var exports = $PS["Turing.Firebase.Firestore"];
  var $foreign = $PS["Turing.Firebase.Firestore"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Argonaut_Decode_Class = $PS["Data.Argonaut.Decode.Class"];
  var Data_Argonaut_Decode_Error = $PS["Data.Argonaut.Decode.Error"];
  var Data_Array = $PS["Data.Array"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Traversable = $PS["Data.Traversable"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Turing_Data_Spec = $PS["Turing.Data.Spec"];
  var Turing_Effect_Error = $PS["Turing.Effect.Error"];
  var Turing_Firebase_CollectionReference = $PS["Turing.Firebase.CollectionReference"];
  var Turing_Firebase_QueryDocumentSnapshot = $PS["Turing.Firebase.QueryDocumentSnapshot"];
  var Turing_Firebase_QuerySnapshot = $PS["Turing.Firebase.QuerySnapshot"];                
  var specsCollection = function __do() {
      var fs = $foreign.firestore();
      return $foreign.collection(fs)("specs")();
  };
  var getSpecs = Control_Bind.bind(Effect_Aff.bindAff)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(specsCollection))(Turing_Firebase_CollectionReference.getAff_))(function (querySnapshot) {
      return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
          var queryData = Data_Traversable.sequence(Data_Traversable.traversableArray)(Effect.applicativeEffect)(Data_Functor.map(Data_Functor.functorArray)(Turing_Firebase_QueryDocumentSnapshot["_data_"])(Turing_Firebase_QuerySnapshot.docs(querySnapshot)))();
          var maybeSpecs = Data_Traversable.sequence(Data_Traversable.traversableArray)(Effect.applicativeEffect)(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorFn)(Turing_Effect_Error.hushError(Data_Argonaut_Decode_Error.showJsonDecodeError))(Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Turing_Data_Spec.decodeJsonSpecGoal))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonNil)(new Data_Symbol.IsSymbol(function () {
              return "name";
          }))()())(new Data_Symbol.IsSymbol(function () {
              return "maxNumberOfCards";
          }))()())(new Data_Symbol.IsSymbol(function () {
              return "id";
          }))()())(new Data_Symbol.IsSymbol(function () {
              return "goal";
          }))()())())))(queryData))();
          return Data_Array.catMaybes(maybeSpecs);
      });
  });
  exports["specsCollection"] = specsCollection;
  exports["getSpecs"] = getSpecs;
  exports["firestore"] = $foreign.firestore;
  exports["collection"] = $foreign.collection;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.AppM"] = $PS["Turing.AppM"] || {};
  var exports = $PS["Turing.AppM"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Error_Class = $PS["Control.Monad.Error.Class"];
  var Control_Monad_Reader_Trans = $PS["Control.Monad.Reader.Trans"];
  var Data_Argonaut_Decode_Class = $PS["Data.Argonaut.Decode.Class"];
  var Data_Argonaut_Decode_Error = $PS["Data.Argonaut.Decode.Error"];
  var Data_Argonaut_Encode_Class = $PS["Data.Argonaut.Encode.Class"];
  var Data_Bifunctor = $PS["Data.Bifunctor"];
  var Data_Either = $PS["Data.Either"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Nullable = $PS["Data.Nullable"];
  var Data_Show = $PS["Data.Show"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Aff_Class = $PS["Effect.Aff.Class"];
  var Effect_Class = $PS["Effect.Class"];
  var Routing_Duplex = $PS["Routing.Duplex"];
  var Routing_Hash = $PS["Routing.Hash"];
  var Turing_Capability_ManageSpec = $PS["Turing.Capability.ManageSpec"];
  var Turing_Capability_Navigate = $PS["Turing.Capability.Navigate"];
  var Turing_Data_Route = $PS["Turing.Data.Route"];
  var Turing_Data_Spec = $PS["Turing.Data.Spec"];
  var Turing_Effect_Error = $PS["Turing.Effect.Error"];
  var Turing_Firebase_CollectionReference = $PS["Turing.Firebase.CollectionReference"];
  var Turing_Firebase_DocumentReference = $PS["Turing.Firebase.DocumentReference"];
  var Turing_Firebase_DocumentSnapshot = $PS["Turing.Firebase.DocumentSnapshot"];
  var Turing_Firebase_Firestore = $PS["Turing.Firebase.Firestore"];
  var runAppM = function (env) {
      return function (v) {
          return Control_Monad_Reader_Trans.runReaderT(v)(env);
      };
  };
  var monadEffectAppM = Control_Monad_Reader_Trans.monadEffectReader(Effect_Aff.monadEffectAff);
  var monadAppM = Control_Monad_Reader_Trans.monadReaderT(Effect_Aff.monadAff);
  var navigateAppM = new Turing_Capability_Navigate.Navigate(function () {
      return monadAppM;
  }, (function () {
      var $13 = Effect_Class.liftEffect(monadEffectAppM);
      var $14 = Routing_Duplex.print(Turing_Data_Route.route);
      return function ($15) {
          return $13(Routing_Hash.setHash($14($15)));
      };
  })());
  var monadAffAppM = Effect_Aff_Class.monadAffReader(Effect_Aff_Class.monadAffAff);  
  var bindAppM = Control_Monad_Reader_Trans.bindReaderT(Effect_Aff.bindAff);
  var applicativeAppM = Control_Monad_Reader_Trans.applicativeReaderT(Effect_Aff.applicativeAff);
  var manageSpecAppM = new Turing_Capability_ManageSpec.ManageSpec(function () {
      return monadAppM;
  }, function (specId) {
      return Control_Bind.bind(bindAppM)(Effect_Class.liftEffect(monadEffectAppM)(function __do() {
          var firestore = Turing_Firebase_Firestore.firestore();
          var collectionRef = Turing_Firebase_Firestore.collection(firestore)("specs")();
          return Turing_Firebase_CollectionReference.doc(collectionRef)(specId)();
      }))(function (documentRef) {
          return Control_Bind.bind(bindAppM)(Effect_Aff_Class.liftAff(monadAffAppM)(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Turing_Firebase_DocumentReference.get_(documentRef))))(function (result) {
              if (result instanceof Data_Either.Left) {
                  return Control_Applicative.pure(applicativeAppM)(Data_Either.Left.create(Turing_Effect_Error.showError(result.value0)));
              };
              if (result instanceof Data_Either.Right) {
                  return Control_Bind.bind(bindAppM)(Effect_Class.liftEffect(monadEffectAppM)(Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)(Turing_Firebase_DocumentSnapshot["_data"](result.value0)(Data_Maybe.Nothing.value))))(function (maybeDocumentSnapshotData) {
                      return Control_Applicative.pure(applicativeAppM)((function () {
                          if (maybeDocumentSnapshotData instanceof Data_Maybe.Nothing) {
                              return new Data_Either.Right(Data_Maybe.Nothing.value);
                          };
                          if (maybeDocumentSnapshotData instanceof Data_Maybe.Just) {
                              return Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Data_Show.show(Data_Argonaut_Decode_Error.showJsonDecodeError))(Data_Argonaut_Decode_Class.decodeJson(Data_Argonaut_Decode_Class.decodeJsonMaybe(Data_Argonaut_Decode_Class.decodeRecord(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Turing_Data_Spec.decodeJsonSpecGoal))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonInt))(Data_Argonaut_Decode_Class.gDecodeJsonCons(Data_Argonaut_Decode_Class.decodeFieldId(Data_Argonaut_Decode_Class.decodeJsonString))(Data_Argonaut_Decode_Class.gDecodeJsonNil)(new Data_Symbol.IsSymbol(function () {
                                  return "name";
                              }))()())(new Data_Symbol.IsSymbol(function () {
                                  return "maxNumberOfCards";
                              }))()())(new Data_Symbol.IsSymbol(function () {
                                  return "id";
                              }))()())(new Data_Symbol.IsSymbol(function () {
                                  return "goal";
                              }))()())()))(maybeDocumentSnapshotData.value0));
                          };
                          throw new Error("Failed pattern match at Turing.AppM (line 84, column 22 - line 88, column 40): " + [ maybeDocumentSnapshotData.constructor.name ]);
                      })());
                  });
              };
              throw new Error("Failed pattern match at Turing.AppM (line 79, column 9 - line 88, column 40): " + [ result.constructor.name ]);
          });
      });
  }, Effect_Aff_Class.liftAff(monadAffAppM)(Data_Functor.map(Effect_Aff.functorAff)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Turing_Effect_Error.showError))(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Turing_Firebase_Firestore.getSpecs))), function (spec) {
      return Effect_Aff_Class.liftAff(monadAffAppM)(Data_Functor.map(Effect_Aff.functorAff)(Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(Turing_Effect_Error.showError))(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
          var collectionRef = Turing_Firebase_Firestore.specsCollection();
          return Turing_Firebase_CollectionReference.doc(collectionRef)(spec.id)();
      }))(function (documentRef) {
          return Turing_Firebase_DocumentReference.set(documentRef)(Data_Argonaut_Encode_Class.encodeJson(Data_Argonaut_Encode_Class.encodeRecord(Data_Argonaut_Encode_Class.gEncodeJsonCons(Turing_Data_Spec.encodeJsonSpecGoal)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonInt)(Data_Argonaut_Encode_Class.gEncodeJsonCons(Data_Argonaut_Encode_Class.encodeJsonJString)(Data_Argonaut_Encode_Class.gEncodeJsonNil)(new Data_Symbol.IsSymbol(function () {
              return "name";
          }))())(new Data_Symbol.IsSymbol(function () {
              return "maxNumberOfCards";
          }))())(new Data_Symbol.IsSymbol(function () {
              return "id";
          }))())(new Data_Symbol.IsSymbol(function () {
              return "goal";
          }))())())(spec));
      }))));
  });
  exports["runAppM"] = runAppM;
  exports["monadEffectAppM"] = monadEffectAppM;
  exports["monadAffAppM"] = monadAffAppM;
  exports["navigateAppM"] = navigateAppM;
  exports["manageSpecAppM"] = manageSpecAppM;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Component.Html.Utility"] = $PS["Turing.Component.Html.Utility"] || {};
  var exports = $PS["Turing.Component.Html.Utility"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Semigroup = $PS["Data.Semigroup"];
  var Data_Show = $PS["Data.Show"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_HTML_Properties = $PS["Halogen.HTML.Properties"];
  var Network_RemoteData = $PS["Network.RemoteData"];
  var Routing_Duplex = $PS["Routing.Duplex"];
  var Turing_Data_Route = $PS["Turing.Data.Route"];                
  var whenFailure = function (v) {
      return function (v1) {
          if (v instanceof Network_RemoteData.Failure) {
              return v1(v.value0);
          };
          return Halogen_HTML_Core.text("");
      };
  };
  var safeHref = (function () {
      var $10 = Data_Semigroup.append(Data_Semigroup.semigroupString)("#");
      var $11 = Routing_Duplex.print(Turing_Data_Route.route);
      return function ($12) {
          return Halogen_HTML_Properties.href($10($11($12)));
      };
  })();
  var notFound = function (resourceType) {
      return function (id) {
          return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h1_([ Halogen_HTML_Core.text("404 - Not found") ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text("The " + (resourceType + (" " + (id + " could not be found.")))) ]) ]);
      };
  };
  var notAsked = Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h1_([ Halogen_HTML_Core.text("Resource not requested") ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text("\x0a                The resource that this page is supposed to display has not been asked for.\x0a                This is usually an error.\x0a                ") ]) ]);
  var loading = function (resourceType) {
      return function (id) {
          return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h1_([ Halogen_HTML_Core.text("Loading " + resourceType) ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text("The " + (resourceType + (" " + (Data_Show.show(Data_Show.showString)(id) + " is loading, and will be available shortly.")))) ]) ]);
      };
  };
  var failure = function (error) {
      return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h1_([ Halogen_HTML_Core.text("Error") ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text("There was an error performing the task at hand. See the error message for details.") ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text(error) ]) ]);
  };
  var remoteData = function (rd) {
      return function (resourceType) {
          return function (id) {
              return function (f) {
                  if (rd instanceof Network_RemoteData.NotAsked) {
                      return notAsked;
                  };
                  if (rd instanceof Network_RemoteData.Loading) {
                      return loading(resourceType)(id);
                  };
                  if (rd instanceof Network_RemoteData.Failure) {
                      return failure(rd.value0);
                  };
                  if (rd instanceof Network_RemoteData.Success && rd.value0 instanceof Data_Maybe.Nothing) {
                      return notFound(resourceType)(id);
                  };
                  if (rd instanceof Network_RemoteData.Success && rd.value0 instanceof Data_Maybe.Just) {
                      return f(rd.value0.value0);
                  };
                  throw new Error("Failed pattern match at Turing.Component.Html.Utility (line 57, column 5 - line 62, column 40): " + [ rd.constructor.name ]);
              };
          };
      };
  };
  exports["safeHref"] = safeHref;
  exports["whenFailure"] = whenFailure;
  exports["remoteData"] = remoteData;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Data.Campaign"] = $PS["Turing.Data.Campaign"] || {};
  var exports = $PS["Turing.Data.Campaign"];
  var Turing_Data_Spec = $PS["Turing.Data.Spec"];                
  var campaigns = [ {
      id: "jJe78fb",
      name: "Busy beavers",
      specs: [ {
          id: "U0481dB",
          name: "BB1",
          maxNumberOfCards: 1,
          goal: Turing_Data_Spec.BusyBeaver.value
      }, {
          id: "0Y1R2JQ",
          name: "BB2",
          maxNumberOfCards: 2,
          goal: Turing_Data_Spec.BusyBeaver.value
      }, {
          id: "TNntfDM",
          name: "BB3",
          maxNumberOfCards: 3,
          goal: Turing_Data_Spec.BusyBeaver.value
      }, {
          id: "n6CwGpA",
          name: "BB4",
          maxNumberOfCards: 4,
          goal: Turing_Data_Spec.BusyBeaver.value
      }, {
          id: "TOrmdjW",
          name: "BB5",
          maxNumberOfCards: 5,
          goal: Turing_Data_Spec.BusyBeaver.value
      } ]
  } ];
  exports["campaigns"] = campaigns;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Page.Campaign"] = $PS["Turing.Page.Campaign"] || {};
  var exports = $PS["Turing.Page.Campaign"];
  var Data_Array = $PS["Data.Array"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Network_RemoteData = $PS["Network.RemoteData"];
  var Turing_Component_Html_Utility = $PS["Turing.Component.Html.Utility"];
  var Turing_Data_Campaign = $PS["Turing.Data.Campaign"];
  var Turing_Data_Route = $PS["Turing.Data.Route"];                
  var component = (function () {
      var render = function (state) {
          var renderSpec = function (spec) {
              return Halogen_HTML_Elements.h2_([ Halogen_HTML_Elements.a([ Turing_Component_Html_Utility.safeHref(new Turing_Data_Route.Spec(spec.id)) ])([ Halogen_HTML_Core.text(spec.name) ]) ]);
          };
          if (state.campaign instanceof Network_RemoteData.NotAsked) {
              return Halogen_HTML_Core.text("Not asked");
          };
          if (state.campaign instanceof Network_RemoteData.Loading) {
              return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h1_([ Halogen_HTML_Core.text("Campaign") ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text("Loadign campaign") ]) ]);
          };
          if (state.campaign instanceof Network_RemoteData.Failure) {
              return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h1_([ Halogen_HTML_Core.text("Campaign") ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text(state.campaign.value0) ]) ]);
          };
          if (state.campaign instanceof Network_RemoteData.Success && state.campaign.value0 instanceof Data_Maybe.Nothing) {
              return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h1_([ Halogen_HTML_Core.text("Campaign - 404") ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text("Campaign " + (state.campaignId + " not found")) ]) ]);
          };
          if (state.campaign instanceof Network_RemoteData.Success && state.campaign.value0 instanceof Data_Maybe.Just) {
              return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h1_([ Halogen_HTML_Core.text(state.campaign.value0.value0.name) ]), Halogen_HTML_Elements.div_(Data_Functor.map(Data_Functor.functorArray)(renderSpec)(state.campaign.value0.value0.specs)) ]);
          };
          throw new Error("Failed pattern match at Turing.Page.Campaign (line 37, column 9 - line 58, column 22): " + [ state.campaign.constructor.name ]);
      };
      var initialState = function (campaignId) {
          return {
              campaignId: campaignId,
              campaign: Network_RemoteData.Success.create(Data_Array.find(function (campaign) {
                  return campaign.id === campaignId;
              })(Turing_Data_Campaign.campaigns))
          };
      };
      var $$eval = Halogen_Component.mkEval(Halogen_Component.defaultEval);
      return Halogen_Component.mkComponent({
          initialState: initialState,
          render: render,
          "eval": $$eval
      });
  })();
  exports["component"] = component;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Page.Home"] = $PS["Turing.Page.Home"] || {};
  var exports = $PS["Turing.Page.Home"];
  var Data_Function = $PS["Data.Function"];
  var Data_Unit = $PS["Data.Unit"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Turing_Component_Html_Utility = $PS["Turing.Component.Html.Utility"];
  var Turing_Data_Route = $PS["Turing.Data.Route"];                
  var component = (function () {
      var render = function (_state) {
          return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.header_([ Halogen_HTML_Elements.h1_([ Halogen_HTML_Core.text("Turing") ]) ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Elements.a([ Turing_Component_Html_Utility.safeHref(Turing_Data_Route.Play.value) ])([ Halogen_HTML_Core.text("Play") ]) ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Elements.a([ Turing_Component_Html_Utility.safeHref(Turing_Data_Route.Specs.value) ])([ Halogen_HTML_Core.text("Specs") ]) ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Elements.a([ Turing_Component_Html_Utility.safeHref(Turing_Data_Route.Settings.value) ])([ Halogen_HTML_Core.text("Settings") ]) ]) ]);
      };
      var initialState = Data_Function["const"](Data_Unit.unit);
      var $$eval = Halogen_Component.mkEval(Halogen_Component.defaultEval);
      return Halogen_Component.mkComponent({
          initialState: initialState,
          render: render,
          "eval": $$eval
      });
  })();
  exports["component"] = component;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Page.Play"] = $PS["Turing.Page.Play"] || {};
  var exports = $PS["Turing.Page.Play"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Unit = $PS["Data.Unit"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Turing_Component_Html_Utility = $PS["Turing.Component.Html.Utility"];
  var Turing_Data_Campaign = $PS["Turing.Data.Campaign"];
  var Turing_Data_Route = $PS["Turing.Data.Route"];                
  var component = (function () {
      var renderCampaign = function (campaign) {
          return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h3_([ Halogen_HTML_Elements.a([ Turing_Component_Html_Utility.safeHref(new Turing_Data_Route.Campaign(campaign.id)) ])([ Halogen_HTML_Core.text(campaign.name) ]) ]) ]);
      };
      var render = function (v) {
          return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h1_([ Halogen_HTML_Core.text("Play") ]), Halogen_HTML_Elements.h2_([ Halogen_HTML_Core.text("Campaigns") ]), Halogen_HTML_Elements.div_(Data_Functor.map(Data_Functor.functorArray)(renderCampaign)(Turing_Data_Campaign.campaigns)) ]);
      };
      var initialState = Data_Function["const"](Data_Unit.unit);
      var $$eval = Halogen_Component.mkEval(Halogen_Component.defaultEval);
      return Halogen_Component.mkComponent({
          initialState: initialState,
          render: render,
          "eval": $$eval
      });
  })();
  exports["component"] = component;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Page.Settings"] = $PS["Turing.Page.Settings"] || {};
  var exports = $PS["Turing.Page.Settings"];
  var Data_Function = $PS["Data.Function"];
  var Data_Unit = $PS["Data.Unit"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];                
  var component = (function () {
      var render = Data_Function["const"](Halogen_HTML_Elements.h1_([ Halogen_HTML_Core.text("Settings") ]));
      var initialState = Data_Function["const"](Data_Unit.unit);
      var $$eval = Halogen_Component.mkEval(Halogen_Component.defaultEval);
      return Halogen_Component.mkComponent({
          initialState: initialState,
          render: render,
          "eval": $$eval
      });
  })();
  exports["component"] = component;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Data.Program"] = $PS["Turing.Data.Program"] || {};
  var exports = $PS["Turing.Data.Program"];
  var Data_Map_Internal = $PS["Data.Map.Internal"];
  var Turing_Effect_Random = $PS["Turing.Effect.Random"];                
  var mkProgram = function (spec) {
      return function __do() {
          var id = Turing_Effect_Random.randomId();
          return {
              id: id,
              name: "My program",
              specId: spec.id,
              deck: Data_Map_Internal.empty
          };
      };
  };
  exports["mkProgram"] = mkProgram;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Page.Spec"] = $PS["Turing.Page.Spec"] || {};
  var exports = $PS["Turing.Page.Spec"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Data_Array = $PS["Data.Array"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Show = $PS["Data.Show"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect_Class = $PS["Effect.Class"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_HTML_Events = $PS["Halogen.HTML.Events"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Network_RemoteData = $PS["Network.RemoteData"];
  var Turing_Component_Html_Utility = $PS["Turing.Component.Html.Utility"];
  var Turing_Data_Campaign = $PS["Turing.Data.Campaign"];
  var Turing_Data_Program = $PS["Turing.Data.Program"];                
  var Initialize = (function () {
      function Initialize() {

      };
      Initialize.value = new Initialize();
      return Initialize;
  })();
  var ClickedCreateProgram = (function () {
      function ClickedCreateProgram() {

      };
      ClickedCreateProgram.value = new ClickedCreateProgram();
      return ClickedCreateProgram;
  })();
  var component = function (dictMonadEffect) {
      var render = function (state) {
          var renderSpec = function (spec) {
              return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h1_([ Halogen_HTML_Core.text(spec.name) ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text("\x0a                        Busy beavers try to light as many lightbulbs as possible before terminating.\x0a                        All lightbulbs on the tape are turned off.\x0a                        ") ]), Halogen_HTML_Elements.h3_([ Halogen_HTML_Core.text("Maximum number of cards") ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text(Data_Show.show(Data_Show.showInt)(spec.maxNumberOfCards)) ]), Halogen_HTML_Elements.h2_([ Halogen_HTML_Core.text("My programs") ]), Halogen_HTML_Elements.button([ Halogen_HTML_Events.onClick(Data_Function["const"](ClickedCreateProgram.value)) ])([ Halogen_HTML_Core.text("Create new program") ]) ]);
          };
          return Turing_Component_Html_Utility.remoteData(state.spec)("spec")(state.specId)(renderSpec);
      };
      var initialState = function (specId) {
          return {
              specId: specId,
              spec: Network_RemoteData.NotAsked.value
          };
      };
      var $$eval = (function () {
          var handleAction = function (v) {
              if (v instanceof Initialize) {
                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                      return v1.specId;
                  }))(function (specId) {
                      var v1 = Data_Array.find(function (spec) {
                          return spec.id === specId;
                      })(Control_Bind.join(Control_Bind.bindArray)(Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                          return v2.specs;
                      })(Turing_Data_Campaign.campaigns)));
                      if (v1 instanceof Data_Maybe.Just) {
                          return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v2) {
                              var $11 = {};
                              for (var $12 in v2) {
                                  if ({}.hasOwnProperty.call(v2, $12)) {
                                      $11[$12] = v2[$12];
                                  };
                              };
                              $11.spec = Network_RemoteData.Success.create(new Data_Maybe.Just(v1.value0));
                              return $11;
                          });
                      };
                      if (v1 instanceof Data_Maybe.Nothing) {
                          return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v2) {
                              var $15 = {};
                              for (var $16 in v2) {
                                  if ({}.hasOwnProperty.call(v2, $16)) {
                                      $15[$16] = v2[$16];
                                  };
                              };
                              $15.spec = new Network_RemoteData.Success(Data_Maybe.Nothing.value);
                              return $15;
                          });
                      };
                      throw new Error("Failed pattern match at Turing.Page.Spec (line 77, column 13 - line 79, column 66): " + [ v1.constructor.name ]);
                  });
              };
              if (v instanceof ClickedCreateProgram) {
                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                      return v1.spec;
                  }))(function (specRD) {
                      if (specRD instanceof Network_RemoteData.Success && specRD.value0 instanceof Data_Maybe.Just) {
                          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(dictMonadEffect))(Turing_Data_Program.mkProgram(specRD.value0.value0)))(function (program) {
                              return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                          });
                      };
                      return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
                  });
              };
              throw new Error("Failed pattern match at Turing.Page.Spec (line 74, column 9 - line 74, column 78): " + [ v.constructor.name ]);
          };
          return Halogen_Component.mkEval({
              handleAction: handleAction,
              handleQuery: Halogen_Component.defaultEval.handleQuery,
              receive: Halogen_Component.defaultEval.receive,
              initialize: new Data_Maybe.Just(Initialize.value),
              finalize: Halogen_Component.defaultEval.finalize
          });
      })();
      return Halogen_Component.mkComponent({
          initialState: initialState,
          render: render,
          "eval": $$eval
      });
  };
  exports["component"] = component;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Component.Form.Spec"] = $PS["Turing.Component.Form.Spec"] || {};
  var exports = $PS["Turing.Component.Form.Spec"];
  var DOM_HTML_Indexed_InputType = $PS["DOM.HTML.Indexed.InputType"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_Int = $PS["Data.Int"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Show = $PS["Data.Show"];
  var Data_String_Common = $PS["Data.String.Common"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Formless_Action = $PS["Formless.Action"];
  var Formless_Class_Initial = $PS["Formless.Class.Initial"];
  var Formless_Component = $PS["Formless.Component"];
  var Formless_Internal_Transform = $PS["Formless.Internal.Transform"];
  var Formless_Retrieve = $PS["Formless.Retrieve"];
  var Formless_Transform_Record = $PS["Formless.Transform.Record"];
  var Formless_Transform_Row = $PS["Formless.Transform.Row"];
  var Formless_Types_Form = $PS["Formless.Types.Form"];
  var Formless_Validation = $PS["Formless.Validation"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_HTML_Events = $PS["Halogen.HTML.Events"];
  var Halogen_HTML_Properties = $PS["Halogen.HTML.Properties"];
  var Heterogeneous_Mapping = $PS["Heterogeneous.Mapping"];
  var Turing_Data_Spec = $PS["Turing.Data.Spec"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var EmptyName = (function () {
      function EmptyName() {

      };
      EmptyName.value = new EmptyName();
      return EmptyName;
  })();
  var InvalidInt = (function () {
      function InvalidInt() {

      };
      InvalidInt.value = new InvalidInt();
      return InvalidInt;
  })();
  var TooLow = (function () {
      function TooLow() {

      };
      TooLow.value = new TooLow();
      return TooLow;
  })();
  var component = function (dictMonadEffect) {
      return function (dictMonadAff) {
          var render = function (v) {
              return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.p_([ Halogen_HTML_Elements.label_([ Halogen_HTML_Core.text("Name"), Halogen_HTML_Elements.input([ Halogen_HTML_Properties.value(Formless_Retrieve.getInput(new Data_Symbol.IsSymbol(function () {
                  return "name";
              }))()()(Type_Proxy["Proxy"].value)(v.form)), Halogen_HTML_Events.onValueInput(Formless_Action.setValidate(new Data_Symbol.IsSymbol(function () {
                  return "name";
              }))()()(Type_Proxy["Proxy"].value)) ]), Halogen_HTML_Core.text((function () {
                  var v1 = Formless_Retrieve.getError(new Data_Symbol.IsSymbol(function () {
                      return "name";
                  }))()()(Type_Proxy["Proxy"].value)(v.form);
                  if (v1 instanceof Data_Maybe.Just) {
                      return "Name cannot be empty";
                  };
                  if (v1 instanceof Data_Maybe.Nothing) {
                      return "";
                  };
                  throw new Error("Failed pattern match at Turing.Component.Form.Spec (line 86, column 25 - line 88, column 42): " + [ v1.constructor.name ]);
              })()) ]) ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Elements.label_([ Halogen_HTML_Core.text("Maximum number of cards"), Halogen_HTML_Elements.input([ Halogen_HTML_Properties.type_(Halogen_HTML_Core.isPropInputType)(DOM_HTML_Indexed_InputType.InputNumber.value), Halogen_HTML_Properties.min(1.0), Halogen_HTML_Properties.value(Formless_Retrieve.getInput(new Data_Symbol.IsSymbol(function () {
                  return "maxNumberOfCards";
              }))()()(Type_Proxy["Proxy"].value)(v.form)), Halogen_HTML_Events.onValueInput(Formless_Action.setValidate(new Data_Symbol.IsSymbol(function () {
                  return "maxNumberOfCards";
              }))()()(Type_Proxy["Proxy"].value)) ]), Halogen_HTML_Core.text((function () {
                  var v1 = Formless_Retrieve.getError(new Data_Symbol.IsSymbol(function () {
                      return "maxNumberOfCards";
                  }))()()(Type_Proxy["Proxy"].value)(v.form);
                  if (v1 instanceof Data_Maybe.Just && v1.value0 instanceof InvalidInt) {
                      return "Must be a valid integer";
                  };
                  if (v1 instanceof Data_Maybe.Just && v1.value0 instanceof TooLow) {
                      return "Must be greater than zero";
                  };
                  if (v1 instanceof Data_Maybe.Nothing) {
                      return "";
                  };
                  throw new Error("Failed pattern match at Turing.Component.Form.Spec (line 101, column 25 - line 104, column 42): " + [ v1.constructor.name ]);
              })()) ]) ]), Halogen_HTML_Elements.button([ Halogen_HTML_Events.onClick(Data_Function["const"](Formless_Action.submit)) ])([ Halogen_HTML_Core.text("Save spec") ]) ]);
          };
          var mkInput = function (spec) {
              return {
                  validators: {
                      id: Formless_Validation.hoistFn_((dictMonadAff.MonadEffect0()).Monad0())(Data_Function["const"](spec.id)),
                      name: Formless_Validation.hoistFnE_((dictMonadAff.MonadEffect0()).Monad0())(function (str) {
                          var v = Data_String_Common.trim(str);
                          if (v === "") {
                              return new Data_Either.Left(EmptyName.value);
                          };
                          return new Data_Either.Right(v);
                      }),
                      maxNumberOfCards: Formless_Validation.hoistFnE_((dictMonadAff.MonadEffect0()).Monad0())(function (str) {
                          var v = Data_Int.fromString(str);
                          if (v instanceof Data_Maybe.Nothing) {
                              return new Data_Either.Left(InvalidInt.value);
                          };
                          if (v instanceof Data_Maybe.Just) {
                              if (v.value0 < 1) {
                                  return new Data_Either.Left(TooLow.value);
                              };
                              if (Data_Boolean.otherwise) {
                                  return new Data_Either.Right(v.value0);
                              };
                          };
                          throw new Error("Failed pattern match at Turing.Component.Form.Spec (line 61, column 17 - line 65, column 47): " + [ v.constructor.name ]);
                      }),
                      goal: Formless_Validation.hoistFn_((dictMonadAff.MonadEffect0()).Monad0())(Data_Function["const"](Turing_Data_Spec.BusyBeaver.value))
                  },
                  initialInputs: Data_Maybe.Just.create(Formless_Transform_Record.wrapInputFields()(Heterogeneous_Mapping.hmapRecord()(Heterogeneous_Mapping.mapRecordWithIndexCons(new Data_Symbol.IsSymbol(function () {
                      return "goal";
                  }))(Heterogeneous_Mapping.constMapping(Formless_Transform_Record.wrapField()))(Heterogeneous_Mapping.mapRecordWithIndexCons(new Data_Symbol.IsSymbol(function () {
                      return "id";
                  }))(Heterogeneous_Mapping.constMapping(Formless_Transform_Record.wrapField()))(Heterogeneous_Mapping.mapRecordWithIndexCons(new Data_Symbol.IsSymbol(function () {
                      return "maxNumberOfCards";
                  }))(Heterogeneous_Mapping.constMapping(Formless_Transform_Record.wrapField()))(Heterogeneous_Mapping.mapRecordWithIndexCons(new Data_Symbol.IsSymbol(function () {
                      return "name";
                  }))(Heterogeneous_Mapping.constMapping(Formless_Transform_Record.wrapField()))(Heterogeneous_Mapping.mapRecordWithIndexNil)()())()())()())()()))({
                      id: spec.id,
                      name: spec.name,
                      maxNumberOfCards: Data_Show.show(Data_Show.showInt)(spec.maxNumberOfCards),
                      goal: ""
                  }))
              };
          };
          return Formless_Component.component(dictMonadAff)()()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()(new Data_Symbol.IsSymbol(function () {
              return "name";
          }))(Formless_Types_Form.eqInputField(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
              return "maxNumberOfCards";
          }))(Formless_Types_Form.eqInputField(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
              return "id";
          }))(Formless_Types_Form.eqInputField(Data_Eq.eqString)))()(new Data_Symbol.IsSymbol(function () {
              return "goal";
          }))(Formless_Types_Form.eqInputField(Data_Eq.eqString)))(Formless_Internal_Transform.inputFieldsToFormFieldsCons(new Data_Symbol.IsSymbol(function () {
              return "goal";
          }))()(Formless_Internal_Transform.inputFieldsToFormFieldsCons(new Data_Symbol.IsSymbol(function () {
              return "id";
          }))()(Formless_Internal_Transform.inputFieldsToFormFieldsCons(new Data_Symbol.IsSymbol(function () {
              return "maxNumberOfCards";
          }))()(Formless_Internal_Transform.inputFieldsToFormFieldsCons(new Data_Symbol.IsSymbol(function () {
              return "name";
          }))()(Formless_Internal_Transform.inputFieldsToFormFieldsNil)())())())())(Formless_Internal_Transform.inputFieldsToInputCons(new Data_Symbol.IsSymbol(function () {
              return "goal";
          }))()(Formless_Internal_Transform.inputFieldsToInputCons(new Data_Symbol.IsSymbol(function () {
              return "id";
          }))()(Formless_Internal_Transform.inputFieldsToInputCons(new Data_Symbol.IsSymbol(function () {
              return "maxNumberOfCards";
          }))()(Formless_Internal_Transform.inputFieldsToInputCons(new Data_Symbol.IsSymbol(function () {
              return "name";
          }))()(Formless_Internal_Transform.inputFieldsToInputNil)())())())())(Formless_Internal_Transform.consCountErrors(new Data_Symbol.IsSymbol(function () {
              return "goal";
          }))()(Formless_Internal_Transform.consCountErrors(new Data_Symbol.IsSymbol(function () {
              return "id";
          }))()(Formless_Internal_Transform.consCountErrors(new Data_Symbol.IsSymbol(function () {
              return "maxNumberOfCards";
          }))()(Formless_Internal_Transform.consCountErrors(new Data_Symbol.IsSymbol(function () {
              return "name";
          }))()(Formless_Internal_Transform.nilCountErrors)))))(Formless_Internal_Transform.consAllTouched(new Data_Symbol.IsSymbol(function () {
              return "goal";
          }))()(Formless_Internal_Transform.consAllTouched(new Data_Symbol.IsSymbol(function () {
              return "id";
          }))()(Formless_Internal_Transform.consAllTouched(new Data_Symbol.IsSymbol(function () {
              return "maxNumberOfCards";
          }))()(Formless_Internal_Transform.consAllTouched(new Data_Symbol.IsSymbol(function () {
              return "name";
          }))()(Formless_Internal_Transform.nilAllTouched)))))(Formless_Internal_Transform.setFormFieldsTouchedCons(new Data_Symbol.IsSymbol(function () {
              return "goal";
          }))()(Formless_Internal_Transform.setFormFieldsTouchedCons(new Data_Symbol.IsSymbol(function () {
              return "id";
          }))()(Formless_Internal_Transform.setFormFieldsTouchedCons(new Data_Symbol.IsSymbol(function () {
              return "maxNumberOfCards";
          }))()(Formless_Internal_Transform.setFormFieldsTouchedCons(new Data_Symbol.IsSymbol(function () {
              return "name";
          }))()(Formless_Internal_Transform.setFormFieldsTouchedNil)())())())())(Formless_Internal_Transform.replaceFormFieldInputsTouchedCons(new Data_Symbol.IsSymbol(function () {
              return "goal";
          }))()()()()()(Formless_Internal_Transform.replaceFormFieldInputsTouchedCons(new Data_Symbol.IsSymbol(function () {
              return "id";
          }))()()()()()(Formless_Internal_Transform.replaceFormFieldInputsTouchedCons(new Data_Symbol.IsSymbol(function () {
              return "maxNumberOfCards";
          }))()()()()()(Formless_Internal_Transform.replaceFormFieldInputsTouchedCons(new Data_Symbol.IsSymbol(function () {
              return "name";
          }))()()()()()(Formless_Internal_Transform.replaceFormFieldInputsTouchedNil)))))(Formless_Internal_Transform.modifyAllCons(new Data_Symbol.IsSymbol(function () {
              return "goal";
          }))()()()()()(Formless_Internal_Transform.modifyAllCons(new Data_Symbol.IsSymbol(function () {
              return "id";
          }))()()()()()(Formless_Internal_Transform.modifyAllCons(new Data_Symbol.IsSymbol(function () {
              return "maxNumberOfCards";
          }))()()()()()(Formless_Internal_Transform.modifyAllCons(new Data_Symbol.IsSymbol(function () {
              return "name";
          }))()()()()()(Formless_Internal_Transform.modifyAllNil)))))(Formless_Internal_Transform.applyToValidationCons(new Data_Symbol.IsSymbol(function () {
              return "goal";
          }))((dictMonadAff.MonadEffect0()).Monad0())()()()()(Formless_Internal_Transform.applyToValidationCons(new Data_Symbol.IsSymbol(function () {
              return "id";
          }))((dictMonadAff.MonadEffect0()).Monad0())()()()()(Formless_Internal_Transform.applyToValidationCons(new Data_Symbol.IsSymbol(function () {
              return "maxNumberOfCards";
          }))((dictMonadAff.MonadEffect0()).Monad0())()()()()(Formless_Internal_Transform.applyToValidationCons(new Data_Symbol.IsSymbol(function () {
              return "name";
          }))((dictMonadAff.MonadEffect0()).Monad0())()()()()(Formless_Internal_Transform.applyToValidationNil((dictMonadAff.MonadEffect0()).Monad0()))))))(Formless_Internal_Transform.formFieldsToMaybeOutputCons(new Data_Symbol.IsSymbol(function () {
              return "goal";
          }))()(Formless_Internal_Transform.formFieldsToMaybeOutputCons(new Data_Symbol.IsSymbol(function () {
              return "id";
          }))()(Formless_Internal_Transform.formFieldsToMaybeOutputCons(new Data_Symbol.IsSymbol(function () {
              return "maxNumberOfCards";
          }))()(Formless_Internal_Transform.formFieldsToMaybeOutputCons(new Data_Symbol.IsSymbol(function () {
              return "name";
          }))()(Formless_Internal_Transform.formFieldsToMaybeOutputNil)())())())())(Formless_Transform_Row.mkInputFieldsFromRowCons(new Data_Symbol.IsSymbol(function () {
              return "goal";
          }))(Formless_Class_Initial.initialString)()(Formless_Transform_Row.mkInputFieldsFromRowCons(new Data_Symbol.IsSymbol(function () {
              return "id";
          }))(Formless_Class_Initial.initialString)()(Formless_Transform_Row.mkInputFieldsFromRowCons(new Data_Symbol.IsSymbol(function () {
              return "maxNumberOfCards";
          }))(Formless_Class_Initial.initialString)()(Formless_Transform_Row.mkInputFieldsFromRowCons(new Data_Symbol.IsSymbol(function () {
              return "name";
          }))(Formless_Class_Initial.initialString)()(Formless_Transform_Row.mkInputFieldsFromRowNil)())())())())()()()()()()()()()()()()()()()()()(mkInput)({
              render: render,
              handleAction: Formless_Component.defaultSpec.handleAction,
              handleQuery: Formless_Component.defaultSpec.handleQuery,
              handleEvent: Formless_Component.raiseResult()(Heterogeneous_Mapping.hmapRecord()(Heterogeneous_Mapping.mapRecordWithIndexCons(new Data_Symbol.IsSymbol(function () {
                  return "goal";
              }))(Heterogeneous_Mapping.constMapping(Formless_Transform_Record.unwrapField()))(Heterogeneous_Mapping.mapRecordWithIndexCons(new Data_Symbol.IsSymbol(function () {
                  return "id";
              }))(Heterogeneous_Mapping.constMapping(Formless_Transform_Record.unwrapField()))(Heterogeneous_Mapping.mapRecordWithIndexCons(new Data_Symbol.IsSymbol(function () {
                  return "maxNumberOfCards";
              }))(Heterogeneous_Mapping.constMapping(Formless_Transform_Record.unwrapField()))(Heterogeneous_Mapping.mapRecordWithIndexCons(new Data_Symbol.IsSymbol(function () {
                  return "name";
              }))(Heterogeneous_Mapping.constMapping(Formless_Transform_Record.unwrapField()))(Heterogeneous_Mapping.mapRecordWithIndexNil)()())()())()())()())),
              receive: Formless_Component.defaultSpec.receive,
              initialize: Formless_Component.defaultSpec.initialize,
              finalize: Formless_Component.defaultSpec.finalize
          });
      };
  };
  exports["component"] = component;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Page.SpecEditor"] = $PS["Turing.Page.SpecEditor"] || {};
  var exports = $PS["Turing.Page.SpecEditor"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Unit = $PS["Data.Unit"];
  var Formless_Types_Component = $PS["Formless.Types.Component"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML = $PS["Halogen.HTML"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Network_RemoteData = $PS["Network.RemoteData"];
  var Turing_AppM = $PS["Turing.AppM"];
  var Turing_Capability_ManageSpec = $PS["Turing.Capability.ManageSpec"];
  var Turing_Component_Form_Spec = $PS["Turing.Component.Form.Spec"];                
  var Initialize = (function () {
      function Initialize() {

      };
      Initialize.value = new Initialize();
      return Initialize;
  })();
  var HandleSpecForm = (function () {
      function HandleSpecForm(value0) {
          this.value0 = value0;
      };
      HandleSpecForm.create = function (value0) {
          return new HandleSpecForm(value0);
      };
      return HandleSpecForm;
  })();
  var ClickedTestRun = (function () {
      function ClickedTestRun() {

      };
      ClickedTestRun.value = new ClickedTestRun();
      return ClickedTestRun;
  })();
  var component = (function () {
      var render = function (state) {
          return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h1_([ Halogen_HTML_Core.text("Spec " + state.specId) ]), (function () {
              if (state.spec instanceof Network_RemoteData.NotAsked) {
                  return Halogen_HTML_Core.text("Not asked");
              };
              if (state.spec instanceof Network_RemoteData.Loading) {
                  return Halogen_HTML_Core.text("Loading spec");
              };
              if (state.spec instanceof Network_RemoteData.Failure) {
                  return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h2_([ Halogen_HTML_Core.text("Error loading spec") ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text("Something went wrong when loading the spec.") ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text(state.spec.value0) ]) ]);
              };
              if (state.spec instanceof Network_RemoteData.Success && state.spec.value0 instanceof Data_Maybe.Nothing) {
                  return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h2_([ Halogen_HTML_Core.text("404 Not found") ]), Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text("Spec " + (state.specId + " does not exists.")) ]) ]);
              };
              if (state.spec instanceof Network_RemoteData.Success && state.spec.value0 instanceof Data_Maybe.Just) {
                  return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                      return "formless";
                  }))(Data_Ord.ordUnit)(Formless_Types_Component["_formless"])(Data_Unit.unit)(Turing_Component_Form_Spec.component(Turing_AppM.monadEffectAppM)(Turing_AppM.monadAffAppM))(state.spec.value0.value0)(HandleSpecForm.create);
              };
              throw new Error("Failed pattern match at Turing.Page.SpecEditor (line 47, column 15 - line 64, column 78): " + [ state.spec.constructor.name ]);
          })(), (function () {
              if (state.savingSpec instanceof Network_RemoteData.NotAsked) {
                  return Halogen_HTML_Core.text("");
              };
              if (state.savingSpec instanceof Network_RemoteData.Loading) {
                  return Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text("Saving spec") ]);
              };
              if (state.savingSpec instanceof Network_RemoteData.Failure) {
                  return Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text(state.savingSpec.value0) ]);
              };
              if (state.savingSpec instanceof Network_RemoteData.Success) {
                  return Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text("Spec saved \ud83d\udc4c") ]);
              };
              throw new Error("Failed pattern match at Turing.Page.SpecEditor (line 65, column 15 - line 69, column 62): " + [ state.savingSpec.constructor.name ]);
          })(), Halogen_HTML_Elements.button_([ Halogen_HTML_Core.text("Test run") ]) ]);
      };
      var initialState = function (specId) {
          return {
              specId: specId,
              spec: Network_RemoteData.NotAsked.value,
              savingSpec: Network_RemoteData.NotAsked.value
          };
      };
      var $$eval = (function () {
          var handleAction = function (v) {
              if (v instanceof Initialize) {
                  return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.gets(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                      return v1.specId;
                  }))(function (specId) {
                      return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                          var $16 = {};
                          for (var $17 in v1) {
                              if ({}.hasOwnProperty.call(v1, $17)) {
                                  $16[$17] = v1[$17];
                              };
                          };
                          $16.spec = Network_RemoteData.Loading.value;
                          return $16;
                      }))(function () {
                          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Turing_Capability_ManageSpec.getSpec(Turing_Capability_ManageSpec.manageSpecHalogenM(Turing_AppM.manageSpecAppM))(specId))(function (result) {
                              return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                                  var $19 = {};
                                  for (var $20 in v1) {
                                      if ({}.hasOwnProperty.call(v1, $20)) {
                                          $19[$20] = v1[$20];
                                      };
                                  };
                                  $19.spec = Network_RemoteData.fromEither(result);
                                  return $19;
                              });
                          });
                      });
                  });
              };
              if (v instanceof HandleSpecForm) {
                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                      var $22 = {};
                      for (var $23 in v1) {
                          if ({}.hasOwnProperty.call(v1, $23)) {
                              $22[$23] = v1[$23];
                          };
                      };
                      $22.savingSpec = Network_RemoteData.Loading.value;
                      return $22;
                  }))(function () {
                      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Turing_Capability_ManageSpec.saveSpec(Turing_Capability_ManageSpec.manageSpecHalogenM(Turing_AppM.manageSpecAppM))(v.value0))(function (result) {
                          return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                              var $25 = {};
                              for (var $26 in v1) {
                                  if ({}.hasOwnProperty.call(v1, $26)) {
                                      $25[$26] = v1[$26];
                                  };
                              };
                              $25.savingSpec = Network_RemoteData.fromEither(result);
                              return $25;
                          });
                      });
                  });
              };
              if (v instanceof ClickedTestRun) {
                  return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Unit.unit);
              };
              throw new Error("Failed pattern match at Turing.Page.SpecEditor (line 79, column 9 - line 79, column 81): " + [ v.constructor.name ]);
          };
          return Halogen_Component.mkEval({
              handleAction: handleAction,
              handleQuery: Halogen_Component.defaultEval.handleQuery,
              receive: Halogen_Component.defaultEval.receive,
              initialize: new Data_Maybe.Just(Initialize.value),
              finalize: Halogen_Component.defaultEval.finalize
          });
      })();
      return Halogen_Component.mkComponent({
          initialState: initialState,
          render: render,
          "eval": $$eval
      });
  })();
  exports["component"] = component;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Page.Specs"] = $PS["Turing.Page.Specs"] || {};
  var exports = $PS["Turing.Page.Specs"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Data_Array = $PS["Data.Array"];
  var Data_Either = $PS["Data.Either"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Effect_Class = $PS["Effect.Class"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_HTML_Elements = $PS["Halogen.HTML.Elements"];
  var Halogen_HTML_Events = $PS["Halogen.HTML.Events"];
  var Halogen_HTML_Properties = $PS["Halogen.HTML.Properties"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Network_RemoteData = $PS["Network.RemoteData"];
  var Turing_AppM = $PS["Turing.AppM"];
  var Turing_Capability_ManageSpec = $PS["Turing.Capability.ManageSpec"];
  var Turing_Capability_Navigate = $PS["Turing.Capability.Navigate"];
  var Turing_Component_Html_Utility = $PS["Turing.Component.Html.Utility"];
  var Turing_Data_Route = $PS["Turing.Data.Route"];
  var Turing_Data_Spec = $PS["Turing.Data.Spec"];                
  var Initialize = (function () {
      function Initialize() {

      };
      Initialize.value = new Initialize();
      return Initialize;
  })();
  var ClickedNewSpec = (function () {
      function ClickedNewSpec() {

      };
      ClickedNewSpec.value = new ClickedNewSpec();
      return ClickedNewSpec;
  })();
  var component = (function () {
      var render = function (state) {
          var renderSpecs = function (specsRD) {
              return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h2_([ Halogen_HTML_Core.text("Specs") ]), (function () {
                  if (specsRD instanceof Network_RemoteData.NotAsked) {
                      return Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text("Not asked") ]);
                  };
                  if (specsRD instanceof Network_RemoteData.Loading) {
                      return Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text("Loading specs") ]);
                  };
                  if (specsRD instanceof Network_RemoteData.Failure) {
                      return Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text(specsRD.value0) ]);
                  };
                  if (specsRD instanceof Network_RemoteData.Success) {
                      var renderSpec = function (spec) {
                          return Halogen_HTML_Elements.li_(Data_Array.singleton(Halogen_HTML_Elements.a([ Turing_Component_Html_Utility.safeHref(new Turing_Data_Route.SpecEditor(spec.id)) ])([ Halogen_HTML_Core.text(spec.name) ])));
                      };
                      return Halogen_HTML_Elements.ul_(Data_Functor.map(Data_Functor.functorArray)(renderSpec)(specsRD.value0));
                  };
                  throw new Error("Failed pattern match at Turing.Page.Specs (line 70, column 19 - line 79, column 54): " + [ specsRD.constructor.name ]);
              })() ]);
          };
          return Halogen_HTML_Elements.div_([ Halogen_HTML_Elements.h1_([ Halogen_HTML_Core.text("Specs") ]), Halogen_HTML_Elements.button([ Halogen_HTML_Events.onClick(Data_Function["const"](ClickedNewSpec.value)), Halogen_HTML_Properties.disabled(Network_RemoteData.isLoading(state.newSpecCreation)) ])([ Halogen_HTML_Core.text("New spec") ]), Turing_Component_Html_Utility.whenFailure(state.newSpecCreation)(Data_Function["const"](Halogen_HTML_Elements.p_([ Halogen_HTML_Core.text("Something went wrong when creating the spec. Check the logs for details.") ]))), renderSpecs(state.specs) ]);
      };
      var initialState = function (_input) {
          return {
              newSpecCreation: Network_RemoteData.NotAsked.value,
              specs: Network_RemoteData.NotAsked.value
          };
      };
      var $$eval = (function () {
          var handleAction = function (v) {
              if (v instanceof Initialize) {
                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                      var $10 = {};
                      for (var $11 in v1) {
                          if ({}.hasOwnProperty.call(v1, $11)) {
                              $10[$11] = v1[$11];
                          };
                      };
                      $10.specs = Network_RemoteData.Loading.value;
                      return $10;
                  }))(function () {
                      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Turing_Capability_ManageSpec.getSpecs(Turing_Capability_ManageSpec.manageSpecHalogenM(Turing_AppM.manageSpecAppM)))(function (result) {
                          return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                              var $13 = {};
                              for (var $14 in v1) {
                                  if ({}.hasOwnProperty.call(v1, $14)) {
                                      $13[$14] = v1[$14];
                                  };
                              };
                              $13.specs = Network_RemoteData.fromEither(result);
                              return $13;
                          });
                      });
                  });
              };
              if (v instanceof ClickedNewSpec) {
                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                      var $16 = {};
                      for (var $17 in v1) {
                          if ({}.hasOwnProperty.call(v1, $17)) {
                              $16[$17] = v1[$17];
                          };
                      };
                      $16.newSpecCreation = Network_RemoteData.Loading.value;
                      return $16;
                  }))(function () {
                      return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(Turing_AppM.monadEffectAppM))(Turing_Data_Spec.mkSpec))(function (spec) {
                          return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Turing_Capability_ManageSpec.saveSpec(Turing_Capability_ManageSpec.manageSpecHalogenM(Turing_AppM.manageSpecAppM))(spec))(function (result) {
                              if (result instanceof Data_Either.Left) {
                                  return Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                                      var $20 = {};
                                      for (var $21 in v1) {
                                          if ({}.hasOwnProperty.call(v1, $21)) {
                                              $20[$21] = v1[$21];
                                          };
                                      };
                                      $20.newSpecCreation = new Network_RemoteData.Failure(result.value0);
                                      return $20;
                                  });
                              };
                              if (result instanceof Data_Either.Right) {
                                  return Turing_Capability_Navigate.navigate(Turing_Capability_Navigate.navigateHalogenM(Turing_AppM.navigateAppM))(new Turing_Data_Route.SpecEditor(spec.id));
                              };
                              throw new Error("Failed pattern match at Turing.Page.Specs (line 97, column 13 - line 99, column 57): " + [ result.constructor.name ]);
                          });
                      });
                  });
              };
              throw new Error("Failed pattern match at Turing.Page.Specs (line 88, column 9 - line 88, column 81): " + [ v.constructor.name ]);
          };
          return Halogen_Component.mkEval({
              handleAction: handleAction,
              handleQuery: Halogen_Component.defaultEval.handleQuery,
              receive: Halogen_Component.defaultEval.receive,
              initialize: new Data_Maybe.Just(Initialize.value),
              finalize: Halogen_Component.defaultEval.finalize
          });
      })();
      return Halogen_Component.mkComponent({
          initialState: initialState,
          render: render,
          "eval": $$eval
      });
  })();
  exports["component"] = component;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Component.Router"] = $PS["Turing.Component.Router"] || {};
  var exports = $PS["Turing.Component.Router"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_State_Class = $PS["Control.Monad.State.Class"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Function = $PS["Data.Function"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Ord = $PS["Data.Ord"];
  var Data_Symbol = $PS["Data.Symbol"];
  var Data_Unit = $PS["Data.Unit"];
  var Data_Void = $PS["Data.Void"];
  var Effect = $PS["Effect"];
  var Effect_Class = $PS["Effect.Class"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_HTML = $PS["Halogen.HTML"];
  var Halogen_HTML_Core = $PS["Halogen.HTML.Core"];
  var Halogen_Query_HalogenM = $PS["Halogen.Query.HalogenM"];
  var Routing_Duplex = $PS["Routing.Duplex"];
  var Routing_Hash = $PS["Routing.Hash"];
  var Turing_AppM = $PS["Turing.AppM"];
  var Turing_Capability_Navigate = $PS["Turing.Capability.Navigate"];
  var Turing_Data_Route = $PS["Turing.Data.Route"];
  var Turing_Page_Campaign = $PS["Turing.Page.Campaign"];
  var Turing_Page_Home = $PS["Turing.Page.Home"];
  var Turing_Page_Play = $PS["Turing.Page.Play"];
  var Turing_Page_Settings = $PS["Turing.Page.Settings"];
  var Turing_Page_Spec = $PS["Turing.Page.Spec"];
  var Turing_Page_SpecEditor = $PS["Turing.Page.SpecEditor"];
  var Turing_Page_Specs = $PS["Turing.Page.Specs"];
  var Type_Proxy = $PS["Type.Proxy"];                
  var Navigate = (function () {
      function Navigate(value0, value1) {
          this.value0 = value0;
          this.value1 = value1;
      };
      Navigate.create = function (value0) {
          return function (value1) {
              return new Navigate(value0, value1);
          };
      };
      return Navigate;
  })();
  var Initialize = (function () {
      function Initialize() {

      };
      Initialize.value = new Initialize();
      return Initialize;
  })();
  var component = (function () {
      var render = function (state) {
          if (state.route instanceof Data_Maybe.Just && state.route.value0 instanceof Turing_Data_Route.Home) {
              return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                  return "home";
              }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Turing_Page_Home.component)(Data_Unit.unit)(Data_Void.absurd);
          };
          if (state.route instanceof Data_Maybe.Just && state.route.value0 instanceof Turing_Data_Route.Specs) {
              return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                  return "specs";
              }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Turing_Page_Specs.component)(Data_Unit.unit)(Data_Void.absurd);
          };
          if (state.route instanceof Data_Maybe.Just && state.route.value0 instanceof Turing_Data_Route.Play) {
              return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                  return "play";
              }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Turing_Page_Play.component)(Data_Unit.unit)(Data_Void.absurd);
          };
          if (state.route instanceof Data_Maybe.Just && state.route.value0 instanceof Turing_Data_Route.Settings) {
              return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                  return "settings";
              }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Turing_Page_Settings.component)(Data_Unit.unit)(Data_Void.absurd);
          };
          if (state.route instanceof Data_Maybe.Just && state.route.value0 instanceof Turing_Data_Route.SpecEditor) {
              return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                  return "specEditor";
              }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Turing_Page_SpecEditor.component)(state.route.value0.value0)(Data_Void.absurd);
          };
          if (state.route instanceof Data_Maybe.Just && state.route.value0 instanceof Turing_Data_Route.Spec) {
              return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                  return "spec";
              }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Turing_Page_Spec.component(Turing_AppM.monadEffectAppM))(state.route.value0.value0)(Data_Void.absurd);
          };
          if (state.route instanceof Data_Maybe.Just && state.route.value0 instanceof Turing_Data_Route.Campaign) {
              return Halogen_HTML.slot()(new Data_Symbol.IsSymbol(function () {
                  return "campaign";
              }))(Data_Ord.ordUnit)(Type_Proxy["Proxy"].value)(Data_Unit.unit)(Turing_Page_Campaign.component)(state.route.value0.value0)(Data_Void.absurd);
          };
          if (state.route instanceof Data_Maybe.Nothing) {
              return Halogen_HTML_Core.text("404 Not found");
          };
          throw new Error("Failed pattern match at Turing.Component.Router (line 55, column 9 - line 63, column 47): " + [ state.route.constructor.name ]);
      };
      var initialState = Data_Function["const"]({
          route: Data_Maybe.Nothing.value
      });
      var $$eval = (function () {
          var initialize = new Data_Maybe.Just(Initialize.value);
          var handleQuery = function (query) {
              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Control_Monad_State_Class.get(Halogen_Query_HalogenM.monadStateHalogenM))(function (v) {
                  return Control_Bind.discard(Control_Bind.discardUnit)(Halogen_Query_HalogenM.bindHalogenM)(Control_Applicative.when(Halogen_Query_HalogenM.applicativeHalogenM)(Data_Eq.notEq(Data_Maybe.eqMaybe(Turing_Data_Route.eqRoute))(v.route)(new Data_Maybe.Just(query.value0)))(Control_Monad_State_Class.modify_(Halogen_Query_HalogenM.monadStateHalogenM)(function (v1) {
                      var $16 = {};
                      for (var $17 in v1) {
                          if ({}.hasOwnProperty.call(v1, $17)) {
                              $16[$17] = v1[$17];
                          };
                      };
                      $16.route = new Data_Maybe.Just(query.value0);
                      return $16;
                  })))(function () {
                      return Control_Applicative.pure(Halogen_Query_HalogenM.applicativeHalogenM)(new Data_Maybe.Just(query.value1));
                  });
              });
          };
          var handleAction = function (action) {
              return Control_Bind.bind(Halogen_Query_HalogenM.bindHalogenM)(Effect_Class.liftEffect(Halogen_Query_HalogenM.monadEffectHalogenM(Turing_AppM.monadEffectAppM))(Data_Functor.map(Effect.functorEffect)((function () {
                  var $23 = Routing_Duplex.parse(Turing_Data_Route.route);
                  return function ($24) {
                      return Data_Either.hush($23($24));
                  };
              })())(Routing_Hash.getHash)))(function (initialRoute) {
                  return Turing_Capability_Navigate.navigate(Turing_Capability_Navigate.navigateHalogenM(Turing_AppM.navigateAppM))(Data_Maybe.fromMaybe(Turing_Data_Route.Home.value)(initialRoute));
              });
          };
          return Halogen_Component.mkEval({
              handleAction: handleAction,
              handleQuery: handleQuery,
              receive: Halogen_Component.defaultEval.receive,
              initialize: initialize,
              finalize: Halogen_Component.defaultEval.finalize
          });
      })();
      return Halogen_Component.mkComponent({
          initialState: initialState,
          render: render,
          "eval": $$eval
      });
  })();
  exports["Navigate"] = Navigate;
  exports["component"] = component;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Data.Env"] = $PS["Turing.Data.Env"] || {};
  var exports = $PS["Turing.Data.Env"];
  var Dev = (function () {
      function Dev() {

      };
      Dev.value = new Dev();
      return Dev;
  })();
  exports["Dev"] = Dev;
})(PS);
(function(exports) {
  "use strict";

  exports.authImpl = function () {
      return firebase.auth();
  };

  exports.onAuthStateChangedImpl = function (auth) {
      return function (nextOrObserver) {
          return function () {
              auth.onAuthStateChanged(function (user) {
                  nextOrObserver(user)();
              });
          };
      };
  };
})(PS["Turing.Firebase.Auth"] = PS["Turing.Firebase.Auth"] || {});
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Effect.Foreign"] = $PS["Turing.Effect.Foreign"] || {};
  var exports = $PS["Turing.Effect.Foreign"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Foreign = $PS["Foreign"];                
  var readNullable = function (dictMonad) {
      return function (read) {
          return function (foreignValue) {
              var $1 = Foreign.isNull(foreignValue);
              if ($1) {
                  return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(dictMonad))(Data_Maybe.Nothing.value);
              };
              return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(dictMonad))(read(foreignValue))(function (value) {
                  return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(dictMonad))(new Data_Maybe.Just(value));
              });
          };
      };
  };
  exports["readNullable"] = readNullable;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Turing.Firebase.Auth"] = $PS["Turing.Firebase.Auth"] || {};
  var exports = $PS["Turing.Firebase.Auth"];
  var $foreign = $PS["Turing.Firebase.Auth"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Control_Monad_Except = $PS["Control.Monad.Except"];
  var Control_Monad_Except_Trans = $PS["Control.Monad.Except.Trans"];
  var Data_Boolean = $PS["Data.Boolean"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_Identity = $PS["Data.Identity"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Foreign = $PS["Foreign"];
  var Foreign_Index = $PS["Foreign.Index"];
  var Turing_Effect_Foreign = $PS["Turing.Effect.Foreign"];                
  var readUser = function (value) {
      return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Foreign_Index.ix(Foreign_Index.indexableForeign(Data_Identity.monadIdentity))(Foreign_Index.indexString(Data_Identity.monadIdentity))(value)("uid"))(Foreign.readString(Data_Identity.monadIdentity)))(function (uid) {
          return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Foreign_Index.ix(Foreign_Index.indexableForeign(Data_Identity.monadIdentity))(Foreign_Index.indexString(Data_Identity.monadIdentity))(value)("displayName"))(Turing_Effect_Foreign.readNullable(Data_Identity.monadIdentity)(Foreign.readString(Data_Identity.monadIdentity))))(function (displayName) {
              return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Foreign_Index.ix(Foreign_Index.indexableForeign(Data_Identity.monadIdentity))(Foreign_Index.indexString(Data_Identity.monadIdentity))(value)("isAnonymous"))(Foreign.readBoolean(Data_Identity.monadIdentity)))(function (isAnonymous) {
                  return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))({
                      uid: uid,
                      displayName: displayName,
                      isAnonymous: isAnonymous
                  });
              });
          });
      });
  };
  var readMaybeUser = function (value) {
      if (Foreign.isNull(value)) {
          return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Maybe.Nothing.value);
      };
      if (Data_Boolean.otherwise) {
          return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Maybe.Just.create)(readUser(value));
      };
      throw new Error("Failed pattern match at Turing.Firebase.Auth (line 35, column 1 - line 35, column 43): " + [ value.constructor.name ]);
  };
  var onAuthStateChanged = function (callback) {
      var callback$prime = function (foreignValue) {
          return callback(Control_Monad_Except.runExcept(readMaybeUser(foreignValue)));
      };
      return function __do() {
          var auth = $foreign.authImpl();
          return $foreign.onAuthStateChangedImpl(auth)(callback$prime)();
      };
  };
  exports["onAuthStateChanged"] = onAuthStateChanged;
})(PS);
(function($PS) {
  // Generated by purs version 0.14.1
  "use strict";
  $PS["Main"] = $PS["Main"] || {};
  var exports = $PS["Main"];
  var Control_Applicative = $PS["Control.Applicative"];
  var Control_Bind = $PS["Control.Bind"];
  var Data_Either = $PS["Data.Either"];
  var Data_Eq = $PS["Data.Eq"];
  var Data_Foldable = $PS["Data.Foldable"];
  var Data_Functor = $PS["Data.Functor"];
  var Data_List_Types = $PS["Data.List.Types"];
  var Data_Maybe = $PS["Data.Maybe"];
  var Data_Unit = $PS["Data.Unit"];
  var Effect = $PS["Effect"];
  var Effect_Aff = $PS["Effect.Aff"];
  var Effect_Class = $PS["Effect.Class"];
  var Effect_Console = $PS["Effect.Console"];
  var Effect_Ref = $PS["Effect.Ref"];
  var Foreign = $PS["Foreign"];
  var Halogen_Aff_Util = $PS["Halogen.Aff.Util"];
  var Halogen_Component = $PS["Halogen.Component"];
  var Halogen_Query = $PS["Halogen.Query"];
  var Halogen_VDom_Driver = $PS["Halogen.VDom.Driver"];
  var Network_RemoteData = $PS["Network.RemoteData"];
  var Routing_Duplex = $PS["Routing.Duplex"];
  var Routing_Hash = $PS["Routing.Hash"];
  var Turing_AppM = $PS["Turing.AppM"];
  var Turing_Component_Router = $PS["Turing.Component.Router"];
  var Turing_Data_Env = $PS["Turing.Data.Env"];
  var Turing_Data_Route = $PS["Turing.Data.Route"];
  var Turing_Firebase_Auth = $PS["Turing.Firebase.Auth"];                
  var main = Halogen_Aff_Util.runHalogenAff(Control_Bind.bind(Effect_Aff.bindAff)(Halogen_Aff_Util.awaitBody)(function (body) {
      return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
          var ref = Effect_Ref["new"](Network_RemoteData.Loading.value)();
          Data_Functor["void"](Effect.functorEffect)(Turing_Firebase_Auth.onAuthStateChanged(function (v) {
              if (v instanceof Data_Either.Left) {
                  return function __do() {
                      Effect_Console.errorShow(Data_List_Types.showNonEmptyList(Foreign.showForeignError))(v.value0)();
                      return Effect_Ref.write(new Network_RemoteData.Failure(v.value0))(ref)();
                  };
              };
              if (v instanceof Data_Either.Right) {
                  return Effect_Ref.write(new Network_RemoteData.Success(v.value0))(ref);
              };
              throw new Error("Failed pattern match at Main (line 31, column 37 - line 37, column 50): " + [ v.constructor.name ]);
          }))();
          return ref;
      }))(function (userRef) {
          var environment = {
              logLevel: Turing_Data_Env.Dev.value,
              userRef: userRef
          };
          var rootComponent = Halogen_Component.hoist(Effect_Aff.functorAff)(Turing_AppM.runAppM(environment))(Turing_Component_Router.component);
          return Control_Bind.bind(Effect_Aff.bindAff)(Halogen_VDom_Driver.runUI(rootComponent)(Data_Unit.unit)(body))(function (halogenIO) {
              return Data_Functor["void"](Effect_Aff.functorAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Routing_Hash.matchesWith(Data_Foldable.foldableEither)(Routing_Duplex.parse(Turing_Data_Route.route))(function (old) {
                  return function ($$new) {
                      return Data_Functor["void"](Effect.functorEffect)(Control_Applicative.when(Effect.applicativeEffect)(Data_Eq.notEq(Data_Maybe.eqMaybe(Turing_Data_Route.eqRoute))(old)(new Data_Maybe.Just($$new)))(Effect_Aff.launchAff_(halogenIO.query(Halogen_Query.mkTell(Turing_Component_Router.Navigate.create($$new))))));
                  };
              })));
          });
      });
  }));
  exports["main"] = main;
})(PS);
PS["Main"].main();